import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TextField,
  Stack,
  Typography,
  Checkbox,
  Alert,
} from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { phone } from 'phone';

import { CountryCodes } from '../../../data/countryCodes';
import { styleProps } from '../../../styles/styleProps';
import LongPrimaryBtn from '../../buttons/LongPrimaryBtn';
import { invalidStyles } from '../../../styles/textFieldErrorStyles';
import OtpEmailVerification from './OtpEmailVerification';
import EmployeeNavigationModal from './EmployeeNavigationModal';
import SecondaryEmailConfirmationModal from '../../alerts/SecondaryEmailConfirmationModal';
import PlatformIntegrationService from '../../../services/AbstractPlatformIntegrationService';
import UrlRoute from '../../../configs/UrlRoute';
import { adminUpdate } from '../../../services/AdminService';

const { fonts, fontSizes, colors } = styleProps;

const RegistrationForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isEmptyBasicFields, setIsEmptyBasicFields] = useState(true);
  const [isEmptyBasicDetailsFields, setIsEmptyBasicDetailsFields] =
    useState(false);
  const countryCodes = CountryCodes.countries;
  const [employeePasswordView, setEmployeePasswordView] = useState(false);
  const [isEmptyBasicAddresFields, setIsEmptyBasicAddresFields] =
    useState(false);
  const [isEmailTPRequested, setEmailOTPRequested] = useState(false);
  const [employeeView, setEmployeeView] = useState(true);
  const [adminView, setAdminView] = useState(true);
  const [employeeRegistrationSuccessful, setEmployeeRegistrationSuccessful] =
    useState(false);
  const [enableEmailWarning, setEnableEmailWarning] = useState(false);
  const [secondaryEmailStatus, setSecondaryEmailStatus] = useState(false);
  const [isPasswordChecked, setIsPasswordChecked] = useState(true);
  const [isMobileNumberInvalid, setIsMobileNumberInvalid] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isNICInvalid, setIsNICInvalid] = useState(false);
  const [isAddaressFieldModel, setIsAddaressFieldModel] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [activeForm, setActiveForm] = useState('Firstform');

  //validation
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [invalidEmployeeName, setInvalidEmployeeName] = useState(false);

  //Password checklist
  const [isCheckListEnabled, setIsCheckListEnabled] = useState(false);
  const [atLeastMinimumCharactors, setAtLeastMinimumCharactors] =
    useState(false);
  const [
    atLeastOneUpperAndLowerCaseLatter,
    setAtLeastOneUpperAndLowerCaseLatter,
  ] = useState(false);
  const [atLeastOneNumberAndLetter, setAtLeastOneNumberAndLetter] =
    useState(false);
  const [atLeastOneSpecialCharactor, setAtLeastOneSpecialCharactor] =
    useState(false);
  const [adminName, setAdminName] = useState('');

  const [selectedMobileCountryName, setSelectedMobileCountryName] =
    useState('LK');

  //get type from params
  const params = useParams();
  const formType = params.type;
  //Need to get these values from params
  const email = params.email;

  useEffect(() => {
    if (formType === 'admin') {
      try {
        const body = {
          email,
        };

        axios.post('/api/user/password/jwt', body).then((response) => {
          if (response.data.success === true) {
            //set the temporary auth in the localStorage
            localStorage.setItem(
              'tempAuth',
              response.headers.get('access-token'),
            );

            PlatformIntegrationService.executeHttpGetRequest(
              UrlRoute.GET_ADMIN,
            ).then((data) => {
              setAdminName(data.adminName);
            });
          } else {
            window.location = '/error';
          }
        });
      } catch (err) {
        console.error(err.message);
      }
    }
  }, []);

  const initialValues = {
    admin: {
      name: adminName,
      code: '+94',
      number: '',
      password: '',
      confirmPassword: '',
    },
    employee: {
      basicDetails: {
        firstName: '',
        lastName: '',
        code: '+94',
        secondaryEmail: '',
        number: '',
        nicNumber: '',
        dateOfBirth: '',
      },
      addressDetails: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      credentials: {
        password: '',
        confirmPassword: '',
      },
    },
  };

  const validationSchema = Yup.object({
    admin: Yup.object().shape({
      name: Yup.string(),
      code: Yup.string(),
      number: Yup.number().positive(),
      password: Yup.string(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords do not match',
      ),
    }),

    employee: Yup.object().shape({
      basicDetails: Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        code: Yup.string(),
        secondaryEmail: Yup.string().email('Invalid Email'),
        number: Yup.number().positive(),
        nicNumber: Yup.string(),
      }),
      addressDetails: Yup.object().shape({
        addressLine1: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        postalCode: Yup.string(),
        country: Yup.string(),
      }),
      credentials: Yup.object().shape({
        password: Yup.string(),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Password do not match',
        ),
      }),
    }),
  });

  const onSubmit = () => {
    if (formType === 'admin') {
      const name = formik.values.admin.name;
      const mobile = formik.values.admin.code + formik.values.admin.number;
      const password = formik.values.admin.password;

      let countryCodeValue = countryCodes.filter(
        (field) => field.code === formik.values.admin.code,
      );
      let country = countryCodeValue[0].name;

      const body = {
        name,
        email,
        mobile,
        country,
        password,
        verified: true,
      };

      adminUpdate(body).then(() => {
        setAdminView(false);
        completeMobileVerification();
      });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  useEffect(() => {
    getTempAuth();
  }, []);

  const getTempAuth = () => {
    try {
      const body = {
        email,
      };

      axios.post('/api/user/password/jwt', body).then((response) => {
        if (response.data.success === true) {
          //set the temporary auth in the localStorage
          localStorage.setItem(
            'tempAuth',
            response.headers.get('access-token'),
          );
        } else {
          window.location = '/error';
        }
      });
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  const passwordCheckListStatus = () => {
    if (
      atLeastMinimumCharactors === true &&
      atLeastOneUpperAndLowerCaseLatter === true &&
      atLeastOneNumberAndLetter === true &&
      atLeastOneSpecialCharactor === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  //password validation
  useEffect(() => {
    //Enable/diable checklist
    if (
      formik.values.admin.password.length >= 1 ||
      formik.values.employee.credentials.password.length >= 1
    ) {
      setIsCheckListEnabled(true);
    } else {
      setIsCheckListEnabled(false);
    }

    //Password length validation
    if (
      formik.values.admin.password.length > 7 ||
      formik.values.employee.credentials.password.length > 7
    ) {
      setAtLeastMinimumCharactors(true);
    } else {
      setAtLeastMinimumCharactors(false);
    }

    //Uppercase and Lowercase validation
    if (
      (/[A-Z]/.test(formik.values.admin.password) &&
        /[a-z]/.test(formik.values.admin.password)) ||
      (/[A-Z]/.test(formik.values.employee.credentials.password) &&
        /[a-z]/.test(formik.values.employee.credentials.password))
    ) {
      setAtLeastOneUpperAndLowerCaseLatter(true);
    } else {
      setAtLeastOneUpperAndLowerCaseLatter(false);
    }

    //Number and Letter validation
    if (
      (/\d/.test(formik.values.admin.password) &&
        /[a-zA-Z]/.test(formik.values.admin.password)) ||
      (/\d/.test(formik.values.employee.credentials.password) &&
        /[a-zA-Z]/.test(formik.values.employee.credentials.password))
    ) {
      setAtLeastOneNumberAndLetter(true);
    } else {
      setAtLeastOneNumberAndLetter(false);
    }

    //Special charactor validation
    if (
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(
        formik.values.admin.password,
      ) ||
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(
        formik.values.employee.credentials.password,
      )
    ) {
      setAtLeastOneSpecialCharactor(true);
    } else {
      setAtLeastOneSpecialCharactor(false);
    }
  }, [
    formik.values.admin.password,
    formik.values.employee.credentials.password,
  ]);

  //Handle password visibility
  const handlePasswordVisibility = () => {
    setIsPasswordVisible((prevVal) => !prevVal);
  };

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (key === 'addressLine2') {
        continue;
      }
      if (obj[key] === null || obj[key] === '') {
        console.log(key);
        return true;
      }
    }
    return false;
  };

  //email Vlaidation
  const validateEmail = (email) => {
    var mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailFormat)) {
      setIsEmailInvalid(false);
    } else {
      setIsEmailInvalid(true);
    }
  };

  //NIC Number validation
  const validateNIC = (nicNumber) => {
    const nicFormat = /^\d{9}[vVxX]$|^\d{12}$/;
    if (nicNumber.match(nicFormat)) {
      setIsNICInvalid(false);
    } else {
      setIsNICInvalid(true);
    }
  };

  // Mobile number validation
  const verifyTheNumber = (code, number) => {
    if (number.substring(0, 1) === '0') {
      setIsMobileNumberInvalid(true);
    } else {
      const mobile = code + number;
      if (number && code) {
        phone(mobile).isValid
          ? setIsMobileNumberInvalid(false)
          : setIsMobileNumberInvalid(true);
      } else {
        setIsMobileNumberInvalid(true);
      }
    }
  };

  //Only number input function
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  const sendOtp = (mode) => {
    try {
      const body = {
        email,
        mode,
        country: selectedMobileCountryName,
        mobile:
          formik.values.employee.basicDetails.code +
          formik.values.employee.basicDetails.number,
      };

      axios
        .post('/api/otp/send', body, {
          headers: {
            Authorization: `"${localStorage.getItem('tempAuth')}"`,
          },
        })
        .then(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log(error);
            window.location = '/error';
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  //functions related to admin registration --------------------------
  useEffect(() => {
    let res = checkInputFields(formik.values.admin);
    let status = passwordCheckListStatus();
    if (res === false) {
      if (
        status === true &&
        isMobileNumberInvalid === false &&
        checked === true
      ) {
        setIsEmptyBasicFields(false);
      } else {
        setIsEmptyBasicFields(true);
      }
    } else {
      setIsEmptyBasicFields(true);
    }
  }, [formik.values.admin]);

  const navigateToLogin = () => {
    localStorage.removeItem('tempAuth');
    window.location = '/login';
  };

  const handleChangeAdmin = (event) => {
    setChecked(event.target.checked);
    let res = checkInputFields(formik.values.admin);
    if (res === false) {
      if (isMobileNumberInvalid === false && event.target.checked === true) {
        setIsEmptyBasicFields(false);
      } else {
        setIsEmptyBasicFields(true);
      }
    } else {
      setIsEmptyBasicFields(true);
    }
  };

  //functions related to employee registration -------------------------

  useEffect(() => {
    let res = checkInputFields(formik.values.employee.basicDetails);
    if (res) {
      setIsEmptyBasicDetailsFields(true);
    } else {
      setIsEmptyBasicDetailsFields(false);
    }
  }, [formik.values.employee.basicDetails]);

  useEffect(() => {
    let res = checkInputFields(formik.values.employee.addressDetails);
    if (res) {
      setIsEmptyBasicAddresFields(true);
    } else {
      setIsEmptyBasicAddresFields(false);
    }
  }, [formik.values.employee.addressDetails]);

  useEffect(() => {
    let res = checkInputFields(formik.values.employee.credentials);
    let status = passwordCheckListStatus();
    if (res === false) {
      if (status === true) {
        setIsPasswordChecked(false);
      } else {
        setIsPasswordChecked(true);
      }
    }
  }, [formik.values.employee.credentials]);

  useEffect(() => {
    if (formik.values.employee.basicDetails.secondaryEmail.length != 0) {
      setEnableEmailWarning(true);
    } else {
      setEnableEmailWarning(false);
    }
  }, [formik.values.employee.basicDetails.secondaryEmail]);

  const secondaryEmailConfirmation = () => {
    setSecondaryEmailStatus(false);
    setEmployeeView(false);
    setEmployeePasswordView(true);
  };

  const secondaryEmailReject = () => {
    setSecondaryEmailStatus(false);
  };

  const registrationSuccessful = () => {
    setEmailOTPRequested(false);
    setEmployeeRegistrationSuccessful(true);
  };

  const submitBasicDetails = () => {
    setEmployeePasswordView(false);
    setActiveForm('secnodForm');
    setIsAddaressFieldModel(true);
  };

  const submitAddresDetails = () => {
    setEmployeePasswordView(true);
    basicDeatisFrom(false);
    setEmployeeView(false);
  };

  const registerEmployee = () => {
    if (
      formik.values.employee.credentials.password ===
      formik.values.employee.credentials.confirmPassword
    ) {
      const firstName = formik.values.employee.basicDetails.firstName;
      const lastName = formik.values.employee.basicDetails.lastName;
      const secondaryEmail = formik.values.employee.basicDetails.secondaryEmail;
      const mobile =
        formik.values.employee.basicDetails.code +
        formik.values.employee.basicDetails.number;
      const nic = formik.values.employee.basicDetails.nicNumber;
      const dateOfBirth = formik.values.employee.basicDetails.dateOfBirth;
      const addressOne = formik.values.employee.addressDetails.addressLine1;
      const addressTwo = formik.values.employee.addressDetails.addressLine2;
      const city = formik.values.employee.addressDetails.city;
      const state = formik.values.employee.addressDetails.state;
      const postal = formik.values.employee.addressDetails.postalCode;
      const empCountry = formik.values.employee.addressDetails.country;
      const password = formik.values.employee.credentials.password;

      let countryCodeValue = countryCodes.filter(
        (field) => field.code === formik.values.employee.basicDetails.code,
      );

      let country = countryCodeValue[0].name;

      const body = {
        firstName,
        lastName,
        mobile,
        country,
        password,
        secondaryEmail,
        nic,
        addressOne,
        addressTwo,
        city,
        state,
        postal,
        empCountry,
        dateOfBirth,
      };

      axios
        .put('/api/employees/register', body, {
          headers: {
            Authorization: `"${localStorage.getItem('tempAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              setEmployeePasswordView(false);
              // setMobileOTPRequested(true);
              // sendOtp(1);
              completeMobileVerification();
            }
          },
          (error) => {
            console.log(error);
            window.location = '/error';
          },
        );
    } else {
      setIsPasswordInvalid(true);
    }
  };

  const handleChangeEmployee = (event) => {
    setChecked(event.target.checked);
    let res = checkInputFields(formik.values.employee.basicDetails);
    if (res === false) {
      if (isMobileNumberInvalid === false && event.target.checked === true) {
        setIsEmptyBasicFields(false);
      } else {
        setIsEmptyBasicFields(true);
      }
    } else {
      setIsEmptyBasicFields(true);
    }
  };

  // display error message
  const displayAlert = (isError, errorMessage) => {
    if (isError) {
      return (
        <Box sx={{ my: 1 }}>
          <Alert severity='error'>{errorMessage}</Alert>
        </Box>
      );
    }
  };

  // Employee registration Forms
  const basicDeatisFrom = () => {
    return (
      <InputContainer>
        <TextInput
          name='employee.basicDetails.firstName'
          placeholder='First Name*'
          value={formik.values.employee.basicDetails.firstName}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
            setInvalidEmployeeName(false);
          }}
          onBlur={() =>
            !formik.values.employee.basicDetails.firstName &&
            setInvalidEmployeeName(true)
          }
        />

        <TextInput
          name='employee.basicDetails.lastName'
          placeholder='Last Name*'
          value={formik.values.employee.basicDetails.lastName}
          sx={{ marginTop: 2 }}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
            setInvalidEmployeeName(false);
          }}
          onBlur={() =>
            !formik.values.employee.basicDetails.lastName &&
            setInvalidEmployeeName(true)
          }
        />

        {displayAlert(invalidEmployeeName, 'Invalid Name')}

        <TextInput
          disabled={true}
          placeholder={email}
          sx={{ marginTop: 2 }}
          InputLabelProps={{ style: { fontSize: 14 } }}
        />

        <TextInput
          label='Secondary Email *'
          name='employee.basicDetails.secondaryEmail'
          sx={invalidStyles(
            formik.errors?.employee?.basicDetails.secondaryEmail ? false : true,
          )}
          InputLabelProps={{ style: { fontSize: 14 } }}
          onBlur={() => {
            validateEmail(formik.values.employee.basicDetails.secondaryEmail);
          }}
          onChange={(e) => {
            formik.handleChange(e);
            setIsEmailInvalid(false);
          }}
        />
        {displayAlert(isEmailInvalid, 'Invalid Email')}

        {enableEmailWarning && (
          <Alert
            sx={{ color: '#71717A', borderColor: 'white' }}
            variant='outlined'
            severity='info'
          >
            If you have an existing account use the same secondary email to link
            the old account.
          </Alert>
        )}

        <Box sx={{ width: '100%' }}>
          <CountryCodeSelect
            name='employee.basicDetails.code'
            defaultValue={formik.values.employee.basicDetails.code}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: 250,
                  height: 250,
                },
              },
            }}
            renderValue={(value) => value}
            onChange={(e) => {
              formik.handleChange(e);
              setIsMobileNumberInvalid(false);
            }}
            onBlur={() =>
              verifyTheNumber(
                formik.values.employee.basicDetails.code,
                formik.values.employee.basicDetails.number,
              )
            }
          >
            {countryCodes.map((item, index) => (
              <MenuItem
                key={index}
                value={item.code}
                onClick={() => setSelectedMobileCountryName(item.name)}
              >
                {item.name.length > 15
                  ? item.name.slice(0, 15) + `... (${item.code})`
                  : item.name + ` (${item.code})`}
              </MenuItem>
            ))}
          </CountryCodeSelect>

          <MobileNumberInput
            placeholder='Mobile number'
            type='tel'
            value={formik.values.employee.basicDetails.number}
            name='employee.basicDetails.number'
            onInput={(e) => onlyNumbers(e)}
            onChange={(e) => {
              formik.handleChange(e);
              setIsMobileNumberInvalid(false);
            }}
            onBlur={() =>
              verifyTheNumber(
                formik.values.employee.basicDetails.code,
                formik.values.employee.basicDetails.number,
              )
            }
          />
        </Box>
        {displayAlert(isMobileNumberInvalid, 'Incorrect mobile number')}

        <TextInput
          sx={{ my: 2 }}
          name='employee.basicDetails.nicNumber'
          placeholder='NIC Number*'
          value={formik.values.employee.basicDetails.nicNumber}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
            setIsNICInvalid(false);
          }}
          onBlur={() =>
            validateNIC(formik.values.employee.basicDetails.nicNumber)
          }
        />
        {displayAlert(isNICInvalid, 'Incorrect NIC number')}

        <TextInput
          sx={{ my: 2 }}
          name='employee.basicDetails.dateOfBirth'
          placeholder='Date of Birth*'
          type='date'
          value={formik.values.employee.basicDetails.dateOfBirth}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />

        <LongPrimaryBtn
          text='Next'
          disabled={
            isEmptyBasicDetailsFields ||
            isMobileNumberInvalid ||
            isEmailInvalid ||
            isNICInvalid
          }
          onClick={submitBasicDetails}
        />
      </InputContainer>
    );
  };

  const addressDetailsFrom = () => {
    return (
      <InputContainer>
        <TextInput
          sx={{ marginTop: 2 }}
          name='employee.addressDetails.addressLine1'
          placeholder='Address Line 01 *'
          value={formik.values.employee.addressDetails.addressLine1}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />

        <TextInput
          sx={{ marginTop: 2 }}
          name='employee.addressDetails.addressLine2'
          placeholder='Address Line 02'
          value={formik.values.employee.addressDetails.addressLine2}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />

        <TextInput
          sx={{ marginTop: 2 }}
          name='employee.addressDetails.city'
          placeholder='City*'
          value={formik.values.employee.addressDetails.city}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />

        <TextInput
          sx={{ marginTop: 2 }}
          name='employee.addressDetails.state'
          placeholder='State/Province/Region *'
          value={formik.values.employee.addressDetails.state}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />

        <TextInput
          sx={{ marginTop: 2 }}
          name='employee.addressDetails.postalCode'
          placeholder='ZIP/Postal Code *'
          value={formik.values.employee.addressDetails.postalCode}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />

        <TextInput
          sx={{ marginTop: 2 }}
          name='employee.addressDetails.country'
          placeholder='Country *'
          value={formik.values.employee.addressDetails.country}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />

        <div style={{ textAlign: 'center' }}>
          <Typography sx={checkBoxStyle}>
            <Checkbox
              checked={checked}
              onChange={handleChangeEmployee}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            I agree to the,{' '}
            <a
              href='https://www.nimikash.com/privacyPolicy'
              target='_blank'
              rel='noreferrer'
              style={{
                ...checkBoxStyle,
              }}
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href='https://www.nimikash.com/termsAndConditions'
              target='_blank'
              rel='noreferrer'
              style={{
                ...checkBoxStyle,
              }}
            >
              Terms & Conditions
            </a>
          </Typography>
        </div>
        <LongPrimaryBtn
          text='Next'
          disabled={isEmptyBasicAddresFields || !checked}
          onClick={submitAddresDetails}
        />
      </InputContainer>
    );
  };

  const setPasswordForm = () => {
    return (
      <>
        <TitleContainer sx={{ marginTop: 5 }}>
          <Title>Set Password</Title>
        </TitleContainer>

        <InputContainer>
          <TextInput
            label='Password'
            name='employee.credentials.password'
            type={isPasswordVisible ? 'text' : 'password'}
            value={formik.values.employee.credentials.password}
            onChange={formik.handleChange}
            sx={{ marginTop: '15px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' onClick={handlePasswordVisibility}>
                    {isPasswordVisible ? (
                      <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                    ) : (
                      <VisibilityOutlined sx={{ fontSize: 18 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />

          <TextInput
            label='Confirm password'
            name='employee.credentials.confirmPassword'
            type={isPasswordVisible ? 'text' : 'password'}
            value={formik.values.employee.credentials.confirmPassword}
            onChange={(e) => {
              formik.handleChange(e);
              setIsPasswordInvalid(false);
              formik.errors?.employee?.credentials.confirmPassword &&
                delete formik.errors.employee.credentials.confirmPassword;
            }}
            sx={invalidStyles(
              formik.errors?.employee?.credentials.confirmPassword
                ? false
                : true,
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' onClick={handlePasswordVisibility}>
                    {isPasswordVisible ? (
                      <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                    ) : (
                      <VisibilityOutlined sx={{ fontSize: 18 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />

          {isPasswordInvalid && (
            <>
              <br />
              <Alert severity='error'>Password do not match</Alert>
            </>
          )}
          {isCheckListEnabled === true ? (
            <Box m={2}>
              {atLeastMinimumCharactors === false ? (
                <Stack direction='row' spacing={2} sx={{ color: '#EA5455' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>At least 8 characters</Typography>
                </Stack>
              ) : (
                <Stack direction='row' spacing={2} sx={{ color: '#00909E' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>At least 8 characters</Typography>
                </Stack>
              )}
              {atLeastOneUpperAndLowerCaseLatter === false ? (
                <Stack direction='row' spacing={2} sx={{ color: '#EA5455' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>
                    A mixture of both uppercase and lowercase letters
                  </Typography>
                </Stack>
              ) : (
                <Stack direction='row' spacing={2} sx={{ color: '#00909E' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>
                    A mixture of both uppercase and lowercase letters
                  </Typography>
                </Stack>
              )}
              {atLeastOneNumberAndLetter === false ? (
                <Stack direction='row' spacing={2} sx={{ color: '#EA5455' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>A mixture of letters and numbers</Typography>
                </Stack>
              ) : (
                <Stack direction='row' spacing={2} sx={{ color: '#00909E' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>A mixture of letters and numbers</Typography>
                </Stack>
              )}
              {atLeastOneSpecialCharactor === false ? (
                <Stack direction='row' spacing={2} sx={{ color: '#EA5455' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>
                    Inclusion of at least one special character, e.g., ! @ # ?
                  </Typography>
                </Stack>
              ) : (
                <Stack direction='row' spacing={2} sx={{ color: '#00909E' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>
                    Inclusion of at least one special character, e.g., ! @ # ?
                  </Typography>
                </Stack>
              )}{' '}
            </Box>
          ) : null}
          <LongPrimaryBtn
            text='Set Password'
            disabled={isPasswordChecked}
            onClick={registerEmployee}
          />
        </InputContainer>
      </>
    );
  };

  const completeMobileVerification = () => {
    setEmailOTPRequested(true);
    sendOtp(0);
  };

  return (
    <div style={containerStyles}>
      <SecondaryEmailConfirmationModal
        modalStatus={secondaryEmailStatus}
        confirm={secondaryEmailConfirmation}
        reject={secondaryEmailReject}
      />
      {formType === 'admin' ? (
        <>
          {isEmailTPRequested === true ? (
            <>
              <TitleContainer>
                <Title>Verify Account</Title>
              </TitleContainer>

              <InputContainerBox>
                <OtpEmailVerification
                  email={email}
                  type={formType}
                  emailVerified={navigateToLogin}
                />
              </InputContainerBox>
            </>
          ) : adminView === true ? (
            <>
              <TitleContainer>
                <Title>Admin Registration</Title>
                <SubTitle>Add your details to join to your company</SubTitle>
              </TitleContainer>
              <InputContainer>
                <TextInput
                  label='Name'
                  name='admin.name'
                  value={formik.values.admin.name}
                  InputLabelProps={{
                    style: { fontSize: 14 },
                  }}
                  onChange={formik.handleChange}
                />

                <TextInput
                  disabled={true}
                  placeholder={email}
                  name='admin.email'
                  sx={{ marginTop: 2 }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />

                <Box sx={{ width: '100%' }}>
                  <CountryCodeSelect
                    name='admin.code'
                    defaultValue={formik.values.admin.code}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: 250,
                          height: 250,
                        },
                      },
                    }}
                    renderValue={(value) => value}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsMobileNumberInvalid(false);
                    }}
                    onBlur={() =>
                      verifyTheNumber(
                        formik.values.admin.code,
                        formik.values.admin.number,
                      )
                    }
                  >
                    {countryCodes.map((item, index) => (
                      <MenuItem key={index} value={item.code}>
                        {item.name.length > 15
                          ? item.name.slice(0, 15) + `... (${item.code})`
                          : item.name + ` (${item.code})`}
                      </MenuItem>
                    ))}
                  </CountryCodeSelect>

                  <MobileNumberInput
                    placeholder='Mobile number'
                    type='tel'
                    value={formik.values.admin.number}
                    name='admin.number'
                    onInput={(e) => onlyNumbers(e)}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsMobileNumberInvalid(false);
                    }}
                    onBlur={() =>
                      verifyTheNumber(
                        (formik.values.admin.code, formik.values.admin.number),
                      )
                    }
                  />
                </Box>
                {isMobileNumberInvalid === true ? (
                  <>
                    <br />
                    <Alert severity='error'>Incorrect mobile number</Alert>
                  </>
                ) : null}
                <TextInput
                  label='Password *'
                  name='admin.password'
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={formik.values.admin.password}
                  onChange={formik.handleChange}
                  sx={{ marginTop: '15px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onClick={handlePasswordVisibility}
                        >
                          {isPasswordVisible ? (
                            <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                          ) : (
                            <VisibilityOutlined sx={{ fontSize: 18 }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />

                <TextInput
                  label='Confirm password *'
                  name='admin.confirmPassword'
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={formik.values.admin.confirmPassword}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.errors?.admin?.confirmPassword &&
                      delete formik.errors.admin.confirmPassword;
                  }}
                  sx={invalidStyles(
                    formik.errors?.admin?.confirmPassword ? false : true,
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onClick={handlePasswordVisibility}
                        >
                          {isPasswordVisible ? (
                            <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                          ) : (
                            <VisibilityOutlined sx={{ fontSize: 18 }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
                {formik.errors?.admin?.confirmPassword && (
                  <>
                    <br />
                    <Alert severity='error'>
                      {formik.errors.admin.confirmPassword}
                    </Alert>
                  </>
                )}
                {isCheckListEnabled === true ? (
                  <Box m={2}>
                    {atLeastMinimumCharactors === false ? (
                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{ color: '#EA5455' }}
                      >
                        <div style={buttonBorderStyle}>
                          <div style={buttonStyle}>
                            <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                          </div>
                        </div>
                        <Typography>At least 8 characters</Typography>
                      </Stack>
                    ) : (
                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{ color: '#00909E' }}
                      >
                        <div style={buttonBorderStyle}>
                          <div style={buttonStyle}>
                            <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                          </div>
                        </div>
                        <Typography>At least 8 characters</Typography>
                      </Stack>
                    )}
                    {atLeastOneUpperAndLowerCaseLatter === false ? (
                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{ color: '#EA5455' }}
                      >
                        <div style={buttonBorderStyle}>
                          <div style={buttonStyle}>
                            <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                          </div>
                        </div>
                        <Typography>
                          A mixture of both uppercase and lowercase letters
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{ color: '#00909E' }}
                      >
                        <div style={buttonBorderStyle}>
                          <div style={buttonStyle}>
                            <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                          </div>
                        </div>
                        <Typography>
                          A mixture of both uppercase and lowercase letters
                        </Typography>
                      </Stack>
                    )}
                    {atLeastOneNumberAndLetter === false ? (
                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{ color: '#EA5455' }}
                      >
                        <div style={buttonBorderStyle}>
                          <div style={buttonStyle}>
                            <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                          </div>
                        </div>
                        <Typography>
                          A mixture of letters and numbers
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{ color: '#00909E' }}
                      >
                        <div style={buttonBorderStyle}>
                          <div style={buttonStyle}>
                            <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                          </div>
                        </div>
                        <Typography>
                          A mixture of letters and numbers
                        </Typography>
                      </Stack>
                    )}
                    {atLeastOneSpecialCharactor === false ? (
                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{ color: '#EA5455' }}
                      >
                        <div style={buttonBorderStyle}>
                          <div style={buttonStyle}>
                            <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                          </div>
                        </div>
                        <Typography>
                          Inclusion of at least one special character, e.g., ! @
                          # ?
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack
                        direction='row'
                        spacing={2}
                        sx={{ color: '#00909E' }}
                      >
                        <div style={buttonBorderStyle}>
                          <div style={buttonStyle}>
                            <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                          </div>
                        </div>
                        <Typography>
                          Inclusion of at least one special character, e.g., ! @
                          # ?
                        </Typography>
                      </Stack>
                    )}{' '}
                  </Box>
                ) : null}
                <div style={{ textAlign: 'center' }}>
                  <Typography sx={checkBoxStyle}>
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeAdmin}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    I agree to the,{' '}
                    <a
                      href='https://www.nimikash.com/privacyPolicy'
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        ...checkBoxStyle,
                      }}
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href='https://www.nimikash.com/termsAndConditions'
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        ...checkBoxStyle,
                      }}
                    >
                      Terms & Conditions
                    </a>
                  </Typography>
                </div>
                <LongPrimaryBtn
                  text='Next'
                  disabled={isEmptyBasicFields}
                  onClick={formik.handleSubmit}
                />
              </InputContainer>
            </>
          ) : null}
        </>
      ) : formType === 'employee' ? (
        <>
          {employeePasswordView === true ? (
            setPasswordForm()
          ) : isEmailTPRequested === true ? (
            <>
              <TitleContainer>
                <Title>Verify Account</Title>
              </TitleContainer>

              <InputContainerBox>
                <OtpEmailVerification
                  email={email}
                  type={formType}
                  emailVerified={registrationSuccessful}
                />
              </InputContainerBox>
            </>
          ) : employeeView === true ? (
            <>
              <TitleContainer sx={{ marginTop: 2 }}>
                <Title>Employee Registration</Title>
                <SubTitle>Add your details to join to your company</SubTitle>
              </TitleContainer>
              {isAddaressFieldModel ? addressDetailsFrom() : basicDeatisFrom()}
            </>
          ) : employeeRegistrationSuccessful === true ? (
            <EmployeeNavigationModal />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const buttonBorderStyle = {
  border: '1px solid #FF7528',
  width: '15px',
  height: '15px',
  borderRadius: 100,
  marginTop: '3px',
};

const buttonStyle = {
  height: '5px',
  marginTop: '5px',
  marginLeft: '1.17px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
};

const containerStyles = {
  width: '100%',
  minHeight: '500px',
  borderRadius: '10px',
  background: 'white',
  padding: '20px 0px',
};

const TitleContainer = styled(Box)({
  width: '100%',
  height: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '28px',
  fontWeight: 500,
  color: colors.textDarkGrey,
});

const SubTitle = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: fontSizes.normal,
  fontWeight: 400,
  color: colors.textLightGrey,
  textAlign: 'center',
});

const InputContainer = styled(Box)({
  boxSizing: 'border-box',
  padding: '8% 12% 2% 12%',
  width: '100%',
  height: '80%',
});

const TextInput = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const InputContainerBox = styled(Box)({
  boxSizing: 'border-box',
  padding: '8% 12% 2% 12%',
  width: '100%',
  height: '80%',
});

const MobileNumberInput = styled(TextField)({
  width: '75%',
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '0 4px 4px 0',
    },
    '& input::placeholder': {
      fontSize: fontSizes.normal,
    },
  },
});

const CountryCodeSelect = styled(Select)({
  width: '25%',
  height: 50,
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '4px 0 0 4px',
    },
  },
  '& fieldset': {
    borderRadius: '4px 0 0 4px',
  },
});

const checkBoxStyle = {
  color: colors.textLightGrey,
  fontSize: '15px',
  fontFamily: fonts.primary,
  padding: '2% 0 4% 0',
};

export default RegistrationForm;
