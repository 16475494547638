/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Button,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import { useEffect, useState } from 'react';
import { getByEmployeeDisplayId } from '../../services/PaysubServices';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import format from 'date-fns/format';
import { lastDayOfMonth, parse } from 'date-fns';
import PdfFormat from '../paystubs/pdfFormat';
import { PDFDownloadLink } from '@react-pdf/renderer';
const { fonts, colors, fontSizes } = styleProps;

function Paystubs({ employeeId }) {
  const [paystubs, setPaystubs] = useState([]);

  const getPaystubs = () => {
    getByEmployeeDisplayId(employeeId).then((res) => {
      setPaystubs(res);
    });
  };

  const payPeriodGetter = (month, year) => {
    const date = parse(`01-${month + 1}-${year}`, 'dd-MM-yyyy', new Date());
    const startDate = format(date, 'dd MMM yyyy');
    const endDate = format(lastDayOfMonth(date), 'dd MMM yyyy');
    return `${startDate} - ${endDate}`;
  };

  const payDateGetter = (month, year) => {
    const date = parse(`01-${month + 1}-${year}`, 'dd-MM-yyyy', new Date());
    const payDate = format(date, '25 MMM yyyy');
    return `${payDate}`;
  };

  const viewButtonGetter = (data) => {
    const month = data.month;
    const year = data.year;
    return (
      <a href={`/employeePaystubs/${employeeId}?month=${month}&year=${year}`}>
        <Button>
          <Icon sx={roundedIcon}>
            <VisibilityOutlined />
          </Icon>
        </Button>
      </a>
    );
  };

  const downloadButtonGetter = (data) => {
    const payPeriods = payPeriodGetter(data.month, data.year).split(' - ');
    return (
      <>
        <PDFDownloadLink
          fileName={`${data.employeeFirstName}.pdf`}
          document={
            <PdfFormat
              data={data}
              PayDate={payPeriods[1]}
              previousMonthPayDate={payPeriods[0]}
            />
          }
        >
          <Button>
            <Icon sx={roundedIcon}>
              <FileDownloadOutlinedIcon />
            </Icon>
          </Button>
        </PDFDownloadLink>
      </>
    );
  };

  useEffect(() => {
    getPaystubs();
  }, []);

  const columns = ['Paystub ID', 'Pay Period', 'Pay Date', 'Currency'];

  return (
    <Box>
      <Table sx={tableStyles.container}>
        <TableHead sx={[tableStyles.header, { borderRadius: '1rem' }]}>
          <TableRow>
            {columns.map((item) => (
              <TableCell sx={tableStyles.headerCell}>{item}</TableCell>
            ))}
            <TableCell sx={[tableStyles.headerCell, tableStyles.actionCell]}>
              Download
            </TableCell>
            <TableCell sx={[tableStyles.headerCell, tableStyles.actionCell]}>
              View
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paystubs.map((item) => {
            return (
              <TableRow>
                <TableCell sx={tableStyles.bodyCell}>{item.id}</TableCell>
                <TableCell sx={tableStyles.bodyCell}>
                  {payPeriodGetter(item.month, item.year)}
                </TableCell>
                <TableCell sx={tableStyles.bodyCell}>
                  {payDateGetter(item.month, item.year)}
                </TableCell>
                <TableCell sx={tableStyles.bodyCell}>
                  {item.employeeCurrencyCode}
                </TableCell>
                <TableCell>{downloadButtonGetter(item)}</TableCell>
                <TableCell>{viewButtonGetter(item)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}

export default Paystubs;

const tableStyles = {
  container: {
    marginTop: 5,
  },

  header: {
    backgroundColor: colors.backgroundLow,
    borderRadius: 10,
    '&.MuiTableRow-root': {
      borderRadius: 10,
    },
  },

  headerCell: {
    fontFamily: fonts.primary,
    fontSize: fontSizes.normal,
    border: 'none',
  },

  actionCell: {
    width: '5rem',
  },

  bodyCell: {
    fontFamily: fonts.primary,
    color: colors.textLightGrey,
    paddingX: 3,
  },
};

const roundedIcon = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #FF7528',
  borderColor: colors.btnPrimary,
  width: 40,
  height: 40,
  borderRadius: 100,
};
