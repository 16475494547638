import { React, useState } from 'react';
import { Box, Stack, styled } from '@mui/system';
import { Typography } from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import EditIcon from '@mui/icons-material/Edit';
import UpdateProject from './modal/updateProject';
import useFetchEmployeeByStatus from '../../hooks/useFetchEmployeeByStatus';

const ProjectCard = (project) => {
  const [show, setShow] = useState(false);
  const [projectData, setProjectData] = useState({
    projectName: project.projectName,
    description: project.description,
    projectMembers: project.employees,
    displayId: project.displayId,
    projectId: project.id,
  });
  const employees = useFetchEmployeeByStatus();

  const handleEdit = () => {
    setShow(true);
  };

  const updateHandler = (data) => {
    setProjectData({
      ...data,
      projectMembers: data.employees.map((employee) => employee),
    });
  };

  const defaultEmployees = employees.filter((employee) =>
    projectData.projectMembers.find(
      (member) => member.employeeId === employee.id,
    ),
  );

  return (
    <Box sx={styleProps.projectsContainer}>
      {show && (
        <UpdateProject
          visible={true}
          setVisible={setShow}
          project={projectData}
          updateHandler={(data) => updateHandler(data)}
          defaultEmployees={defaultEmployees}
          availableEmployees={employees}
        />
      )}
      <Box
        sx={{ flexDirection: 'column', display: 'flex', padding: '2%', gap: 1 }}
      >
        <Typography variant='h7' fontSize={28} fontWeight={500}>
          {projectData.projectName}
        </Typography>
        <Box onClick={handleEdit}>
          <Typography
            position={'absolute'}
            variant='h7'
            right={'2%'}
            top={'6%'}
            fontSize={16}
            fontWeight={400}
            color={'#0666EB'}
          >
            <TextButton>
              <EditIcon fontSize='16' /> Edit
            </TextButton>
          </Typography>
        </Box>
        <Typography
          color={styleProps.colors.textLightGrey}
          fontSize={16}
          variant='h7'
        >
          {projectData.description}
        </Typography>
        <Typography fontSize={20} variant='h7' mt={2} fontWeight={400}>
          Employees
        </Typography>
        <Stack flexDirection={'row'} flexWrap={'wrap'} gap={2}>
          {projectData.projectMembers.map((employee) => {
            return (
              <Typography
                key={employee.employeeId}
                color={styleProps.colors.textLightGrey}
                variant='h7'
              >
                {employee.employeeName}
              </Typography>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

const TextButton = styled('div')({
  cursor: 'pointer',
  padding: 0,
  margin: 0,
});

export default ProjectCard;
