import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ROLE_DELIVERY_MANAGER } from '../../enums/User';

import { styleProps } from '../../styles/styleProps';
import LongPrimaryBtn from '../buttons/LongPrimaryBtn';
import { invalidStyles } from '../../styles/textFieldErrorStyles';
import EmployeeCsvUploardModal from '../employee/csv/EmployeeCsvUploardModal';
import { getEmployeeByStatus } from '../../services/EmployeeService';
import { EmployeeStatus } from '../../enums/Employee';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

const { fonts, fontSizes, colors } = styleProps;

//Styles and styled component
const containerStyles = {
  width: '100%',
  borderRadius: '10px',
  background: 'white',
  padding: '20px 0px',
};

const TitleContainer = styled(Box)({
  width: '100%',
  height: '50px',
  textAlign: 'center',
  marginTop: '5%',
});

const Title = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: fontSizes.header,
  fontWeight: 500,
  color: colors.textDarkGrey,
});

const InputContainer = styled(Box)({
  boxSizing: 'border-box',
  padding: '8% 12% 2% 12%',
  width: '100%',
  height: '80%',
});

const TextInput = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const TextButton = styled(Button)({
  fontWeight: 'bold',
  fontSize: '16px',

  textTransform: 'none',
  padding: '10px 0px',
  color: colors.btnPrimary,
});

const ErrorText = styled(Typography)({
  fontSize: '12px',
  textTransform: 'none',
  padding: '0px 0px',
  color: 'red',
});

const ForgetPassword = styled(Box)({
  width: '100%',
  marginBottom: '30px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const ForgetPasswordText = styled(Typography)({
  color: colors.textLightGrey,
  fontFamily: fonts.primary,
  fontSize: fontSizes.normal,
});

let accessToken = '';
let adminId = '';

const LoginForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isEmptyFields, setIsEmptyFields] = useState(true);
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [shrinkEmail, setShrinkEmail] = useState(false);
  const [shrinkPassword, setShrinkPassword] = useState(false);
  const { user, loading, setToken } = useAuth();
  const [isEmployeeExists, setIsEmployeeExists] = useState(false);
  const navigate = useNavigate();

  // trigger when animation starts on input fields
  const checkIsAutoFilled = (setAutoFiled) => (e) => {
    // check if the input is autofilled by browser
    const autofilled = !!e.target?.matches('*:-webkit-autofill');
    // set state if mui input has autofilled classes
    if (
      e.animationName === 'mui-auto-fill' ||
      e.animationName === 'mui-auto-fill-cancel'
    ) {
      setAutoFiled(autofilled);
    }
  };

  //csv uploading states
  const [csvUploardModalStatus, setCsvUploardModalStatus] = useState(false);

  //to open csv upload modal
  const openCsvModal = () => {
    setCsvUploardModalStatus(true);
  };

  //to close csv upload modal
  const closeCsvModal = () => {
    navigate('/login');
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid Email'),
    password: Yup.string(),
  });

  const onSubmit = () => {
    try {
      const body = {
        email: formik.values.email,
        password: formik.values.password,
      };

      axios.post('/api/admin/login', body).then(async (response) => {
        if (response.data.success === true) {
          accessToken = response.headers.get('access-token');
          adminId = response.data.message.adminId;
          setSessions();
          const isEmptyExists = await checkEmployeeExists();
          setIsEmployeeExists(isEmptyExists);
          setToken(accessToken);
        } else {
          setIsLoginSuccessful(true);
        }
      });
    } catch (err) {
      console.error(err.message);
      navigate('/error');
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    let res = checkInputFields(formik.values);
    setIsEmptyFields(res);
  }, [formik.values]);

  //Handle password visibility
  const handlePasswordVisibility = () => {
    setIsPasswordVisible((prevVal) => !prevVal);
  };

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  const navigateToRest = () => {
    navigate('/passwordReset/loggedOut');
  };

  if (!loading && user) {
    // get current admin from backend since authContext is not updated yet
    if (user.adminRole == ROLE_DELIVERY_MANAGER) {
      navigate('/projects');
      return null;
    } else {
      // redirect to employees page if there are no employees and open csv modal
      !isEmployeeExists
        ? (navigate('/employees'), openCsvModal())
        : navigate('/dashboard');
      return null;
    }
  }

  return (
    <div style={containerStyles}>
      <EmployeeCsvUploardModal
        modalStatus={csvUploardModalStatus}
        onClose={closeCsvModal}
        whileAdminRegister={true}
        nextFunctoin={setSessions}
      />
      <TitleContainer>
        <Title>Admin Login</Title>
      </TitleContainer>
      <InputContainer>
        <TextInput
          label='Email address'
          type='email'
          name='email'
          onBlur={formik.handleBlur}
          value={formik.values?.email}
          sx={invalidStyles(formik.errors?.email ? false : true)}
          InputLabelProps={{
            style: { fontSize: 14 },
            shrink: shrinkEmail || formik.values.email,
          }}
          inputProps={{ onAnimationStart: checkIsAutoFilled(setShrinkEmail) }}
          onChange={(e) => {
            setIsLoginSuccessful(false);
            formik.handleChange(e);
            formik.errors?.email && delete formik.errors.email;
          }}
        />
        {formik.errors?.email && <ErrorText>{formik.errors.email}</ErrorText>}

        <TextInput
          label='Password'
          name='password'
          type={isPasswordVisible ? 'text' : 'password'}
          value={formik.values.password}
          onChange={(e) => {
            setIsLoginSuccessful(false);
            formik.handleChange(e);
          }}
          sx={{ marginTop: '15px' }}
          InputProps={{
            onAnimationStart: checkIsAutoFilled(setShrinkPassword),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton edge='end' onClick={handlePasswordVisibility}>
                  {isPasswordVisible ? (
                    <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                  ) : (
                    <VisibilityOutlined sx={{ fontSize: 18 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { fontSize: 14 },
            shrink: shrinkPassword || formik.values.password,
          }}
        />
        {isLoginSuccessful === true ? (
          <Alert sx={{ marginTop: '15px' }} severity='error'>
            Email or password you entered doesn’t match
          </Alert>
        ) : null}
        <LongPrimaryBtn
          text='Login'
          disabled={isEmptyFields}
          onClick={formik.handleSubmit}
        />
        <ForgetPassword>
          <ForgetPasswordText>Forgot Password ?</ForgetPasswordText>
          <TextButton onClick={navigateToRest}>Reset</TextButton>
        </ForgetPassword>
      </InputContainer>
    </div>
  );
};

const setSessions = async (message, severity) => {
  localStorage.setItem('loggedInAuth', accessToken); // jwt
  localStorage.setItem('sessionValid', true);
  localStorage.setItem('adminId', adminId);
  if (message && severity) {
    localStorage.setItem('message', message);
    localStorage.setItem('severity', severity);
  }
};

//to cehck whether the company has any employes or not
const checkEmployeeExists = async () => {
  try {
    const activeEmployeeList = await getActiveEmployeeData();
    const inactiveEmployeeList = await getInactiveEmployeeData();
    const invitedEmployeeList = await getInvitedEmployeeData();
    if (
      activeEmployeeList.employees.length === 0 &&
      inactiveEmployeeList.employees.length === 0 &&
      invitedEmployeeList.employees.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getActiveEmployeeData = async () => {
  return await getEmployeeByStatus(EmployeeStatus.ENABLED);
};

const getInactiveEmployeeData = async () => {
  return await getEmployeeByStatus(EmployeeStatus.DISABLED);
};

const getInvitedEmployeeData = async () => {
  return await getEmployeeByStatus(EmployeeStatus.INVITED);
};

export default LoginForm;
