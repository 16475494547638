/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import Header from '../StepHeader';
import Table from '../tables/Step1-Table';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styleProps } from '../../../styles/styleProps';
import { getPayrollEmployeeByEligibility } from '../../../services/EmployeeService';
import { useParams } from 'react-router-dom';
import {
  getAllPayRollsByYearAndMonth,
  getPayRollById,
} from '../../../services/PayRollService';
import { getCurrentMonthDateRange } from '../helpers/dateHelpers';

function Step1(props) {
  const {
    selectedEmployees,
    setSelectedEmployees,
    setBulkData,
    bulkData,
    setErrorMessage,
  } = props;
  const urlParams = useParams();
  const payrollDisplayId = urlParams.payrollId;

  const { colors } = styleProps;
  const [employees, setEmployees] = useState([]);
  const [filteredData, setFilteredData] = useState(employees);
  const [searchValue, setSearchValue] = useState('');
  const [updateTableData, setUpdateTableData] = useState('');
  const [payrollId, setPayrollId] = useState(null);

  const { payrollStartDate, payrollEndDate, payDate } = bulkData;

  const toUTC = (date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
  };

  const { minDate, maxDate } = getCurrentMonthDateRange();

  useEffect(() => {
    if (payrollDisplayId) {
      getPayRollById(payrollDisplayId).then((res) => {
        if (res.success) {
          const data = res.message.payRoll;
          const payStubs = res.message.payStubs;

          setPayrollId(data.id);

          setBulkData((prev) => ({
            ...prev,
            payrollStartDate: new Date(data.startDate),
            payrollEndDate: new Date(data.endDate),
            payDate: new Date(data.payDate),
          }));
          setSelectedEmployees(
            new Set(payStubs.map((employee) => employee.employeeDisplayId)),
          );
        } else {
          window.history.pushState({}, null, '/payrollSchedule');
        }
      });
    }
  }, [payrollDisplayId]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredData(employees);
      return;
    }

    const filteredData = employees.filter((row) => {
      return (
        row.employeeEmployeeId.toLowerCase().includes(searchValue) ||
        row.employeeFirstName.toLowerCase().includes(searchValue) ||
        row.employeeLastName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredData(filteredData);
  }, [searchValue, employees, updateTableData]);

  useEffect(() => {
    setErrorMessage('');

    if (payrollEndDate < payrollStartDate) {
      setErrorMessage('Payroll end date cannot be before payroll start date');
      setBulkData((prev) => ({ ...prev, payrollEndDate: null }));
      return;
    }
  }, [payrollEndDate, payrollStartDate]);

  const columns = [
    'Employee ID',
    'Name',
    'Email',
    'Designation',
    'Salary',
    'Mobile',
    'Paystub Status',
  ];

  useEffect(() => {
    getPyayrollEligibleEmployees();
  }, [updateTableData, payrollId]);

  const getPyayrollEligibleEmployees = async () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const res = await getPayrollEmployeeByEligibility(true);
    const payRollsRsponse = await getAllPayRollsByYearAndMonth(
      currentYear,
      currentMonth,
    );
    const payRolls = payRollsRsponse.message;

    const payRollIdsSet = new Set(payRolls.map((payroll) => payroll.id));

    const filteredEmployees = payrollDisplayId
      ? res.employees.filter((employee) =>
          employee.PayStubs.some(
            (payStub) =>
              payStub.payRollId === payrollId || payStub.payRollId == null,
          ),
        )
      : res.employees.filter((employee) =>
          employee.PayStubs.some(
            (payStub) =>
              payStub.payRollId == null ||
              !payRollIdsSet.has(payStub.payRollId),
          ),
        );

    setEmployees(filteredEmployees);
  };

  return (
    <Box>
      <Header
        title='Pay Period & Employee'
        subtitle='Select the pay period and employee to include in this payroll'
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', marginY: 3 }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='Payroll start date'
              slotProps={{ textField: { size: 'small' } }}
              value={payrollStartDate}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(newValue) =>
                setBulkData((prev) => ({
                  ...prev,
                  payrollStartDate: toUTC(newValue),
                }))
              }
              format='dd/MMM/yyyy'
            />
            <DatePicker
              label='Payroll end date'
              slotProps={{ textField: { size: 'small' } }}
              minDate={payrollStartDate || minDate}
              maxDate={maxDate}
              value={payrollEndDate}
              onChange={(newValue) =>
                setBulkData((prev) => ({
                  ...prev,
                  payrollEndDate: toUTC(newValue),
                }))
              }
              format='dd/MMM/yyyy'
            />
            <DatePicker
              label='Pay date'
              slotProps={{ textField: { size: 'small' } }}
              minDate={payrollStartDate || minDate}
              maxDate={maxDate}
              value={payDate}
              disablePast
              onChange={(newValue) =>
                setBulkData((prev) => ({ ...prev, payDate: toUTC(newValue) }))
              }
              format='dd/MMM/yyyy'
            />
          </LocalizationProvider>
        </Box>

        {/* Search bar */}
        <Box>
          <TextField
            size='small'
            label='Search by Id or Name'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            InputProps={{
              endAdornment: <SearchIcon sx={{ color: colors.textLightGrey }} />,
            }}
          />
        </Box>
      </Box>
      <Table
        data={filteredData}
        columns={columns}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
        setUpdateTableData={setUpdateTableData}
      />
    </Box>
  );
}

export default Step1;
