/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Typography, Box } from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
const { fonts } = styleProps;

const PaymentOverviewChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      const chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.labels,
          datasets: [
            {
              label: '',
              data: data.values,
              backgroundColor: '#0666EB',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: false,
                text: 'Year and Month',
                font: {
                  style: styles.chartText,
                },
              },
              grid: {
                drawOnChartArea: false,
              },
            },
            y: {
              title: {
                display: false,
                text: 'Total Payment Amount',
                font: {
                  style: styles.chartText,
                },
              },
              ticks: {
                callback: (value, index, values) => {
                  const formatValue = value / 1000;
                  const paddedValue =
                    value == 0
                      ? `LKR     ${formatValue}`
                      : `LKR ${formatValue}K`;
                  return paddedValue;
                },
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
      chartInstanceRef.current = chart;
    }
  }, [data]);

  // resize chart on window resize
  useEffect(() => {
    const handleResize = () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.resize();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box style={styles.container}>
      <Typography style={styles.title}>Payment Overview</Typography>
      <Box
        style={{
          height: '252px',
        }}
      >
        <canvas ref={chartRef} />
      </Box>
    </Box>
  );
};

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: fonts.primary,
    lineHeight: '32px',
    marginBottom: '33px',
  },
  container: {
    flex: '1',
    padding: '20px',
    borderRadius: '8px',
    border: '2px solid #DEEBFC',
    height: '345px',
  },
  chartText: {
    size: 14,
    fontWeight: '400px',
    fontFamily: fonts.primary,
    color: '#71717A',
  },
};

export default PaymentOverviewChart;
