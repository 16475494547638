/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Modal,
  Button,
  Stack,
  Typography,
  TextField,
  styled,
  MenuItem,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import MediumPrimaryBtn from '../buttons/MediumPrimaryBtn';
import MediumSecondaryBtn from '../buttons/MediumSecondaryBtn';
import { styleProps } from '../../styles/styleProps';
import ConfirmationModal from '../alerts/ConfirmationModal';

const { fonts, colors } = styleProps;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '460px',
  borderRadius: '8px',
  background: '#FFFFFF',
};

const CustomSelect = styled(TextField)({
  width: '100%',
  height: 50,
  paddingTop: 10,
  paddingBottom: 10,
  '& .MuiInputBase-root': {
    height: 60,
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: fonts.primary,
  },
  '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

const menuItemStyles = {
  m: 0.5,
  '&:focus': {
    background: colors.btnPrimary,
    color: 'white',
    m: 0.5,
    borderRadius: 1,
    '&:hover': {
      background: colors.btnPrimary,
      color: 'white',
      m: 0.5,
      borderRadius: 1,
    },
  },
};
const StackStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '7%',
};

const BtnStyle = {
  backgroundColor: 'none',
  '&:hover': {
    backgroundColor: '#FFDFAC',
  },
  color: colors.textDarkGrey,
};

const HeadingStyle = {
  margin: '5%',
  color: colors.textDarkGrey,
  fontWeight: 550,
  fontSize: '20px',
};

const TextInput = styled(TextField)({
  width: '100%',
  borderRadius: '8px',
});

const EditCompanyDetailsModal = ({
  modalStatus,
  onClose,
  currencyList,
  fieldList,
  companyData,
  refreshData,
}) => {
  const [isEmptyFields, setIsEmptyFields] = useState(false);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState(false);

  //Validation
  const [invalidCompanyName, setInvalidCompanyName] = useState(false);
  const initialValues = {
    companyData: {
      companyName: companyData.companyName,
      field: companyData.companyFieldId,
      currency: companyData.companyCurrencyId,
    },
  };
  const validationSchema = Yup.object({
    companyData: Yup.object().shape({
      companyName: Yup.string(),
      field: Yup.string(),
      currency: Yup.string(),
    }),
  });

  const editCompanyDetails = () => {
    try {
      const companyId = companyData.companyId;
      const companyName = formik.values.companyData.companyName;
      const fieldId = formik.values.companyData.field;
      const currencyId = formik.values.companyData.currency;

      if (companyName.trim().length === 0) {
        setInvalidCompanyName(true);
      } else {
        const body = {
          companyId,
          companyName,
          fieldId,
          currencyId,
        };

        axios
          .put('/api/company/update', body, {
            headers: {
              Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
            },
          })
          .then(
            (response) => {
              if (response.data.success === true) {
                onClose();
                refreshData();
              } else {
                console.log(response.data.error);
                window.location = '/error';
              }
            },
            (error) => {
              if (error.response.status === 401) {
                localStorage.setItem('sessionValid', 'false');
                window.location = '/login';
              } else {
                window.location = '/error';
              }
            },
          );
      }
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  const onSubmit = () => {
    editCompanyDetails();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    let res = checkInputFields(formik.values);
    setIsEmptyFields(res);
  }, [formik.values]);

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  const confirmationModalApproval = () => {
    setConfirmationModalStatus(false);
    onClose();
  };

  const confirmationModalDecline = () => {
    setConfirmationModalStatus(false);
  };

  return (
    <div>
      <ConfirmationModal
        modalStatus={confirmationModalStatus}
        confirm={confirmationModalApproval}
        reject={confirmationModalDecline}
      />
      <Modal open={modalStatus} onClose={onClose}>
        <Box sx={{ ...style, height: invalidCompanyName ? '450px' : '398px' }}>
          <Stack justifyContent='space-between' direction='row'>
            <Typography sx={HeadingStyle}>Edit Company Profile</Typography>
            <Button onClick={onClose} style={BtnStyle}>
              <CloseIcon />
            </Button>
          </Stack>
          <hr style={{ border: '1px solid #E5E5E5' }}></hr>

          <Grid sx={{ boxShadow: 0, margin: '5%' }}>
            <TextInput
              placeholder='Company name'
              name='companyData.companyName'
              value={formik.values.companyData.companyName}
              onChange={(e) => {
                formik.handleChange(e);
                setInvalidCompanyName(false);
              }}
            />
            {invalidCompanyName ? (
              <>
                <br />
                <Alert severity='error'>Invalid Company Name!</Alert>
              </>
            ) : null}
            <CustomSelect
              placeholder='Field'
              name='companyData.field'
              value={formik.values.companyData.field}
              onChange={formik.handleChange}
              select
            >
              {fieldList.map((item, index) => (
                <MenuItem
                  sx={menuItemStyles}
                  key={index}
                  value={item.companyFieldDisplayId}
                >
                  {item.companyField}
                </MenuItem>
              ))}
            </CustomSelect>

            <CustomSelect
              placeholder='Default currency'
              name='companyData.currency'
              value={formik.values.companyData.currency}
              onChange={formik.handleChange}
              select
            >
              {currencyList.map((item, index) => (
                <MenuItem
                  sx={menuItemStyles}
                  key={index}
                  value={item.currencyDisplayId}
                >
                  {item.currencyCode}
                </MenuItem>
              ))}
            </CustomSelect>

            <Stack spacing={1} direction='row' sx={StackStyle}>
              <MediumSecondaryBtn
                text='Cancel'
                onClick={onClose}
                variant='contained'
              ></MediumSecondaryBtn>
              <MediumPrimaryBtn
                disabled={isEmptyFields}
                sx={{
                  marginLeft: '5px',
                  marginRight: '10%',
                }}
                variant='contained'
                text='Update'
                onClick={formik.handleSubmit}
              ></MediumPrimaryBtn>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default EditCompanyDetailsModal;
