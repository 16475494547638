import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const steps = [
  'Pay Period & Employee',
  'Additional Earnings',
  'Deductions',
  'Review ',
];

// eslint-disable-next-line react/prop-types
const StepperComponent = ({ activeStep, children }) => {
  return (
    <Box sx={{ width: '100%', paddingTop: '2rem' }}>
      <Box sx={{ width: '50%', marginX: 'auto' }}>
        <Stepper
          nonLinear
          connector={
            <ArrowForwardIosIcon sx={{ color: '#71717A' }} fontSize='small' />
          }
          activeStep={activeStep}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  sx={stepperStyles(index, activeStep)}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>

      <Box sx={{ width: '100%', marginTop: '2rem' }}>{children}</Box>
    </Box>
  );
};

export default StepperComponent;

const stepperStyles = (index, activeStep) => ({
  '& .MuiStepLabel-label': {
    fontWeight: '600',
    color:
      index === activeStep
        ? '#0666EB'
        : index < activeStep
          ? '#059669'
          : '#71717A',
  },
  '& .MuiStepIcon-root': {
    color:
      index === activeStep
        ? '#0666EB'
        : index < activeStep
          ? '#059669'
          : '#71717A',
  },
  '& .MuiStepConnector-line': {
    borderColor:
      index === activeStep
        ? '#0666EB'
        : index < activeStep
          ? '#059669'
          : '#71717A',
  },
  '& .MuiStepConnector-root': {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  '& .Mui-active': {
    '& .MuiStepLabel-label': {
      fontWeight: '600',
      color:
        index === activeStep
          ? '#0666EB'
          : index < activeStep
            ? '#059669'
            : '#71717A',
    },
  },
});
