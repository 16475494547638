/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { React, useState, useRef, useEffect } from 'react';
import { Box, Typography, styled, Chip } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import LinearProgress from '@mui/material/LinearProgress';

//progress bar style
const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
}));

const DragDropFiles = ({ fileStatus, removeFile, buttonStatus, user }) => {
  const [files, setFiles] = useState(null);
  const inputRef = useRef();
  const [progress, setProgress] = useState(10);

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const removeUploadedFile = () => {
    setFiles(null);
    setProgress(10);
    removeFile();
  };

  //handle file drop event
  const handleDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setFiles(event.dataTransfer.files);
    if (event.dataTransfer.files[0].type != 'text/csv') {
      fileStatus();
    } else {
      handleUpload(event.dataTransfer.files[0]);
    }
  };

  const handleUpload = (fileData) => {
    const formData = new FormData();
    formData.append('import-csv', fileData);
    if (user != null) {
      formData.append('companyId', user.adminCompany.companyId);
    }

    buttonStatus(formData);
  };

  //Progress bar loading time
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10,
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  //handle file browse event
  const uploadFiles = (event) => {
    setFiles(event.target.files);
    if (event.target.files[0].type != 'text/csv') {
      fileStatus();
    } else {
      handleUpload(event.target.files[0]);
    }
  };

  if (files)
    return (
      <div className='uploads'>
        <Box>
          <div
            className='dropzone'
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{ padding: '100px' }}
          >
            <UploadIcon
              sx={{ paddingLeft: '120px', fontSize: 50, color: '#979797' }}
              fontSize='inherit'
            />

            <input
              type='file'
              multiple
              onChange={(event) => setFiles(event.target.files)}
              hidden
              accept='*'
              ref={inputRef}
            />
            <Typography>
              Drag and drop your file here or{' '}
              <span style={{ color: '#0666EB', cursor: 'pointer' }}>
                Browse
              </span>
            </Typography>
          </div>
          {progress != 100 ? (
            <div style={{ marginTop: '-70px', width: '95%', marginLeft: '2%' }}>
              <Typography sx={{ fontSize: '12px' }}>Uploading</Typography>
              <BorderLinearProgress variant='determinate' value={progress} />
            </div>
          ) : (
            <div
              style={{ marginTop: '-80px', width: '100%', marginLeft: '2%' }}
            >
              {Array.from(files).map((file, idx) => (
                <Chip
                  color='primary'
                  variant='outlined'
                  key={idx}
                  label={file.name}
                  onDelete={() => removeUploadedFile()}
                />
              ))}
            </div>
          )}
        </Box>
      </div>
    );

  return (
    <Box>
      <div
        className='dropzone'
        onDragOver={(event) => handleDragOver(event)}
        onDrop={(event) => handleDrop(event)}
        style={{ padding: '100px' }}
      >
        <UploadIcon
          sx={{ paddingLeft: '120px', fontSize: 50, color: '#979797' }}
          fontSize='inherit'
        />

        <input
          type='file'
          onChange={(event) => uploadFiles(event)}
          hidden
          accept='*'
          ref={inputRef}
        />
        <Typography id='modal-modal-title'>
          Drag and drop your file here or{' '}
          <a onClick={() => inputRef.current.click()}>
            <span style={{ color: '#0666EB' }}>Browse</span>
          </a>
        </Typography>
      </div>
    </Box>
  );
};

export default DragDropFiles;
