import React, { useState, useEffect } from 'react';
import { Grid, Alert, Box, Snackbar } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TopBar from '../components/top-bar/TopBar';
import { getCurrentAdmin } from '../services/AdminService';
import ShortPrimaryBtn from '../components/buttons/ShortPrimaryBtn';
import ShortSecondaryBtn from '../components/buttons/ShortSecondaryBtn';
import { useFormik } from 'formik';
import { CountryCodes } from '../data/countryCodes';
import BasicData from '../components/employee-edit/BasicDetails';
import EmployeeHeader from '../components/employee-edit/EmployeeHeader';
import Adjustments from '../components/employee-edit/Adjustments';
import Paystubs from '../components/employee-edit/Paystubs';
import { getAllCurrencies } from '../services/CurrencyService';
import { getEmployeeByDisplayId } from '../services/EmployeeService';

const TabStyles = {
  '&.Mui-selected': {
    fontWeight: 600,
    backgroundColor: '#0666EB1A',
    transition: '0.5s',
    borderRadius: '10px 10px 0px 0px',
  },
  fontWeight: 700,
  width: '10rem',
  borderBottom: '1px solid #71717A',
};

const CustomTabPanel = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
  };
};

const EmployeeDetails = () => {
  //get type from params
  const params = useParams();
  const employeeDisplayId = params.id;
  const isEditableparam = params.editable === 1 ? true : false;

  // state for tabs
  const [tabValue, setTabValue] = useState(0);

  const [allCurrencies, setAllCurrencies] = useState([]);
  const [user, setUser] = useState('');
  const [employeeDetails, setEmployeeDetails] = useState();
  const [isEditable, setIsEditable] = useState(isEditableparam);

  const [isEmptyBasicDetailsFields, setIsEmptyBasicDetailsFields] =
    useState(false);
  const [isEmptyContactDetailsFields, setIsEmptyContactDetailsFields] =
    useState(false);
  const [isEmptyAddressDetailsFields, setIsEmptyAddressDetailsFields] =
    useState(false);
  const [isEmptyEmployementDetailsFields, setIsEmptyEmployementDetailsFields] =
    useState(false);
  const [isMobileNumberInvalid, setIsMobileNumberInvalid] = useState(false);
  const [isNICInvalid, setIsNICInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();

  const [showAlertBox, setShowAlertBox] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  const countryCodes = CountryCodes.countries;

  const initialValues = {
    basicDetails: {
      employeeEmployeeId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      employeeNic: '',
    },
    contactDetails: {
      countryCode: '',
      employeeMobile: '',
      employeeWorkEmail: '',
      employeeFullMobileNumber: '',
    },
    addressDetails: {
      employeeAddressLineOne: '',
      employeeAddressLineTwo: '',
      employeeCity: '',
      employeeState: '',
      employeePostalCode: '',
      employeeCountry: '',
    },
    employementDetails: {
      employeeSalary: '',
      employeeDesignation: '',
      employeeCurrency: '',
      employeeCurrencyDisplayId: '',
      employeeType: '',
    },
  };

  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (key === 'middleName' || key === 'employeeAddressLineTwo') {
        // these fields are optional
        continue;
      }
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  const resetForm = () => {
    formik.resetForm();
    setIsEmptyBasicDetailsFields(false);
    setIsEmptyContactDetailsFields(false);
    setIsEmptyAddressDetailsFields(false);
    setIsEmptyEmployementDetailsFields(false);
    setIsMobileNumberInvalid(false);
    setIsNICInvalid(false);
    setIsEmailInvalid(false);
  };

  const getEmployeeData = async () => {
    const employeeData = await getEmployeeByDisplayId(employeeDisplayId);
    setEmployeeDetails(employeeData);

    initialValues.basicDetails.employeeEmployeeId =
      employeeData.employeeEmployeeId;
    initialValues.basicDetails.firstName = employeeData.employeeFirstName;
    initialValues.basicDetails.lastName = employeeData.employeeLastName;
    initialValues.basicDetails.employeeNic = employeeData.employeeNic;
    initialValues.contactDetails.employeeWorkEmail =
      employeeData.employeeWorkEmail;
    initialValues.contactDetails.employeeFullMobileNumber =
      employeeData.employeeMobile;
    initialValues.addressDetails.employeeAddressLineOne =
      employeeData.employeeAddressLineOne;
    initialValues.addressDetails.employeeAddressLineTwo =
      employeeData.employeeAddressLineTwo;
    initialValues.addressDetails.employeeCity = employeeData.employeeCity;
    initialValues.addressDetails.employeeState = employeeData.employeeState;
    initialValues.addressDetails.employeePostalCode =
      employeeData.employeePostalCode;
    initialValues.addressDetails.employeeCountry = employeeData.employeeCountry;
    initialValues.employementDetails.employeeSalary =
      employeeData.employeeSalary;
    initialValues.employementDetails.employeeDesignation =
      employeeData.employeeDesignation;
    initialValues.employementDetails.employeeCurrency =
      employeeData.Currency.currency;
    initialValues.employementDetails.employeeCurrencyDisplayId =
      employeeData.Currency.currencyDisplayId;
    initialValues.employementDetails.employeeType = employeeData.employeeType;

    splitMobileNumber(employeeData.employeeMobile);
    setSelectedCurrency(employeeData.Currency.currency);
    setIsEmptyBasicDetailsFields(false);
    setIsEmptyContactDetailsFields(false);
    setIsEmptyAddressDetailsFields(false);
    setIsEmptyEmployementDetailsFields(false);
  };

  const getCurrencies = async () => {
    getAllCurrencies().then((data) => {
      setAllCurrencies(data);
    });
  };

  async function getData() {
    setUser(await getCurrentAdmin());
  }

  function toggleEdit(value) {
    if (value === 0) {
      formik.resetForm();
    }
    setIsEditable(value);
  }

  const onSubmit = async () => {
    try {
      const employeeEmployeeId = formik.values.basicDetails.employeeEmployeeId;
      const firstName = formik.values.basicDetails.firstName;
      const lastName = formik.values.basicDetails.lastName;
      const employeeNic = formik.values.basicDetails.employeeNic;
      const employeeMobile =
        formik.values.contactDetails.countryCode +
        formik.values.contactDetails.employeeMobile;
      const countryCode = await getCountryNameUsingCountyCode(
        formik.values.contactDetails.countryCode,
      );
      const employeeWorkEmail = formik.values.contactDetails.employeeWorkEmail;
      const employeeAddressLineOne =
        formik.values.addressDetails.employeeAddressLineOne;
      const employeeAddressLineTwo =
        formik.values.addressDetails.employeeAddressLineTwo;
      const employeeCity = formik.values.addressDetails.employeeCity;
      const employeeState = formik.values.addressDetails.employeeState;
      const employeePostalCode =
        formik.values.addressDetails.employeePostalCode;
      const employeeCountry = formik.values.addressDetails.employeeCountry;
      const employeeSalary = formik.values.employementDetails.employeeSalary;
      const employeeDesignation =
        formik.values.employementDetails.employeeDesignation;
      const employeeCurrencyDisplayId =
        formik.values.employementDetails.employeeCurrencyDisplayId;
      const employeeType = formik.values.employementDetails.employeeType;

      const body = {
        email: employeeWorkEmail,
        employeeEmployeeId,
        firstName,
        lastName,
        nic: employeeNic,
        mobile: employeeMobile,
        country: countryCode,
        addressOne: employeeAddressLineOne,
        addressTwo: employeeAddressLineTwo,
        city: employeeCity,
        state: employeeState,
        postal: employeePostalCode,
        empCountry: employeeCountry,
        currency: employeeCurrencyDisplayId,
        salary: String(employeeSalary),
        designation: employeeDesignation,
        type: employeeType,
      };
      axios
        .put('/api/employees/update', body, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            setShowAlertBox(true);
            setAlertMessage('Employee updated successfully!');
            setAlertType('success');
            setIsEditable(false);
            formik.values.employementDetails.employeeCurrency =
              selectedCurrency;
            formik.values.contactDetails.employeeFullMobileNumber =
              employeeMobile;
            getEmployeeData();
          } else {
            throw new Error(response.data.error);
          }
        })
        .catch((error) => {
          setShowAlertBox(true);
          setAlertMessage(error.message);
          setAlertType('error');
        });
    } catch (error) {
      setShowAlertBox(true);
      setAlertMessage(error.message);
      setAlertType('error');
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const splitMobileNumber = async (fullMobileNumberValue) => {
    await Promise.all(
      countryCodes.map(async (singleCountryCode) => {
        if (fullMobileNumberValue.includes(singleCountryCode['code'])) {
          initialValues.contactDetails.countryCode = singleCountryCode['code'];
          initialValues.contactDetails.employeeMobile =
            fullMobileNumberValue.replace(singleCountryCode['code'], '');
        }
      }),
    );
  };

  const getCountryNameUsingCountyCode = async (counryCode) => {
    const result = countryCodes.find((item) => item.code === counryCode);
    return result.name;
  };

  useEffect(() => {
    getEmployeeData();
    getCurrencies();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let res = checkInputFields(formik.values.basicDetails);
    if (res) {
      setIsEmptyBasicDetailsFields(true);
    } else {
      setIsEmptyBasicDetailsFields(false);
    }
  }, [formik.values.basicDetails]);
  useEffect(() => {
    let res = checkInputFields(formik.values.contactDetails);
    if (res) {
      setIsEmptyContactDetailsFields(true);
    } else {
      setIsEmptyContactDetailsFields(false);
    }
  }, [formik.values.contactDetails]);
  useEffect(() => {
    let res = checkInputFields(formik.values.addressDetails);
    if (res) {
      setIsEmptyAddressDetailsFields(true);
    } else {
      setIsEmptyAddressDetailsFields(false);
    }
  }, [formik.values.addressDetails]);
  useEffect(() => {
    let res = checkInputFields(formik.values.employementDetails);
    if (res) {
      setIsEmptyEmployementDetailsFields(true);
    } else {
      setIsEmptyEmployementDetailsFields(false);
    }
  }, [formik.values.employementDetails]);

  const isSaveDisabled =
    isEmptyBasicDetailsFields ||
    isEmptyContactDetailsFields ||
    isEmptyAddressDetailsFields ||
    isEmptyEmployementDetailsFields ||
    isMobileNumberInvalid ||
    isEmailInvalid ||
    isNICInvalid
      ? true
      : false;

  return (
    <>
      <TopBar
        title={`Employees > ${employeeDetails?.employeeFirstName} ${employeeDetails?.employeeLastName}`}
        user={user}
      />

      <Snackbar
        open={showAlertBox}
        autoHideDuration={6000}
        onClose={() => {
          setShowAlertBox(false);
        }}
      >
        <Alert severity={alertType}>{alertMessage}</Alert>
      </Snackbar>

      <Box sx={{ height: 'calc(100vh - 60px)', overflowY: 'scroll' }}>
        <div style={{ margin: '2vw' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <EmployeeHeader employee={employeeDetails} />
            <Grid sx={{ textAlign: 'right' }}>
              <Grid hidden={tabValue !== 0}>
                {isEditable ? (
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='flex-end'
                    spacing={2}
                  >
                    <Grid item>
                      <ShortPrimaryBtn
                        text={'Save'}
                        disabled={isSaveDisabled}
                        onClick={formik.handleSubmit}
                      />
                    </Grid>
                    <Grid item>
                      <ShortSecondaryBtn
                        text={'Cancel'}
                        disabled={false}
                        onClick={() => {
                          resetForm();
                          toggleEdit(0);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <ShortPrimaryBtn
                      text={'Edit Employee'}
                      disabled={false}
                      onClick={() => {
                        toggleEdit(1);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>

          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
          >
            <Tab label='Profile' {...a11yProps(0)} sx={TabStyles} />
            <Tab label='Adjustments' {...a11yProps(1)} sx={TabStyles} />
            <Tab label='Paystubs' {...a11yProps(2)} sx={TabStyles} />
          </Tabs>

          <CustomTabPanel value={tabValue} index={0}>
            <BasicData
              isEditable={isEditable}
              formik={formik}
              isNICInvalid={isNICInvalid}
              setIsNICInvalid={setIsNICInvalid}
              setIsEmailInvalid={setIsEmailInvalid}
              setIsMobileNumberInvalid={setIsMobileNumberInvalid}
              allCurrencies={allCurrencies}
              isEmailInvalid={isEmailInvalid}
              isMobileNumberInvalid={isMobileNumberInvalid}
              setSelectedCurrency={setSelectedCurrency}
              checkInputFields={checkInputFields}
              setIsEmptyBasicDetailsFields={setIsEmptyBasicDetailsFields}
              setIsEmptyContactDetailsFields={setIsEmptyContactDetailsFields}
              setIsEmptyAddressDetailsFields={setIsEmptyAddressDetailsFields}
              setIsEmptyEmployementDetailsFields={
                setIsEmptyEmployementDetailsFields
              }
            />
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={1}>
            <Adjustments />
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={2}>
            <Paystubs employeeId={employeeDisplayId} />
          </CustomTabPanel>
        </div>
      </Box>
    </>
  );
};

export default EmployeeDetails;
