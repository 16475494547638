/* eslint-disable react/prop-types */
import React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  Stack,
  Typography,
  Alert,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import axios from 'axios';

import MediumPrimaryBtn from '../../buttons/MediumPrimaryBtn';
import DragDropFiles from '../helpers/dragAndDrop';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { styleProps } from '../../../styles/styleProps';
import * as dateFns from 'date-fns';
import csv from '../../../assets/Csv-template/SampleAttendance.csv';

const { colors } = styleProps;

const EmployeeCsvUploardModal = ({ visible, setVisible, onFinish }) => {
  const [files, setFiles] = useState(null);
  const uploadButtonDisbaled = Boolean(!files);

  useEffect(() => {
    setFiles(null);
  }, [visible]);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
  const Years = Array.from(
    new Array(5),
    (val, index) => new Date().getFullYear() - index,
  );
  const Months = Array.from(new Array(12), (val, index) =>
    dateFns.format(new Date(0, index), 'MMMM'),
  );
  const [selectedMonth, setSelectedMonth] = useState(
    Months[new Date().getMonth()],
  );
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const invalidFileFormatErrorHandler = () => {
    setIsDialogBoxOpen(true);
    setErrorMessage('File format does not match. Please upload a CSV file');
    setSuccessMessage('');
    setFiles(null);
  };

  const closeInvalidFileFormatErrorHandler = () => {
    setIsDialogBoxOpen(false);
    setErrorMessage('');
    setSuccessMessage('');
    setFiles(null);
  };

  const handleCloseModal = () => {
    setVisible(false);
    setFiles(null);
    setSelectedMonth(Months[new Date().getMonth()]);
    setSelectedYear(new Date().getFullYear());
  };

  const enableUploadButton = (formData) => {
    setFiles(formData);
  };

  const uploadCsv = () => {
    const authToken = localStorage.getItem('loggedInAuth');

    if (files) {
      files.get('month')
        ? files.set('month', selectedMonth)
        : files.append('month', selectedMonth);
      files.get('year')
        ? files.set('year', selectedYear)
        : files.append('year', selectedYear);
    } else {
      setIsDialogBoxOpen(true);
      setSuccessMessage('');
      setErrorMessage('Please upload a CSV file');
      return;
    }

    axios
      .post('/api/paystub/noPay', files, {
        headers: {
          Authorization: `"${authToken}"`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          handleCloseModal();
          onFinish();
        }
      })
      .catch((err) => {
        setIsDialogBoxOpen(true);
        setSuccessMessage('');
        setErrorMessage(err.message);
      });
  };

  const MonthsRenderer = () => {
    return Months.map((month) => (
      <MenuItem key={month} value={month}>
        {month}
      </MenuItem>
    ));
  };

  const YearsRenderer = () => {
    return Years.map((year) => (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    ));
  };

  return (
    <div>
      <Modal open={visible} onClose={handleCloseModal}>
        <Box sx={style}>
          <Stack
            justifyContent='space-between'
            direction='row'
            marginBottom={7}
          >
            <Stack width={800} textAlign={'center'} direction='column'>
              <Typography variant='h7' fontSize={25} gutterBottom>
                Employee Data for Payroll Calculation
              </Typography>
              <Typography
                variant='h7'
                fontSize={15}
                color={colors.textLightGrey}
              >
                Upload a CSV file of Employee data for payroll calculation,
                including No Pay, Allowances, and Deductions.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={'column'} width={'100%'}>
            <Stack direction='column' gap={2}>
              <Typography variant='h7' sx={typographyStyle}>
                Choose the Year & Month
              </Typography>
              <Stack direction='row' gap={2} marginBottom={5}>
                <FormControl sx={{ width: 150 }} size='small'>
                  <InputLabel>Year</InputLabel>
                  <Select
                    label='Year'
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    {YearsRenderer()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 150 }} size='small'>
                  <InputLabel>Month</InputLabel>
                  <Select
                    label='Month'
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    {MonthsRenderer()}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <Stack width={'100%'} alignSelf={'center'} direction='row' gap={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant='h7' sx={typographyStyle}>
                  Choose the CSV file
                </Typography>
                <Box sx={dragNDropStyles}>
                  <DragDropFiles
                    fileStatus={invalidFileFormatErrorHandler}
                    removeFile={closeInvalidFileFormatErrorHandler}
                    buttonStatus={enableUploadButton}
                  />
                </Box>
              </Box>
              <Box sx={{ marginTop: 5 }}>
                <Typography sx={typographyStyle2}>
                  <span style={{ fontSize: '16px' }}>
                    File Upload Guidelines
                  </span>

                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={spanStyle}>
                      <CircleRoundedIcon sx={{ fontSize: '10px' }} /> File
                      should be in CSV file format.
                    </span>
                    <span style={spanStyle}>
                      <CircleRoundedIcon sx={{ fontSize: '10px' }} /> Must
                      contain title column.
                    </span>
                  </span>

                  <a style={downloadButtonStyle} href={csv} download>
                    Download sample CSV
                  </a>
                </Typography>
              </Box>
            </Stack>
            <Stack justifyContent='space-between' direction='row'>
              <Box sx={{ width: 500 }}>
                <Collapse in={isDialogBoxOpen}>
                  {errorMessage !== '' && successMessage === '' ? (
                    <Alert severity='error'>{errorMessage}</Alert>
                  ) : (
                    ''
                  )}
                  {successMessage !== '' && errorMessage === '' ? (
                    <Alert severity='success'>{successMessage}</Alert>
                  ) : (
                    ''
                  )}
                </Collapse>
              </Box>
            </Stack>
            <br /> <br />
            <Stack
              spacing={1}
              direction='row'
              marginTop={3}
              alignSelf={'center'}
            >
              <MediumPrimaryBtn
                onClick={uploadCsv}
                variant='contained'
                disabled={uploadButtonDisbaled}
                text={'Upload CSV'}
              ></MediumPrimaryBtn>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default EmployeeCsvUploardModal;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  paddingY: 3,
  paddingX: 20,
  background: '#FFFFFF',
  borderRadius: '8px',
};

const typographyStyle2 = {
  color: colors.textDarkGrey,
  fontWeight: 500,
  fontSize: '20px',
  gap: 3,
  display: 'flex',
  flexDirection: 'column',
};

const typographyStyle = {
  color: '#000000',
  fontWeight: '500',
  fontSize: '16px',
};

const spanStyle = {
  fontSize: '14px',
  color: '#979797',
};

const dragNDropStyles = {
  display: 'flex',
  borderRadius: 4,
  border: '2px solid #0666EB',
  borderStyle: 'dashed',
  padding: 1,
};

const downloadButtonStyle = {
  textDecoration: 'none',
  color: colors.primaryBlue,
  fontWeight: 'bold',
  fontSize: '16px',
};
