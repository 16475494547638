/* eslint-disable react/prop-types */
import {
  Grid,
  Typography,
  styled,
  IconButton,
  Alert,
  Stack,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import EditIcon from '@mui/icons-material/Edit';

import UserProfile from './UserProfile';
import EditUserDetailsModal from './EditUserDetailsModal';
import EditCompanyDetailsModal from './EditCompanyDetailsModal';
import EditCompanyProfileDetailsModal from '../../components/profile/EditCompanyProfileDetailsModal';
import { styleProps } from '../../styles/styleProps';
import SignatureUpload from './SignatureUpload';

const { colors } = styleProps;

const TitleText = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  paddingTop: 10,
  color: colors.textLightGrey,
});

const PaystubAlert = styled(Alert)({
  color: colors.btnPrimary,
  fontWeight: 400,
});

const ProfileDetails = ({
  user,
  refreshData,
  companyData,
  currencyList,
  fieldList,
  countryCode,
  number,
  companyNumber,
  companyCountryCodeValue,
}) => {
  const [userDetailModalStatus, setUserDetailModalStatus] = useState(false);
  const [companyProfileEditModalStatus, setCompanyProfileEditModalStatus] =
    useState(false);
  useState(false);
  const [companyDetailModalStatus, setCompanyDetailModalStatus] =
    useState(false);

  const openUserDetailModal = (type) => {
    if (type === 'User Profile') {
      setUserDetailModalStatus(true);
    } else if (type === 'Company Details') {
      setCompanyDetailModalStatus(true);
    } else {
      setCompanyProfileEditModalStatus(true);
    }
  };

  const closeUserModal = () => {
    setUserDetailModalStatus(false);
  };

  const closeCompanyModal = () => {
    setCompanyProfileEditModalStatus(false);
  };

  const closeCompanyProfileModal = () => {
    setCompanyDetailModalStatus(false);
  };
  const signatureUploadButton = <SignatureUpload />;

  const ProfileEditModal = () => {
    return (
      <>
        {user && companyData && currencyList ? (
          <EditUserDetailsModal
            modalStatus={userDetailModalStatus}
            userDetails={user}
            onClose={closeUserModal}
            refreshData={refreshData}
            countryCodeValue={countryCode}
            number={number}
          />
        ) : null}
      </>
    );
  };

  const CompanyDetailsModal = () => {
    return (
      <>
        {user && companyData ? (
          <EditCompanyProfileDetailsModal
            modalStatus={companyDetailModalStatus}
            onClose={closeCompanyProfileModal}
            refreshData={refreshData}
            companyNumber={companyNumber}
            companyProfileData={companyData}
            companyCountryCodeValue={companyCountryCodeValue}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      {user && companyData && currencyList ? (
        <>
          <CompanyDetailsModal />

          <EditCompanyDetailsModal
            modalStatus={companyProfileEditModalStatus}
            onClose={closeCompanyModal}
            companyData={companyData}
            currencyList={currencyList}
            fieldList={fieldList}
            refreshData={refreshData}
            feild2Value={companyData.companyFieldId}
            feild3Value={companyData.currencyCode}
          />

          <ProfileEditModal />

          <Grid container>
            <UserProfile
              titleText={'User Profile'}
              felid1={'Name'}
              felid2={'Email'}
              felid3={'Mobile No:'}
              felid4={'Signature'}
              feild1Value={user.adminName}
              feild2Value={user.adminEmail}
              feild3Value={user.adminMobile}
              openModal={openUserDetailModal}
              signatureUploadButton={signatureUploadButton}
              role={user.adminRole}
            />
            <br />
            <UserProfile
              titleText={'Company Profile'}
              felid1={'Company Name'}
              felid2={'Field'}
              felid3={'Default Currency'}
              feild1Value={companyData.companyName}
              feild2Value={companyData.companyField}
              feild3Value={companyData.companyCurrencyCode}
              openModal={openUserDetailModal}
              role={user.adminRole}
            />
            <br />

            {companyData && companyData.companyDetailsComplete ? (
              <Box
                width={'100%'}
                sx={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '3%' }}
              >
                <Stack
                  direction='row'
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <TitleText sx={{ fontWeight: 600, marginBottom: '30px' }}>
                    Company Details
                  </TitleText>
                  {user.adminRole === 'super-admin' ? (
                    <IconButton
                      onClick={() => openUserDetailModal('Company Details')}
                    >
                      <EditIcon
                        sx={{ fontSize: 18, color: colors.primaryBlue }}
                      />
                    </IconButton>
                  ) : null}
                </Stack>
                <LineData
                  title={'Company Address'}
                  value={
                    companyData.companyAddressOne +
                    ', ' +
                    companyData.companyAddressTwo +
                    ', ' +
                    companyData.companyCity +
                    ', ' +
                    companyData.companyCountry
                  }
                />
                <LineData
                  title={'Contact No:'}
                  value={companyData.companyMobile}
                />
                <LineData
                  title={'Company Email'}
                  value={companyData.companyEmail}
                />
                <LineData
                  title={'Pay Date'}
                  value={companyData.companyPayDay}
                />
              </Box>
            ) : (
              <Grid
                container
                sx={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '3%' }}
              >
                <Grid item xs={12} md={5}>
                  <TitleText sx={{ fontWeight: 600 }}>
                    Company Details
                  </TitleText>
                </Grid>
                <Grid item xs={12} md={7} sx={{ textAlign: 'right' }}>
                  {!user.adminRole === 'super-admin' ? (
                    <IconButton
                      onClick={() => openUserDetailModal('Company Details')}
                    >
                      <EditIcon
                        sx={{ fontSize: 18, color: colors.primaryBlue }}
                      />
                    </IconButton>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={12} sx={{ paddingTop: '3%' }}>
                  {user.adminRole === 'admin' ? null : (
                    <PaystubAlert variant='outlined' severity='info'>
                      Please Complete company details to proceed with uploading
                      paystubs of employees
                    </PaystubAlert>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <div style={{ padding: '50px 20px 10px 20px' }}>
          <SkeletonTheme baseColor='#F5F7F8' highlightColor='#ffff'>
            <p>
              <Skeleton count={1} height={30} />
              <br />
              <Skeleton count={4} height={30} />
              <br />
              <br />
              <Skeleton count={1} height={30} />
              <br />
              <Skeleton count={4} height={30} />
              <br />
              <br />
              <Skeleton count={1} height={30} />
              <br />
              <Skeleton count={4} height={30} />
            </p>
          </SkeletonTheme>
        </div>
      )}
    </>
  );
};

export default ProfileDetails;

const LineData = ({ title, value }) => {
  return (
    <Stack
      direction='row'
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      marginTop={'10px'}
    >
      <Typography sx={{ fontWeight: 400, color: '#71717A', fontSize: '16px' }}>
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '16px',
          color: colors.textDarkGrey,
          textAlign: 'right',
          width: '70%',
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};
