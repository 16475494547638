import { getWeeklyReportsOfTheMonth } from '../../services/ProjectReportService';

export const getReportsOfTheMonth = async ({ params }) => {
  {
    const weeklyReports = await getWeeklyReportsOfTheMonth(params.id);
    if (weeklyReports) {
      return weeklyReports.message;
    } else return [];
  }
};
