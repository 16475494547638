/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Stack,
  Typography,
  Alert,
  Collapse,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

import MediumPrimaryBtn from '../../buttons/MediumPrimaryBtn';
import DragDropFiles from '../../paystubs/csv/DragDropFiles';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { styleProps } from '../../../styles/styleProps';
import csv from '../../../assets/Csv-template/SampleEmployeeBulkNimiKash.csv';

const { fonts, colors } = styleProps;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '961px',
  height: '637px',
  background: '#FFFFFF',
  borderRadius: '8px',
};

const typographyStyle2 = {
  color: colors.textDarkGrey,
  marginRight: '8%',
  fontWeight: 500,
  fontSize: '20px',
};

const typographyStyle3 = {
  color: colors.primaryBlue,
  fontWeight: 'bold',
  fontSize: '16px',
  fontFamily: fonts.primary,
};

const typographyStyle = {
  marginTop: '2%',
  color: '#000000',
  marginLeft: '3%',
  fontWeight: 'bold',
  fontSize: '16px',
};

const spanStyle = {
  fontSize: '14px',
  color: '#979797',
};

const EmployeeCsvUploardModal = ({
  modalStatus,
  onClose,
  whileAdminRegister,
  nextFunctoin,
}) => {
  const [csvUploardButtonStatus, setCsvUploardButtonStatus] = useState(true);
  const [files, setFiles] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);

  const invalidFileFormatErrorHandler = () => {
    setIsDialogBoxOpen(true);
    setErrorMessage('File format does not match. Please upload a CSV file');
    setSuccessMessage('');
  };

  const closeInvalidFileFormatErrorHandler = () => {
    setIsDialogBoxOpen(false);
    setErrorMessage('');
    setSuccessMessage('');
    setCsvUploardButtonStatus(true);
  };

  const closeCsvModal = () => {
    setIsDialogBoxOpen(false);
    setErrorMessage('');
    setSuccessMessage('');
    setCsvUploardButtonStatus(true);
    onClose();
  };

  const enableUploadButton = (formData) => {
    setCsvUploardButtonStatus(false);
    setFiles(formData);
  };

  //Uploard CSV
  const enableUpload = () => {
    let authToken = '';
    if (whileAdminRegister) {
      authToken = localStorage.getItem('auth');
    } else {
      authToken = localStorage.getItem('loggedInAuth');
    }
    axios({
      method: 'post',
      url: '/api/employees/upload',
      data: files,
      headers: {
        Authorization: `"${authToken}"`,
      },
    })
      .then(function (response) {
        if (response.data.success) {
          if (response.data.message.newEmployees.length == 0) {
            throw new Error(
              'No any employee uploaded. Please check the data and re-upload.',
            );
          }
          setErrorMessage('');

          let message;
          let severity;

          if (
            response.data.message.existingEmployees.length == 0 &&
            response.data.message.invalidEmails.length == 0 &&
            response.data.message.duplicatedEmployeeIds.length == 0 &&
            response.data.message.invalidEmployeeIds.length == 0 &&
            response.data.message.invalidDesignations.length == 0 &&
            response.data.message.invalidSalaries.length == 0 &&
            response.data.message.invalidCurrencyCode.length == 0 &&
            response.data.message.newEmployees.length != 0
          ) {
            message = 'Employee bulk uploaded Successfully';
            severity = 'success';
          } else if (
            response.data.message.existingEmployees.length == 0 ||
            response.data.message.invalidEmails.length == 0 ||
            response.data.message.duplicatedEmployeeIds.length == 0 ||
            response.data.message.invalidEmployeeIds.length == 0 ||
            response.data.message.invalidDesignations.length == 0 ||
            response.data.message.invalidSalaries.length == 0 ||
            response.data.message.invalidCurrencyCode.length == 0
          ) {
            message =
              'Failed to upload some records. You will receive an email containing additional information.';
            severity = 'warning';
          }

          if (whileAdminRegister) {
            nextFunctoin(message, severity);
          } else {
            nextFunctoin(message, severity);
            closeCsvModal();
          }
        } else {
          throw new Error(response.data.error);
        }
      })
      .catch(function (error) {
        setIsDialogBoxOpen(true);
        setSuccessMessage('');
        setErrorMessage(error.message);
      });
  };

  return (
    <div>
      <Modal open={modalStatus} onClose={!whileAdminRegister && closeCsvModal}>
        <Box sx={{ ...style, width: 961 }}>
          <Stack
            justifyContent='space-between'
            direction='row'
            marginBottom={7}
          >
            <Stack width={800} textAlign={'center'} direction='column'>
              <Typography
                sx={{
                  marginTop: '3%',
                  color: '#2A2A2A',
                  fontWeight: 500,
                  fontSize: '24px',
                }}
              >
                Employee Bulk Upload
              </Typography>
              <Typography
                sx={{
                  // marginTop: '3%',
                  color: '#71717A',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Upload a CSV file of Employee details to bulk upload employees
              </Typography>
            </Stack>

            {!whileAdminRegister && (
              <Button
                onClick={closeCsvModal}
                style={{
                  backgroundColor: 'none',
                  '&:hover': {
                    backgroundColor: '#FFDFAC',
                  },
                  color: '#2A2A2A',
                  marginTop: 0,
                }}
              >
                <CloseIcon />
              </Button>
            )}
          </Stack>
          <Stack direction={'column'} width={'100%'} paddingX={0}>
            <Stack justifyContent='space-between' direction='row'>
              <Typography sx={typographyStyle}>Choose the CSV file</Typography>
            </Stack>
            <Stack
              justifyContent='space-between'
              width={'100%'}
              alignSelf={'center'}
              direction='row'
            >
              <Box
                sx={{
                  width: 500,
                  marginLeft: '3%',
                  height: 250,
                  borderRadius: '1%',
                  border: '2px solid #979797',
                  borderStyle: 'dashed',
                }}
              >
                <DragDropFiles
                  fileStatus={invalidFileFormatErrorHandler}
                  removeFile={closeInvalidFileFormatErrorHandler}
                  buttonStatus={enableUploadButton}
                  user={null}
                />
              </Box>
              <Typography sx={typographyStyle2}>
                <span style={{ fontSize: '16px' }}>File Upload Guidelines</span>
                <br />
                <br />
                <span style={spanStyle}>
                  <CircleRoundedIcon sx={{ fontSize: '10px' }} /> File should be
                  in CSV file format.
                </span>
                <br />
                <span style={spanStyle}>
                  <CircleRoundedIcon sx={{ fontSize: '10px' }} /> Must contain
                  title column.
                </span>
                <br />
                <span style={spanStyle}>
                  <CircleRoundedIcon sx={{ fontSize: '10px' }} /> Currencies
                  should be in ISO standards
                </span>
                <br />
                <span style={spanStyle}>
                  <CircleRoundedIcon sx={{ fontSize: '10px' }} /> Currencies
                  should be in capital letters
                </span>
                <br />
                <br />
                <span style={typographyStyle3}>
                  {' '}
                  <a
                    style={{
                      textDecoration: 'none',
                      color: colors.primaryBlue,
                      fontWeight: 'bold',
                    }}
                    href={csv}
                    download
                  >
                    Download sample CSV
                  </a>
                </span>
              </Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row'>
              <Box sx={{ width: 500, marginLeft: '3%' }}>
                <Collapse in={isDialogBoxOpen}>
                  {errorMessage != '' && successMessage == '' ? (
                    <Alert severity='error'>{errorMessage}</Alert>
                  ) : (
                    ''
                  )}
                  {successMessage != '' && errorMessage == '' ? (
                    <Alert severity='success'>{successMessage}</Alert>
                  ) : (
                    ''
                  )}
                </Collapse>
              </Box>
            </Stack>
            <br /> <br />
            <Stack spacing={1} direction='row' alignSelf={'center'}>
              <MediumPrimaryBtn
                onClick={enableUpload}
                variant='contained'
                disabled={csvUploardButtonStatus}
                text={'Upload CSV'}
              ></MediumPrimaryBtn>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default EmployeeCsvUploardModal;
