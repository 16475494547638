import PlatformIntegrationService from './AbstractPlatformIntegrationService';
import UrlRoute from '../configs/UrlRoute';

const getCurrentAdmin = async () => {
  const res = await PlatformIntegrationService.executeHttpGetRequest(
    UrlRoute.GET_ADMIN,
  );
  return res.data;
};

const getAdmins = async (companyId) => {
  const res = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.GET_ADMIN}?companyId=${companyId}`,
  );
  return res.data;
};

const adminRegister = async (data) => {
  const res = await PlatformIntegrationService.executeHttpPostRequest(
    UrlRoute.ADMIN_REGISTER,
    data,
  );
  return res?.data;
};

const adminUpdate = async (data) => {
  const res = await PlatformIntegrationService.executeHttpPutRequest(
    UrlRoute.ADMIN_UPDATE,
    data,
  );
  return res.data;
};

const adminInvite = async (data) => {
  const res = await PlatformIntegrationService.executeHttpPostRequest(
    UrlRoute.ADMIN_INVITE,
    data,
  );
  return res.data;
};

const adminPasswordReset = async (data) => {
  const res = await PlatformIntegrationService.executeHttpPostRequest(
    UrlRoute.ADMIN_PASSWORD_RESET,
    data,
  );
  return res.data;
};

const adminPasswordUpdate = async (data) => {
  const res = await PlatformIntegrationService.executeHttpPutRequest(
    UrlRoute.ADMIN_PASSWORD_UPDATE,
    data,
  );
  return res.data;
};

const adminPasswordCheck = async (data) => {
  const res = await PlatformIntegrationService.executeHttpPostRequest(
    UrlRoute.ADMIN_PASSWORD_CHECK,
    data,
  );
  return res.data;
};

export {
  getCurrentAdmin,
  getAdmins,
  adminRegister,
  adminUpdate,
  adminInvite,
  adminPasswordReset,
  adminPasswordUpdate,
  adminPasswordCheck,
};
