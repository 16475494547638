import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import Selector from '../selector';
import FileIUpload from '../../inputs/fileUpload';
import PrimaryButton from '../../buttons/PrimaryButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const OfferLetterUploadModal = ({ open, setOpen }) => {
  const filesAccepted = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const formSchema = Yup.object().shape({
    letterType: Yup.string().required('Required'),
    hiringEntity: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    currency: Yup.string().required('Required'),
    file: Yup.mixed().required('Required'),
  });

  const initailState = {
    letterType: '',
    hiringEntity: '',
    country: '',
    currency: '',
    file: null,
  };

  const formik = useFormik({
    initialValues: initailState,
    validationSchema: formSchema,
    onSubmit: (values) => {
      console.log(values); // TODO : send the data to backend
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  // TODO: Remove these mock data and get the data from the API
  const letterTypes = ['Intern', 'Contract', 'Permanent'];
  const hiringEntities = ['Nimi Tech Limited', 'Nimi LLC'];
  const contries = ['Sri Lanka', 'United States', 'India'];
  const currencies = ['LKR', 'USD', 'INR'];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={open}>
        <Box sx={styles.modal}>
          <Typography sx={styles.heading}>Offer letter template</Typography>
          <Typography sx={styles.subTitle}>Subtitle</Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={styles.uploadContainer}>
              <Typography>Choose the offer letter properties</Typography>
              <Stack
                direction='row'
                spacing={2}
                sx={{ marginTop: '24px', marginBottom: '40px' }}
              >
                <Selector
                  name='letterType'
                  label='Letter type'
                  options={letterTypes}
                  value={formik.values.letterType}
                  onChange={formik.handleChange}
                />
                <Selector
                  name='hiringEntity'
                  label='Hiring entity'
                  options={hiringEntities}
                  value={formik.values.hiringEntity}
                  onChange={formik.handleChange}
                />
                <Selector
                  name='country'
                  label='Country'
                  options={contries}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                />
                <Selector
                  name='currency'
                  label='Currency'
                  options={currencies}
                  value={formik.values.currency}
                  onChange={formik.handleChange}
                />
              </Stack>
              <Typography marginBottom={'20px'}>
                Choose the template file
              </Typography>
              <Box display={'flex'}>
                <FileIUpload
                  files={formik.values.file}
                  setFiles={(files) => formik.setFieldValue('file', files)}
                  onError={(error) => console.log(error)}
                  filesAccepted={filesAccepted}
                />
                <Box sx={{ paddingLeft: '40px' }}>
                  <Typography>File Upload Guidelines</Typography>
                  <Typography
                    color={'text.lightGray'}
                    fontSize={'14px'}
                    marginTop={'20px'}
                  >
                    <ul style={{ paddingLeft: '15px' }}>
                      <li>File should be in .doc/.docx format</li>
                      <li>Must match the correct template</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
              <PrimaryButton
                styles={styles.uploadButton}
                type='submit'
                disabled={!formik.isValid || !formik.dirty}
              >
                Upload
              </PrimaryButton>
            </Box>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

OfferLetterUploadModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

const styles = {
  modal: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1240px',
    height: '804px',
    borderRadius: '8px',
    bgcolor: 'white',
    boxShadow: 24,
  },
  heading: {
    fontSize: '28px',
    fontWeight: 500,
    marginTop: '30px',
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'text.lightGray',
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '60px',
    width: '766px',
    height: '594px',
  },
  uploadButton: { marginTop: 'auto', width: '400px', alignSelf: 'center' },
};

export default OfferLetterUploadModal;
