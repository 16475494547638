/* eslint-disable react/no-unescaped-entities */
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import TopBar from '../components/top-bar/TopBar';
import { getCurrentAdmin } from '../services/AdminService';
import { getProjects } from '../services/ProjectServices';
import ProjectsComponent from '../components/projects/projectsPage';
import { Typography } from '@mui/material';
import { styleProps } from '../styles/styleProps';
import Button from '../components/buttons/ShortEmployeePrimaryButton';
import AddProjectModal from '../components/projects/modal/createProject';

const ProjectsPage = () => {
  const [user, setUser] = useState('');
  const [projects, setProjects] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleOpen = () => {
    setVisible(true);
  };

  useEffect(() => {
    getCurrentAdmin().then((data) => {
      setUser(data);
    });
    getProjects()
      .then((data) => {
        setProjects(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [visible]);

  const NoProjectsFound = () => {
    return (
      <Stack
        flex={1}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        alignContent={'center'}
        height={'100%'}
        spacing={2}
      >
        <Typography
          align='center'
          variant={'h7'}
          color={styleProps.colors.textLightGrey}
        >
          Invite employee It looks like there are no projects created yet.
          <br />
          To get started, click the "Create a New Project" button
          <br />
          and start organizing your work for insightful reporting
          <br />
        </Typography>
        <Button text={'Create a New Project'} onClick={handleOpen} />
      </Stack>
    );
  };

  const ConditionalRender = () => {
    if (projects.length === 0 && !isLoading) {
      return <NoProjectsFound />;
    }
    return (
      <ProjectsComponent
        projects={projects}
        setVisible={setVisible}
        isLoading={isLoading}
      />
    );
  };

  return (
    <>
      <TopBar title={'Projects'} user={user} />
      <Box flex={1}>
        <Box
          style={{
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            bgcolor: 'green',
          }}
        ></Box>
        {ConditionalRender()}
      </Box>
      <AddProjectModal setVisible={setVisible} visible={visible} />
    </>
  );
};

export default ProjectsPage;
