export const getCurrentMonthDateRange = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const minDate = new Date(currentYear, currentMonth, 1);
  const maxDate = new Date(currentYear, currentMonth + 1, 0);

  return { minDate, maxDate };
};
