/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Box,
  Modal,
  Button,
  Stack,
  Typography,
  TextField,
  Select,
  styled,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import axios from 'axios';

import ShortPrimaryBtn from '../buttons/ShortPrimaryBtn';
import { styleProps } from '../../styles/styleProps';
import ErrorAlert from '../alerts/ErrorAlert';

import { EmployeeType } from '../../enums/Employee';
import { getAllCurrencies } from '../../services/CurrencyService';

const { fonts, colors } = styleProps;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#FFFFFF',
  borderRadius: '8px',
};

const typographyStyle1 = {
  color: colors.textLightGrey,
  marginLeft: '3%',
  marginTop: '2%',
  fontWeight: 400,
  fontSize: '16px',
};

const TextInput = styled(TextField)({
  borderRadius: '50%',
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: fonts.primary,
  },
  '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

const menuItemStyles = {
  m: 0.5,
  '&:focus': {
    background: colors.btnPrimary,
    color: 'white',
    m: 0.5,
    borderRadius: 1,
    '&:hover': {
      background: colors.btnPrimary,
      color: 'white',
      m: 0.5,
      borderRadius: 1,
    },
  },
};

const InviteEmployeeModal = ({
  modalStatus,
  onClose,
  refreshData,
  openInviteSuccessModal,
}) => {
  const [currency, setCurrency] = useState('');

  let [bulkFields, setBulkFields] = useState([]);
  const [employeeId, setEmployeeId] = useState('');
  const [email, setEmail] = useState('');
  const [designation, setDesignation] = useState('');
  const [salary, setSalary] = useState('');
  const [type, setType] = useState(EmployeeType.Permanent);
  const [companyCurrency, setCompanyCurrency] = useState([]);
  const [emailErrorStatus, setEmailErrorStatus] = useState(false);
  const [emptyFieldsAlert, setEmptyFieldsAlert] = useState(false);
  const [bulkEmailErrorStatus, setBulkEmailErrorStatus] = useState(false);
  const [existingEmployees, setExistingEmployees] = useState([]);
  const [duplicatedEmployeeIds, setDuplicatedEmployeeIds] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [salaryErrorStatus, setSalaryErrorStatus] = useState(false);
  const [invalidDesignations, setInvalidDesignations] = useState([]);
  const [designationErrorState, setDesignationErrorState] = useState(false);
  const [emptyFieldsStatus, setEmptyFieldsStatus] = useState(true);

  //validations
  const [isEmployeeIdEmpty, setIsEmployeeIdEmpty] = useState(false);

  useEffect(() => {
    getAllCurrencies().then((data) => {
      setCompanyCurrency(data);
    });
  }, []);

  const BulkInviteFields = (index, data) => {
    return (
      <>
        <Stack
          spacing={1}
          direction='row'
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            px: 3,
          }}
        >
          <TextInput
            sx={{ width: 180, borderRadius: '50%' }}
            value={data.employeeId}
            onChange={(e) => changeEmployeeId(e, index)}
          />

          <TextInput
            sx={{ width: 400 }}
            placeholder='Email'
            value={data.email}
            onChange={(e) => changeEmail(e, index)}
          />
          <TextInput
            sx={{ width: 180 }}
            placeholder='Designation'
            value={data.designation}
            onChange={(e) => changeDesignation(e, index)}
          />

          <Select
            sx={{ width: 151 }}
            value={data.currency}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={(e) => changeCurrency(e, index)}
          >
            {companyCurrency.map((item, index) => (
              <MenuItem
                sx={menuItemStyles}
                key={index}
                value={item.currencyCode}
              >
                {item.currency}
              </MenuItem>
            ))}
          </Select>

          <Select
            sx={{ width: 180 }}
            inputProps={{ 'aria-label': 'Without label' }}
            value={data.type}
            onChange={(e) => changeEmployeeType(e, index)}
          >
            {Object.keys(EmployeeType).map((key) => (
              <MenuItem sx={menuItemStyles} key={key} value={EmployeeType[key]}>
                {key}
              </MenuItem>
            ))}
          </Select>

          <TextInput
            sx={{ width: 180 }}
            placeholder='Salary'
            value={data.salary}
            onChange={(e) => changeSalary(e, index)}
          />
          <IconButton onClick={() => removeRecord(index)}>
            <DeleteOutlineIcon sx={{ fontSize: 35, color: '#EA5455' }} />
          </IconButton>
        </Stack>
        <br />
      </>
    );
  };

  useEffect(() => {
    if (companyCurrency != [] && email != '' && employeeId != '') {
      setEmptyFieldsStatus(false);
    } else {
      setEmptyFieldsStatus(true);
    }
  }, [companyCurrency, email, employeeId]);

  const changeEmployeeId = (e, i) => {
    setEmptyFieldsAlert(false);
    let bulkFieldsCopy = [...bulkFields];
    bulkFieldsCopy[i].employeeId = e.target.value;
    setBulkFields(bulkFieldsCopy);
  };

  const changeEmail = (e, i) => {
    setEmptyFieldsAlert(false);
    setBulkEmailErrorStatus(false);
    let bulkFieldsCopy = [...bulkFields];
    bulkFieldsCopy[i].email = e.target.value;

    setBulkFields(bulkFieldsCopy);
  };

  const changeDesignation = (e, i) => {
    setEmptyFieldsAlert(false);
    setBulkEmailErrorStatus(false);
    setDesignationErrorState(false);
    let bulkFieldsCopy = [...bulkFields];
    bulkFieldsCopy[i].designation = e.target.value;

    setBulkFields(bulkFieldsCopy);
  };

  const changeSalary = (e, i) => {
    setDesignationErrorState(false);
    let bulkFieldsCopy = [...bulkFields];
    bulkFieldsCopy[i].salary = e.target.value;
    setBulkFields(bulkFieldsCopy);
  };

  const changeCurrency = (e, i) => {
    setEmptyFieldsAlert(false);
    let bulkFieldsCopy = [...bulkFields];
    bulkFieldsCopy[i].currency = e.target.value;
    setBulkFields(bulkFieldsCopy);
  };

  const changeEmployeeType = (e, i) => {
    setEmptyFieldsAlert(false);
    let bulkFieldsCopy = [...bulkFields];
    bulkFieldsCopy[i].type = e.target.value;
    setBulkFields(bulkFieldsCopy);
  };

  const removeRecord = (index) => {
    bulkFields.splice(index, 1);
    setBulkFields(bulkFields.filter((field) => field.index !== index));
  };

  const addNewRow = () => {
    if (!/^\d+\.\d{2}$/.test(salary)) {
      setSalaryErrorStatus(true);
      setCurrency('');
      setEmployeeId('');
      setEmail('');
      setSalary('');
      setType(EmployeeType.Permanent);
      setDesignation('');
    } else if (
      employeeId != '' &&
      email != '' &&
      currency != '' &&
      designation != '' &&
      salary != '' &&
      type != ''
    ) {
      if (employeeId.trim().length != 0) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          setBulkFields((current) => [
            ...current,
            {
              employeeId: employeeId,
              email: email,
              currency: currency,
              designation: designation,
              salary: salary,
              type: type,
            },
          ]);
          setCurrency('');
          setEmployeeId('');
          setEmail('');
          setDesignation('');
          setType(EmployeeType.Permanent);
          setSalary('');
          setEmailErrorStatus(false);
          setSalaryErrorStatus(false);
          setDesignationErrorState(false);
          setEmptyFieldsStatus(true);
        } else {
          setEmailErrorStatus(true);
          setDesignationErrorState(false);
          setCurrency('');
          setEmployeeId('');
          setEmail('');
          setSalary('');
          setDesignation('');
          setType(EmployeeType.Permanent);
          setEmptyFieldsStatus(true);
        }
      } else {
        setIsEmployeeIdEmpty(true);
      }
    }
  };

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleSalary = () => {
    const inputValue = salary;
    // Format the numeric value to always have two decimal places
    const formattedValue = parseFloat(inputValue).toFixed(2);
    setSalary(formattedValue.toString());
    setSalaryErrorStatus(false);
  };

  //function need to handle
  const inviteEmployeeList = () => {
    let isFormInvalid = false;
    for (let i = 0; i < bulkFields.length; ++i) {
      if (
        bulkFields[i].employeeId === '' ||
        bulkFields[i].email === '' ||
        bulkFields[i].currency === '' ||
        bulkFields[i].designation === '' ||
        bulkFields[i].salary === '' ||
        bulkFields[i].type === ''
      ) {
        setEmptyFieldsAlert(true);
        isFormInvalid = true;
      }

      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          bulkFields[i].email,
        ) === false
      ) {
        setBulkEmailErrorStatus(true);
        isFormInvalid = true;
      }
    }

    if (!isFormInvalid) {
      try {
        const body = {
          bulkFields,
        };

        axios
          .post('/api/employees/add', body, {
            headers: {
              Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
            },
          })
          .then(
            (response) => {
              if (
                response.data.message.newEmployees.length === bulkFields.length
              ) {
                clearBulkFields();
                openInviteSuccessModal();
                refreshData();
              } else {
                setInvalidEmails(response.data.message.invalidEmails);
                setExistingEmployees(response.data.message.existingEmployees);
                setDuplicatedEmployeeIds(
                  response.data.message.duplicatedEmployeeIds,
                );
                setInvalidDesignations(
                  response.data.message.invalidDesignations,
                );
              }
            },
            (error) => {
              if (error.response.status === 401) {
                localStorage.setItem('sessionValid', 'false');
                window.location = '/login';
              } else {
                window.location = '/error';
              }
            },
          );
      } catch (err) {
        console.error(err.message);
        window.location = '/error';
      }
    }
  };

  const clearBulkFields = () => {
    setBulkFields([]);
    setInvalidEmails([]);
    setInvalidDesignations([]);
    setExistingEmployees([]);
    setDuplicatedEmployeeIds([]);
    setSalaryErrorStatus(false);
    setEmailErrorStatus(false);
    setEmptyFieldsAlert(false);
    setBulkEmailErrorStatus(false);
    setDesignationErrorState(false);
    setEmptyFieldsStatus(true);
    onClose();
  };
  return (
    <div>
      <Modal open={modalStatus} onClose={clearBulkFields}>
        <Box sx={{ ...style, width: 1050 }}>
          <Stack justifyContent='space-between' direction='row'>
            <Typography
              sx={{
                margin: '3%',
                color: '#2A2A2A',
                fontWeight: 500,
                fontSize: '24px',
              }}
              id='modal-modal-title'
              variant='h6'
              component='h2'
            >
              Invite employee
            </Typography>
            <Button
              onClick={clearBulkFields}
              style={{
                backgroundColor: 'none',
                '&:hover': {
                  backgroundColor: '#FFDFAC',
                },
                color: '#2A2A2A',
                marginTop: 0,
              }}
            >
              <CloseIcon />
            </Button>
          </Stack>
          <hr style={{ border: '1px solid #E5E5E5' }}></hr>
          <br />
          <Stack
            spacing={1}
            direction='row'
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              px: 3,
            }}
          >
            <TextInput
              sx={{ width: 155, borderRadius: '50%' }}
              placeholder='Employee ID'
              value={employeeId}
              onChange={(e) => {
                setEmployeeId(e.target.value);
                setIsEmployeeIdEmpty(false);
              }}
            />

            <TextInput
              sx={{ width: 400 }}
              value={email}
              placeholder='Email'
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErrorStatus(false);
              }}
            />
            <TextInput
              sx={{ width: 180, borderRadius: '50%' }}
              value={designation}
              placeholder='Designation'
              onChange={(e) => {
                setDesignation(e.target.value);
                setDesignationErrorState(false);
              }}
            />

            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id='currency-label'>Currency</InputLabel>
                <Select
                  labelId='currency-label'
                  id='currency'
                  label='Currency'
                  value={currency}
                  onChange={handleChange}
                >
                  {companyCurrency.map((item, index) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={index}
                      value={item.currencyCode}
                    >
                      {item.currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Select
              sx={{ width: 180 }}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={(e) => {
                setType(e.target.value);
              }}
              value={type}
            >
              {Object.values(EmployeeType).map((key) => (
                <MenuItem
                  sx={menuItemStyles}
                  key={key}
                  value={EmployeeType[key]}
                >
                  {key}
                </MenuItem>
              ))}
            </Select>

            <TextInput
              sx={{ width: 180, borderRadius: '50%' }}
              value={salary}
              placeholder='Salary'
              type='number'
              onChange={(e) => {
                setSalary(e.target.value);
                setSalaryErrorStatus(false);
              }}
              onBlur={() => {
                handleSalary();
              }}
            />

            <IconButton onClick={addNewRow}>
              <AddCircleIcon
                sx={{
                  fontSize: 35,
                  color: '#0874FF',
                  opacity: `${emptyFieldsStatus === true ? '0.3' : '1'}`,
                }}
              />
            </IconButton>
          </Stack>
          {isEmployeeIdEmpty ? (
            <div style={{ padding: '25px' }}>
              {' '}
              <ErrorAlert text={'Invalid Employee ID'} />
            </div>
          ) : null}
          {emailErrorStatus === true ? (
            <div style={{ padding: '25px' }}>
              {' '}
              <ErrorAlert text={'Invalid Email'} />
            </div>
          ) : null}
          {designationErrorState === true ? (
            <div style={{ padding: '25px' }}>
              {' '}
              <ErrorAlert text={'Please enter a designation'} />
            </div>
          ) : null}
          {salaryErrorStatus && (
            <div style={{ padding: '25px' }}>
              <ErrorAlert text={'Invalid Salary'} />
            </div>
          )}
          {bulkFields && bulkFields.length >= 1 ? (
            <Stack justifyContent='space-between' direction='row'>
              <Typography
                sx={typographyStyle1}
                id='modal-modal-title'
                variant='h6'
                component='h2'
              >
                Employee invitation list
              </Typography>
            </Stack>
          ) : null}

          {bulkFields &&
            bulkFields.map((data, index) => BulkInviteFields(index, data))}

          {emptyFieldsAlert === true ? (
            <div style={{ padding: '0 25px 0 25px' }}>
              <ErrorAlert text={'Please fill all the fields'} />
            </div>
          ) : null}
          {bulkEmailErrorStatus === true ? (
            <div style={{ padding: '0 25px 0 25px' }}>
              <ErrorAlert text={'Please enter a Valid email address'} />
            </div>
          ) : null}
          {existingEmployees.length > 0 &&
            existingEmployees.map((item) => (
              <div style={{ padding: '0 25px 0 25px' }}>
                <ErrorAlert
                  text={
                    item.employeeWorkEmail + ' - email address already exists'
                  }
                />
              </div>
            ))}
          {duplicatedEmployeeIds.length > 0 &&
            duplicatedEmployeeIds.map((item) => (
              <div style={{ padding: '0 25px 0 25px' }}>
                <ErrorAlert
                  text={item.email + ' - employee id already exists'}
                />
              </div>
            ))}
          {invalidDesignations.length > 0 &&
            invalidDesignations.map((item) => (
              <div style={{ padding: '0 25px 0 25px' }}>
                <ErrorAlert text={item.email + ' - designation is empty'} />
              </div>
            ))}
          {invalidEmails.length > 0 &&
            invalidEmails.map((item) => (
              <div style={{ padding: '0 25px 0 25px' }}>
                <ErrorAlert
                  text={item.employeeWorkEmail + ' - email address is Invalid'}
                />
              </div>
            ))}
          <Stack
            spacing={1}
            direction='row'
            sx={{
              marginTop: 10,
              alignItems: 'right',
              justifyContent: 'right',
              marginRight: '4%',
              marginBottom: '3%',
            }}
          >
            <ShortPrimaryBtn
              sx={{
                marginLeft: '5px',
                marginRight: '10%',
              }}
              disabled={bulkFields.length != 0 ? false : true}
              onClick={inviteEmployeeList}
              variant='contained'
              text='Invite'
            ></ShortPrimaryBtn>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default InviteEmployeeModal;
