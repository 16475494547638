import UrlRoute from '../configs/UrlRoute';
import PlatformIntegrationService from './AbstractPlatformIntegrationService';

export const getAdvancePaymentRequests = async (status) => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.PAYMENT}/${status}`,
  );
  return data.message;
};

export const changeSalaryAdvanceStatus = async ({ status, id }) => {
  const data = await PlatformIntegrationService.executeHttpPutRequest(
    `${UrlRoute.PAYMENT}/${id}`,
    { status },
  );
  return data;
};
