import React, { useState, useEffect } from 'react';
import { Box, Typography, ListItem } from '@mui/material';
import { getNotes } from '../../../services/NoteService';
import { format } from 'date-fns';
import { styleProps } from '../../../styles/styleProps';

const { fonts } = styleProps;

const PayrollUpdatesLog = () => {
  const [updateLogNotes, setUpdateLogNotes] = useState([]);
  useEffect(() => {
    getUpdateLogNotes();
  }, []);

  async function getUpdateLogNotes() {
    const response = await getNotes();
    setUpdateLogNotes(response.message);
  }
  const formatCreatedAt = (createdAt) => {
    return format(new Date(createdAt), 'dd MMM yyyy');
  };
  return (
    <Box style={styles.container}>
      <Typography style={styles.title}>Payroll Updates Log</Typography>
      <Box style={styles.itemContainer}>
        {updateLogNotes.map((update, index) => (
          <ListItem style={styles.listContainer} key={index}>
            <Box style={styles.listNote} width={100} color={'#71717A'}>
              {formatCreatedAt(update.createdAt)}
            </Box>
            <Box style={styles.listNote}>{update.description}</Box>
          </ListItem>
        ))}
      </Box>
    </Box>
  );
};

export default PayrollUpdatesLog;

const styles = {
  container: {
    flex: '1',
    padding: '20px',
    borderRadius: '8px',
    border: '2px solid #DEEBFC',
    height: '345px',
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: fonts.primary,
    lineHeight: '32px',
  },
  listContainer: {
    flexDirection: 'row',
    gap: '10px',
    minHeight: '40px',
    alignItems: 'flex-start',
    marginTop: '8px',
    padding: '10px',
  },
  listNote: {
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: fonts.primary,
  },
  itemContainer: {
    marginTop: '20px',
    height: '90%',
    overflowY: 'auto',
  },
};
