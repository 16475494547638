/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Box,
  styled,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { styleProps } from '../../styles/styleProps';
import { invalidStyles } from '../../../src/styles/textFieldErrorStyles';
import ResponseBox from '../alerts/ResponseBox';
import { adminPasswordUpdate } from '../../services/AdminService';
import PrimaryButton from '../buttons/PrimaryButton';

const { colors } = styleProps;

const buttonBorderStyle = {
  border: '1px solid #FF7528',
  width: '15px',
  height: '15px',
  borderRadius: 100,
  marginTop: '3px',
};

const buttonStyle = {
  height: '5px',
  marginTop: '5px',
  marginLeft: '1.17px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
};

const flexStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const TextInput = styled(TextField)({
  marginTop: '80px',
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const InputContainerBox = styled(Box)({
  boxSizing: 'border-box',
  padding: '8% 12% 2% 12%',
  width: '100%',
  height: '80%',
});

const TitleContainer = styled(Box)({
  width: '100%',
  height: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Typography)({
  fontSize: '28px',
  fontWeight: 500,
  marginTop: '100px',
  color: colors.textDarkGrey,
});

const DetailsContainer = styled(Box)({
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '400px',
});

const ResetPassword = ({ type, email }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isEmptyBasicFields, setIsEmptyBasicFields] = useState(true);
  const [isPasswordChangeSuccessful, setIsPasswordChangeSuccessful] =
    useState(false);

  //Password checklist
  const [isCheckListEnabled, setIsCheckListEnabled] = useState(false);
  const [atLeastMinimumCharactors, setAtLeastMinimumCharactors] =
    useState(false);
  const [
    atLeastOneUpperAndLowerCaseLatter,
    setAtLeastOneUpperAndLowerCaseLatter,
  ] = useState(false);
  const [atLeastOneNumberAndLetter, setAtLeastOneNumberAndLetter] =
    useState(false);
  const [atLeastOneSpecialCharactor, setAtLeastOneSpecialCharactor] =
    useState(false);

  const initialValues = {
    userData: {
      password: '',
      confirmPassword: '',
    },
  };

  const validationSchema = Yup.object({
    userData: Yup.object().shape({
      password: Yup.string(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords do not match',
      ),
    }),
  });

  const onSubmit = () => {
    const password = formik.values.userData.password;
    const body = { password, email };

    adminPasswordUpdate(body).then(() => {
      setIsPasswordChangeSuccessful(true);
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    let res = checkInputFields(formik.values.userData);
    let status = passwordCheckListStatus();
    if (res === false && status === true) {
      setIsEmptyBasicFields(false);
    } else {
      setIsEmptyBasicFields(true);
    }
  }, [formik.values.userData]);

  const passwordCheckListStatus = () => {
    if (
      atLeastMinimumCharactors === true &&
      atLeastOneUpperAndLowerCaseLatter === true &&
      atLeastOneNumberAndLetter === true &&
      atLeastOneSpecialCharactor === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  //password validation
  useEffect(() => {
    //Enable/diable checklist
    if (formik.values.userData.password.length >= 1) {
      setIsCheckListEnabled(true);
    } else {
      setIsCheckListEnabled(false);
    }

    //Password length validation
    if (formik.values.userData.password.length > 7) {
      setAtLeastMinimumCharactors(true);
    } else {
      setAtLeastMinimumCharactors(false);
    }

    //Uppercase and Lowercase validation
    if (
      /[A-Z]/.test(formik.values.userData.password) &&
      /[a-z]/.test(formik.values.userData.password)
    ) {
      setAtLeastOneUpperAndLowerCaseLatter(true);
    } else {
      setAtLeastOneUpperAndLowerCaseLatter(false);
    }

    //Number and Letter validation
    if (
      /\d/.test(formik.values.userData.password) &&
      /[a-zA-Z]/.test(formik.values.userData.password)
    ) {
      setAtLeastOneNumberAndLetter(true);
    } else {
      setAtLeastOneNumberAndLetter(false);
    }

    //Special charactor validation
    if (
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(
        formik.values.userData.password,
      )
    ) {
      setAtLeastOneSpecialCharactor(true);
    } else {
      setAtLeastOneSpecialCharactor(false);
    }
  }, [formik.values.userData.password]);

  //Handle password visibility
  const handlePasswordVisibility = () => {
    setIsPasswordVisible((prevVal) => !prevVal);
  };

  const closeSuccessModal = () => {
    if (type === 'loggedIn') {
      try {
        axios
          .get('/api/user/logout', {
            headers: {
              Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
            },
          })
          .then((response) => {
            if (response.data.success === true) {
              //remove the auth from localStorage
              localStorage.removeItem('loggedInAuth');
              localStorage.removeItem('tempAuth');
              localStorage.removeItem('sessionValid');
              window.location = '/';
            }
          });
      } catch (err) {
        console.error(err.message);
        window.location = '/error';
      }
    } else {
      window.location = '/login';
    }
  };

  return (
    <>
      <ResponseBox
        text={'Password Reset Successfully'}
        modalStatus={isPasswordChangeSuccessful}
        onClose={closeSuccessModal}
      />
      <TitleContainer>
        <Title> Add New Password</Title>
      </TitleContainer>
      <InputContainerBox>
        <DetailsContainer
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ display: 'block', marginTop: '10%' }}>
            <TextInput
              label='Password'
              name='userData.password'
              type={isPasswordVisible ? 'text' : 'password'}
              value={formik.values.userData.password}
              onChange={formik.handleChange}
              sx={{ marginTop: '15px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' onClick={handlePasswordVisibility}>
                      {isPasswordVisible ? (
                        <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                      ) : (
                        <VisibilityOutlined sx={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <TextInput
              label='Confirm password'
              name='userData.confirmPassword'
              type={isPasswordVisible ? 'text' : 'password'}
              value={formik.values.userData.confirmPassword}
              onChange={(e) => {
                formik.handleChange(e);
                formik.errors?.userData?.confirmPassword &&
                  delete formik.errors.userData.confirmPassword;
              }}
              sx={invalidStyles(
                formik.errors?.userData?.confirmPassword ? false : true,
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' onClick={handlePasswordVisibility}>
                      {isPasswordVisible ? (
                        <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                      ) : (
                        <VisibilityOutlined sx={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
            {formik.errors?.userData?.confirmPassword && (
              <>
                <br />
                <Alert severity='error'>
                  {formik.errors.userData.confirmPassword}
                </Alert>
              </>
            )}
            {isCheckListEnabled === true ? (
              <Box m={2}>
                {atLeastMinimumCharactors === false ? (
                  <Stack direction='row' spacing={2} sx={{ color: 'red' }}>
                    <div style={buttonBorderStyle}>
                      <div style={buttonStyle}>
                        <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                      </div>
                    </div>
                    <Typography>At least 8 characters</Typography>
                  </Stack>
                ) : (
                  <Stack direction='row' spacing={2} sx={{ color: 'green' }}>
                    <div style={buttonBorderStyle}>
                      <div style={buttonStyle}>
                        <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                      </div>
                    </div>
                    <Typography>At least 8 characters</Typography>
                  </Stack>
                )}
                {atLeastOneUpperAndLowerCaseLatter === false ? (
                  <Stack direction='row' spacing={2} sx={{ color: 'red' }}>
                    <div style={buttonBorderStyle}>
                      <div style={buttonStyle}>
                        <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                      </div>
                    </div>
                    <Typography>
                      A mixture of both uppercase and lowercase letters
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction='row' spacing={2} sx={{ color: 'green' }}>
                    <div style={buttonBorderStyle}>
                      <div style={buttonStyle}>
                        <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                      </div>
                    </div>
                    <Typography>
                      A mixture of both uppercase and lowercase letters
                    </Typography>
                  </Stack>
                )}
                {atLeastOneNumberAndLetter === false ? (
                  <Stack direction='row' spacing={2} sx={{ color: 'red' }}>
                    <div style={buttonBorderStyle}>
                      <div style={buttonStyle}>
                        <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                      </div>
                    </div>
                    <Typography>A mixture of letters and numbers</Typography>
                  </Stack>
                ) : (
                  <Stack direction='row' spacing={2} sx={{ color: 'green' }}>
                    <div style={buttonBorderStyle}>
                      <div style={buttonStyle}>
                        <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                      </div>
                    </div>
                    <Typography>A mixture of letters and numbers</Typography>
                  </Stack>
                )}
                {atLeastOneSpecialCharactor === false ? (
                  <Stack direction='row' spacing={2} sx={{ color: 'red' }}>
                    <div style={buttonBorderStyle}>
                      <div style={buttonStyle}>
                        <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                      </div>
                    </div>
                    <Typography>
                      Inclusion of at least one special character, e.g., ! @ # ?
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction='row' spacing={2} sx={{ color: 'green' }}>
                    <div style={buttonBorderStyle}>
                      <div style={buttonStyle}>
                        <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                      </div>
                    </div>
                    <Typography>
                      Inclusion of at least one special character, e.g., ! @ # ?
                    </Typography>
                  </Stack>
                )}{' '}
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              ...flexStyles,
              flexDirection: 'column',
              marginBottom: '70px',
            }}
          >
            <PrimaryButton
              fullWidth
              disabled={isEmptyBasicFields}
              onClick={formik.handleSubmit}
            >
              Set new password
            </PrimaryButton>
          </Box>
        </DetailsContainer>
      </InputContainerBox>
    </>
  );
};

export default ResetPassword;
