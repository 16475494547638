/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
// this function will import all svgs in this folder
const reqSvgs = require.context('./', true, /\.svg$/);

// this will create an object with the name of the svg as the key and the svg as the value
const svgs = reqSvgs
  .keys()
  .map((path) => ({ path, file: reqSvgs(path), name: path.slice(2, -4) }))
  .reduce((acc, { path, file, name }) => {
    acc[name] = file;
    return acc;
  }, {});

export default svgs;
