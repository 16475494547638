import { Stack, Typography } from '@mui/material';
import React from 'react';
import { styleProps } from '../../styles/styleProps';

const NoReportsFound = () => {
  return (
    <Stack
      flex={1}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      alignContent={'center'}
      height={'100%'}
      spacing={2}
    >
      <Typography
        align='center'
        variant={'h7'}
        color={styleProps.colors.textLightGrey}
      >
        No reports available !
      </Typography>
    </Stack>
  );
};

export default NoReportsFound;
