/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';

const style = {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

export default function EditModal({
  title,
  data,
  fieldId,
  visible,
  onChange,
  handleClose,
}) {
  const [value, setValue] = useState(data[fieldId] || 0);

  useEffect(() => {
    setValue(data[fieldId] || 0);
  }, [data, fieldId, visible]);

  return (
    <div>
      <Modal open={visible} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant='h6' component='h' marginRight='auto'>
            {title}
          </Typography>
          <TextField
            sx={{ marginY: 2, width: '100%' }}
            variant='outlined'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type='number'
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant='outlined'
              sx={[styles.buttonStyle, styles.InvertedButtonStyle]}
              color='primary'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={styles.buttonStyle}
              onClick={() => {
                onChange(value ? parseFloat(value) : parseFloat(0));
                handleClose();
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

const styles = {
  buttonStyle: {
    margin: '0 .4rem',
    borderRadius: '30px',
    fontWeight: '600',
    paddingX: 3,
    paddingY: 1,
    width: '40%',
  },
  InvertedButtonStyle: {
    backgroundColor: '#fff',
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
};
