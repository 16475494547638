import React, { useEffect, useState } from 'react';
import ShortEmployeeSecondaryButton from '../buttons/ShortEmployeeSecondaryButton';
import { Box, Typography } from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import AdjustmentsModal from './modals/AdjustmentModal';
import { getEmployeeNotes } from '../../services/NoteService';
import { useParams } from 'react-router-dom';

const { colors, fonts } = styleProps;
//hi
function Adjustments() {
  const [modalType, setModalType] = useState('');
  const [employeeNotes, setEmployeeNotes] = useState([]);
  const open = Boolean(modalType);
  const params = useParams();
  const employeeDisplayId = params.id;

  const handleGetNotes = async () => {
    const notes = await getEmployeeNotes(employeeDisplayId);
    setEmployeeNotes(notes);
  };

  useEffect(() => {
    handleGetNotes();
  }, [open]);

  return (
    <>
      <Box sx={containerStyles}>
        <Box sx={headerStyles}>
          <Typography
            variant='h6'
            sx={{
              fontSize: '20px',
              fontFamily: fonts.primary,
              color: colors.textDarkGrey,
            }}
          >
            Adjustment log
          </Typography>
          <Box>
            <ShortEmployeeSecondaryButton
              text='Add Allowance'
              onClick={() => setModalType('A')}
            />
            <ShortEmployeeSecondaryButton
              text='Add Deduction'
              onClick={() => setModalType('D')}
            />
          </Box>
        </Box>
        <Box sx={logStyles}>
          {employeeNotes.map((value, key) => (
            <Typography sx={logStyles} key={key}>
              On {new Date(value.createdAt).toDateString()} -{' '}
              {value.description}
            </Typography>
          ))}
        </Box>
      </Box>

      <AdjustmentsModal open={open} setOpen={setModalType} type={modalType} />
    </>
  );
}

export default Adjustments;

const containerStyles = {
  margin: '2rem 1rem',
  padding: '20px',
  borderRadius: '4px',
  backgroundColor: '#E7F0FD',
};

const headerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};
const logStyles = {
  display: 'flex',
  flexDirection: 'column',
  fontWeight: '400',
  fontFamily: fonts.primary,
  color: colors.textDarkGrey,
  marginTop: '24px',
};
