import React, { useState } from 'react';
import { Box } from '@mui/material';
import TopBar from '../components/top-bar/TopBar';
import OfferLetterReviewRejectDrawer from '../components/offerLetterReview/drawers/OfferLetterReviewRejectDrawer';
import { styleProps } from '../styles/styleProps';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { useLoaderData } from 'react-router-dom';
import OfferLetterService from '../services/OfferLetterServices';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  OFFER_LETTER_STATUS_REJECTED,
  OFFER_LETTER_STATUS_APPROVED,
} from '../constants/OfferLetter';
const OfferLetterReviewPage = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const offerLetterId = urlParams.id;

  const { offerLetter, letterType, candidateName } = useLoaderData();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const handleWarningButtonClick = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const updateOfferLetterStatus = async (status, options = {}) => {
    await OfferLetterService.updateOfferLetter(offerLetterId, status, options);
    navigate(`/offerLetters`);
  };
  const handleApprove = async () => {
    await updateOfferLetterStatus(OFFER_LETTER_STATUS_APPROVED);
  };

  const handleRejectSubmit = async () => {
    await updateOfferLetterStatus(OFFER_LETTER_STATUS_REJECTED, {
      reason: rejectionReason,
    });
    setIsDrawerOpen(false);
    setRejectionReason('');
  };
  return (
    <>
      <>
        <TopBar
          title={`Offer Letters > Offer letter ${letterType} ${candidateName}`}
        />
        <Box flex={1} sx={{ margin: 4, padding: '20px' }}>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <PrimaryButton
              styles={approveRejectBtnStyle}
              onClick={handleApprove}
            >
              Approve
            </PrimaryButton>
            <PrimaryButton
              onClick={handleWarningButtonClick}
              styles={approveRejectBtnStyle}
              color={'error'}
            >
              Reject
            </PrimaryButton>
          </Box>
          <div
            dangerouslySetInnerHTML={{ __html: offerLetter }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '80px 111px 0 111px',
            }}
          />
        </Box>
      </>
      <OfferLetterReviewRejectDrawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        onRejectSubmit={handleRejectSubmit}
        rejectionReason={rejectionReason}
        setRejectionReason={setRejectionReason}
      />
    </>
  );
};

export default OfferLetterReviewPage;

const approveRejectBtnStyle = {
  fontFamily: styleProps.fonts.primary,
  fontSize: '16px',
  fontWeight: 500,
  minWidth: '160px',
  height: '50px',
  padding: '8px',
  borderRadius: '30px',
  textTransform: 'none',
};
