import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Divider, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const WeeklySummaryPanel = ({ employee, onClose, weekDays }) => {
  const [totalWeeklyHours, setTotalWeeklyHours] = useState(0);

  // Calculate total weekly hours based on time records within the provided weekDays
  useEffect(() => {
    const calculateTotalWeeklyHours = () => {
      const currentWeekDates = weekDays.map((day) => day.date);
      let totalHours = 0;

      employee.timeRecords
        .filter((record) =>
          currentWeekDates.includes(record.startTs.split('T')[0]),
        )
        .forEach((record) => {
          const checkInTime = new Date(record.startTs);
          const checkOutTime = new Date(record.endTs);
          const hoursWorked = (checkOutTime - checkInTime) / (1000 * 60 * 60);
          totalHours += hoursWorked;
        });

      setTotalWeeklyHours(totalHours.toFixed(2));
    };

    calculateTotalWeeklyHours();
  }, [employee.timeRecords, weekDays]);

  const formatUTCTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const isPM = hours >= 12;
    const formattedHours = isPM ? hours % 12 || 12 : hours;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const ampm = isPM ? 'PM' : 'AM';
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '400px',
        height: '100%',
        backgroundColor: 'white',
        boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mb={2}
      >
        <Typography variant='h5'>Weekly Summary</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{ marginBottom: '16px' }} />

      <Typography variant='h6' sx={{ marginBottom: '20px' }}>
        {employee.name}
      </Typography>

      {weekDays
        .filter((day) => day.day !== 'Sat' && day.day !== 'Sun')
        .map((weekDay) => {
          const record = employee.timeRecords.find(
            (rec) =>
              new Date(rec.startTs).toISOString().split('T')[0] ===
              weekDay.date,
          );

          return (
            <Box key={weekDay.date} mb={2}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                <Typography variant='body2'>
                  {weekDay.day}, {new Date(weekDay.date).toLocaleDateString()}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  label='Check-in'
                  value={record ? formatUTCTime(record.startTs) : ''}
                  placeholder='No Check-in'
                  size='small'
                  onChange={(e) => {
                    if (record) {
                      record.startTs = e.target.value;
                    }
                  }}
                  InputProps={{
                    style: record ? {} : { color: '#B0B0B0' },
                  }}
                />
                <TextField
                  label='Check-out'
                  value={record ? formatUTCTime(record.endTs) : ''}
                  placeholder='No Check-out'
                  size='small'
                  onChange={(e) => {
                    if (record) {
                      record.endTs = e.target.value;
                    }
                  }}
                  InputProps={{
                    style: record ? {} : { color: '#B0B0B0' },
                  }}
                />
              </Box>
            </Box>
          );
        })}

      <Typography variant='h6' mt={4}>
        Total Weekly Hours: {totalWeeklyHours} hrs
      </Typography>
    </Box>
  );
};

WeeklySummaryPanel.propTypes = {
  employee: PropTypes.shape({
    name: PropTypes.string.isRequired,
    timeRecords: PropTypes.arrayOf(
      PropTypes.shape({
        recordId: PropTypes.string.isRequired,
        startTs: PropTypes.string.isRequired,
        endTs: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  weekDays: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      day: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default WeeklySummaryPanel;
