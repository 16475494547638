import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TextField,
  Stack,
  Typography,
  Checkbox,
  Alert,
} from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { phone } from 'phone';

import { CountryCodes } from '../../../data/countryCodes';
import { styleProps } from '../../../styles/styleProps';
import LongPrimaryBtn from '../../buttons/LongPrimaryBtn';
import EnterOTPView from '../otp-step/EnterOTPView';
import { invalidStyles } from '../../../styles/textFieldErrorStyles';
import axios from 'axios';
import { getAllCurrencies } from '../../../services/CurrencyService';
import { getCompanyCategories } from '../../../services/CompanyService';
import PlatformIntegrationService from '../../../services/AbstractPlatformIntegrationService';

const { fonts, fontSizes, colors } = styleProps;

const GeneralRegistrationForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isBasicDetailsRequested, setIsBasicDetailsRequested] = useState(true);
  const [isCompanyFormVisible, setIsCompanyFormVisible] = useState(false);
  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const [isEmptyBasicFields, setIsEmptyBasicFields] = useState(true);
  const [isEmptyCompanyFields, setIsEmptyCompanyFields] = useState(true);
  const countryCodes = CountryCodes.countries;
  // eslint-disable-next-line no-unused-vars
  const [userAccessToken, setUserAccessToken] = useState('');
  const [isEmailAlreadyExists, setIsEmailAlreadyExists] = useState(false);
  const [isCompanyAlreadyExists, setIsCompanyAlreadyExists] = useState(false);
  const [companyFields, setCompanyFields] = useState([]);
  const [companyCurrency, setCompanyCurrency] = useState([]);
  const [checked, setChecked] = useState(false);

  //Validation
  const [isMobileNumberInvalid, setIsMobileNumberInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [invalidName, setInvalidName] = useState(false);
  const [invalidCompanyName, setInvalidCompanyName] = useState(false);
  //Password checklist
  const [isCheckListEnabled, setIsCheckListEnabled] = useState(false);
  const [atLeastMinimumCharactors, setAtLeastMinimumCharactors] =
    useState(false);
  const [
    atLeastOneUpperAndLowerCaseLatter,
    setAtLeastOneUpperAndLowerCaseLatter,
  ] = useState(false);
  const [atLeastOneNumberAndLetter, setAtLeastOneNumberAndLetter] =
    useState(false);
  const [atLeastOneSpecialCharactor, setAtLeastOneSpecialCharactor] =
    useState(false);

  const initialValues = {
    userData: {
      name: '',
      email: '',
      code: '+94',
      number: '',
      password: '',
      confirmPassword: '',
    },
    companyData: {
      companyName: '',
      field: '',
      currency: '',
    },
  };

  const validationSchema = Yup.object({
    userData: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid Email'),
      code: Yup.string(),
      number: Yup.number().positive(),
      password: Yup.string(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Password do not match',
      ),
    }),
    companyData: Yup.object().shape({
      companyName: Yup.string(),
      field: Yup.string(),
      currency: Yup.string(),
    }),
  });

  const onSubmit = () => {
    if (isBasicDetailsRequested) {
      setIsBasicDetailsRequested(false);
      setIsCompanyFormVisible(true);
    }
    if (isCompanyFormVisible) {
      setIsBasicDetailsRequested(false);
      registerSuperAdmin();
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    loadDataFromDb();
  }, []);

  useEffect(() => {
    let res = checkInputFields(formik.values.userData);
    let status = passwordCheckListStatus();
    if (res === false) {
      if (
        status === true &&
        isEmailAlreadyExists === false &&
        isMobileNumberInvalid === false &&
        checked === true &&
        invalidName === false &&
        formik.values.userData.name.trim() !== '' &&
        formik.values.userData.number.trim() !== ''
      ) {
        setIsEmptyBasicFields(false);
      } else {
        setIsEmptyBasicFields(true);
      }
    } else {
      setIsEmptyBasicFields(true);
    }
  }, [
    formik.values.userData,
    isEmailAlreadyExists,
    isMobileNumberInvalid,
    checked,
  ]);

  useEffect(() => {
    if (formik.values.userData.email.trim() != '') {
      getExistingAdminEmails();
    }
  }, [formik.values.userData.email]);

  const passwordCheckListStatus = () => {
    if (
      atLeastMinimumCharactors === true &&
      atLeastOneUpperAndLowerCaseLatter === true &&
      atLeastOneNumberAndLetter === true &&
      atLeastOneSpecialCharactor === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  //password validation
  useEffect(() => {
    //Enable/diable checklist
    if (formik.values.userData.password.length >= 1) {
      setIsCheckListEnabled(true);
    } else {
      setIsCheckListEnabled(false);
    }

    //Password length validation
    if (formik.values.userData.password.length > 7) {
      setAtLeastMinimumCharactors(true);
    } else {
      setAtLeastMinimumCharactors(false);
    }

    //Uppercase and Lowercase validation
    if (
      /[A-Z]/.test(formik.values.userData.password) &&
      /[a-z]/.test(formik.values.userData.password)
    ) {
      setAtLeastOneUpperAndLowerCaseLatter(true);
    } else {
      setAtLeastOneUpperAndLowerCaseLatter(false);
    }

    //Number and Letter validation
    if (
      /\d/.test(formik.values.userData.password) &&
      /[a-zA-Z]/.test(formik.values.userData.password)
    ) {
      setAtLeastOneNumberAndLetter(true);
    } else {
      setAtLeastOneNumberAndLetter(false);
    }

    //Special charactor validation
    if (
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(
        formik.values.userData.password,
      )
    ) {
      setAtLeastOneSpecialCharactor(true);
    } else {
      setAtLeastOneSpecialCharactor(false);
    }
  }, [formik.values.userData.password]);

  useEffect(() => {
    let res = checkInputFields(formik.values.companyData);
    if (!res) {
      if (
        !isEmailAlreadyExists &&
        !isCompanyAlreadyExists &&
        !invalidCompanyName
      ) {
        setIsEmptyBasicFields(false);
      } else {
        setIsEmptyBasicFields(true);
      }
    }
    setIsEmptyCompanyFields(res);
  }, [formik.values.companyData]);

  //Handle password visibility
  const handlePasswordVisibility = () => {
    setIsPasswordVisible((prevVal) => !prevVal);
  };

  //handle GO BACK button
  const handleGoBack = () => {
    setIsBasicDetailsRequested(true);
    setIsCompanyFormVisible(false);
    setIsOTPRequested(false);
  };

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key].trim() === ''
      ) {
        return true;
      }
    }
    return false;
  };

  //Only number input function
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  //get existing emails list
  const getExistingAdminEmails = () => {
    const email = formik.values.userData.email;
    const body = {
      email,
    };
    //generate a temp JWT token
    axios.post('/api/user/password/jwt', body).then((response) => {
      if (response.data.success === true) {
        localStorage.setItem('tempAuth', response.headers.get('access-token'));
        //check whether the email is already used/not
        PlatformIntegrationService.executeHttpGetRequest(
          `/api/admin?check=${email}`,
          true,
        ).then((data) => {
          if (!data.message) {
            setEmailError('Email already exist');
            setIsEmailAlreadyExists(true);
          }
        });
      } else {
        setIsEmailInvalid(true);
      }
    });
  };

  //load data from db
  const loadDataFromDb = () => {
    getAllCurrencies().then((res) => {
      setCompanyCurrency(res);
    });

    //get company field
    getCompanyCategories().then((res) => {
      setCompanyFields(res);
    });
  };

  //Register users
  const registerSuperAdmin = () => {
    const name = formik.values.userData.name;
    const email = formik.values.userData.email;
    const mobile = formik.values.userData.code + formik.values.userData.number;
    const password = formik.values.userData.password;

    let countryCodeValue = countryCodes.filter(
      (field) => field.code === formik.values.userData.code,
    );

    let country = countryCodeValue[0].name;

    const body = {
      name,
      email,
      mobile,
      country,
      password,
    };
    axios.post('/api/admin/register', body).then(
      (response) => {
        if (response.data.success === true) {
          //set the auth in the localstorage
          localStorage.setItem('auth', response.headers.get('access-token'));
          setUserAccessToken(response.headers.get('access-token'));
          registerCompany(response.headers.get('access-token'));
        } else {
          window.location = '/error';
        }
      },
      (error) => {
        console.log(error);
        window.location = '/error';
      },
    );
  };

  //Check Company status
  const checkCompanyStatus = (name) => {
    const companyNameFormat = /^(?!^\s+$).+$/;
    if (name.trim() !== '' && !name.match(companyNameFormat)) {
      setInvalidCompanyName(true);
    } else {
      setInvalidCompanyName(false);
    }
    if (name.trim() !== '') {
      setInvalidCompanyName(false);
      axios
        .get(`/api/company/check/${formik.values.companyData.companyName}`, {
          headers: {
            Authorization: `"${localStorage.getItem('tempAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === false) {
              setIsCompanyAlreadyExists(true);
            }
          },
          (error) => {
            console.log(error);
            window.location = '/error';
          },
        );
    }
  };

  //verify mobile number format
  const verifyTheNumber = (type) => {
    if (formik.values.userData.number.trim() === '') {
      setIsMobileNumberInvalid(false);
      return;
    } else {
      const mobile =
        formik.values.userData.code + formik.values.userData.number;
      if (type === 'number' && formik.values.userData.code != '') {
        if (phone(mobile).isValid === false) {
          setIsMobileNumberInvalid(true);
        } else {
          setIsMobileNumberInvalid(false);
        }
      } else if (type === 'code' && formik.values.userData.number != '') {
        if (phone(mobile).isValid === false) {
          setIsMobileNumberInvalid(true);
        } else {
          setIsMobileNumberInvalid(false);
        }
      }
    }
  };

  //Register Company
  const registerCompany = (accessToken) => {
    try {
      const name = formik.values.companyData.companyName;
      const field = formik.values.companyData.field;
      const currency = formik.values.companyData.currency;

      const body = {
        name,
        field,
        currency,
      };

      axios
        .post('/api/company/register', body, {
          headers: {
            Authorization: `"${accessToken}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              setIsCompanyFormVisible(false);
              setIsOTPRequested(true);
              sendEmailOtp();
            }
          },
          (error) => {
            console.error(error);
            window.location = '/error';
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  //send the email OTP
  const sendEmailOtp = () => {
    try {
      const mode = 0;
      const email = formik.values.userData.email;

      const body = {
        email,
        mode,
      };

      axios
        .post('/api/otp/send', body, {
          headers: {
            Authorization: `"${localStorage.getItem('auth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === false) {
              console.log(response.data.message);
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            console.error(error);
            window.location = '/error';
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    let res = checkInputFields(formik.values.userData);
    let status = passwordCheckListStatus();
    if (res === false) {
      if (
        status === true &&
        isEmailAlreadyExists === false &&
        isMobileNumberInvalid === false &&
        event.target.checked === true &&
        invalidName === false
      ) {
        setIsEmptyBasicFields(false);
      } else {
        setIsEmptyBasicFields(true);
      }
    } else {
      setIsEmptyBasicFields(true);
    }
  };

  const validateUserName = (name) => {
    const userNameFormat = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
    setInvalidName(name.trim() !== '' && !name.match(userNameFormat));
  };

  return (
    <div style={containerStyles}>
      <TitleContainer>
        <Title>{isOTPRequested ? 'Verify Account' : 'Registration'}</Title>
        <SubTitle>
          {!isOTPRequested ? (
            'Add your details to get your company registered'
          ) : (
            <br />
          )}
        </SubTitle>
      </TitleContainer>
      <InputContainer>
        {/* Basic details form */}
        <Box sx={{ display: isBasicDetailsRequested ? 'block' : 'none' }}>
          <TextInput
            label='Name *'
            name='userData.name'
            value={formik.values.userData.name}
            InputLabelProps={{
              style: { fontSize: 14 },
            }}
            onChange={(e) => {
              formik.handleChange(e);
              setInvalidName(false);
            }}
            onBlur={() => validateUserName(formik.values.userData.name)}
          />
          {invalidName === true ? (
            <>
              <br /> <br />
              <Alert severity='error'>Invalid Name</Alert>
            </>
          ) : null}

          <TextInput
            label='Email address *'
            type='email'
            name='userData.email'
            value={formik.values.userData.email}
            sx={invalidStyles(formik.errors?.userData?.email ? false : true)}
            InputLabelProps={{ style: { fontSize: 14 } }}
            onChange={(e) => {
              setIsEmailAlreadyExists(false);
              setIsEmailInvalid(false);
              formik.handleChange(e);
              formik.errors?.userData?.email &&
                delete formik.errors.userData.email;
            }}
          />
          {formik.errors?.userData?.email && (
            <>
              <br />
              <Alert severity='error'>{formik.errors.userData.email}</Alert>
            </>
          )}
          {isEmailAlreadyExists === true ? (
            <>
              <br /> <br />
              <Alert severity='error'>{emailError}</Alert>
            </>
          ) : null}
          {isEmailInvalid === true ? (
            <>
              <br /> <br />
              <Alert severity='error'>Invalid Email</Alert>
            </>
          ) : null}
          <Box sx={{ width: '100%' }}>
            <CountryCodeSelect
              name='userData.code'
              defaultValue={formik.values.userData.code}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: 250,
                    height: 250,
                  },
                },
              }}
              renderValue={(value) => value}
              onChange={(e) => {
                formik.handleChange(e);
                setIsMobileNumberInvalid(false);
              }}
              onBlur={() => verifyTheNumber('code')}
            >
              {countryCodes.map((item, index) => (
                <MenuItem key={index} value={item.code}>
                  {item.name.length > 15
                    ? item.name.slice(0, 15) + `... (${item.code})`
                    : item.name + ` (${item.code})`}
                </MenuItem>
              ))}
            </CountryCodeSelect>

            <MobileNumberInput
              placeholder='Mobile number*'
              type='tel'
              value={formik.values.userData.number}
              name='userData.number'
              onInput={(e) => onlyNumbers(e)}
              onChange={(e) => {
                formik.handleChange(e);
                setIsMobileNumberInvalid(false);
              }}
              onBlur={() => verifyTheNumber('number')}
            />
          </Box>
          {isMobileNumberInvalid === true ? (
            <>
              <br />
              <Alert severity='error'>Incorrect mobile number</Alert>
            </>
          ) : null}
          <TextInput
            label='Password *'
            name='userData.password'
            type={isPasswordVisible ? 'text' : 'password'}
            value={formik.values.userData.password}
            onChange={formik.handleChange}
            sx={{ marginTop: '15px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' onClick={handlePasswordVisibility}>
                    {isPasswordVisible ? (
                      <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                    ) : (
                      <VisibilityOutlined sx={{ fontSize: 18 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />

          <TextInput
            label='Confirm password *'
            name='userData.confirmPassword'
            type={isPasswordVisible ? 'text' : 'password'}
            value={formik.values.userData.confirmPassword}
            onChange={(e) => {
              formik.handleChange(e);
              formik.errors?.userData?.confirmPassword &&
                delete formik.errors.userData.confirmPassword;
            }}
            sx={invalidStyles(
              formik.errors?.userData?.confirmPassword ? false : true,
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' onClick={handlePasswordVisibility}>
                    {isPasswordVisible ? (
                      <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                    ) : (
                      <VisibilityOutlined sx={{ fontSize: 18 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />
          {formik.errors?.userData?.confirmPassword && (
            <>
              <br />
              <Alert severity='error'>
                {formik.errors.userData.confirmPassword}
              </Alert>
            </>
          )}
          {isCheckListEnabled === true ? (
            <Box m={2}>
              {atLeastMinimumCharactors === false ? (
                <Stack direction='row' spacing={2} sx={{ color: 'red' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>At least 8 characters</Typography>
                </Stack>
              ) : (
                <Stack direction='row' spacing={2} sx={{ color: 'green' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>At least 8 characters</Typography>
                </Stack>
              )}
              {atLeastOneUpperAndLowerCaseLatter === false ? (
                <Stack direction='row' spacing={2} sx={{ color: 'red' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>
                    A mixture of both uppercase and lowercase letters
                  </Typography>
                </Stack>
              ) : (
                <Stack direction='row' spacing={2} sx={{ color: 'green' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>
                    A mixture of both uppercase and lowercase letters
                  </Typography>
                </Stack>
              )}
              {atLeastOneNumberAndLetter === false ? (
                <Stack direction='row' spacing={2} sx={{ color: 'red' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>A mixture of letters and numbers</Typography>
                </Stack>
              ) : (
                <Stack direction='row' spacing={2} sx={{ color: 'green' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>A mixture of letters and numbers</Typography>
                </Stack>
              )}
              {atLeastOneSpecialCharactor === false ? (
                <Stack direction='row' spacing={2} sx={{ color: 'red' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>
                    Inclusion of at least one special character, e.g., ! @ # ?
                  </Typography>
                </Stack>
              ) : (
                <Stack direction='row' spacing={2} sx={{ color: 'green' }}>
                  <div style={buttonBorderStyle}>
                    <div style={buttonStyle}>
                      <CheckOutlinedIcon sx={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <Typography>
                    Inclusion of at least one special character, e.g., ! @ # ?
                  </Typography>
                </Stack>
              )}{' '}
            </Box>
          ) : null}
          <div style={{ textAlign: 'center' }}>
            <Typography sx={checkBoxStyle}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              I agree to the,{' '}
              <a
                href='https://www.nimikash.com/privacyPolicy'
                target='_blank'
                rel='noreferrer'
                style={{
                  ...checkBoxStyle,
                }}
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href='https://www.nimikash.com/termsAndConditions'
                target='_blank'
                rel='noreferrer'
                style={{
                  ...checkBoxStyle,
                }}
              >
                Terms & Conditions
              </a>
            </Typography>
          </div>
          <LongPrimaryBtn
            text='Next'
            disabled={
              isEmptyBasicFields ||
              isEmailInvalid ||
              isEmailAlreadyExists ||
              invalidName ||
              isMobileNumberInvalid
            }
            onClick={formik.handleSubmit}
          />
        </Box>
        {/* Company Details Form */}
        <CompanyDetailsForContainer
          sx={{
            display: isCompanyFormVisible ? 'flex' : 'none',
          }}
        >
          <Box>
            <TextInput
              label='Company name *'
              name='companyData.companyName'
              value={formik.values.companyData.companyName}
              onBlur={() =>
                checkCompanyStatus(formik.values.companyData.companyName)
              }
              InputLabelProps={{
                style: { fontSize: 14 },
              }}
              onChange={(e) => {
                setIsCompanyAlreadyExists(false);
                setInvalidCompanyName(false);
                formik.handleChange(e);
              }}
            />
            {isCompanyAlreadyExists === true ? (
              <>
                <br />
                <Alert severity='error'>Company already exsits!</Alert>
              </>
            ) : null}
            {invalidCompanyName && (
              <>
                <br /> <br />
                <Alert severity='error'>Invalid Company Name</Alert>
              </>
            )}
            <CustomSelect
              label='Industry *'
              name='companyData.field'
              value={formik.values.companyData.field}
              onChange={formik.handleChange}
              select
            >
              {companyFields.map((item, index) => (
                <MenuItem
                  sx={menuItemStyles}
                  key={index}
                  value={item.companyFieldDisplayId}
                >
                  {item.companyField}
                </MenuItem>
              ))}
            </CustomSelect>
            <CustomSelect
              label='Default currency *'
              name='companyData.currency'
              value={formik.values.companyData.currency}
              onChange={formik.handleChange}
              select
            >
              {companyCurrency.map((item, index) => (
                <MenuItem
                  sx={menuItemStyles}
                  key={index}
                  value={item.currencyDisplayId}
                >
                  {item.currency}
                </MenuItem>
              ))}
            </CustomSelect>
          </Box>
          <Box sx={{ ...flexStyles, flexDirection: 'column', mb: -3 }}>
            <LongPrimaryBtn
              text='Register'
              onClick={formik.handleSubmit}
              disabled={
                isEmptyCompanyFields ||
                isCompanyAlreadyExists ||
                formik.values.companyData.companyName.trim() == ''
              }
            />
            <TextButton onClick={handleGoBack}>Go back</TextButton>
          </Box>
        </CompanyDetailsForContainer>
        {isOTPRequested && (
          <EnterOTPView
            mobile={formik.values.userData.code + formik.values.userData.number}
            email={formik.values.userData.email}
          />
        )}
      </InputContainer>
    </div>
  );
};

const buttonBorderStyle = {
  border: '1px solid #FF7528',
  width: '15px',
  height: '15px',
  borderRadius: 100,
  marginTop: '3px',
};

const buttonStyle = {
  height: '5px',
  marginTop: '5px',
  marginLeft: '1.17px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
};

//Styles and styled component
const flexStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const containerStyles = {
  width: '100%',
  minHeight: '500px',
  borderRadius: '10px',
  background: 'white',
  padding: '20px 0px',
};

const TitleContainer = styled(Box)({
  width: '100%',
  height: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: fontSizes.header,
  fontWeight: 500,
  color: colors.textDarkGrey,
});

const SubTitle = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: fontSizes.normal,
  fontWeight: 400,
  color: colors.textLightGrey,
  textAlign: 'center',
});

const InputContainer = styled(Box)({
  boxSizing: 'border-box',
  padding: '8% 12% 2% 12%',
  width: '100%',
  height: '80%',
});

const TextInput = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const MobileNumberInput = styled(TextField)({
  width: '75%',
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '0 4px 4px 0',
    },
    '& input::placeholder': {
      fontSize: fontSizes.normal,
    },
  },
});

const CountryCodeSelect = styled(Select)({
  width: '25%',
  height: 50,
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '4px 0 0 4px',
    },
  },
  '& fieldset': {
    borderRadius: '4px 0 0 4px',
  },
});

const CustomSelect = styled(TextField)({
  width: '100%',
  height: 50,
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const CompanyDetailsForContainer = styled(Box)({
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '400px',
});

const TextButton = styled(Button)({
  fontSize: '16px',
  textTransform: 'none',
  padding: '10px 0px',
  color: colors.btnPrimary,
  fontWeight: 550,
});

const menuItemStyles = {
  m: 0.5,
  '&:focus': {
    background: colors.btnPrimary,
    color: 'white',
    m: 0.5,
    borderRadius: 1,
    '&:hover': {
      background: colors.btnPrimary,
      color: 'white',
      m: 0.5,
      borderRadius: 1,
    },
  },
};

const checkBoxStyle = {
  color: colors.textLightGrey,
  fontSize: '15px',
  fontFamily: fonts.primary,
  padding: '2% 0 4% 0',
};

export default GeneralRegistrationForm;
