import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../buttons/PrimaryButton';
import { Box } from '@mui/material';
import { OFFER_LETTER_STATUS_REJECTED } from '../../constants/OfferLetter';
import RejectReasonDrawer from './RejectReasonDrawer';

const OfferLetterHeader = ({ comments, letterStatus }) => {
  const [open, setOpen] = useState(false);

  switch (letterStatus) {
    case OFFER_LETTER_STATUS_REJECTED:
      return (
        <Box sx={styles.buttonContaier}>
          <PrimaryButton onClick={() => setOpen(true)}>
            View Reason for rejection
          </PrimaryButton>
          <RejectReasonDrawer
            open={open}
            rejectionReason={comments}
            setOpen={setOpen}
          />
        </Box>
      );
    default:
      return;
  }
};

export default OfferLetterHeader;

OfferLetterHeader.propTypes = {
  comments: PropTypes.string,
  letterStatus: PropTypes.string,
};

const styles = {
  buttonContaier: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
    marginRight: '24px',
  },
};
