/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from '../StepHeader';
import svgs from '../../../assets/svgs/index';
import ExpensesChart from '../charts/ExpensesChart';
import { styleProps } from '../../../styles/styleProps';

import payrollCalculations from '../helpers/calculations';
import { format } from 'date-fns';

const { fonts, colors } = styleProps;

function Step4({ bulkData }) {
  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
  const [data, setData] = useState({});

  const dataWidgetsList = [
    {
      icon: svgs.coins,
      text: `LKR ${nf.format(data.totalPayLKR)}`,
      subText: 'Total LKR Payment',
    },
    {
      icon: svgs.coins,
      text: `USD ${nf.format(data.totalPayUSD)}`,
      subText: 'Total USD Payment',
    },
    {
      icon: svgs.businessman,
      text: `${data.localCurrencyEmployeeCount} LKR / ${
        data.employeeCount - data.localCurrencyEmployeeCount
      } USD`,
      subText: 'Total employees',
    },
    {
      icon: svgs.calender1,
      text:
        format(new Date(bulkData.payrollStartDate), 'dd MMM yyyy') +
        ' - ' +
        format(new Date(bulkData.payrollEndDate), 'dd MMM yyyy'),
      subText: 'Payroll period',
    },
    {
      icon: svgs.calender2,
      text: format(new Date(bulkData.payDate), 'dd MMM yyyy'),
      subText: 'Pay date',
    },
  ];

  useEffect(() => {
    setData(payrollCalculations(bulkData));
  }, [bulkData]);

  return (
    <Box sx={styles.mainContaineer}>
      <Header
        title='Review Payroll'
        subtitle={
          'Thoroughly Review and Professionally Finalize Payroll Details'
        }
      />

      {/* Overview Container */}
      <Box sx={styles.overviewContainer}>
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <img
            src={svgs.money_bag}
            style={{ height: '5rem', padding: 3 }}
            alt='money bag'
          />

          <Box sx={styles.overviewParent}>
            <CustomTypography variant='h7' noWrap>
              Payroll amount will debit from your FBO Account
            </CustomTypography>

            <CustomTypography noWrap variant='h5' fontWeight={600}>
              LKR {nf.format(data.totalPayLKR)} & USD{' '}
              {nf.format(data.totalPayUSD)}
            </CustomTypography>

            <CustomTypography variant='h7' noWrap color={colors.textLightGrey}>
              For {data.startPayrollDate} - {data.endPayrollDate}, and{' '}
              {data.employeeCount} employees will paid at {data.payDay}
            </CustomTypography>
          </Box>
        </Box>

        <img
          src={svgs.Invoice_curate}
          style={{ height: '8rem', padding: 3 }}
          alt='Invoice curate'
        />
      </Box>

      {/* Payroll Chart and Details */}
      <Box sx={{ display: 'flex', gap: 4 }}>
        {/* Payroll Chart */}
        <Box flex={5} sx={styles.roundedBorder}>
          <ExpensesChart calculatedData={data} />
        </Box>

        <Box
          flex={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {dataWidgetsList.map((item) => (
            // eslint-disable-next-line react/jsx-key
            <Box flex={1} sx={[styles.roundedBorder, { display: 'flex' }]}>
              <img src={item.icon} alt={item.text} style={{ padding: 15 }} />

              <Box sx={styles.dataWidgetTextContainer}>
                <CustomTypography noWrap variant='h5' fontWeight={600}>
                  {item.text}
                </CustomTypography>

                <CustomTypography
                  noWrap
                  variant='h7'
                  color={colors.textLightGrey}
                >
                  {item.subText}
                </CustomTypography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Step4;

const CustomTypography = styled(Typography)({
  fontFamily: fonts.primary,
});

const styles = {
  mainContaineer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 3,
    marginBottom: '90px',
  },
  overviewContainer: {
    display: 'flex',
    backgroundColor: '#0666EB1A',
    justifyContent: 'space-between',
    gap: 2,
    paddingY: 1,
    paddingX: 2,
    borderRadius: 2,
  },
  overviewParent: {
    display: 'flex',
    gap: 0.5,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  roundedBorder: {
    borderRadius: '7px',
    border: '2px solid #DEEBFC',
    padding: '10px',
  },
  dataWidgetTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 0.5,
  },
};
