import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';

import AddNewAdminForm from '../components/admin-management/add-new-admin/AddNewAdminForm';
import AdminsList from '../components/admin-management/admins-list/AdminsList';
import { getCurrentAdmin, getAdmins } from '../services/AdminService';
import TopBar from '../components/top-bar/TopBar';

const Admins = () => {
  const [user, setUser] = useState('');
  const [admin, setAdmin] = useState('');

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const admin = await getCurrentAdmin();
    setUser(admin);
    setAdmin(await getAdmins(admin.adminCompany.companyDisplayId));
  }

  return (
    <>
      <TopBar title='Admin Management' user={user} />
      <Grid
        container
        sx={{ height: 'calc(100vh - 60px)', overflowY: 'scroll' }}
      >
        <Grid item xs={12} md={5}>
          <AddNewAdminForm refreshData={getData} />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ backgroundColor: '#F5F7F8', height: 'calc(100vh - 60px)' }}
        >
          <AdminsList data={admin} refreshData={getData} />
        </Grid>
      </Grid>
    </>
  );
};

export default Admins;
