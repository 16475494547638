// NOTE: This page is responsible for viewing edit monthly report page
import React, { useEffect, useState } from 'react';
import { PAGES } from '../data/routeDefinition';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import ReportsContainer from '../components/project-reports/ReportsContainer';
import TopBar from '../components/top-bar/TopBar';
import Header from '../components/payroll-schedule/StepHeader';
import { Box, Grid } from '@mui/material';
import { useAuth } from '../contexts/authContext';

const MonthlyReportPage = () => {
  const { user } = useAuth();
  const loaderData = useLoaderData();
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  let { id: reportId } = useParams();

  useEffect(() => {
    if (loaderData) {
      setReports(loaderData ?? []);
      setIsLoading(false);
    }
  }, [loaderData]);

  useEffect(() => {
    if (!reportId) {
      navigate(PAGES.errorResponsePage.to); // navigate to error page
    }
  }, [reportId, navigate]);

  return (
    <>
      <TopBar title={'Project Report > Add summary'} user={user} />
      <Header />
      <Grid>
        <Box
          sx={{
            padding: '1.5%',
            paddingRight: '1%',
            paddingTop: '0',
            height: 'calc(100vh - 200px)',
            overflowY: 'scroll',
          }}
        >
          <ReportsContainer
            isLoading={isLoading}
            reports={reports}
            isMonthlyReport={true}
          />
        </Box>
      </Grid>
    </>
  );
};

export default MonthlyReportPage;
