import { format, addDays, startOfMonth, endOfMonth } from 'date-fns';

export const statusConfig = {
  PRESENT: {
    abbreviation: 'P',
  },
  WEEKEND: {
    abbreviation: 'W',
  },
  NO_PAY: {
    abbreviation: 'N',
  },
  ANNUAL_LEAVE: {
    abbreviation: 'A',
  },
  HOLIDAY: {
    abbreviation: 'H',
  },
  MEDICAL_CASUAL_LEAVE: {
    abbreviation: 'M/C',
  },
  NONE: {
    abbreviation: '',
  },
};

export const recordTypeMap = {
  'P - Present': 'ATTENDANCE',
  'M/C - Medical/Casual Leave': 'MEDICAL_CASUAL_LEAVE',
  'A - Annual Leave': 'ANNUAL_LEAVE',
  'N - No Pay': 'NO_PAY',
};

export const getAbbreviation = (day, attendanceRecord = null) => {
  if (attendanceRecord) {
    switch (attendanceRecord.recordType) {
      case 'ATTENDANCE':
        return statusConfig.PRESENT.abbreviation;
      case 'MEDICAL_CASUAL_LEAVE':
        return statusConfig.MEDICAL_CASUAL_LEAVE.abbreviation;
      case 'ANNUAL_LEAVE':
        return statusConfig.ANNUAL_LEAVE.abbreviation;
      case 'NO_PAY':
        return statusConfig.NO_PAY.abbreviation;
      case 'HOLIDAY':
        return statusConfig.HOLIDAY.abbreviation;
      default:
        break;
    }
  }

  if (day === 'Sat' || day === 'Sun') {
    return statusConfig.WEEKEND.abbreviation;
  }

  return statusConfig.NONE.abbreviation;
};

// Map status abbreviation to colors
export const getRectangleColor = (abbreviation) => {
  const colorMap = {
    P: '#47B881', // Present
    W: '#FFAD0D', // Weekend
    N: '#E04545', // No Pay
    H: '#7B61FF', // Holiday
    A: '#EA38B8', // Annual Leave
    'M/C': '#4594FF', // Medical/Casual Leave
  };
  return colorMap[abbreviation] || '#FFFFFF';
};

export const getWeekDays = (currentDate, currentWeekStart) => {
  const startOfTheWeek = currentWeekStart;
  const endOfTheMonthDate = endOfMonth(currentDate);
  const startOfTheMonthDate = startOfMonth(currentDate);

  return Array.from({ length: 7 }).map((_, index) => {
    const day = addDays(startOfTheWeek, index);
    return {
      date: format(day, 'yyyy-MM-dd'),
      day: format(day, 'EEE'),
      isDisabled: day < startOfTheMonthDate || day > endOfTheMonthDate,
    };
  });
};
