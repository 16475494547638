import UrlRoute from '../configs/UrlRoute';
import PlatformIntegrationService from './AbstractPlatformIntegrationService';

export const createPaystubRule = async (employeeId, type, amount) => {
  const data = await PlatformIntegrationService.executeHttpPostRequest(
    `${UrlRoute.PAYSTUB_RULE_ADD}/${employeeId}`,
    {
      type: type,
      amount: amount,
    },
  );
  return data.data;
};
