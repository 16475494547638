/* eslint-disable react/prop-types */
import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import axios from 'axios';
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_DELIVERY_MANAGER,
  ROLE_HR_MANAGER,
} from '../../enums/User';
import LogoImage from '../../assets/images/NimiKashLogo.svg';
import MenuItem from './menu-item/MenuItem';
import { styleProps } from '../../styles/styleProps';
import {
  superAdminMenuItems,
  adminMenuItems,
  deliveryManagerMenuItems,
  hrManagerMenuItems,
} from '../../data//genericConstants';
import { clearAuthData } from '../../services/AuthService';

const { fonts, fontSizes, colors } = styleProps;

const SideNavbar = ({ user }) => {
  const logoutUser = () => {
    try {
      axios
        .get('/api/user/logout', {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            //remove saved data from local storage
            clearAuthData();
            window.location = '/';
          }
        });
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  return (
    <SidebarContainer>
      <LogoContainer>
        <img src={LogoImage} alt='nimikash-logo' width='60%' />
      </LogoContainer>
      {user && user.adminRole === ROLE_ADMIN ? (
        <MenuContainer>
          {adminMenuItems.map((item, index) => (
            <MenuItem key={index} menuItem={item} />
          ))}
        </MenuContainer>
      ) : user && user.adminRole === ROLE_SUPER_ADMIN ? (
        <MenuContainer>
          {superAdminMenuItems.map((item, index) => (
            <MenuItem key={index} menuItem={item} />
          ))}
        </MenuContainer>
      ) : user && user.adminRole === ROLE_HR_MANAGER ? (
        <MenuContainer>
          {hrManagerMenuItems.map((item, index) => (
            <MenuItem key={index} menuItem={item} />
          ))}
        </MenuContainer>
      ) : user && user.adminRole === ROLE_DELIVERY_MANAGER ? (
        <MenuContainer>
          {deliveryManagerMenuItems.map((item, index) => (
            <MenuItem key={index} menuItem={item} />
          ))}
        </MenuContainer>
      ) : (
        <div style={{ padding: '100px 50px 10px 50px' }}>
          <SkeletonTheme baseColor='#B9D3F9' highlightColor='#76AAF4'>
            <p>
              <Skeleton count={1} height={50} />
              <br />
              <Skeleton count={1} height={50} />
              <br />
              <Skeleton count={1} height={50} />
              <br />
              <Skeleton count={1} height={50} />
            </p>
          </SkeletonTheme>
        </div>
      )}

      <BottomContainer>
        <HorizontalLine />
        <Logout onClick={logoutUser}>
          <LogoutRoundedIcon sx={{ fontSize: 20 }} />
          <Text>Logout</Text>
        </Logout>
      </BottomContainer>
    </SidebarContainer>
  );
};

export default SideNavbar;

//Styled components
const SidebarContainer = styled(Box)({
  height: '100vh',
  background: colors.backgroundLow,
  width: '100%',
  position: 'relative',
});

const LogoContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '70px',
});

const MenuContainer = styled(Box)({
  marginTop: '10%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '70vh',
  overflowY: 'auto',

  /* Scrollbar styles */
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#E9F0FD',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#c7c7c7',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',
  },
});

const BottomContainer = styled(Box)({
  width: '100%',
  fontSize: fontSizes.normal,
  position: 'absolute',
  bottom: '30px',
});

const HorizontalLine = styled(Box)({
  width: '70%',
  height: '1px',
  background: 'grey',
  left: '15%',
  position: 'absolute',
});

const Text = styled(Typography)({
  marginLeft: 10,
  fontWeight: 500,
  fontSize: fontSizes.normal,
  fontFamily: fonts.primary,
});

const Logout = styled(Box)({
  marginTop: '20%',
  marginLeft: '18%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});
