import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
import { formatDuration, makeTwoDigits } from '../../../utils/TemporalService';

const EmployeeTimeRecord = ({ employee }) => {
  const { hrs, mins } = formatDuration(employee.timeInMs);

  return (
    <Stack
      key={employee.id}
      flexDirection={'row'}
      justifyContent={'space-between'}
    >
      <Typography
        key={employee.name}
        color={styleProps.colors.textLightGrey}
        variant='h7'
      >
        {employee.name}
      </Typography>
      <Box alignContent={'center'} display={'inline-flex'} flexWrap={'wrap'}>
        <Typography
          key={employee.name}
          color={styleProps.colors.textLightGrey}
          variant='h7'
        >
          {makeTwoDigits(hrs)} hrs :
        </Typography>
        <Typography
          key={employee.name}
          color={styleProps.colors.textLightGrey}
          variant='h7'
          width={65}
          sx={{ marginLeft: 'auto', textAlign: 'end' }}
        >
          {mins} mins
        </Typography>
      </Box>
    </Stack>
  );
};

EmployeeTimeRecord.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    timeInMs: PropTypes.number.isRequired,
  }).isRequired,
};

export default EmployeeTimeRecord;
