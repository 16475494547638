import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import Layout from '../components/Layout';

const ProtectedRoutes = () => {
  const { authenticated, loading } = useAuth();

  return !loading ? (
    authenticated ? (
      <Layout>
        <Outlet />
      </Layout>
    ) : (
      <Navigate to='/login' replace={true} />
    )
  ) : null;
};

export default ProtectedRoutes;
