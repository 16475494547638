/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, styled } from '@mui/material';

import { styleProps } from '../../styles/styleProps';

const { fonts, colors } = styleProps;

const OutlinedButton = styled(Button)({
  width: 150,
  height: 50,
  fontFamily: fonts.primary,
  fontWeight: 400,
  borderRadius: 30,
  borderColor: colors.btnPrimary,
  color: colors.textPrimary,
  textTransform: 'none',
  ':disabled': {
    opacity: 0.3,
    color: colors.textPrimary,
  },
});

// eslint-disable-next-line react/prop-types
const ShortSecondaryBtn = ({ text, onClick, disabled }) => {
  return (
    <OutlinedButton onClick={onClick} variant='outlined'>
      {text}
    </OutlinedButton>
  );
};

export default ShortSecondaryBtn;
