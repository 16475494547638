import * as React from 'react';
import { Box, Modal, Stack, Typography, styled } from '@mui/material';

import { styleProps } from '../../styles/styleProps';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

const { colors } = styleProps;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '170px',
  background: '#FFFFFF',
  borderRadius: '8px',
  width: '353px',
};

const ModalDescription = styled(Typography)({
  fontWeight: '500',
  fontSize: '16px',
  letterSpacing: '-0.408px',
  paddingLeft: '10%',
});

const stackStyle = {
  justifyContent: 'center',
  marginTop: '25px',
};

// eslint-disable-next-line react/prop-types
const ConfirmationModal = ({ modalStatus, confirm, reject }) => {
  return (
    <div>
      <Modal open={modalStatus}>
        <Box sx={style}>
          <Stack spacing={1} sx={stackStyle}>
            <ModalDescription sx={{ color: colors.textDarkGrey }}>
              Are you sure you want to cancel ?
            </ModalDescription>
            <ModalDescription
              sx={{ color: colors.textLightGrey, fontWeight: '400' }}
            >
              You have unsaved changes.
            </ModalDescription>
          </Stack>

          <Stack spacing={1} direction='row' sx={stackStyle}>
            <PrimaryButton
              styles={{ width: '140px', fontWeight: '400' }}
              onClick={confirm}
            >
              YES
            </PrimaryButton>
            <SecondaryButton
              styles={{ width: '140px', fontWeight: '400' }}
              onClick={reject}
            >
              NO
            </SecondaryButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
