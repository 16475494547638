import UrlRoute from '../configs/UrlRoute';
import PlatformIntegrationService from './AbstractPlatformIntegrationService';

export const getNotes = async () => {
  const noteData = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.GET_NOTES}`,
  );
  return noteData;
};
export const getEmployeeNotes = async (employeeId) => {
  const noteData = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.GET_NOTES}/${employeeId}`,
  );
  return noteData.message;
};
