function PayRollFormatter(userData, employeeType) {
  this.employeeDisplayId = userData.employeeDisplayId;
  this.employeeEmployeeId = userData.employeeEmployeeId;
  this.employeeFirstName = userData.employeeFirstName;
  this.employeeLastName = userData.employeeLastName;
  this.employeeCurrencyCode = userData.employeeCurrencyCode;
  this.employeeType = employeeType || '';

  // earnings
  this.employeeSalary = parseFloat(userData.employeeSalary) || 0.0;
  this.employeeAllowanceFuel =
    parseFloat(userData.employeeAllowanceFuel) || 0.0;
  this.employeeAllowanceTravel =
    parseFloat(userData.employeeAllowanceTravel) || 0.0;
  this.employeeAllowanceDailyPay =
    parseFloat(userData.employeeAllowanceDailyPay) || 0.0;
  this.employeeAllowanceOt = parseFloat(userData.employeeAllowanceOt) || 0.0;
  this.employeeAllowanceBonus =
    parseFloat(userData.employeeAllowanceBonus) || 0.0;
  this.employeeReimbursements =
    parseFloat(userData.employeeReimbursements) || 0.0;
  this.employeeTotalEarnings =
    parseFloat(userData.employeeTotalEarnings) || 0.0;

  // deductions
  this.employeeDeductionEpf = parseFloat(userData.employeeDeductionEpf) || 0.0;
  this.employeeDeductionAdvance =
    parseFloat(userData.employeeDeductionAdvance) || 0.0;
  this.employeePayeeTax = parseFloat(userData.employeePayeeTax) || 0.0;
  this.employeeDeductionLoan =
    parseFloat(userData.employeeDeductionLoan) || 0.0;
  this.employeeNoPay = parseFloat(userData.employeeNoPay) || 0.0;
  this.employeeTotalDeductions =
    parseFloat(userData.employeeTotalDeductions) || 0.0;

  // total pay
  this.totolPay =
    parseFloat(userData.totolPay) ||
    this.employeeTotalEarnings - this.employeeTotalDeductions;

  this.calculateEarningsAndDeductions = function () {
    this.employeeTotalEarnings = this.calcEarnings();
    this.employeeTotalDeductions = this.calcDeductions();
    this.totolPay = this.employeeTotalEarnings - this.employeeTotalDeductions;
  };

  this.calcEarnings = function () {
    let totalEarnings =
      this.employeeSalary +
      this.employeeAllowanceFuel +
      this.employeeAllowanceTravel +
      this.employeeAllowanceDailyPay +
      this.employeeAllowanceOt +
      this.employeeAllowanceBonus +
      this.employeeReimbursements;
    return totalEarnings;
  };

  this.calcDeductions = function () {
    let totalDeductions =
      this.employeeDeductionEpf +
      this.employeeDeductionAdvance +
      this.employeePayeeTax +
      this.employeeDeductionLoan +
      this.employeeNoPay;
    return totalDeductions;
  };
}

export default PayRollFormatter;
