export const EmployeeType = Object.freeze({
  Permanent: 'Permanent',
  Intern: 'Intern',
  Contract: 'Contract',
  Consultant: 'Consultant',
});
export const EmployeeStatus = Object.freeze({
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  INVITED: 'Invited',
});
