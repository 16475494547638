/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Box, styled, TextField, Grid, Typography, Alert } from '@mui/material';
import axios from 'axios';

import LogoWhite from '../../../assets/images/img_nimikash_logo.png';
import { styleProps } from '../../../styles/styleProps';
import { timeLimit } from '../../../data/genericConstants';
import PrimaryButton from '../../buttons/PrimaryButton';

const { colors } = styleProps;

const OTPViewContainer = styled(Box)({
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
});

const NormalText = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: colors.textLightGrey,
});

const TextBackButton = {
  fontSize: '16px',
  fontWeight: 500,
  color: colors.primaryBlue,
  textAlign: 'center',
  paddingTop: '10px',
  cursor: 'pointer',
};

const InputContainer = styled(Box)({
  width: '100%',
  marginTop: 20,
  display: 'flex',
  flexDirection: 'row',
  marginRight: 20,
  marginLeft: -5,
});

const OTPInputBox = styled(TextField)({
  padding: '1%',
  borderRadius: 10,
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.primaryBlue,
    },
  },
});

//Styles and styled components
const backgroundStyles = {
  minHeight: '100vh',

  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};

const flexStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const containerStyles = {
  width: '100%',
  minHeight: '500px',
  borderRadius: '10px',
  background: 'white',
  padding: '20px 0px',
  boxShadow: '0px 0px 50px rgba(6, 102, 235, 0.15)',
};

const test = {
  padding: '8% 12% 2% 12%',
};

const TitleContainer = styled(Box)({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Typography)({
  fontSize: '28px',
  fontWeight: 500,
  color: colors.textDarkGrey,
});

const SubTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  color: colors.textLightGrey,
});

const ResendCode = styled(Typography)({
  fomtSize: '16px',
  color: '#0666EB',
  fontWeight: 500,
  paddingTop: '10px',
  cursor: 'pointer',
});

const OtpModal = ({ email, disableOtpView, verifyEmailOtp }) => {
  const [otpCode, setOtp] = useState({
    d_1: null,
    d_2: null,
    d_3: null,
    d_4: null,
    d_5: null,
    d_6: null,
  });
  const [isEmailOtpEnabled, setIsEmailOtpEnabled] = useState(true);
  const [invalidCodeWarining, setInvalidCodeWarining] = useState(false);
  const [timeOutCodeWarining, setTimeOutCodeWarining] = useState(false);
  const [timer, setTimer] = useState(timeLimit);
  const [triggerTimmer, setTriggerTimmer] = useState(false);

  //Only number input function
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  const timeId = useRef();

  //set the timmer
  useEffect(() => {
    if (timer > 0) {
      timeId.current = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 60000);
      return () => clearInterval(timeId.current);
    }
  }, [triggerTimmer]);

  //enable the verify button
  useEffect(() => {
    setTimeOutCodeWarining(false);
    setInvalidCodeWarining(false);
    let res = checkInputFields(otpCode);
    if (res === false) {
      setIsEmailOtpEnabled(false);
    }
  }, [otpCode]);

  //check wheather all the boxes are filled
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  //Handling input behavior for onPaste and OnKeyUp events
  const handleOTPInput = async (e) => {
    const inputs = document.querySelectorAll('.otpInputs input');

    if (e.type === 'paste') {
      await navigator.clipboard.readText().then((res) => {
        const value = res.split('');
        if (value.length > 0) {
          setOtp({
            d_1: value[0],
            d_2: value[1],
            d_3: value[2],
            d_4: value[3],
            d_5: value[4],
            d_6: value[5],
          });
        }
        inputs.forEach((item, index) => {
          item.value = value[index];
        });
        const lastInput = document.querySelector('input[name=d_6]');
        if (lastInput !== null) {
          lastInput.focus();
        }
      });
    } else {
      setOtp({ ...otpCode, [e.target.name]: e.target.value });
      const { value, name } = e.target;
      // eslint-disable-next-line no-unused-vars
      const [fieldName, fieldIndex] = name.split('_');

      if (parseInt(fieldIndex) <= 6 && e.key === 'Backspace') {
        const prevSibling = document.querySelector(
          `input[name=d_${parseInt(fieldIndex) - 1}]`,
        );
        if (prevSibling !== null) {
          prevSibling.focus();
        }
      } else if (value.length > 0) {
        if (parseInt(fieldIndex) < 6) {
          const nextSibling = document.querySelector(
            `input[name=d_${parseInt(fieldIndex) + 1}]`,
          );
          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      }
    }
  };

  //verfity OTP from the db
  const verifyOtp = async () => {
    setInvalidCodeWarining(false);
    if (timer <= 0) {
      setTimeOutCodeWarining(true);
    } else {
      try {
        const otp = parseInt(
          otpCode.d_1 +
            otpCode.d_2 +
            otpCode.d_3 +
            otpCode.d_4 +
            otpCode.d_5 +
            otpCode.d_6,
        );

        const mode = 0;
        const isAdmin = true;

        const body = {
          email,
          otp,
          mode,
          isAdmin,
        };

        await axios
          .post('/api/otp/verify', body, {
            headers: {
              Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
            },
          })
          .then((response) => {
            if (response.data.success === true) {
              verifyEmailOtp();
            } else {
              setInvalidCodeWarining(true);
            }
          });
      } catch (err) {
        console.error(err.message);
        window.location = '/error';
      }
    }
  };

  //resend OTP from the db
  const resendOtp = () => {
    setInvalidCodeWarining(false);
    setTimeOutCodeWarining(false);
    setIsEmailOtpEnabled(true);
    try {
      const mode = 0;

      const body = {
        email,
        mode,
      };

      axios
        .post('/api/otp/send', body, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then((response) => {
          if (response.data.success === false) {
            console.log(response.data.message);
          } else {
            console.log(response.data.error);
            setTimer(2);
            setTriggerTimmer(true);
          }
        });
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };
  return (
    <div style={backgroundStyles}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            height: '20vh',
            ...flexStyle,
            mb: 8,
          }}
        >
          <Box sx={flexStyle}>
            <img src={LogoWhite} alt='logo-white' width='250px' />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            ...flexStyle,
            alignItems: 'flex-start',
            marginTop: -10,
          }}
        >
          <Grid
            item
            xs={9}
            md={5}
            lg={4}
            sx={{
              height: 'auto',
            }}
          >
            <div style={containerStyles}>
              <div style={test}>
                <TitleContainer>
                  <Title>Verify Account</Title>
                  <br />
                  <SubTitle>
                    We have sent a 6-digit verification code to your registered
                    email {email}
                  </SubTitle>
                </TitleContainer>

                <OTPViewContainer>
                  <InputContainer className='otpInputs'>
                    <OTPInputBox
                      className='otpInput'
                      inputProps={{ maxLength: 1, sx: { textAlign: 'center' } }}
                      onInput={(e) => onlyNumbers(e)}
                      InputProps={{
                        sx: {
                          borderRadius: 3,
                        },
                      }}
                      name='d_1'
                      onKeyUp={(e) => handleOTPInput(e)}
                      focused={otpCode.d_1 ? true : false}
                      onPaste={(e) => handleOTPInput(e)}
                    />
                    <OTPInputBox
                      className='otpInput'
                      inputProps={{ maxLength: 1, sx: { textAlign: 'center' } }}
                      onInput={(e) => onlyNumbers(e)}
                      InputProps={{
                        sx: {
                          borderRadius: 3,
                        },
                      }}
                      name='d_2'
                      onKeyUp={(e) => handleOTPInput(e)}
                      focused={otpCode.d_2 ? true : false}
                    />
                    <OTPInputBox
                      className='otpInput'
                      inputProps={{ maxLength: 1, sx: { textAlign: 'center' } }}
                      onInput={(e) => onlyNumbers(e)}
                      InputProps={{
                        sx: {
                          borderRadius: 3,
                        },
                      }}
                      name='d_3'
                      onKeyUp={(e) => handleOTPInput(e)}
                      focused={otpCode.d_3 ? true : false}
                    />
                    <OTPInputBox
                      className='otpInput'
                      inputProps={{ maxLength: 1, sx: { textAlign: 'center' } }}
                      onInput={(e) => onlyNumbers(e)}
                      InputProps={{
                        sx: {
                          borderRadius: 3,
                        },
                      }}
                      name='d_4'
                      onKeyUp={(e) => handleOTPInput(e)}
                      focused={otpCode.d_4 ? true : false}
                    />
                    <OTPInputBox
                      className='otpInput'
                      inputProps={{
                        maxLength: 1,
                        sx: { textAlign: 'center' },
                      }}
                      onInput={(e) => onlyNumbers(e)}
                      InputProps={{
                        sx: {
                          borderRadius: 3,
                        },
                      }}
                      name='d_5'
                      onKeyUp={(e) => handleOTPInput(e)}
                      focused={otpCode.d_5 ? true : false}
                    />
                    <OTPInputBox
                      className='otpInput'
                      inputProps={{ maxLength: 1, sx: { textAlign: 'center' } }}
                      onInput={(e) => onlyNumbers(e)}
                      InputProps={{
                        sx: {
                          borderRadius: 3,
                        },
                      }}
                      name='d_6'
                      onKeyUp={(e) => handleOTPInput(e)}
                      focused={otpCode.d_6 ? true : false}
                    />
                  </InputContainer>
                  <br />
                  {invalidCodeWarining === true ? (
                    <Alert severity='error'>Invalid code</Alert>
                  ) : timeOutCodeWarining === true ? (
                    <Alert severity='error'>
                      Invalid code, wait time exceeded
                    </Alert>
                  ) : timer > 0 && invalidCodeWarining === false ? (
                    <NormalText sx={{ mt: 1 }}>
                      {timer} minutes remaining
                    </NormalText>
                  ) : null}
                  {timer <= 0 ? (
                    <ResendCode onClick={resendOtp}>Resend code</ResendCode>
                  ) : (
                    <ResendCode>Resend code</ResendCode>
                  )}

                  <Box sx={{ mt: 8 }}>
                    <PrimaryButton
                      onClick={verifyOtp}
                      disabled={isEmailOtpEnabled}
                      fullWidth
                    >
                      Verify OTP
                    </PrimaryButton>
                    <Typography onClick={disableOtpView} sx={TextBackButton}>
                      Go back
                    </Typography>
                  </Box>
                </OTPViewContainer>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OtpModal;
