import { enqueueSnackbar } from 'notistack';
import { alertHandler } from '../utils/alertHandler';

export const clearAuthData = () => {
  localStorage.removeItem('loggedInAuth');
  localStorage.removeItem('tempAuth');
  localStorage.removeItem('sessionValid');
  localStorage.removeItem('adminId');
  localStorage.removeItem('userData');
  localStorage.removeItem('message');
  localStorage.removeItem('severity');
};

export const logout = (message) => {
  if (message) {
    alertHandler(message, 'info', enqueueSnackbar);
  }
  clearAuthData();
  window.location.href = '/';
};
