import React, { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import PropTypes from 'prop-types';
import generateCanvasImage from './helper/generateCanvasImage';

const TypeSignatureTab = ({ onCapture }) => {
  const [name, setName] = useState('');
  const [canvasImage, setCanvasImage] = useState(null);

  useEffect(() => {
    const imageDataURL = generateCanvasImage(name);
    if (name.trim() !== '') {
      setCanvasImage(imageDataURL);
      onCapture(imageDataURL);
    } else {
      setCanvasImage(imageDataURL);
      onCapture(null);
    }
  }, [name, onCapture]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        margin: 2,
        borderRadius: 2,
      }}
    >
      <TextField
        label='Type Name here'
        variant='outlined'
        value={name}
        onChange={handleNameChange}
        sx={signatureTextFieldStyles}
      />
      <Box>
        {canvasImage ? (
          <img src={canvasImage} alt='Signature' style={canvasImgStyles} />
        ) : null}
      </Box>
    </Box>
  );
};

TypeSignatureTab.propTypes = {
  onCapture: PropTypes.func.isRequired,
};

export default TypeSignatureTab;
const canvasImgStyles = {
  maxWidth: '100%',
  height: 96,
  marginLeft: 165,
};
const signatureTextFieldStyles = {
  height: 20,
  width: 430,
  marginTop: 3,
  marginLeft: 3,
};
