//generate canvas image
const generateCanvasImage = (name) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = 400;
  canvas.height = 60;
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.font = '22px Waterfall';

  context.fillText(name, 20, 50);
  return canvas.toDataURL('image/png');
};

export default generateCanvasImage;
