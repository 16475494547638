import React from 'react';
import { Box, Typography, styled } from '@mui/material';

import payRollImages from '../../assets/imagesData/payroll';

import { styleProps } from '../../styles/styleProps';
import ShortEmployeePrimaryBtn from '../buttons/ShortEmployeePrimaryButton';

const { fonts, colors } = styleProps;

const PayRollOverview = () => {
  const monthlySalaryDate = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', {
      month: 'long',
    });
    const firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1,
    );
    const lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0,
    );

    const formattedFirstDay = firstDay.getDate();
    const formattedLastDay = lastDay.getDate();
    return `${currentMonth} ${formattedFirstDay}st - ${formattedLastDay}th`;
  };
  const monthlySalaryDateBanner = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', {
      month: 'long',
    });
    return ` Kindly arrange the scheduling of the payroll for the month of ${currentMonth} no later than the 25th of ${currentMonth}`;
  };
  return (
    <Box sx={styles.overviewContainer} marginTop={4}>
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
        <img
          src={payRollImages.CALENDAR}
          style={{ height: '5rem', padding: 3 }}
          alt='money bag'
        />

        <Box sx={styles.overviewParent} margin={2}>
          <CustomTypography variant='h5' noWrap fontWeight={600}>
            Monthly Salary : {monthlySalaryDate()}
          </CustomTypography>

          <CustomTypography
            variant='h7'
            noWrap
            color={colors.textLightGrey}
            marginTop={2}
          >
            {monthlySalaryDateBanner()}
          </CustomTypography>

          <Box sx={{ marginTop: 2 }}>
            <ShortEmployeePrimaryBtn
              text='Schedule Payroll'
              onClick={() => (window.location.href = '/payrollSchedule')}
            />
          </Box>
        </Box>
      </Box>

      <img
        src={payRollImages.INVOICE}
        style={{ height: '8rem', padding: 3 }}
        alt='Invoice curate'
      />
    </Box>
  );
};

export default PayRollOverview;

const CustomTypography = styled(Typography)({
  fontFamily: fonts.primary,
});

const styles = {
  mainContaineer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 3,
    marginBottom: '90px',
  },
  overviewContainer: {
    display: 'flex',
    backgroundColor: '#0666EB1A',
    justifyContent: 'space-between',
    gap: 2,
    paddingY: 1,
    paddingX: 2,
    borderRadius: 2,
  },
  overviewParent: {
    display: 'flex',
    gap: 0.5,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  roundedBorder: {
    borderRadius: '7px',
    border: '2px solid #DEEBFC',
    padding: '10px',
  },
  dataWidgetTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 0.5,
  },
};
