/* eslint-disable no-undef */
import axios from 'axios';
import { alertHandler } from '../utils/alertHandler';
import { enqueueSnackbar } from 'notistack';
import { logout } from './AuthService';

const PlatformIntegrationService = {
  /**
   * Function to execute a http post request
   *
   * @param { string } postPath - path to the api
   * @param { any } postBody - body of the request
   * @param { boolean } auth - if the request requires authentication header
   * @returns response of the request
   */
  executeHttpPostRequest: function async(postPath, postBody, auth) {
    return executeHttpRequest('post', postPath, auth, postBody);
  },

  /**
   * Function to execute a http delete request
   *
   * @param {*} deletePath - path to the api
   * @param {*} auth - if the request requires authentication header
   *
   */
  executeHttpDeleteRequest: function async(deletePath, auth) {
    return executeHttpRequest('delete', deletePath, auth);
  },

  /**
   * Function to execute a http put request
   *
   * @param { string } postPath - path to the api
   * @param { any } postBody - body of the request
   * @param { boolean } auth - if the request requires authentication header
   * @returns response of the request
   */
  executeHttpPutRequest: function (putPath, putBody, auth) {
    return executeHttpRequest('put', putPath, auth, putBody);
  },

  /**
   * Function to execute a http delete request
   *
   * @param { string } postPath - path to the api
   * @param { boolean } auth - if the request requires authentication header
   * @returns response of the request
   */
  executeHttpGetRequest: function (getPath, auth) {
    return executeHttpRequest('get', getPath, auth);
  },

  /**
   * Function to execute a http patch request
   *
   * @param { string } patchPath - path to the api
   * @param { any } patchBody - body of the request
   * @param { boolean } auth - if the request requires authentication header
   * @returns response of the request
   */
  executeHttpPatchRequest: function (patchPath, patchBody, auth) {
    return executeHttpRequest('patch', patchPath, auth, patchBody);
  },
};

async function executeHttpRequest(method, path, auth = true, body) {
  // Dynamically set base URL from the current domain
  const baseURL = `${window.location.origin}`; // This will set it like `https://app-qa.nimikash.com/`

  const config = {
    method: method.toUpperCase(),
    url: path,
    headers: auth ? await constructHeader() : {},
    data: body,
    baseURL: baseURL,
  };

  return axios(config)
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.error);
      }
    })
    .catch((error) => {
      const responseData = error?.response?.data;
      // if error 401 then logout and redirect to login page
      if (error?.response?.status === 401) {
        return logout('Session expired. Please login again');
      }
      const errorMessages = Array.isArray(responseData?.error)
        ? responseData.error.map((err) => err.message)
        : responseData?.error
          ? [responseData.error]
          : [error.message];

      errorMessages.forEach((message) =>
        alertHandler(message, 'error', enqueueSnackbar),
      );
    });
}

async function constructHeader() {
  let token = await getToken();
  return {
    Authorization: `"${token}"`,
  };
}

async function getToken() {
  return (
    localStorage.getItem('loggedInAuth') ?? localStorage.getItem('tempAuth')
  );
}

export default PlatformIntegrationService;
