import { format } from 'date-fns';
import { getEmployeeByStatus } from '../services/EmployeeService';
import { EmployeeStatus } from '../enums/Employee';
import PlatformIntegrationService from './AbstractPlatformIntegrationService';
import UrlRoute from '../configs/UrlRoute';

const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

// Service to fetch FBO balance
export const getFBOBalance = async () => {
  const response = await PlatformIntegrationService.executeHttpGetRequest(
    UrlRoute.GET_FBO_BALANCE,
  );
  const formatFbobalnce = nf.format(response.message[0].balance);

  return formatFbobalnce;
};

// Service to fetch Total number of employes
export const getTotalEmployee = async () => {
  const res = await getEmployeeByStatus(EmployeeStatus.ENABLED);
  return res.employees?.length || 0;
};

// Service to fetch last pay date and last pay amount of last payroll
export const getLastPayDateAndAmount = async () => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    UrlRoute.PAYROLLS,
  );
  let lastPayRollAmount;
  let fullDate;

  if (data.message.length > 0) {
    const filteredAndSortedData = data.message
      .filter((item) => item.status.includes('P') || item.status.includes('PP'))
      .sort(
        (b, a) =>
          new Date(a.payDate) - new Date(b.payDate) ||
          new Date(a.lastPaymentTs) - new Date(b.lastPaymentTs),
      );
    if (filteredAndSortedData.length > 0) {
      const lastPayDate =
        filteredAndSortedData[0].payDate ||
        filteredAndSortedData[0].lastPaymentTs;
      lastPayRollAmount = nf.format(filteredAndSortedData[0].amount);

      const date = format(new Date(lastPayDate), 'dd');
      const month = format(new Date(lastPayDate), 'MMM');
      const year = format(new Date(lastPayDate), 'yyyy');
      fullDate = `${date} ${month} ${year}`;
      return { fullDate, lastPayRollAmount };
    }
  }
  return { fullDate, lastPayRollAmount };
};
