import React, { useMemo } from 'react';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { getCurrentAdmin } from '../services/AdminService';
import { Grid } from '@mui/material';
import TopBar from '../components/top-bar/TopBar';
import Header from '../components/project-reports/Header';
import { getProjectReports } from '../services/ProjectReportService';
import { useProjectReport } from '../contexts/project-reports-context/ProjectReportsContext';
import ReportsContainer from '../components/project-reports/ReportsContainer';

const ProjectReportPage = () => {
  const [user, setUser] = useState('');
  const { projectReports, actions } = useProjectReport();
  const {
    selectedTab,
    reports,
    dmStatus,
    weekYear,
    currentPage,
    modifiedReport,
  } = projectReports;
  const { setReports } = actions;
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    try {
      (async () => {
        setIsLoading(true);
        const data = await getCurrentAdmin();
        setUser(data);

        const reports = await getProjectReports(
          selectedTab,
          dmStatus,
          weekYear,
          currentPage,
        );
        if (reports) {
          setReports(reports);
        }
        setIsLoading(false);
      })();
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, [selectedTab, dmStatus, weekYear, modifiedReport]);

  const sortedReports = useMemo(() => {
    // Create a copy of the original data to avoid mutating it
    const dataCopy = [...reports];

    return dataCopy.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.projectName - b.projectName;
      } else {
        return b.projectName - a.projectName;
      }
    });
  }, [reports, sortOrder]);

  // TODO: Sorting function as a upcoming feature
  // eslint-disable-next-line no-unused-vars
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <>
      <TopBar title={'Project Report'} user={user} />
      <Header />
      <Grid>
        <Box
          sx={{
            padding: '1.5%',
            paddingRight: '1%',
            paddingTop: '0',
            height: 'calc(100vh - 200px)',
            overflowY: 'scroll',
          }}
        >
          <ReportsContainer isLoading={isLoading} reports={sortedReports} />
        </Box>
      </Grid>
    </>
  );
};

export default ProjectReportPage;
