import React, { useState, useRef } from 'react';
import { Box, Typography, Chip, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import PropTypes from 'prop-types';

const UploadSignatureTab = ({ onCapture }) => {
  const [file, setFile] = useState(null);
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);
    handleUpload(file);
  };

  const handleUpload = (fileData) => {
    onCapture(fileData);
  };

  const uploadFiles = (event) => {
    const file = event.target.files[0];
    setFile(file);
    handleUpload(file);
  };

  const removeUploadedFile = () => {
    setFile(null);
  };

  return (
    <Box sx={{ backgroundColor: 'white', margin: 2, borderRadius: 2 }}>
      <Box
        className='dropzone'
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={dropBoxStyles}
      >
        <UploadIcon sx={uploadIconStyles} fontSize='inherit' />
        <input
          type='file'
          onChange={uploadFiles}
          hidden
          accept='image/*'
          ref={inputRef}
        />
        <Typography>
          Drag and drop your file here or{' '}
          <Button onClick={() => inputRef.current.click()} sx={browsBtnStyles}>
            Browse
          </Button>
        </Typography>
        {file && (
          <Box sx={chipBoxStyles}>
            <Chip
              color='primary'
              variant='outlined'
              label={file.name}
              onDelete={removeUploadedFile}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

UploadSignatureTab.propTypes = {
  onCapture: PropTypes.func.isRequired,
};

export default UploadSignatureTab;

const dropBoxStyles = {
  padding: '20px',
  marginLeft: 70,
  width: 480,
  height: 104,
};
const browsBtnStyles = {
  cursor: 'pointer',
  color: '#0666EB',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  marginBottom: 0.3,
  fontSize: 16,
};
const uploadIconStyles = {
  paddingLeft: '120px',
  fontSize: 30,
  color: '#979797',
  marginTop: 1,
};

const chipBoxStyles = {
  width: '100%',
  marginLeft: '80px',
};
