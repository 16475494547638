/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Modal,
  Button,
  Stack,
  Typography,
  TextField,
  styled,
  OutlinedInput,
  Select,
  MenuItem,
  Menu,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { CountryCodes } from '../../../src/data/countryCodes';
import InputAdornment from '@mui/material/InputAdornment';
import { styleProps } from '../../styles/styleProps';

const { fonts, fontSizes } = styleProps;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: 5,
    borderRadius: 6,
    width: 275,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      '&:hover': {
        backgroundColor: '#0666EB',
        color: '#FFFFFF',
      },
    },
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  background: '#FFFFFF',
  borderRadius: '5px',
};

const TextInput = styled(OutlinedInput)({
  width: '315px',
  borderRadius: '5px',
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: fonts.primary,
  },
  '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

const CountryCodeSelect = styled(Select)({
  width: '25%',
  height: 55,

  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '5px 0 0 5px',
    },
  },
  '& fieldset': {
    borderRadius: '5px 0 0 5px',
  },
});
const MobileNumberInput = styled(TextField)({
  width: '75%',

  '& .MuiInputBase-root': {
    height: 55,
    '& fieldset': {
      borderRadius: '0 5px 5px 0',
    },
    '& input::placeholder': {
      fontSize: fontSizes.normal,
    },
  },
});

const modalRightGrid = {
  boxShadow: 0,
  paddingRight: '20px',
};

const typographyStyle4 = {
  marginTop: '2%',
  color: '#71717A',
  paddingLeft: 4,
  fontWeight: 400,
  fontSize: '16px',
  marginBottom: '2%',
};

const EditCompanyProfileDetailsModal = ({
  onClose,
  modalStatus,
  companyProfileData,
  refreshData,
  companyNumber,
  companyCountryCodeValue,
}) => {
  const [isEmptyFields, setIsEmptyFields] = useState(false);
  const countryCodes = CountryCodes.countries;
  const [date, setDate] = useState(companyProfileData.companyPayDay);
  const [isMobileNumberInvalid, setIsMobileNumberInvalid] = useState(false);
  const [isUpdateUnsuccessful, setIsUpdateUnsuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const datePickerValues = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  const initialValues = {
    address_01: companyProfileData.companyAddressOne,
    address_02: companyProfileData.companyAddressTwo,
    city: companyProfileData.companyCity,
    country: companyProfileData.companyCountry,
    email: companyProfileData.companyEmail,
    code: companyCountryCodeValue,
    number: companyNumber,
  };
  const validationSchema = Yup.object({
    address_01: Yup.string(),
    address_02: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    email: Yup.string(),
    number: Yup.string().test('len', 'Invalid mobile number', function (value) {
      const selectedCountry = countryCodes.find(
        (country) => country.code === this.parent.code,
      );
      if (selectedCountry && value.length !== selectedCountry.numberLength) {
        return false;
      }

      return true;
    }),
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setDate(e.target.value || date);
    setAnchorEl(null);
  };

  const onSubmit = () => {
    try {
      const companyId = companyProfileData.companyId;
      const addressOne = formik.values.address_01;
      const addressTwo = formik.values.address_02;
      const city = formik.values.city;
      const email = formik.values.email;
      const country = formik.values.country;
      const mobile = formik.values.code + formik.values.number;
      const payday = date;

      let countryCodeValue = countryCodes.filter(
        (field) => field.code === formik.values.code,
      );

      let countryCode = countryCodeValue[0].name;

      const body = {
        companyId,
        addressOne,
        addressTwo,
        city,
        country,
        email,
        countryCode,
        mobile,
        payday,
      };

      axios
        .put('/api/company/update', body, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              refreshData();
              onClose();
            } else {
              setErrorMessage(response.data.error);
              setIsUpdateUnsuccessful(true);
            }
          },
          (error) => {
            if (error.response.status === 401) {
              localStorage.setItem('sessionValid', 'false');
              window.location = '/login';
            } else {
              window.location = '/error';
            }
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    let res = checkInputFields(formik.values);

    if (res === false && isMobileNumberInvalid === false) {
      setIsEmptyFields(false);
    } else {
      setIsEmptyFields(true);
    }
  }, [formik.values]);

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  //Only number input function
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  //verify mobile number format

  return (
    <div>
      <Modal open={modalStatus} onClose={onClose}>
        <Box
          sx={{
            ...style,
            width: '719px',
            height: 'auto',
          }}
        >
          <Stack justifyContent='space-between' direction='row'>
            <Typography
              sx={{
                margin: '3% 3% 3% 4%',
                color: '#2A2A2A',
                fontWeight: 500,
                fontSize: '20px',
              }}
            >
              Edit Company Details
            </Typography>
            <Button
              onClick={onClose}
              style={{
                backgroundColor: 'none',
                '&:hover': {
                  backgroundColor: '#FFDFAC',
                },
                color: '#2A2A2A',
                marginTop: 0,
              }}
            >
              <CloseIcon />
            </Button>
          </Stack>
          <hr style={{ border: '1px solid #E5E5E5' }}></hr>

          <Stack justifyContent='space-between' direction='row'>
            <Typography sx={typographyStyle4}>Company Address</Typography>
          </Stack>
          <Grid
            sx={{ boxShadow: 0, paddingLeft: 4, paddingRight: 3 }}
            container
          >
            <Grid container>
              <Grid sx={modalRightGrid} item xs={6}>
                <TextInput
                  placeholder='Address line 01 *'
                  name='address_01'
                  value={formik.values.address_01}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid sx={modalRightGrid} item xs={6}>
                <TextInput
                  placeholder='Address line 02'
                  name='address_02'
                  value={formik.values.address_02}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ paddingTop: '10px' }}>
              <Grid sx={modalRightGrid} item xs={6}>
                <TextInput
                  placeholder='City'
                  name='city'
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid sx={modalRightGrid} item xs={6}>
                <TextInput
                  placeholder='Country'
                  name='country'
                  value={formik.values.country}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Stack justifyContent='space-between' direction='row'>
            <Typography sx={typographyStyle4}>Contact Details</Typography>
          </Stack>
          <Grid
            sx={{ boxShadow: 0, paddingLeft: 4, paddingRight: 3 }}
            container
          >
            <Grid container>
              <Grid sx={modalRightGrid} item xs={6}>
                <TextInput
                  placeholder='Email address *'
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid sx={modalRightGrid} item xs={6}>
                <Box sx={{ width: '100%' }}>
                  <CountryCodeSelect
                    name='code'
                    value={formik.values.code}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: 250,
                          height: 250,
                        },
                      },
                    }}
                    renderValue={(value) => value}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsMobileNumberInvalid(false);
                      setIsUpdateUnsuccessful(false);
                    }}
                  >
                    {countryCodes.map((item, index) => (
                      <MenuItem key={index} value={item.code}>
                        {item.name.length > 15
                          ? item.name.slice(0, 15) + `... (${item.code})`
                          : item.name + ` (${item.code})`}
                      </MenuItem>
                    ))}
                  </CountryCodeSelect>
                  <MobileNumberInput
                    placeholder='Mobile number *'
                    type='tel'
                    value={formik.values.number}
                    name='number'
                    onInput={(e) => onlyNumbers(e)}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsMobileNumberInvalid(false);
                      setIsUpdateUnsuccessful(false);
                    }}
                  />{' '}
                  {formik.touched.number && formik.errors.number ? (
                    <>
                      <br />
                      <br />
                      <Alert severity='error'>Incorrect mobile number</Alert>
                    </>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Stack justifyContent='space-between' direction='row'>
            <Typography sx={typographyStyle4}>Pay Day *</Typography>
          </Stack>

          <Grid
            sx={{ boxShadow: 0, paddingLeft: 4, paddingRight: 3 }}
            container
          >
            <Grid container>
              <Grid sx={modalRightGrid} item xs={12}>
                <TextField
                  sx={{
                    width: '70px',
                    '& .MuiInputBase-root': {
                      height: 55,
                      '& fieldset': {
                        borderRadius: '5px 0 0 5px',
                      },
                      '& input::placeholder': {
                        fontSize: fontSizes.normal,
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>Every</InputAdornment>
                    ),
                  }}
                  disabled={true}
                  onChange={formik.handleChange}
                />

                <Button
                  id='demo-customized-button'
                  variant='contained'
                  disableElevation
                  sx={{
                    backgroundColor: 'white',
                    color: '#71717A',
                    border: '1px solid #C4C4C4',
                    height: '55px',
                    width: '245px',
                    borderRadius: '0 5px 5px 0',
                    alignItems: 'center',
                    justifyContent: 'end',
                    '&:hover': {
                      backgroundColor: '#fff',
                      color: '#3c52b2',
                    },
                  }}
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {date}
                </Button>
                <StyledMenu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(e) => {
                    handleClose(e);
                  }}
                >
                  <Grid sx={{ boxShadow: 0 }} container>
                    <Grid container>
                      {datePickerValues.map((item, index) => (
                        <Grid item xs={1.7} key={index}>
                          <MenuItem
                            onClick={handleClose}
                            value={item}
                            disableRipple
                            sx={{ justifyContent: 'center' }}
                          >
                            {item}
                          </MenuItem>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </StyledMenu>
              </Grid>
            </Grid>
          </Grid>

          {isUpdateUnsuccessful === true ? (
            <div style={{ paddingLeft: '4%', paddingRight: '6%' }}>
              <br />
              <Alert severity='error'>{errorMessage}</Alert>
            </div>
          ) : null}

          <Stack
            spacing={1}
            direction='row'
            sx={{
              marginTop: 5,
              alignItems: 'right',
              justifyContent: 'right',
              marginRight: '4%',
              marginBottom: '3%',
            }}
          >
            <SecondaryButton styles={{ width: '195px' }} onClick={onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={formik.handleSubmit}
              disabled={isEmptyFields}
              styles={{
                marginLeft: '5px',
                marginRight: '10%',
                width: '195px',
              }}
            >
              Update
            </PrimaryButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default EditCompanyProfileDetailsModal;
