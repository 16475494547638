import PlatformIntegrationService from './AbstractPlatformIntegrationService';
import UrlRoute from '../configs/UrlRoute';

export const getProjectReports = async (view, dmStatus, weekYear, page) => {
  const reports = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.PROJECT_REPORTS}/?view=${view}&dmStatus=${dmStatus}&week=${weekYear.week}&year=${weekYear.year}&page=${page}`,
  );
  return reports.message;
};

export const addProjectSummary = async (reportId, body) => {
  const updatedReport =
    await PlatformIntegrationService.executeHttpPatchRequest(
      `${UrlRoute.PROJECT_REPORT_ADD_SUMMARY}/${reportId}`,
      body,
    );

  return updatedReport.message;
};

export const approveProjectSummary = async (reportId, body) => {
  const updatedReport =
    await PlatformIntegrationService.executeHttpPatchRequest(
      `${UrlRoute.PROJECT_REPORT_APPROVE}/${reportId}/approve`,
      body,
    );

  return updatedReport.message;
};

export const editEmployeeTime = async (reportId, body) => {
  const updatedReport =
    await PlatformIntegrationService.executeHttpPatchRequest(
      `${UrlRoute.PROJECT_REPORT_UPDATE_TIME}/${reportId}/time-duration`,
      body,
    );

  return updatedReport.message;
};

export const getWeeklyReportsOfTheMonth = async (reportId) => {
  const updatedReport = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.PROJECT_REPORT_EDIT_MONTHLY_SUMMARY}/${reportId}`,
  );

  return updatedReport;
};
