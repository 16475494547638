const totalPayrollCalculation = (employees) => {
  const totalPay =
    calculateTotalEarnings(employees) - calculateTotalDeductions(employees);

  return totalPay;
};

export default totalPayrollCalculation;

const calculateTotalEarnings = (employees) => {
  return employees.reduce((totalEarnings, employee) => {
    const employeeTotalIncomeWithoutTax =
      stringToIntegerConverter(employee.employeeBasicSalary) +
      stringToIntegerConverter(employee.employeeAllowanceBonus) +
      stringToIntegerConverter(employee.employeeAllowanceDailyPay) +
      stringToIntegerConverter(employee.employeeAllowanceFuel) +
      stringToIntegerConverter(employee.employeeAllowanceOt) +
      stringToIntegerConverter(employee.employeeAllowanceTravel) +
      stringToIntegerConverter(employee.employeeReimbursements);

    let taxDeduction = 0.0;
    if (employee.employeePayeeTax) {
      taxDeduction = calculateAdvancedPayeeTax(employeeTotalIncomeWithoutTax);
    }

    const employeeEarningsAfterTaxDeduction =
      employeeTotalIncomeWithoutTax - taxDeduction;

    return totalEarnings + employeeEarningsAfterTaxDeduction;
  }, 0);
};
const calculateTotalDeductions = (employees) => {
  return employees.reduce((value, employee) => {
    return (
      value +
      stringToIntegerConverter(employee.employeeDeductionEpf) +
      stringToIntegerConverter(employee.employeeNoPay) +
      stringToIntegerConverter(employee.employeeDeductionLoan) +
      stringToIntegerConverter(employee.employeeDeductionAdvance)
    );
  }, 0);
};
export const calculateAdvancedPayeeTax = (salary) => {
  let tax = 0.0;
  if (salary <= 100000) {
    tax = 0;
  } else if (salary > 100000 && salary < 141667) {
    tax = (salary - 100000) * 0.06;
  } else if (salary >= 141667 && salary < 183333) {
    tax = 41667 * 0.06 + (salary - 141667) * 0.12;
  } else if (salary >= 183333 && salary < 225000) {
    tax = 41667 * 0.06 + 41666 * 0.12 + (salary - 183333) * 0.18;
  } else if (salary >= 225000 && salary < 266667) {
    tax = 41667 * 0.06 + 41666 * 0.12 + 41667 * 0.18 + (salary - 225000) * 0.24;
  } else if (salary >= 266667 && salary < 308333) {
    tax =
      41667 * 0.06 +
      41666 * 0.12 +
      41667 * 0.18 +
      41667 * 0.24 +
      (salary - 266667) * 0.3;
  } else {
    tax =
      41667 * 0.06 +
      41666 * 0.12 +
      41667 * 0.18 +
      41667 * 0.24 +
      41666 * 0.3 +
      (salary - 308333) * 0.36;
  }
  return tax;
};

const stringToIntegerConverter = (stringValue) => {
  return parseInt(stringValue);
};
