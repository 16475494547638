/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import styled from '@emotion/styled';
import { useState } from 'react';
import useFetchEmployeeByStatus from '../../../hooks/useFetchEmployeeByStatus';
import { createProject } from '../../../services/ProjectServices';

export default function CreateProject({ visible, setVisible }) {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectMembers, setProjectMembers] = useState([]);
  const employees = useFetchEmployeeByStatus();

  const handleClose = () => {
    setProjectName('');
    setProjectDescription('');
    setProjectMembers([]);

    setVisible(false);
  };

  const handleSave = () => {
    createProject({
      name: projectName,
      description: projectDescription,
      employeeList: projectMembers,
    }).then(() => {
      handleClose();
    });
  };

  return (
    <div>
      <Modal open={visible} onClose={handleClose}>
        <Box sx={ModalStyle}>
          <Typography variant='h7' fontSize={'17px'} marginRight='auto'>
            Create a new project
          </Typography>

          <FormControl sx={{ gap: 2 }} fullWidth>
            <TextFieldStyle
              label='Project Name'
              size='small'
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <TextFieldStyle
              multiline
              rows={4}
              label='Project Description'
              size='small'
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
            />
            <Autocomplete
              size='small'
              multiple
              options={employees}
              getOptionLabel={(option) => option.label}
              key={(option) => option.id}
              filterSelectedOptions
              onChange={(e, value) =>
                setProjectMembers(value.map((member) => member.id))
              }
              renderInput={(params) => (
                <TextFieldStyle {...params} label='Projects Members' />
              )}
            />
          </FormControl>

          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              variant='outlined'
              sx={[styles.buttonStyle, styles.InvertedButtonStyle]}
              color='primary'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={styles.buttonStyle}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

const ModalStyle = {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '10px',
  gap: '1.5rem',
};

const styles = {
  buttonStyle: {
    margin: '0 .4rem',
    borderRadius: '30px',
    fontWeight: '600',
    paddingX: 3,
    paddingY: 1,
    width: '40%',
  },
  InvertedButtonStyle: {
    backgroundColor: '#fff',
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
};

const TextFieldStyle = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 7px;
  }
`;
