/* eslint-disable react/react-in-jsx-scope */
import DashBoard from '../pages/DashBoard';
import Admins from '../pages/AdminPage';
import Profile from '../pages/CompanyProfilePage';
import LoginPage from '../pages/LoginPage';
import EmployeeDetails from '../pages/EmployeeDetailsPage';
import PasswordReset from '../pages/PasswordResetPage';
import EmployeeRegistration from '../pages/EmployeeRegistrationPage';
import ErrorResponsePage from '../pages/ErrorResponsePage';
import ConstructionPage from '../pages/ConstructionPage';
import PayrollSetting from '../pages/PayrollSettingPage';
import Payroll from '../pages/PayrollPage';
import PayrollSchedule from '../pages/PayrollSchedulePage';
import ProjectsPage from '../pages/ProjectsPage';
import ProjectReportPage from '../pages/ProjectReportPage';
import AttendanceSettingPage from '../pages/AttendanceSettingPage';
import HybridSchedulePage from '../pages/HybridSchedulePage';
import AttendancePage from '../pages/AttendancePage';

import OfferLettersPage from '../pages/OfferLettersPage';
import OfferLetterTemplatesPage from '../pages/OfferLetterTemplatesPage';
import RegistrationPage from '../pages/CompanyRegistrationPage';
import EmployeePaystub from '../pages/PaystubPage';
import OfferLetterViewPage from '../pages/OfferLetterView';

// Icons
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import Employees from '../pages/EmployeePage';
import OfferLetterReviewPage from '../pages/OfferLetterReviewPage';
import MonthlyReportPage from '../pages/MonthlyReportPage';

// This can be used for routes and Layout component
// NOTE: '/' is removed only from the protected routes.
const PAGES = {
  admins: {
    id: 'admins',
    component: <Admins />,
    to: 'admins',
    title: 'Admin Management',
    icon: ManageAccountsRoundedIcon,
  },
  companyRegistration: {
    id: 'companyRegistration',
    component: <RegistrationPage />,
    to: '/register',
    title: undefined,
    icon: undefined,
  },
  constructionPage: {
    id: 'constructionPage',
    component: <ConstructionPage />,
    to: '/info',
    title: undefined,
    icon: undefined,
  },
  dashboard: {
    id: 'dashboard',
    component: <DashBoard />,
    to: 'dashboard',
    title: 'Dashboard',
    icon: DashboardRoundedIcon,
  },
  employeeDetails: {
    id: 'employeeDetails',
    component: <EmployeeDetails />,
    to: 'employeeDetails/:id/:editable',
    // TODO: Make this customizable
    title: 'Employees > ',
    icon: undefined,
  },
  employees: {
    id: 'employees',
    component: <Employees />,
    to: 'employees',
    title: 'Employees',
    icon: PeopleRoundedIcon,
  },
  employeeRegistration: {
    id: 'employeeRegistration',
    component: <EmployeeRegistration />,
    to: '/genericRegistration/:type/:email',
    title: undefined,
    icon: undefined,
  },
  errorResponsePage: {
    id: 'errorResponsePage',
    component: <ErrorResponsePage />,
    to: '/error',
    title: undefined,
    icon: undefined,
  },

  initLogin: {
    id: 'initLogin',
    component: <LoginPage />,
    to: '/',
    title: undefined,
    icon: undefined,
  },
  login: {
    id: 'login',
    component: <LoginPage />,
    to: '/login',
    title: undefined,
    icon: undefined,
  },
  offerLetterId: {
    id: 'offerLetterId',
    component: <OfferLetterViewPage />,
    to: 'offerLetter/:id',
    title: 'Offer Letters',
    icon: undefined,
  },
  offerLetterTemplates: {
    id: 'offerLetterTemplates',
    component: <OfferLetterTemplatesPage />,
    to: 'offerLetterTemplates',
    title: 'Offer Letter Templates',
    icon: undefined,
  },
  offerLetters: {
    id: 'offerLetters',
    component: <OfferLettersPage />,
    to: 'offerLetters',
    title: 'Offer Letters',
    icon: undefined,
  },
  offerLettersReviewId: {
    id: 'offerLettersReview',
    component: <OfferLetterReviewPage />,
    to: 'offerLetterReview/:id',
    title: 'Offer Letters > Offer letter',
    icon: undefined,
  },
  offerLetterTemplateId: {
    id: 'offerLetterTemplateId',
    component: <OfferLetterViewPage />,
    to: 'offerLetter/template/:id',
    title: 'Offer Letters > Offer letter',
    icon: undefined,
  },
  passwordReset: {
    id: 'passwordReset',
    component: <PasswordReset />,
    to: '/passwordReset/:type',
    title: undefined,
    icon: undefined,
  },
  payroll: {
    id: 'payroll',
    component: <Payroll />,
    to: 'payroll',
    title: 'Payroll',
    icon: RequestQuoteRoundedIcon,
  },
  payrollSchedule: {
    id: 'payrollSchedule',
    component: <PayrollSchedule />,
    to: 'payrollSchedule',
    title: 'Payroll > Schedule Payroll',
    icon: undefined,
  },
  payrollScheduleId: {
    id: 'payrollScheduleId',
    component: <PayrollSchedule />,
    to: 'payrollSchedule/:payrollId',
    title: 'Payroll > Schedule Payroll',
    icon: undefined,
  },
  payrollSetting: {
    id: 'payrollSetting',
    component: <PayrollSetting />,
    to: 'payrollSetting',
    title: 'Payroll > Payroll Settings',
    icon: undefined,
  },
  employeePaystub: {
    id: 'employeePaystub',
    component: <EmployeePaystub />,
    to: 'employeePaystubs/:id',
    // TODO: Make this customizable
    title: 'Employee Profile > ${employeeName} > Paystub',
    icon: undefined,
  },
  profile: {
    id: 'profile',
    component: <Profile />,
    to: 'profile',
    title: 'Profile',
    icon: PersonRoundedIcon,
  },
  projects: {
    id: 'projects',
    component: <ProjectsPage />,
    to: 'projects',
    title: 'Projects',
    icon: undefined,
  },
  projectReports: {
    id: 'projectReports',
    component: <ProjectReportPage />,
    to: 'projectReports',
    title: 'Project Report',
    icon: undefined,
  },
  monthlyProjectReport: {
    id: 'monthlyProjectReport',
    component: <MonthlyReportPage />,
    to: 'project-reports/monthly-report/:id',
    title: 'Project Report > Add summary',
    icon: undefined,
  },
  hybridSchedule: {
    id: 'hybridSchedule',
    component: <HybridSchedulePage />,
    to: 'hybridSchedule',
    title: 'Hybrid Schedule',
    icon: undefined,
  },
  attendanceSettings: {
    id: 'attendanceSettings',
    component: <AttendanceSettingPage />,
    to: 'attendanceSettings',
    title: 'Attendance Settings',
    icon: undefined,
  },
  attendance: {
    id: 'attendance',
    component: <AttendancePage />,
    to: 'attendance',
    title: 'Attendance',
    icon: undefined,
  },
};

const DEFAULT_PAGE = { ...PAGES.dashboard };

// return the path with '/' if it's missing in the path
const resolvePath = (path) => {
  return path.charAt(0) === '/' ? path : '/' + path;
};

export { PAGES, DEFAULT_PAGE, resolvePath };
