import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TopBar from '../components/top-bar/TopBar';
import { Box, Typography } from '@mui/material';
import HolidaySettingsPrimaryBtn from '../components/buttons/HolidaySettingsPrimaryBtn';
import HolidaySettingsPanel from '../components/attendance-table/HolidaySettingsPanel';
import { getCurrentAdmin } from '../services/AdminService';
import { getWeekDays } from '../utils/attendanceUtils';
import { statusLegend } from '../constants/attendanceEmployee';
import MonthYearSelector from '../components/attendance-table/MonthYearSelector';
import WeekNavigationButtons from '../components/attendance-table/WeekNavigationButtons';
import AttendanceTable from '../components/attendance-table/AttendanceTable';
import {
  addDays,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  isSameDay,
  isBefore,
  isAfter,
} from 'date-fns';

const AttendancePage = () => {
  const [user, setUser] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentWeekStart, setCurrentWeekStart] = useState(
    startOfWeek(new Date()),
  );
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  useEffect(() => {
    getCurrentAdmin().then((data) => {
      setUser(data);
    });
  }, []);

  const handleMonthChange = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + direction);

    const startMonth = startOfMonth(newDate);
    setCurrentDate(startMonth);
    setCurrentWeekStart(startOfWeek(startMonth));
  };

  const handleYearChange = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setFullYear(currentDate.getFullYear() + direction);

    const startMonth = startOfMonth(newDate);
    setCurrentDate(startMonth);
    setCurrentWeekStart(startOfWeek(startMonth));
  };

  const handleNextWeek = () => {
    const nextWeek = addDays(currentWeekStart, 7);
    setCurrentWeekStart(nextWeek);
  };

  const handlePreviousWeek = () => {
    const previousWeek = addDays(currentWeekStart, -7);
    setCurrentWeekStart(previousWeek);
  };

  const startOfTheMonth = startOfMonth(currentDate);
  const endOfTheMonth = endOfMonth(currentDate);

  // Determine if we're in the first or last week of the month
  const isFirstWeek =
    isBefore(currentWeekStart, startOfTheMonth) ||
    isSameDay(currentWeekStart, startOfTheMonth);
  const isLastWeek =
    isAfter(addDays(currentWeekStart, 6), endOfTheMonth) ||
    isSameDay(addDays(currentWeekStart, 6), endOfTheMonth);

  const weekDays = getWeekDays(currentDate, currentWeekStart);

  return (
    <>
      <TopBar title={'Attendance'} user={user} />

      {isPanelOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={() => setIsPanelOpen(false)}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '80vh',
          padding: '20px',
        }}
      >
        {/* Month-Year Selector Centered with Holiday Button to the Right */}
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ marginBottom: '10px' }}
        >
          <Box display='flex' justifyContent='center' flexGrow={1}>
            <MonthYearSelector
              currentDate={currentDate}
              handleMonthChange={handleMonthChange}
              handleYearChange={handleYearChange}
            />
          </Box>

          {/* Align Holiday Settings Button to the right */}
          <HolidaySettingsPrimaryBtn
            text='Holiday Settings'
            onClick={() => setIsPanelOpen(true)}
          />
        </Box>

        <WeekNavigationButtons
          handlePreviousWeek={handlePreviousWeek}
          handleNextWeek={handleNextWeek}
          isFirstWeek={isFirstWeek} // Disable when in the first week
          isLastWeek={isLastWeek} // Disable when in the last week
        />

        {/* Reusable AttendanceTable Component */}
        <AttendanceTable weekDays={weekDays} />

        <HolidaySettingsPanel
          isOpen={isPanelOpen}
          onClose={() => setIsPanelOpen(false)}
        />
      </Box>

      {/* Legend for Attendance Types */}
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '10px',
          display: 'flex',
          justifyContent: 'left',
          gap: 5,
        }}
      >
        {statusLegend.map((status) => (
          <Box
            key={status.abbreviation}
            display='flex'
            alignItems='center'
            gap={1}
          >
            <Box
              sx={{
                width: '4px',
                height: '40px',
                backgroundColor: status.color,
              }}
            />
            <Typography sx={{ fontWeight: 'bold' }}>
              {status.abbreviation} - {status.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

AttendancePage.propTypes = {
  user: PropTypes.string,
};

export default AttendancePage;
