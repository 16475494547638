import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';

import images from '../../../assets/imagesData/imagesData';
import {
  getFBOBalance,
  getTotalEmployee,
  getLastPayDateAndAmount,
} from '../../../services/DasboardSummaryService';
import { styleProps } from '../../../styles/styleProps';

const { fonts, colors } = styleProps;

const summaryData = [
  {
    icon: images.FBO,
    value: 'LKR 2,789.00',
    title: 'FBO Balance',
  },
  {
    icon: images.Emp,
    value: '120',
    title: 'Total Employee',
  },
  {
    icon: images.LASTPAY,
    value: '25 Aug 2023',
    title: 'Last Pay Date',
  },
  {
    icon: images.LATPAYROLL,
    value: 'LKR 2,789.00',
    title: 'Last Payroll',
  },
];

//Function to get the title value
const getTitleValue = async (title) => {
  if (title === 'FBO Balance') {
    try {
      const balance = await getFBOBalance();

      return `USD ${balance}`;
    } catch (error) {
      console.error('Error fetching FBO balance:', error);
    }
  } else if (title === 'Total Employee') {
    try {
      const totalEmp = await getTotalEmployee();

      return totalEmp;
    } catch (error) {
      console.error('Error fetching Total emloyees:', error);
    }
  } else if (title === 'Last Pay Date') {
    try {
      const { fullDate } = await getLastPayDateAndAmount();
      if (fullDate) {
        return fullDate;
      }
    } catch (error) {
      console.error('Error fetching last pay date of payroll:', error);
    }
  } else if (title === 'Last Payroll') {
    try {
      const { lastPayRollAmount } = await getLastPayDateAndAmount();
      if (lastPayRollAmount) {
        return `LKR ${lastPayRollAmount}`;
      }
    } catch (error) {
      console.error('Error fetching last pay date of payroll:', error);
    }
  } else {
    return title;
  }
};

const DashboardSummary = () => {
  const [data, setData] = useState([]);

  //fetch and set the Dashboard summary data
  useEffect(() => {
    const fetchData = async () => {
      const dashboardSummaryData = await Promise.all(
        summaryData.map(async (summary) => {
          const value = await getTitleValue(summary.title);

          return {
            ...summary,
            value,
          };
        }),
      );
      setData(dashboardSummaryData);
    };
    fetchData();
  }, []);

  return (
    <Box style={styles.container}>
      {summaryData.map((summary, index) => (
        <Box style={styles.block} key={index}>
          <img
            src={summary.icon}
            alt={`${summary.title}`}
            style={styles.icon}
          />
          <Typography style={styles.titleText}>
            {data[index]?.value || ''}
          </Typography>
          <Typography style={styles.valueText}>{summary.title}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    gap: '32px',
    flexWrap: 'wrap',
    width: '100%',
  },
  block: {
    flex: '1',
    padding: '20px',
    borderRadius: '8px',
    border: '2px solid #DEEBFC',
  },
  icon: {
    marginBottom: '20px',
    width: '40px',
    height: '40px',
  },
  titleText: {
    fontWeight: 500,
    fontSize: 28,
    fontFamily: fonts.primary,
    lineHeight: '32px',
    marginBottom: '8px',
  },
  valueText: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: fonts.primary,
    color: colors.textLightGrey,
  },
};

export default DashboardSummary;
