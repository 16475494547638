/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import DeafultAvatar from '../../assets/images/default-profile-photo.png';
import { styleProps } from '../../styles/styleProps';

const { colors } = styleProps;

function EmployeeHeader({ employee }) {
  const detailsList = [
    { title: 'Employee ID', attribute: 'employeeEmployeeId' },
    { title: 'Name', attribute: 'employeeFirstName employeeLastName' },
    { title: 'Designation', attribute: 'employeeDesignation' },
    { title: 'Email', attribute: 'employeeWorkEmail' },
    { title: 'Mobile', attribute: 'employeeMobile' },
  ];

  function LineRender({ title, attribute }) {
    let value;

    if (attribute.includes(' ')) {
      const attributes = attribute.split(' ');
      value = employee
        ? `${employee[attributes[0]] || ''} ${employee[attributes[1]] || ''}`
        : 'Loading...';
    } else {
      value = employee ? employee[attribute] || '' : 'Loading...';
    }

    return (
      <Stack direction='row' spacing={2}>
        <Typography variant='h7' sx={titleStyle}>
          {title}
        </Typography>
        <Typography variant='h7' sx={valueStyle}>
          {value}
        </Typography>
      </Stack>
    );
  }

  return (
    <Box sx={conatinerStyle}>
      <img
        src={DeafultAvatar}
        alt='Profile'
        style={{ width: '10rem', height: '10rem' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {detailsList.map((detail) => (
          <LineRender
            key={detail.title}
            title={detail.title}
            attribute={detail.attribute}
          />
        ))}
      </Box>
    </Box>
  );
}

export default EmployeeHeader;

const titleStyle = {
  color: colors.primary,
  width: '10rem',
};

const valueStyle = {
  color: colors.textLightGrey,
};

const conatinerStyle = {
  pb: 3,
  mb: 2,
  display: 'flex',
  gap: 5,
  justifyContent: 'center',
  alignItems: 'center',
};
