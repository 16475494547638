import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Readex Pro',
  },
  palette: {
    primary: {
      main: '#0666EB',
    },
    text: {
      accent: '#0666EB',
      lightGray: '#71717A',
      darkGray: '#2A2A2A',
    },
    error: { main: '#F2484B' },
    enabled: { main: '#00909E' },
    disabled: { main: '#EA5455' },
    white: '#ffff',
    black: '#000000',
    gray: {
      main: '#71717A',
    },
  },
});

export default theme;
