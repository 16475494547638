/* eslint-disable react/prop-types */
import { Box, Typography, outlinedInputClasses } from '@mui/material';
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState } from 'react';
import { styleProps } from '../../styles/styleProps';

const NotificationSchedule = ({
  title,
  description,
  label,
  value,
  onUpdate,
}) => {
  const [time, setTime] = useState(value);
  const onChange = (value) => {
    setTime(value);
    onUpdate(value);
  };
  return (
    <Box display={'flex'} justifyContent={'space-between'} sx={{ margin: 2 }}>
      <Box>
        <Typography sx={styles.title}>{title}</Typography>
        <Typography sx={styles.description}>{description}</Typography>
      </Box>

      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label={label}
            value={time}
            onChange={onChange}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            slotProps={styles.timePicker}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default NotificationSchedule;
const styles = {
  title: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: styleProps.fonts.primary,
    color: styleProps.colors.textDarkGrey,
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: styleProps.fonts.primary,
    color: styleProps.colors.textLightGrey,
    marginTop: '4px',
  },
  timePicker: {
    textField: {
      sx: {
        borderRadius: 8,
        [`.${outlinedInputClasses.root}`]: {
          height: 44,
          width: 192,
        },
        '& .MuiInputLabel-root': { lineHeight: 1, position: 'center' },
      },
    },
  },
};
