import React from 'react';
import PropTypes from 'prop-types';
import BaseButton from './BaseButton';

/**
 * JSX component that returns a PrimaryButton component
 *
 * @param {object} props
 * @returns  {JSX.Element} - PrimaryButton component
 */
const PrimaryButton = ({ children, ...props }) => {
  return (
    <BaseButton variant='contained' {...props}>
      {children}
    </BaseButton>
  );
};

export default PrimaryButton;

PrimaryButton.propTypes = {
  icon: PropTypes.node,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
};
