import CALENDAR from '../images/calendarIcon.png';

import INVOICE from '../images/Invoice.png';

const payRollImages = {
  CALENDAR,
  INVOICE,
};

export default payRollImages;
