import {
  addDays,
  endOfWeek,
  format,
  getDay,
  getWeek,
  lastDayOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';

//generate year array of the year drop down
const generateYearsBetween = () => {
  let today = new Date();
  let currentYear = today.getFullYear();
  let beginYear = currentYear - 10;
  let endYear = currentYear + 10;
  let years = [];

  for (beginYear; beginYear <= endYear; ) {
    years.push(beginYear);
    beginYear++;
  }

  return years;
};

function getMonthNumberFromName(monthName) {
  return new Date(`${monthName} 1, 2022`).getMonth() + 1;
}

function toMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'long',
  });
}

//get current
const getCurrentTimeStamp = () => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let monthName = toMonthName(month);
  let formattedDay;
  if (day < 10) {
    formattedDay = `0${day}`;
  } else {
    formattedDay = day;
  }

  let formattedMonth;
  if (month < 10) {
    formattedMonth = `0${month}`;
  } else {
    formattedMonth = month;
  }

  let previousMonth;
  if (month < 10) {
    previousMonth = `0${month - 1}`;
  } else {
    previousMonth = month - 1;
  }

  return {
    month: month,
    day: day,
    year: year,
    monthName: monthName,
    formattedDay: formattedDay,
    formattedMonth: formattedMonth,
    previousMonth: previousMonth,
  };
};

const formatPeriod = ({ week, year }) => {
  // Get Monday of the given week and year
  const monday = startOfWeek(new Date(year, 0, 1), { weekStartsOn: 1 }); // 1 corresponds to Monday
  // Add the number of weeks to get the desired week
  const desiredMonday = new Date(monday);

  if (desiredMonday.getFullYear() === year) {
    desiredMonday.setDate(desiredMonday.getDate() + (week - 1) * 7);
  } else {
    desiredMonday.setDate(desiredMonday.getDate() + week * 7);
  }

  // Get Friday of the given week and year
  const friday = endOfWeek(desiredMonday, { weekStartsOn: 6 });

  // Format the results
  const formattedMonday = format(desiredMonday, 'dd MMMM yyyy');
  const formattedFriday = format(friday, 'dd MMMM yyyy');

  return `${formattedMonday} - ${formattedFriday}`;
};

function formatDuration(durationInMillis) {
  // console.log(durationInMillis);
  const hours = Math.floor(durationInMillis / (1000 * 60 * 60));
  const remainingMins = Math.floor(
    (durationInMillis % (1000 * 60 * 60)) / (1000 * 60),
  );
  return {
    hrs: hours,
    mins: remainingMins < 10 ? '0' + remainingMins : remainingMins,
  };
}

function convertToMs({ hours, minutes }) {
  return hours * 1000 * 60 * 60 + minutes * 1000 * 60;
}

function getDateFromWeekAndYear(weekNumber, year) {
  const januaryFirst = new Date(year, 0, 1);

  // Calculate the Monday of the first week
  let firstMonday = startOfWeek(januaryFirst, { weekStartsOn: 1 }); // 1 corresponds to Monday

  // If January 1st is not Monday, adjust to the next Monday
  if (januaryFirst.getDay() !== 1) {
    firstMonday = addDays(firstMonday, 7);
  }

  // Calculate the Monday of the desired week
  const monday = addDays(firstMonday, (weekNumber - 1) * 7);
  return monday;
}

const getLastWeekNumberOfMonth = (date) => {
  const lastDayOfMonthDate = lastDayOfMonth(date);

  // Find the last Friday of the month
  let lastFriday = lastDayOfMonthDate;
  while (getDay(lastFriday) !== 5) {
    // 5 represents Friday
    lastFriday = subDays(lastFriday, 1);
  }

  const lastWeekNumber = getWeek(lastFriday);
  const year = lastFriday.getFullYear();
  return { week: lastWeekNumber, year: year };
};

// NOTE: Negative values need to be handled in BE
const makeTwoDigits = (number) => {
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export {
  generateYearsBetween,
  getMonthNumberFromName,
  getCurrentTimeStamp,
  toMonthName,
  formatDuration,
  convertToMs,
  formatPeriod,
  getDateFromWeekAndYear,
  getLastWeekNumberOfMonth,
  makeTwoDigits,
};
