import { Box, Typography } from '@mui/material';
import React from 'react';
import { styleProps } from '../../styles/styleProps';
import styled from '@emotion/styled';

const { fonts, fontSizes, colors } = styleProps;

// eslint-disable-next-line react/prop-types
function Header({ title, subtitle }) {
  return (
    <Box>
      <TitleText>{title}</TitleText>
      <UserNameText>{subtitle}</UserNameText>
    </Box>
  );
}

export default Header;

const TitleText = styled(Typography)({
  fontSize: fontSizes.header,
  fontWeight: 500,
  color: colors.textDarkGrey,
  fontFamily: fonts.primary,
});

const UserNameText = styled(Typography)({
  fontSize: fontSizes.normal,
  fontWeight: 500,
  color: colors.textLightGrey,
  fontFamily: fonts.primary,
});
