import React from 'react';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/authContext';
import { ThemeProvider } from '@mui/material';
import theme from './styles/theme';
import AppRouter from './routes/appRouter';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} autoHideDuration={1500}>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
