import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Avatar,
  Typography,
} from '@mui/material';
import ChevronDropdown from '../drop-down/ChevronDropdown';
import { EmployeeStatus } from '../../enums/Employee';
import { getTimeRecordsByDateRange } from '../../services/TimeRecordService';
import {
  getAbbreviation,
  getRectangleColor,
} from '../../utils/attendanceUtils';
import { styleProps } from '../../styles/styleProps';
import { recordTypeMap } from '../../utils/attendanceUtils';
import WeeklySummaryPanel from './WeeklySummaryPanel';

const { fonts } = styleProps;

const AttendanceTable = ({ weekDays }) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const dropdownOptions = [
    'P - Present',
    'M/C - Medical/Casual Leave',
    'A - Annual Leave',
    'N - No Pay',
  ];

  useEffect(() => {
    fetchAttendanceRecords();
  }, []);

  const fetchAttendanceRecords = async () => {
    const fromDate = new Date('2024-10-25');
    const toDate = new Date('2024-11-05');

    try {
      const attendanceRecords = await getTimeRecordsByDateRange(
        fromDate.toISOString().split('T')[0],
        toDate.toISOString().split('T')[0],
      );

      const enabledEmployees = attendanceRecords.filter(
        (employee) => employee.status === EmployeeStatus.ENABLED,
      );

      setEmployees(enabledEmployees);
    } catch (error) {
      console.error('Error fetching attendance records:', error.message);
    }
  };

  const handleStatusChange = (fullOptionText, employeeIndex, date) => {
    const newRecordType = recordTypeMap[fullOptionText];

    setEmployees((prevEmployees) => {
      const updatedEmployees = [...prevEmployees];
      const employee = updatedEmployees[employeeIndex];

      let attendanceRecord = employee.timeRecords.find(
        (record) => record.startTs.split('T')[0] === date,
      );

      if (attendanceRecord) {
        attendanceRecord.recordType = newRecordType;
      } else {
        attendanceRecord = {
          startTs: `${date}T00:00:00.000Z`,
          endTs: `${date}T00:00:00.000Z`,
          recordType: newRecordType,
        };
        employee.timeRecords.push(attendanceRecord);
      }

      return updatedEmployees;
    });
  };

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    setIsPanelOpen(true);
  };

  const handleClosePanel = () => {
    setSelectedEmployee(null);
    setIsPanelOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          maxHeight: employees.length > 6 ? '400px' : 'auto',
          overflowY: employees.length > 6 ? 'auto' : 'visible',
          marginBottom: '50px',
          borderRadius: '4px 0px 0px 0px',
          background: 'var(--background-paper-elevation-1, #FFFFFF)',
          boxShadow: `0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F`,
          position: 'relative',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
              <TableCell align='left' sx={tableHeaderStyles}>
                Employee
              </TableCell>
              {weekDays.map((weekDay, index) => (
                <TableCell
                  key={index}
                  align='center'
                  sx={{
                    ...tableHeaderStyles,
                    background: 'var(--background-paper-elevation-1, #FFFFFF)',
                    opacity: weekDay.isDisabled ? 0.5 : 1,
                    zIndex: 1,
                  }}
                >
                  <Typography>{weekDay.date}</Typography>
                  <Typography sx={{ color: '#71717A' }}>
                    {weekDay.day}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee, employeeIndex) => (
              <TableRow
                key={employeeIndex}
                sx={{
                  ...tableRowStyles,
                  backgroundColor: employeeIndex % 2 === 0 ? '#f9f9f9' : '#fff',
                }}
              >
                <TableCell
                  align='left'
                  sx={tableCellStyles}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => handleEmployeeClick(employee)}
                >
                  <Avatar
                    alt={employee.name}
                    src={employee.profileImage || ''}
                    sx={{ marginRight: '10px' }}
                  >
                    {employee.name.charAt(0)}
                  </Avatar>
                  {employee.name}
                </TableCell>
                {weekDays.map((weekDay) => {
                  const attendanceRecord = employee.timeRecords.find(
                    (rec) => rec.startTs.split('T')[0] === weekDay.date,
                  );

                  const abbreviation = getAbbreviation(
                    weekDay.day,
                    attendanceRecord,
                  );
                  const color = getRectangleColor(abbreviation);
                  const isDisabled = weekDay.isDisabled;

                  return (
                    <TableCell
                      key={weekDay.date}
                      align='center'
                      sx={{
                        ...tableCellStyles,
                        backgroundColor: 'inherit',
                        opacity: isDisabled ? 0.4 : 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {!isDisabled ? (
                          <ChevronDropdown
                            abbreviation={abbreviation || ''}
                            options={dropdownOptions}
                            onUpdateAbbreviation={(newAbbreviation) =>
                              handleStatusChange(
                                newAbbreviation,
                                employeeIndex,
                                weekDay.date,
                              )
                            }
                          />
                        ) : (
                          <Typography>{abbreviation || '-'}</Typography>
                        )}

                        <Box
                          sx={{
                            width: '70px',
                            height: '5px',
                            backgroundColor: color,
                            marginTop: '3px',
                            borderRadius: '2px',
                          }}
                        />
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {isPanelOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={handleClosePanel}
        />
      )}

      {selectedEmployee && isPanelOpen && (
        <WeeklySummaryPanel
          employee={selectedEmployee}
          onClose={handleClosePanel}
          weekDays={weekDays}
        />
      )}
    </>
  );
};

AttendanceTable.propTypes = {
  weekDays: PropTypes.array.isRequired,
};

export default AttendanceTable;

const tableHeaderStyles = {
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 500,
  textAlign: 'center',
  borderBottom: '1px solid var(--divider, #0000001F)',
  height: '40px',
};

const tableCellStyles = {
  borderBottom: '1px solid var(--divider, #0000001F)',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  textAlign: 'center',
  padding: '8px 12px',
  height: '48px',
};

const tableRowStyles = {
  borderBottom: '1px solid var(--divider, #0000001F)',
};
