import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { OFFER_LETTER_STATUS_SENT_FOR_APPROVAL } from '../../constants/OfferLetter';
import { ROLE_SUPER_ADMIN } from '../../enums/User';

const OfferLetterTable = ({ rows, toggleButtonValues, adminRole }) => {
  const Navigate = useNavigate();
  const [filter, setFilter] = useState('all');
  const [filteredRows, setFilteredRows] = useState(rows);

  const handleChange = (event, newValue) => {
    if (!newValue) return;
    setFilter(newValue);
  };

  useEffect(() => {
    // Filter rows based on selected status
    if (filter === 'all') {
      setFilteredRows(rows);
    } else {
      const filteredData = rows.filter(
        (row) => row.letterStatus.toLowerCase() === filter.toLowerCase(),
      );
      setFilteredRows(filteredData);
    }
  }, [filter, rows]);

  const handleViewClick = (row) => {
    Navigate(`/offerLetter/${row.displayId}`);
  };

  const handleReview = (row) => {
    Navigate(`/offerLetterReview/${row.displayId}`);
  };

  return (
    <Box sx={{ margin: 5, padding: '20px' }}>
      <Box>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleChange}
          aria-label='Platform'
          sx={{
            border: '1px solid #979797',
            borderRadius: '8px',
            height: '40px',
          }}
        >
          {Object.keys(toggleButtonValues).map((key, index) => (
            <ToggleButton
              key={index}
              sx={{
                ...toggleButtonStyles,
                borderRight:
                  index < Object.keys(toggleButtonValues).length - 1
                    ? '1px solid #979797'
                    : 'none',
              }}
              value={key}
            >
              {toggleButtonValues[key].label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ marginTop: '40px' }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadCellStyles} align='left'>
                  Name
                </TableCell>
                <TableCell sx={tableHeadCellStyles} align='left'>
                  Letter type
                </TableCell>
                <TableCell sx={tableHeadCellStyles} align='left'>
                  Date created
                </TableCell>
                <TableCell sx={tableHeadCellStyles} align='left'>
                  Status
                </TableCell>
                <TableCell sx={tableHeadCellStyles} align='left'>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row, index) => {
                const showReviewButton =
                  row.letterStatus === OFFER_LETTER_STATUS_SENT_FOR_APPROVAL &&
                  adminRole === ROLE_SUPER_ADMIN;
                return (
                  <TableRow key={index}>
                    <TableCell sx={tableBodyCellStyles} align='left'>
                      {row.candidateName}
                    </TableCell>
                    <TableCell sx={tableBodyCellStyles} align='left'>
                      {row.letterType}
                    </TableCell>
                    <TableCell sx={tableBodyCellStyles} align='left'>
                      {new Date(row.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell sx={tableBodyCellStyles} align='left'>
                      <Chip
                        // get label from toggleButtonValuesMap based on letterStatus
                        label={toggleButtonValues?.[row.letterStatus].label}
                        sx={chipStyle}
                      />
                    </TableCell>
                    <TableCell sx={tableBodyCellStyles} align='left'>
                      <Button
                        sx={buttonStyles}
                        onClick={() =>
                          showReviewButton
                            ? handleReview(row)
                            : handleViewClick(row)
                        }
                      >
                        {showReviewButton ? 'Review' : 'View'}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

OfferLetterTable.propTypes = {
  rows: PropTypes.array.isRequired,
  toggleButtonValues: PropTypes.array.isRequired,
  adminRole: PropTypes.string.isRequired,
};

export default OfferLetterTable;

const tableBodyCellStyles = {
  fontFamily: styleProps.fonts.primary,
  fontWeight: 400,
  fontSize: '14px',
  color: styleProps.colors.textLightGrey,
};

const tableHeadCellStyles = {
  fontFamily: styleProps.fonts.primary,
  fontWeight: 500,
  fontSize: '16px',
  color: styleProps.colors.textDarkGrey,
};

const toggleButtonStyles = {
  fontFamily: styleProps.fonts.primary,
  fontWeight: 500,
  fontSize: '14px',
  color: styleProps.colors.textDarkGrey,
  textTransform: 'none',
  padding: '10px 16px 10px 16px',
};

const chipStyle = {
  fontFamily: styleProps.fonts.primary,
  fontWeight: 400,
  fontSize: '14px',
  color: styleProps.colors.textDarkGrey,
  padding: '4px',
  height: '34px',
  borderRadius: '100px',
};

const buttonStyles = {
  fontWeight: 500,
  fontSize: '16px',
  padding: 0,
  minWidth: 0,
  color: styleProps.colors.textPrimary,
  fontFamily: styleProps.fonts.primary,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  textTransform: 'none',
};
