/* eslint-disable react/prop-types */
import { Grid, Typography, styled, IconButton } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

import { styleProps } from '../../styles/styleProps';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '../../enums/User';

const { fonts, colors } = styleProps;

const TitleText = styled(Typography)({
  fontFamily: fonts.primary,

  fontSize: '16px',
  paddingTop: 10,
  color: colors.textLightGrey,
});

const DetailText = styled(Typography)({
  fontFamily: fonts.primary,
  fontWeight: 400,
  fontSize: '16px',
  paddingTop: 10,
  color: colors.textDarkGrey,
});

const UserProfile = ({
  titleText,
  felid1,
  felid2,
  felid3,
  felid4,
  feild1Value,
  feild2Value,
  feild3Value,
  openModal,
  signatureUploadButton,
  role,
}) => {
  return (
    <Grid
      container
      sx={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '3%' }}
    >
      <Grid item xs={12} md={5}>
        <TitleText sx={{ fontWeight: 600 }}>{titleText}</TitleText>
        <TitleText sx={{ paddingTop: 5, fontWeight: 400 }}>{felid1}</TitleText>
        <TitleText sx={{ fontWeight: 400 }}>{felid2}</TitleText>
        <TitleText sx={{ fontWeight: 400 }}>{felid3}</TitleText>
        {role === ROLE_ADMIN || role === ROLE_SUPER_ADMIN ? (
          <TitleText sx={{ fontWeight: 400 }}>{felid4}</TitleText>
        ) : null}
      </Grid>
      <Grid item xs={12} md={7} sx={{ textAlign: 'right' }}>
        {titleText === 'Company Profile' && role === 'admin' ? (
          <>
            <br />
            <br />
          </>
        ) : (
          <IconButton onClick={() => openModal(titleText)}>
            <EditIcon sx={{ fontSize: 18, color: colors.primaryBlue }} />
          </IconButton>
        )}
        <DetailText sx={{ paddingTop: 5 }}>{feild1Value}</DetailText>
        <DetailText>{feild2Value}</DetailText>
        <DetailText>{feild3Value}</DetailText>
        {role === ROLE_ADMIN || role === ROLE_SUPER_ADMIN
          ? signatureUploadButton
          : null}
      </Grid>
    </Grid>
  );
};

export default UserProfile;
