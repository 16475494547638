import PropTypes from 'prop-types';
import React from 'react';
import {
  Drawer,
  IconButton,
  Typography,
  Divider,
  TextField,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styleProps } from '../../styles/styleProps';

const RejectReasonDrawer = ({ open, rejectionReason, setOpen }) => {
  return (
    <Drawer
      anchor='right'
      open={open}
      sx={{ width: '480px' }}
      onClose={() => setOpen(false)}
    >
      <Box
        sx={{
          width: '480px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Typography sx={headerTextStyle}>Reason for rejection</Typography>
        <Divider sx={{ marginY: 3 }} />

        <TextField
          fullWidth
          multiline
          rows={10}
          sx={{
            border: '1px solid #979797',
            borderRadius: '8px',
          }}
          value={rejectionReason}
          disabled
        />

        <IconButton
          edge='end'
          color='inherit'
          onClick={() => setOpen(false)}
          sx={{ position: 'absolute', top: '16px', right: '24px' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};

const headerTextStyle = {
  fontWeight: 500,
  fontSize: '20px',
  color: styleProps.colors.textDarkGrey,
};

RejectReasonDrawer.propTypes = {
  open: PropTypes.bool,
  rejectionReason: PropTypes.string,
  setOpen: PropTypes.func,
};

export default RejectReasonDrawer;
