/* eslint-disable react/prop-types */
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert,
  Snackbar,
} from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { styleProps } from '../../../styles/styleProps';
import SecondaryButton from '../../buttons/SecondaryButton';
import { statusTextColor } from '../../../utils/adminStatusHandler';
import StatusChangeConfirmationModal from '../../alerts/StatusChangeConfirmationModal';

const { colors } = styleProps;

//Styles and styled components
const ListContainer = styled(Box)({
  padding: '15px 15px 15px 35px',
});

const TitleText = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  letterSpacing: '1px',
  color: colors.textLightGrey,
  margin: '17px 0px 17px 0px',
});

const TableContainer = styled(Box)({
  marginTop: '10px',
  marginLeft: -15,
});

const CustomTableRow = styled(TableRow)({
  '& th': {
    backgroundColor: '#F5F7F8',
    color: colors.textLightGrey,
    fontSize: 16,
    fontWeight: 500,
  },
  '& td': { fontWeight: 400, fontSize: 16 },
});

const CustomTableCell = styled(TableCell)({
  borderBottom: 'none',
});

// eslint-disable-next-line react/prop-types
const AdminsList = ({ data, refreshData }) => {
  const [enableModalType, setEnableModalType] = useState('');
  const [enableModalTitle, setEnableModalTitle] = useState('');
  const [enableModalBody, setEnableModalBody] = useState('');
  const [enableModalStatus, setEnableModalStatus] = useState(false);
  const [isReinviteSuccessful, setIsReinviteSuccessful] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const changeStatus = () => {
    try {
      const email = userEmail;

      axios
        .put(`/api/admin/update/status/${email}`, null, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              refreshData();
              setEnableModalStatus(false);
            }
          },
          (error) => {
            if (error.response.status === 401) {
              localStorage.setItem('sessionValid', 'false');
              window.location = '/login';
            } else {
              window.location = '/error';
            }
          },
        );
    } catch (err) {
      window.location = '/error';
    }
  };

  const reinviteAdmin = (email) => {
    try {
      const type = 0;

      const body = {
        email,
        type,
      };
      axios
        .post('/api/user/invite', body, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              setIsReinviteSuccessful(true);
            }
          },
          (error) => {
            if (error.response.status === 401) {
              localStorage.setItem('sessionValid', 'false');
              window.location = '/login';
            } else {
              window.location = '/error';
            }
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  const handleRequest = (type, email) => {
    setUserEmail(email);
    if (type === 'Disabled') {
      setEnableModalStatus(true);
      setEnableModalBody('Are you sure you want to enable this admin ?');
      setEnableModalTitle('Enable Admin');
      setEnableModalType('Enable');
    } else if (type === 'Enabled') {
      setEnableModalStatus(true);
      setEnableModalBody('Are you sure you want to disable this admin ?');
      setEnableModalTitle('Disable Admin');
      setEnableModalType('Disable');
    } else {
      reinviteAdmin(email);
    }
  };

  const closeEnableModal = () => {
    setEnableModalStatus(false);
  };

  const closeSuccessModal = () => {
    setIsReinviteSuccessful(false);
    refreshData();
  };

  return (
    <>
      {data ? (
        <>
          <StatusChangeConfirmationModal
            modalStatus={enableModalStatus}
            title={enableModalTitle}
            type={enableModalType}
            text={enableModalBody}
            reject={closeEnableModal}
            confirm={changeStatus}
          />

          <Snackbar
            open={isReinviteSuccessful}
            autoHideDuration={6000}
            onClose={closeSuccessModal}
          >
            <Alert severity='success'>Invitation email sent successfully</Alert>
          </Snackbar>
          <ListContainer>
            <TitleText>Admin management</TitleText>
            <TableContainer sx={{ maxHeight: 'calc(100vh-250px)' }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <CustomTableRow>
                    <CustomTableCell align='left' width='200px'>
                      Name
                    </CustomTableCell>
                    <CustomTableCell align='left'>Email</CustomTableCell>
                    <CustomTableCell align='left'>Status</CustomTableCell>
                    <CustomTableCell align='left'>Actions</CustomTableCell>
                  </CustomTableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) =>
                    localStorage.getItem('adminId') != item.adminId ? (
                      <CustomTableRow key={index}>
                        <CustomTableCell align='left'>
                          {item.adminName}
                        </CustomTableCell>
                        <CustomTableCell align='left'>
                          {item.adminEmail}
                        </CustomTableCell>
                        <CustomTableCell
                          align='left'
                          sx={{ color: statusTextColor(item.status).color }}
                        >
                          {item.status}
                        </CustomTableCell>
                        <CustomTableCell align='left'>
                          <SecondaryButton
                            styles={styles.secondaryButton}
                            onClick={() =>
                              handleRequest(item.status, item.adminEmail)
                            }
                          >
                            {statusTextColor(item.status).btnText}
                          </SecondaryButton>
                        </CustomTableCell>
                      </CustomTableRow>
                    ) : (
                      <></>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </ListContainer>
        </>
      ) : (
        <div style={{ padding: '50px 20px 10px 20px' }}>
          <SkeletonTheme baseColor='#ffff' highlightColor='#CDE0FB'>
            <p>
              <Skeleton count={1} height={30} />
              <br />
              <Skeleton count={8} height={30} />
            </p>
          </SkeletonTheme>
        </div>
      )}
    </>
  );
};

export default AdminsList;

const styles = {
  secondaryButton: {
    padding: '0px',
    width: '100px',
    height: '40px',
    fontSize: '16px',
    fontWeight: '400',
  },
};
