import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Chip, LinearProgress } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const FileUpload = ({ filesAccepted, onError, files, setFiles }) => {
  const inputRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [progress, setProgress] = useState(null); // TODO: Remove this mock data and get the data uploaded progress from the API

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  //handle file drop event
  const handleDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (!filesAccepted.includes(files[0].type)) {
      onError('File type not supported');
      return;
    }
    setFiles(files);
  };

  //handle file browse event
  const uploadFiles = (event) => {
    const files = event.target.files;
    if (!filesAccepted.includes(files[0].type)) {
      onError('File type not supported');
      return;
    }
    setFiles(files);
  };

  return (
    <Box sx={styles.mainContainer}>
      <div
        onDragOver={(event) => handleDragOver(event)}
        onDrop={(event) => handleDrop(event)}
        style={styles.subContainer}
      >
        <UploadIcon
          sx={{ fontSize: 50, color: '#979797' }}
          fontSize='inherit'
        />
        <input
          onChange={(event) => uploadFiles(event)}
          hidden
          ref={inputRef}
          type='file'
          accept={filesAccepted.join(',')}
        />
        <Typography>
          Drag and drop your file here or{' '}
          <span
            role='button'
            tabIndex={0}
            style={{ color: '#0666EB', cursor: 'pointer' }}
            onClick={() => inputRef.current.click()}
            onKeyDown={(event) =>
              event.key === 'Enter' && inputRef.current.click()
            }
          >
            Browse
          </span>
        </Typography>
        <div style={styles.progressContainer(progress)}>
          <Typography fontSize={'12px'} marginBottom={'8px'}>
            Uploading
          </Typography>
          <LinearProgress
            variant='determinate'
            value={progress}
            sx={{ height: '12px', borderRadius: '30px' }}
          />
        </div>
        <div style={styles.chipContainer(files)}>
          <Chip
            color='primary'
            variant='outlined'
            label={files?.[0]?.name}
            onDelete={() => setFiles(null)}
          />
        </div>
      </div>
    </Box>
  );
};

FileUpload.propTypes = {
  filesAccepted: PropTypes.array,
  onError: PropTypes.func,
  files: PropTypes.object,
  setFiles: PropTypes.func,
};

const styles = {
  mainContainer: {
    border: '2px dashed',
    borderColor: 'text.accent',
    borderRadius: '5px',
    width: '500px',
    height: '250px',
  },
  subContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  progressContainer: (progress) => ({
    position: 'absolute',
    bottom: '15px',
    width: '97%',
    display: progress ? 'block' : 'none',
  }),
  chipContainer: (files) => ({
    position: 'absolute',
    display: files ? 'block' : 'none',
    left: '15px',
    bottom: '15px',
  }),
};

export default FileUpload;
