/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import Header from '../StepHeader';
import Table from '../tables/Step3-Table';
import SearchIcon from '@mui/icons-material/Search';
import { styleProps } from '../../../styles/styleProps';

function Step3({ setBulkData, bulkData }) {
  const { colors } = styleProps;
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  // search by name or id
  useEffect(() => {
    const employees = Object.values(bulkData.employees ?? {});
    let filteredData = [];

    // convert employeees in bulkData to array in order to pass into table
    Object.values(employees).forEach((employee) => {
      filteredData.push(employee);
    });

    if (!searchValue || searchValue === '') {
      // if search value is empty, set filtered data to all employees
      setFilteredData(filteredData);
      return;
    }

    // filter employees by search value
    filteredData = filteredData.filter((row) => {
      return (
        row.employeeEmployeeId.toLowerCase().includes(searchValue) ||
        row.employeeFirstName.toLowerCase().includes(searchValue) ||
        row.employeeLastName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredData(filteredData);
  }, [searchValue, bulkData]);

  const columns = [
    'Name',
    'EPF',
    'Advance',
    'Payee Tax',
    'Loan',
    'No Pay',
    'Total Pay',
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header
          title='Deductions'
          subtitle={
            "Check the employee's deductions to include in this payroll"
          }
        />
        <TextField
          size='small'
          label='Search by Id or Name'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
          InputProps={{
            endAdornment: <SearchIcon sx={{ color: colors.textLightGrey }} />,
          }}
        />
      </Box>
      <Table
        data={filteredData}
        columns={columns}
        bulkData={bulkData}
        setBulkData={setBulkData}
      />
    </>
  );
}

export default Step3;
