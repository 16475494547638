import React from 'react';
import PropTypes from 'prop-types';
import ReportCardLayout from './ReportCardLayout';
import { Box, Stack, Typography } from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format, parseISO } from 'date-fns';
import { CustomDivider } from '.';

const ApprovedCard = ({ report }) => {
  return (
    <ReportCardLayout report={report} showEditTime={false}>
      <Stack sx={{ padding: '2%' }} justifyContent={'space-between'}>
        <Typography color={styleProps.colors.textLightGrey} variant='h7'>
          {report.summary}
        </Typography>
      </Stack>
      <CustomDivider />
      <>
        <Stack sx={{ padding: '8px' }} flexDirection={'row'}>
          <CheckCircleIcon sx={{ fontSize: '20px' }} htmlColor='#000000' />
          <Box width={'2px'} />
          <Typography color={styleProps.colors.black} variant='h7'>
            {report.approvedBy} approved on{' '}
            {format(parseISO(report.approvedAt), 'MMMM dd, yyyy')}.
          </Typography>
        </Stack>
      </>
    </ReportCardLayout>
  );
};

ApprovedCard.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    approvedBy: PropTypes.string.isRequired,
    approvedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default ApprovedCard;
