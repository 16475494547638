const SELECTIVE_TABS = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

const DM_STATUS = {
  ALL: 'ALL',
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
};

const CBC_STATUS = {
  ALL: 'ALL',
  GENERATED: 'GENERATED',
  NOT_GENERATED: 'NOT_GENERATED',
};

const CLOSING_DRAWER_TIMEOUT = 500;

export { SELECTIVE_TABS, DM_STATUS, CBC_STATUS, CLOSING_DRAWER_TIMEOUT };
