import React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
//import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import {
  Grid,
  Button,
  Stack,
  Typography,
  // SwipeableDrawer,
  useMediaQuery,
  useTheme,
  Popper,
} from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
//import Filters from './drawers/Filters';
import { SELECTIVE_TABS } from '../../data/projectReportConstants';
import { useProjectReport } from '../../contexts/project-reports-context/ProjectReportsContext';
import { WeekPicker } from '../date-time-selectors/week-selector/WeekPicker';
import MonthPicker from '../date-time-selectors/month-selector/MonthPicker';
import {
  getDateFromWeekAndYear,
  getLastWeekNumberOfMonth,
} from '../../utils/TemporalService';
import { getISOWeek } from 'date-fns';

// Note : -----------------------
// Some code sections are currently commented out as they are not in use at the moment.
// They are retained for potential future functionality implementation.
// Please refer to the documentation or discuss with the team before making changes or removing this code.

const LeftButton = styled(Button)(({ tabOption }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return {
    height: 'fit-content',
    borderColor: '#979797',
    borderWidth: '1px',
    borderBottomLeftRadius: '8px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: tabOption === SELECTIVE_TABS.WEEKLY && '#9797974f',
    '&:hover': {
      backgroundColor: tabOption === SELECTIVE_TABS.WEEKLY && '#9797974f',
    },
    padding: isSmallScreen ? '8px' : '12px',
  };
});

const RightButton = styled(Button)(({ tabOption }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return {
    height: 'fit-content',
    borderColor: '#979797',
    borderWidth: '1px',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: tabOption === SELECTIVE_TABS.MONTHLY && '#9797974f',
    '&:hover': {
      backgroundColor: tabOption === SELECTIVE_TABS.MONTHLY && '#9797974f',
    },
    padding: isSmallScreen ? '8px' : '12px',
  };
});

const HeaderButton = styled(Button)(() => ({
  // width: width,
  // justifyContent: 'space-between',
  borderColor: '#979797',
}));

const ResponsiveStack = styled(Stack)(() => {
  // const isSmallScreen = useMediaQuery('(max-width:768px)');
  // const isMediumScreen = useMediaQuery('(max-width:1024px)');
  // return {
  //   flexDirection: 'row',
  //   width: isSmallScreen ? '12vw' : isMediumScreen ? '28vw' : '340px',
  //   justifyContent: 'space-between',
  // };
});

const Header = () => {
  const theme = useTheme();
  const { projectReports, actions } = useProjectReport();
  const { selectedTab, weekYear } = projectReports;
  // const { dmStatus } = projectReports;
  const { setTab, setWeekYear } = actions;
  // const {  setDmStatus } = actions;

  //const [isFilterDrawerOpened, setIsFilterDrawerOpened] = useState(false);
  const [isDatesPopperOpened, setIsDatesPopperOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const date = getDateFromWeekAndYear(weekYear.week, weekYear.year);

  const tabHandler = (option) => {
    setTab(option);
  };

  // const filterHandler = ({ _dmStatus }) => {
  //   dmStatus !== _dmStatus && setDmStatus(_dmStatus);
  //   {
  //     /* TODO: Will be used later */
  //   }
  //   // cbcStatus !== _cbcStatus && setCbcStatus(_cbcStatus);
  // };

  const weekYearHandler = (value) => {
    const weekYear =
      selectedTab === SELECTIVE_TABS.MONTHLY
        ? getLastWeekNumberOfMonth(value)
        : {
            week: getISOWeek(value.firstDay),
            year: value.firstDay.getFullYear(),
          };
    setWeekYear(weekYear);
  };

  const selectDatesHandler = (event) => {
    setAnchorEl(event.currentTarget);
    setIsDatesPopperOpened(!isDatesPopperOpened);
  };

  return (
    <Grid>
      <Stack
        sx={{ padding: '1.5%' }}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack flexDirection={'row'}>
          <LeftButton
            theme={theme}
            variant='outlined'
            onClick={() => tabHandler(SELECTIVE_TABS.WEEKLY)}
            tabOption={selectedTab}
          >
            <Typography
              textTransform={'none'}
              color={'black'}
              variant='h7'
              fontSize={18}
              fontWeight={500}
            >
              Weekly Project Report
            </Typography>
          </LeftButton>
          <RightButton
            theme={theme}
            variant='outlined'
            onClick={() => tabHandler(SELECTIVE_TABS.MONTHLY)}
            tabOption={selectedTab}
          >
            <Typography
              textTransform={'none'}
              color={'black'}
              variant='h7'
              fontSize={18}
              fontWeight={500}
            >
              Monthly Project Report
            </Typography>
          </RightButton>
        </Stack>

        <ResponsiveStack>
          {/* <HeaderButton
            variant='outlined'
            onClick={() => setIsFilterDrawerOpened(true)}
          >
            <Typography
              textTransform={'none'}
              color={'#71717A'}
              variant='h7'
              fontSize={18}
              fontWeight={400}
            >
              Filters
            </Typography>
            <FilterListOutlinedIcon
              sx={{ width: '28px', height: '28px', marginLeft: '8px' }}
              htmlColor={'#71717A'}
            />
          </HeaderButton>
          <SwipeableDrawer
            anchor={'right'}
            open={isFilterDrawerOpened}
            onClose={() => setIsFilterDrawerOpened(false)}
            onOpen={() => setIsFilterDrawerOpened(true)}
          >
            <div style={{ width: 480 }}>
              <Filters
                onClose={() => setIsFilterDrawerOpened(false)}
                filterHandler={filterHandler}
              />
            </div>
          </SwipeableDrawer> */}
          <HeaderButton
            variant='outlined'
            onClick={selectDatesHandler}
            open={isDatesPopperOpened}
          >
            <Typography
              textTransform={'none'}
              color={'#71717A'}
              variant='h7'
              fontSize={18}
              fontWeight={400}
            >
              Select dates
            </Typography>
            <EventNoteIcon
              sx={{ width: '28px', height: '45px', marginLeft: '8px' }}
              htmlColor={'#71717A'}
            />
          </HeaderButton>
        </ResponsiveStack>
      </Stack>
      <Popper
        open={isDatesPopperOpened}
        anchorEl={anchorEl}
        placement={'bottom-end'}
      >
        {selectedTab === SELECTIVE_TABS.WEEKLY ? (
          <WeekPicker onChange={weekYearHandler} date={date} />
        ) : (
          <MonthPicker onChange={weekYearHandler} date={date} />
        )}
      </Popper>
    </Grid>
  );
};

export default Header;
