import { Box, Typography, List, ListItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getNotes } from '../../../services/NoteService';
import { format } from 'date-fns';
import { NoteEntityType } from '../../../enums/Notes';

import { styleProps } from '../../../styles/styleProps';
const { fonts } = styleProps;

const PayrollRunHistory = () => {
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    getPayrollNotes();
  }, []);
  async function getPayrollNotes() {
    const notes = await getNotes();
    const payRollNotes = notes.message.filter((note) =>
      note.entityName.includes(NoteEntityType.PAYROLE),
    );

    setNotes(payRollNotes);
  }
  const formatCreatedAt = (createdAt) => {
    return format(new Date(createdAt), 'dd MMM yyyy');
  };
  return (
    <Box style={styles.container}>
      <Typography style={styles.title}>Payroll Run History</Typography>
      <List style={styles.noteList}>
        {notes.map((note, index) => (
          <ListItem key={index}>
            <Box style={styles.noteContainer}>
              <span style={styles.note}>{note.description}</span>
              <Box style={styles.noteDate}>
                {formatCreatedAt(note.createdAt)}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: fonts.primary,
    lineHeight: '32px',
  },
  container: {
    flex: '1',
    padding: '20px',
    borderRadius: '8px',
    border: '2px solid #DEEBFC',
    height: '345px',
  },
  noteContainer: {
    padding: '7px',
  },
  noteList: {
    height: '90%',
    overflowY: 'auto',
  },
  note: {
    fontWeight: 400,
    fontSize: '16px',
    FontFamily: fonts.primary,
    LineHeight: '20px',
  },
  noteDate: {
    fontWeight: 400,
    fontSize: '14px',
    FontFamily: fonts.primary,
    color: '#71717A',
  },
};

export default PayrollRunHistory;
