import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import {
  changeSalaryAdvanceStatus,
  getAdvancePaymentRequests,
} from '../../../services/PaymentService';
import { SALARY_ADVANCE_STATUS } from '../../../enums/SalaryAdvance';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { styleProps } from '../../../styles/styleProps';
import ShortPrimaryBtn from '../../buttons/ShortPrimaryBtn';
import ShortSecondaryBtn from '../../buttons/ShortSecondaryBtn';

const { fonts } = styleProps;

const EmployeeAdvancePaymentRequests = () => {
  const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
  const [advancePaymentRequests, setAdvancePaymentRequests] = useState([]);
  const [expanded, setExpanded] = useState('');

  const fetchAdvancePaymentRequests = async () => {
    getAdvancePaymentRequests(SALARY_ADVANCE_STATUS.NEW).then((data) => {
      setAdvancePaymentRequests(data);
    });
  };

  const handleChangeStatus = ({ status, id }) => {
    changeSalaryAdvanceStatus({
      status,
      id,
    }).then(() => {
      fetchAdvancePaymentRequests();
    });
  };

  useEffect(() => {
    fetchAdvancePaymentRequests();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box style={styles.container}>
      <Typography variant='h6' style={styles.title}>
        Critical Payroll Action Required
      </Typography>

      <Stack spacing={2} style={styles.accordionList}>
        {advancePaymentRequests.map((el, index) => (
          <Accordion
            key={index}
            elevation={0}
            square={false}
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={styles.accordionStyle}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <HeaderText>
                {el.employeeFirstName} has requested advance payment{' '}
              </HeaderText>
            </AccordionSummary>
            <AccordionDetails sx={styles.insideAccordination}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <AccordionText
                  title={'Employee Name'}
                  text={el.employeeFirstName + ' ' + el.employeeLastName}
                />
                <AccordionText
                  title={'Advance Amount'}
                  text={'LKR ' + el.amount ? nf.format(el.amount) : 0}
                />
              </Stack>
              <AccordionText
                title={'Reason for advance'}
                text={el.notes || ''}
              />
              <Stack marginLeft={'auto'} direction={'row'} gap={2}>
                <ShortPrimaryBtn
                  text='Approve'
                  onClick={() =>
                    handleChangeStatus({
                      id: el.id,
                      status: SALARY_ADVANCE_STATUS.ACCEPTED,
                    })
                  }
                />
                <ShortSecondaryBtn
                  text='Decline'
                  onClick={() =>
                    handleChangeStatus({
                      id: el.id,
                      status: SALARY_ADVANCE_STATUS.REJECTED,
                    })
                  }
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Box>
  );
};

export default EmployeeAdvancePaymentRequests;

// eslint-disable-next-line react/prop-types
const AccordionText = ({ title, text }) => (
  <span style={styles.subText}>
    <span style={styles.semiBold}>{title}:</span> {text}
  </span>
);

const styles = {
  container: {
    flex: '1',
    padding: '20px',
    borderRadius: '8px',
    border: '2px solid #DEEBFC',
    height: '345px',
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: fonts.primary,
    lineHeight: '32px',
    marginBottom: '33px',
  },
  accordionList: {
    height: '85%',
    overflowY: 'auto',
  },
  accordionStyle: {
    '& .MuiAccordionSummary-root': {
      border: 'none',
      backgroundColor: '#F5F9FE',
      borderRadius: '8px',
    },
    '& .MuiAccordionDetails-root': {
      border: 'none',
      backgroundColor: '#F5F9FE',
      borderRadius: '0px 0px 8px 8px',
    },
  },
  insideAccordination: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingX: '20px',
  },
  subText: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: fonts.primary,
    color: '#71717A',
  },
  semiBold: {
    fontWeight: '500',
  },
};

const HeaderText = styled(Typography)({
  fontFamily: styleProps.fonts.primary,
  fontSize: '16px',
  fontWeight: '400',
});
