import { Box, Stack, Typography, styled } from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import { format } from 'date-fns';
import React from 'react';

const { colors, fonts } = styleProps;

function paystubData({ paystub }) {
  const payDate =
    format(new Date(paystub?.year, paystub?.month, 25), 'dd/MM/yyyy') || '';
  const monthName = format(new Date(2021, paystub?.month, 1), 'MMMM');
  const currency = paystub.employeeCurrencyCode || '';
  const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  return (
    <Box sx={conatiner}>
      <StyledStack>
        <Typography variant='h7' fontSize={22}>
          Salary Details
        </Typography>

        <Stack sx={{ alignItems: 'flex-end' }}>
          <Typography variant='h7' color={colors.textLightGrey}>
            {payDate}
          </Typography>
          <Typography variant='h7' fontSize={22}>
            {monthName}
          </Typography>
        </Stack>
      </StyledStack>

      <TitleStack>
        <MainTypography>Earnings</MainTypography>
      </TitleStack>

      <StyledStack>
        <MainTypography>Basic Salary - A</MainTypography>
        <MainTypography>
          {currency} {nf.format(paystub.employeeSalary || 0)}
        </MainTypography>
      </StyledStack>

      <StyledStack>
        <MainTypography sx={smPadding}>Allowances - B</MainTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>Fuel & Maintenance</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeeAllowanceFuel || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>Travelling</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeeAllowanceTravel || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>Daily Paid</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeeAllowanceDailyPay || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>OT</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeeAllowanceOt || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>Bonus</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeeAllowanceBonus || 0)}
        </SubTypography>
      </StyledStack>
      <StyledStack>
        <MainTypography>Reimbursement Expenses - C </MainTypography>
        <MainTypography>
          {currency} {nf.format(paystub.employeeReimbursements || 0)}
        </MainTypography>
      </StyledStack>

      <TitleStack>
        <MainTypography>Total Earnings</MainTypography>
        <MainTypography>
          {currency} {nf.format(paystub.employeeTotalEarnings || 0)}
        </MainTypography>
      </TitleStack>
      <StyledStack>
        <MainTypography>No Pay -D </MainTypography>
        <MainTypography>
          {currency} {nf.format(paystub.employeeNoPay || 0)}
        </MainTypography>
      </StyledStack>

      <TitleStack>
        <MainTypography>Gross Salary (A-D)</MainTypography>
        <MainTypography>
          {currency} {nf.format(paystub.employeeGrossSalary || 0)}
        </MainTypography>
      </TitleStack>

      <TitleStack>
        <MainTypography>Deductions</MainTypography>
      </TitleStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>EPF 8% </SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeeDeductionEpf || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>Advance</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeeDeductionAdvance || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>Payee Tax</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeePayeeTax || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography sx={mdPadding}>Loan</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employeeDeductionLoan || 0)}
        </SubTypography>
      </StyledStack>

      <TitleStack>
        <MainTypography>Net Salary</MainTypography>
        <MainTypography>
          {currency} {nf.format(paystub.employeeNetSalary || 0)}
        </MainTypography>
      </TitleStack>

      <StyledStack>
        <SubTypography>EPF 12%</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employerEpf || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography>ETF 3%</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.employerEtf || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography>Total for EPf contribution</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.totalEpf || 0)}
        </SubTypography>
      </StyledStack>

      <StyledStack>
        <SubTypography>Total ETF contribution</SubTypography>
        <SubTypography>
          {currency} {nf.format(paystub.totalEtf || 0)}
        </SubTypography>
      </StyledStack>
    </Box>
  );
}

export default paystubData;

const conatiner = {
  paddingX: 2,
  marginTop: 5,
  marginBottom: 3,
  display: 'flex',
  flexDirection: 'column',
  gap: 1.5,
};

const TitleStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F5F7F8',
  borderRadius: 2,
  paddingTop: 5,
  paddingBottom: 5,
});

const MainTypography = styled(Typography)({
  fontFamily: fonts.primary,
  fontWeight: 400,
  fontSize: '16px',
  paddingLeft: '10px',
  paddingRight: '10px',
});

const SubTypography = styled(Typography)({
  fontFamily: fonts.primary,
  fontWeight: 400,
  fontSize: '16px',
  paddingLeft: '10px',
  paddingRight: '10px',
  color: colors.textLightGrey,
});

const StyledStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const smPadding = {
  paddingLeft: 4,
};

const mdPadding = {
  paddingLeft: 8,
};
