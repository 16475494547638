import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import BackgroundImage from '../assets/images/img_nimikash_background.svg';
import { styleProps } from '../../src/styles/styleProps';

const { colors } = styleProps;

//Styles
const backgroundStyles = {
  minHeight: '100vh',
  background: `linear-gradient(0deg, rgba(6, 102, 235, 0.3), rgba(6, 102, 235, 0.3)), url(${BackgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};

const ConstructionPage = () => {
  return (
    <div style={backgroundStyles}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '28vh',
            }}
          >
            <Typography
              align='center'
              sx={{
                fontSize: '60px',
                color: '#ffff',
                paddingTop: 3,
                fontWeight: 600,
              }}
            >
              Coming Soon !
            </Typography>
            <Typography
              align='center'
              sx={{ fontSize: '20px', color: '#ffff', paddingTop: 3 }}
            >
              NimiKash is under construction.
            </Typography>
            <Typography
              align='center'
              sx={{ fontSize: '20px', color: '#ffff' }}
            >
              Follow Nimi Tech Limited socials to get hands on earliest updates.
            </Typography>
            <Typography
              align='center'
              sx={{ fontSize: '20px', color: '#ffff', paddingTop: 3 }}
            >
              <a
                href='https://www.linkedin.com/company/nimidev/'
                style={{ textDecoration: 'none' }}
              >
                <LinkedInIcon
                  sx={{ fontSize: 40, color: colors.white, lineHeight: '50px' }}
                />
              </a>
              <a
                href='https://www.instagram.com/nimigrowtoday/'
                style={{ textDecoration: 'none' }}
              >
                <InstagramIcon
                  sx={{
                    fontSize: 40,
                    color: colors.white,
                    lineHeight: '50px',
                    marginLeft: '15px',
                  }}
                />
              </a>
              <a
                href='https://twitter.com/GrowNimi'
                style={{ textDecoration: 'none' }}
              >
                <TwitterIcon
                  sx={{
                    fontSize: 40,
                    color: colors.white,
                    lineHeight: '50px',
                    marginLeft: '15px',
                  }}
                />
              </a>
              <a
                href='https://www.facebook.com/people/Grow-with-Nimi/100075882952070/'
                style={{ textDecoration: 'none' }}
              >
                <FacebookRoundedIcon
                  sx={{
                    fontSize: 40,
                    color: colors.white,
                    lineHeight: '50px',
                    marginLeft: '15px',
                  }}
                />
              </a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConstructionPage;
