import * as React from 'react';
import { Box, Modal, Stack, Typography, styled } from '@mui/material';

import { styleProps } from '../../styles/styleProps';
import ShortPrimaryBtn from '../buttons/ShortPrimaryBtn';
import ShortSecondaryBtn from '../buttons/ShortSecondaryBtn';

const { fonts, colors } = styleProps;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '200px',
  background: '#FFFFFF',
  borderRadius: '8px',
  width: '400px',
};

const ModalDescription = styled(Typography)({
  color: colors.textDarkGrey,
  fontFamily: fonts.primary,
  fontWeight: 'bold',
  fontSize: '16px',
  letterSpacing: '-0.408px',
});

const stackStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '30px',
};

// eslint-disable-next-line react/prop-types
const SecondaryEmailConfirmationModal = ({ modalStatus, confirm, reject }) => {
  return (
    <div>
      <Modal open={modalStatus}>
        <Box sx={style}>
          <Stack spacing={1} direction='row' sx={stackStyle}>
            <ModalDescription>
              You have linked to your old NimiKash account. <br />
              You will be able to access your old paystubs. <br />
              Do you wish to proceed ?
            </ModalDescription>
          </Stack>

          <Stack spacing={1} direction='row' sx={stackStyle}>
            <ShortSecondaryBtn
              variant='contained'
              text='Cancel'
              onClick={reject}
            ></ShortSecondaryBtn>
            <ShortPrimaryBtn
              variant='contained'
              text='YES'
              onClick={confirm}
            ></ShortPrimaryBtn>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default SecondaryEmailConfirmationModal;
