import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const OfferLetterTemplateTable = ({
  offerLetters,
  setAnchorEl,
  setSelectedRow,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table size='medium'>
        <TableHead>
          <TableRow>
            <TableCell>Letter Type</TableCell>
            <TableCell>Hiring Entity</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offerLetters?.map((offerLetter) => (
            <TableRow key={offerLetter.id}>
              <TableCell>{offerLetter.letterType}</TableCell>
              <TableCell>{offerLetter.hiringEntity}</TableCell>
              <TableCell>{offerLetter.country}</TableCell>
              <TableCell>{offerLetter.currency}</TableCell>
              <TableCell
                width={50}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setSelectedRow(offerLetter);
                }}
              >
                <MoreVertIcon />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfferLetterTemplateTable;

OfferLetterTemplateTable.propTypes = {
  offerLetters: PropTypes.array,
  setAnchorEl: PropTypes.func,
  setSelectedRow: PropTypes.func,
};
