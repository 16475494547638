import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { styleProps } from '../../../styles/styleProps';
import NestedMenu from './NestedMenu';
import Menu from './Menu';
import { PAGES } from '../../../data/routeDefinition';

const { fonts, fontSizes, colors } = styleProps;

export const ItemWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  fontSize: fontSizes.normal,
});

export const ItemText = styled(Typography)({
  marginLeft: 10,
  fontWeight: 500,
  fontSize: fontSizes.normal,
  fontFamily: fonts.primary,
});

const MenuItem = ({ menuItem }) => {
  let pageObject;
  let pageObjects;

  if (menuItem.hasSubMenu) {
    const pageKeys = menuItem.subMenus
      .map((menu) => menu.pageId)
      .filter((pageId) => Object.keys(PAGES).includes(pageId));
    pageObjects = pageKeys.map((key) => PAGES[key.toString()]);
  } else {
    const pageKey = Object.keys(PAGES).find((key) => key === menuItem.pageId);
    pageObject = pageKey && PAGES[pageKey.toString()];
  }

  return menuItem.hasSubMenu ? (
    <NestedMenu groupMenu={menuItem} colors={colors}>
      {pageObjects.map((obj) => (
        <Menu key={obj.id} data={obj} />
      ))}
    </NestedMenu>
  ) : (
    <Menu data={pageObject} />
  );
};

MenuItem.propTypes = {
  menuItem: PropTypes.shape({
    hasSubMenu: PropTypes.bool.isRequired,
    subMenus: PropTypes.arrayOf(
      PropTypes.shape({
        pageId: PropTypes.string.isRequired,
      }),
    ),
    pageId: PropTypes.string,
  }).isRequired,
};

export default MenuItem;
