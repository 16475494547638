import UrlRoute from '../configs/UrlRoute';
import PlatformIntegrationService from './AbstractPlatformIntegrationService';

const OfferLetterService = {
  createOfferLetter: async (data) => {
    return await PlatformIntegrationService.executeHttpPostRequest(
      UrlRoute.OFFER_LETTER,
      data,
    );
  },

  listOfferLetters: async () => {
    const response = await PlatformIntegrationService.executeHttpGetRequest(
      UrlRoute.OFFER_LETTER,
    );
    return response.data;
  },

  getOfferLetter: async (id) => {
    const response = await PlatformIntegrationService.executeHttpGetRequest(
      `${UrlRoute.OFFER_LETTER}/${id}`,
    );
    return response.data;
  },
  updateOfferLetter: async (id, status, data) => {
    const response = await PlatformIntegrationService.executeHttpPutRequest(
      `${UrlRoute.OFFER_LETTER}/${id}?status=${status}`,
      data,
    );
    return response.data;
  },
};

export default OfferLetterService;
