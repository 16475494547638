import { getPayRollByDate } from '../../../services/PayRollService';
import {
  PAYLOAD_TYPE_ALL,
  PAYLOAD_TYPE_CUSTOM,
} from '../../../enums/PayRollSetting';
import totalPayrollCalculation from './TotalPayrollCalculation';

export const processPayrollData = async (
  selectedType,
  allEmployeesPayload,
  customEmployeesPayload,
  selectedEmployees,
) => {
  // Determine the current payload based on the selected type (all or custom)
  let currentPayload =
    selectedType === PAYLOAD_TYPE_ALL
      ? allEmployeesPayload
      : customEmployeesPayload;

  const employeeIds = selectedEmployees.map((item) => item.id);

  const res = await getPayRollByDate(
    currentPayload.startDate,
    currentPayload.endDate,
  );
  const payRoll = res.message;
  const payStubs = payRoll.PayStubs;

  // Create a new array to store the modified paystub objects
  const employees = [];
  let updatedPaystub;

  // Iterate through each paystub
  for (const paystub of payStubs) {
    // Extract the "Employee" object
    const employee = paystub.Employee;
    // Remove the "Employee" object from the paystubs
    delete paystub.Employee;

    // Add "employeeDisplayId" to the paystubs
    paystub.employeeDisplayId = employee.employeeDisplayId;
    checkNotUpdatedPayloadValues(currentPayload, paystub);

    // Check if the selectedType is 'custom'
    if (selectedType === PAYLOAD_TYPE_CUSTOM) {
      if (employeeIds.includes(paystub.employeeId)) {
        updatedPaystub = updatePaystubValues(paystub, currentPayload);
      } else {
        updatedPaystub = paystub;
      }
      employees.push(updatedPaystub);
    } else {
      // If the selectedType is 'all', update all paystubs
      updatedPaystub = updatePaystubValues(paystub, currentPayload);
      employees.push(updatedPaystub);
    }
  }
  let totalPay;
  if (employees) {
    totalPay = totalPayrollCalculation(employees);
  }

  const data = {
    payrollId: payRoll.displayId,
    status: payRoll.status,
    payrollStartDate: payRoll.startDate,
    payrollEndDate: payRoll.endDate,
    payDate: payRoll.payDate,
    totalPay: totalPay,
    currencyCode: payRoll.currencyCode,
    employees: employees,
  };

  return data;
};

const checkNotUpdatedPayloadValues = (currentPayload, exisitingPaystub) => {
  if (!currentPayload.employeeAllowanceFuel) {
    currentPayload.employeeAllowanceFuel =
      exisitingPaystub.employeeAllowanceFuel;
  }
  if (!currentPayload.employeeAllowanceTravel) {
    currentPayload.employeeAllowanceTravel =
      exisitingPaystub.employeeAllowanceTravel;
  }
  if (!currentPayload.employeeAllowanceDailyPay) {
    currentPayload.employeeAllowanceDailyPay =
      exisitingPaystub.employeeAllowanceDailyPay;
  }
  if (!currentPayload.employeeAllowanceOt) {
    currentPayload.employeeAllowanceOt = exisitingPaystub.employeeAllowanceOt;
  }
  if (!currentPayload.employeeReimbursements) {
    currentPayload.employeeReimbursements =
      exisitingPaystub.employeeReimbursements;
  }
  if (!currentPayload.employeeAllowanceBonus) {
    currentPayload.employeeAllowanceBonus =
      exisitingPaystub.employeeAllowanceBonus;
  }
  if (!currentPayload.employeeDeductionAdvance) {
    currentPayload.employeeDeductionAdvance =
      exisitingPaystub.employeeDeductionAdvance;
  }
  if (!currentPayload.employeeDeductionLoan) {
    currentPayload.employeeDeductionLoan =
      exisitingPaystub.employeeDeductionLoan;
  }
  if (!currentPayload.employeeNoPay) {
    currentPayload.employeeNoPay = exisitingPaystub.employeeNoPay;
  }

  return currentPayload;
};

//update pay load request comapring exiisting pay stub values and pass the updated  pay stub request to the pay roll setting page
export function updatePaystubValues(paystub, payLoad) {
  // Convert payload values to numbers before comparing
  const convertToNumber = (value) => parseFloat(value);

  // Iterate through each key in the payload
  Object.keys(payLoad).forEach((key) => {
    // Skip startDate and endDate
    if (key === 'startDate' || key === 'endDate') return;

    const payloadValue = convertToNumber(payLoad[key]);
    const paystubKey = `employee${key.charAt(0).toUpperCase()}${key.slice(1)}`;
    const correctedKey = key.charAt(0).toLowerCase() + key.slice(1);

    const paystubValue = convertToNumber(paystub[paystubKey]);

    // Check if payload value is different from existing paystub value and is not 0
    if (payloadValue !== paystubValue) {
      // Update paystub only if payload value is different from current paystub value
      paystub[correctedKey] = payloadValue;
    }
  });

  // Return the updated paystub
  return paystub;
}

//handle selected employee type custom or all employee
export function handleSelectedTypeChange(selectedType) {
  return (event) => {
    selectedType(event.target.value);
  };
}

//handle input values
export function handleInputValueChange(
  selectedType,
  setAllEmployeesPayload,
  setCustomEmployeesPayload,
  field,
) {
  return (value) => {
    const setPayload =
      selectedType === PAYLOAD_TYPE_ALL
        ? setAllEmployeesPayload
        : setCustomEmployeesPayload;

    setPayload((prev) => ({ ...prev, [field]: value }));
  };
}
