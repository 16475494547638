/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  styled,
  Alert,
  Snackbar,
  Typography,
  Paper,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import 'react-loading-skeleton/dist/skeleton.css';
import axios from 'axios';

import { styleProps } from '../../styles/styleProps';
import StatusChangeConfirmationModal from '../alerts/StatusChangeConfirmationModal';
import EmployeeCurrencyEdit from './EmployeeCurrencyEditModal';

const { fonts, colors } = styleProps;

export default function EmployeeTable(props) {
  const { value, activatedData, invitedData, inactiveData, refreshData, user } =
    props;
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(0);
  const [editCurrencyModalStatus, setEditCurrencyModalStatus] =
    useState('active');
  const [isReinviteSuccessful, setIsReinviteSuccessful] = useState(false);
  const [enableModalType, setEnableModalType] = useState('');
  const [enableModalTitle, setEnableModalTitle] = useState('');
  const [enableModalBody, setEnableModalBody] = useState('');
  const [enableModalStatus, setEnableModalStatus] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  const tableCells = [
    'Employee ID',
    'Name',
    'Email',
    'Designation',
    'Salary',
    'Mobile',
    'Actions',
  ];

  const openEditCurrencyModal = (index, type) => {
    if (type != 'active') {
      setEditCurrencyModalStatus('invited');
    }
    setSelectedEmployee(index);
    setEditModalStatus(true);
  };

  const closeModal = () => {
    setEditModalStatus(false);
    setEditCurrencyModalStatus('active');
    refreshData();
  };

  const navigateToEmployeeProfile = (id) => {
    window.location = `/employeeDetails/${id}/0`;
  };

  //code is commented - APIs are not completed yet
  const activateEmployee = (email, type) => {
    setUserEmail(email);
    if (type === 'activate') {
      setEnableModalStatus(true);
      setEnableModalBody(
        'Are you sure you want to activate this employee account ?',
      );
      setEnableModalTitle('Activate Employee');
      setEnableModalType('Enable');
    } else {
      setEnableModalStatus(true);
      setEnableModalBody(
        'Are you sure you want to deactivate this employee account ?',
      );
      setEnableModalTitle('Deactivate Employee');
      setEnableModalType('Disable');
    }
  };

  const reInviteEmployee = (email) => {
    try {
      const type = 1;
      const body = {
        email,
        type,
      };
      axios
        .post('/api/user/invite', body, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              setIsReinviteSuccessful(true);
            } else {
              window.location = '/error';
            }
          },
          (error) => {
            if (error.response.status === 401) {
              localStorage.setItem('sessionValid', 'false');
              window.location = '/login';
            } else {
              window.location = '/error';
            }
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  const closeSuccessModal = () => {
    setIsReinviteSuccessful(false);
    refreshData();
  };

  const closeEnableModal = () => {
    setEnableModalStatus(false);
  };

  const enableModalSuccess = () => {
    try {
      const email = userEmail;

      axios
        .put(`/api/employees/update/status/${email}`, null, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              setEnableModalStatus(false);
              setUserEmail('');
              refreshData();
            }
          },
          (error) => {
            if (error.response.status === 401) {
              console.log(error);
              // localStorage.setItem('sessionValid', 'false');
              // window.location = '/login';
            } else {
              window.location = '/error';
            }
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  return (
    <>
      {activatedData && inactiveData && invitedData && user ? (
        <>
          <StatusChangeConfirmationModal
            modalStatus={enableModalStatus}
            title={enableModalTitle}
            type={enableModalType}
            text={enableModalBody}
            reject={closeEnableModal}
            confirm={enableModalSuccess}
          />
          <Snackbar
            open={isReinviteSuccessful}
            autoHideDuration={6000}
            onClose={closeSuccessModal}
          >
            <Alert severity='success'>Invitation email sent successfully</Alert>
          </Snackbar>
          {editModalStatus === true && (
            <EmployeeCurrencyEdit
              modalStatus={editModalStatus}
              onClose={closeModal}
              user={user}
              data={
                editCurrencyModalStatus === 'active'
                  ? activatedData[selectedEmployee]
                  : invitedData[selectedEmployee]
              }
            />
          )}
          <Paper sx={paperStyle}>
            <TableContainer
              sx={{ maxHeight: 'calc(100vh - 60px)', borderBottom: 'none' }}
            >
              {value === 0 ? (
                activatedData != 0 ? (
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow sx={{ border: 'none' }}>
                        {tableCells.map((headerName, index) => (
                          <TableCell
                            key={index}
                            align='left'
                            sx={tableHeaderStyles}
                          >
                            {headerName}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activatedData.map((data) => {
                        return (
                          <>
                            <TableRow
                              sx={{
                                backgroundColor: '#F5F7F8',
                                cursor: 'pointer  ',
                              }}
                              onClick={(e) => {
                                if (e.target.id === 'button') {
                                  return;
                                }
                                e.preventDefault();
                                navigateToEmployeeProfile(
                                  data.employeeDisplayId,
                                );
                              }}
                            >
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeEmployeeId}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {(data.employeeFirstName ?? '') +
                                  ' ' +
                                  (data.employeeLastName ?? '')}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeWorkEmail}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeDesignation}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.Currency.currencyCode}{' '}
                                {nf.format(data.employeeSalary)}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeMobile}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                <ActiveButton
                                  id='button'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    activateEmployee(
                                      data?.employeeWorkEmail,
                                      'deactivate',
                                    );
                                  }}
                                  sx={{
                                    color: '#EA5455',
                                    borderColor: '#EA5455',
                                  }}
                                >
                                  Deactivate
                                </ActiveButton>
                              </TableCell>
                            </TableRow>

                            <br />
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={emptyRecordsDivStyle}>
                    <EmptyRecordsText>No records found</EmptyRecordsText>
                  </div>
                )
              ) : value === 1 ? (
                inactiveData != 0 ? (
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow sx={{ border: 'none' }}>
                        {tableCells.map((headerName, index) => (
                          <TableCell
                            key={index}
                            align='left'
                            sx={tableHeaderStyles}
                          >
                            {headerName}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inactiveData.map((data) => {
                        return (
                          <>
                            <TableRow sx={{ backgroundColor: '#F5F7F8' }}>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeEmployeeId}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {(data.employeeFirstName ?? '') +
                                  ' ' +
                                  (data.employeeLastName ?? '')}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeWorkEmail}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeDesignation}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.Currency.currencyCode}{' '}
                                {nf.format(data.employeeSalary)}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeMobile}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                <ActiveButton
                                  onClick={() => {
                                    activateEmployee(
                                      data.employeeWorkEmail,
                                      'activate',
                                    );
                                  }}
                                  variant='outlined'
                                  sx={{
                                    padding: 1,
                                  }}
                                >
                                  Activate
                                </ActiveButton>
                              </TableCell>
                            </TableRow>

                            <br />
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={emptyRecordsDivStyle}>
                    <EmptyRecordsText>No records found</EmptyRecordsText>
                  </div>
                )
              ) : invitedData.length != 0 ? (
                <>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow sx={{ border: 'none' }}>
                        {tableCells.map((headerName, index) => {
                          if (index === 1 || index === 5) {
                            return;
                          } else {
                            return (
                              <TableCell
                                key={index}
                                align='left'
                                sx={tableHeaderStyles}
                              >
                                {headerName}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invitedData.map((data, index) => {
                        return (
                          <>
                            <TableRow sx={{ backgroundColor: '#F5F7F8' }}>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeEmployeeId}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeWorkEmail}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.employeeDesignation}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                {data.Currency.currencyCode}{' '}
                                {nf.format(data.employeeSalary)}
                              </TableCell>
                              <TableCell align='left' sx={tableCellStyles}>
                                <Button
                                  onClick={() => {
                                    reInviteEmployee(data.employeeWorkEmail);
                                  }}
                                  variant='outlined'
                                  sx={{
                                    padding: 1,
                                    fontWeight: 'bold',
                                    borderRadius: 100,
                                    fontFamily: fonts.primary,
                                    border: '1px solid #0666EB',
                                    textTransform: 'capitalize',
                                    fontSize: '16px',
                                  }}
                                  color='primary'
                                >
                                  Re Invite
                                </Button>
                                <Button
                                  onClick={() => {
                                    openEditCurrencyModal(index);
                                  }}
                                >
                                  <div style={buttonBorderStyle}>
                                    <div style={buttonStyle}>
                                      <EditOutlinedIcon />
                                    </div>
                                  </div>
                                </Button>
                              </TableCell>
                            </TableRow>

                            <br />
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              ) : (
                <div style={emptyRecordsDivStyle}>
                  <EmptyRecordsText>No records found</EmptyRecordsText>
                </div>
              )}
            </TableContainer>
          </Paper>
        </>
      ) : null}
    </>
  );
}

const tableHeaderStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  color: colors.textLightGrey,
};

const ActiveButton = styled(Button)({
  color: colors.textGreen,
  fontWeight: 'bold',
  borderRadius: 100,
  fontFamily: fonts.primary,
  border: '1px solid #00909E',
  textTransform: 'capitalize',
  fontSize: '16px',
});

const EmptyRecordsText = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '16px',
  fontWeight: 400,
  color: colors.textLightGrey,
});

const emptyRecordsDivStyle = { textAlign: 'center', padding: '10%' };

const tableCellStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: colors.textDarkGrey,
};

const paperStyle = {
  width: '100%',
  boxShadow: 'none',
  margin: '25px',
};

const buttonBorderStyle = {
  border: '1px solid #FF7528',
  borderColor: colors.btnPrimary,
  width: 30,
  height: 30,
  borderRadius: 100,
};

const buttonStyle = {
  width: 18,
  height: 18,
  marginTop: '5px',
  marginLeft: '6px',
  borderColor: colors.btnPrimary,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
};
