import React, { useEffect } from 'react';
import { styled, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styleProps } from '../../styles/styleProps';

const { fonts } = styleProps;
const { colors } = styleProps;
const TextInput = styled(TextField)({
  width: '100%',
  margin: '25px',
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: fonts.primary,
  },
  '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

// eslint-disable-next-line react/prop-types
const EmployeeSearch = ({ filterText, setFilterText }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setFilterText(inputValue);
  };
  useEffect(() => {}, [filterText]);
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 5 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextInput
              label='Search by ID or Name'
              name='search'
              value={filterText}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <SearchIcon sx={{ color: colors.textLightGrey }} />
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EmployeeSearch;
