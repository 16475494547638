import React, { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  styled,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ROLE_ADMIN,ROLE_SUPER_ADMIN,ROLE_DELIVERY_MANAGER,ROLE_HR_MANAGER } from '../../../enums/User';
import { styleProps } from '../../../styles/styleProps';
import PrimaryButton from '../../buttons/PrimaryButton';
import { invalidStyles } from '../../../styles/textFieldErrorStyles';
import ResponseBox from '../../alerts/ResponseBox';
import { adminInvite } from '../../../services/AdminService';

const { fonts, colors } = styleProps;

//Styles and styles components

// eslint-disable-next-line react/prop-types
const AddNewAdminForm = ({ refreshData }) => {
  const [ isEmptyFields, setIsEmptyFields ] = useState(false);
  const admin_types = { 'Master admin': ROLE_SUPER_ADMIN, 'Admin': ROLE_ADMIN, 'Delivery Manager': ROLE_DELIVERY_MANAGER, 'HR Manager': ROLE_HR_MANAGER };

  const [isInviteSuccessful, setIsInviteSuccessful] = useState(false);
  const [inviteError, setInviteError] = useState('');

  //Validations
  const [isAdminNameInvalid, setIsAdminNameInvalid] = useState(false);

  const initialValues = {
    name: '',
    email: '',
    adminType: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string(),
    email: Yup.string().email(),
    adminType: Yup.string(),
  });

  const onSubmit = () => {
    if (formik.values.name.trim().length === 0) {
      setIsAdminNameInvalid(true);
      return;
    }

    const name = formik.values.name;
    const email = formik.values.email;
    const type = formik.values.adminType;
    const body = {
      name,
      email,
      type,
    };

    adminInvite(body).then(() => {
      formik.setFieldValue('name', '');
      formik.setFieldValue('email', '');
      formik.setFieldValue('adminType', '');
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    let res = checkInputFields(formik.values);
    setIsEmptyFields(res);
  }, [formik.values]);

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  const closeSuccessModal = () => {
    setIsInviteSuccessful(false);
    refreshData();
  };

  return (
    <>
      <ResponseBox
        text={'Email sent Successfully'}
        modalStatus={isInviteSuccessful}
        onClose={closeSuccessModal}
      />

      <FormContainer>
        <TitleText>Add a new admin</TitleText>
        <InputsContainer>
          <TextInput
            label='Name'
            name='name'
            value={formik.values.name}
            onChange={(e) => {
              formik.handleChange(e);
              setInviteError('');
              setIsAdminNameInvalid(false);
            }}
          />
          {isAdminNameInvalid && (
            <>
              <br />
              <Alert severity='error'>Invalid Name</Alert>
            </>
          )}
          <TextInput
            label='Email'
            name='email'
            value={formik.values.email}
            sx={invalidStyles(formik.errors?.email ? false : true)}
            onChange={(e) => {
              formik.handleChange(e);
              formik.errors?.email && delete formik.errors.email;
              setInviteError('');
            }}
          />
          {formik.errors?.email && (
            <>
              <br />
              <Alert severity='error'>{formik.errors.email}</Alert>
            </>
          )}

          <CustomSelect
            label='Admin type'
            name='adminType'
            value={formik.values.adminType}
            onChange={(e) => {
              formik.handleChange(e);
              setInviteError('');
            }}
            select
          >
            {Object.keys(admin_types).map((item) => (
              <MenuItem
                sx={menuItemStyles}
                key={item}
                value={admin_types[item]}
              >
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
          {inviteError != '' ? (
            <Alert sx={{ marginTop: '15px' }} severity='error'>
              {inviteError}
            </Alert>
          ) : null}
          <PrimaryButton onClick={formik.handleSubmit} disabled={isEmptyFields}>
            Invite an admin
          </PrimaryButton>
        </InputsContainer>
      </FormContainer>
    </>
  );
};

const FormContainer = styled(Box)({
  padding: 15,
});

const TitleText = styled(Typography)({
  fontFamily: fonts.primary,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  letterSpacing: '1px',
  color: colors.textLightGrey,
  margin: '17px 0px 17px 10px',
});

const CustomSelect = styled(TextField)({
  width: '100%',
  height: 50,
  marginTop: '15px',
  marginBottom: '30px',
  '& .MuiInputBase-root': {
    height: 60,
  },
  '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

const TextInput = styled(TextField)({
  width: '100%',
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: fonts.primary,
  },
  '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

const InputsContainer = styled(Box)({
  display: 'flex',
  gap: '2px',
  flexDirection: 'column',
  padding: '0px 23px 0px 10px',
});

const menuItemStyles = {
  m: 0.5,
  '&:focus': {
    background: colors.btnPrimary,
    color: 'white',
    m: 0.5,
    borderRadius: 1,
    '&:hover': {
      background: colors.btnPrimary,
      color: 'white',
      m: 0.5,
      borderRadius: 1,
    },
  },
};

export default AddNewAdminForm;
