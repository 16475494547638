import * as React from 'react';
import { Box, Modal, Stack, Typography } from '@mui/material';

import { styleProps } from '../../styles/styleProps';
import PrimaryButton from '../buttons/PrimaryButton';

const { colors } = styleProps;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '200px',
  background: '#FFFFFF',
  borderRadius: '8px',
  width: '457px',
};

const typographyStyle = {
  color: colors.textDarkGrey,
  fontWeight: 500,
  fontSize: '24px',
};

// eslint-disable-next-line react/prop-types
const ResponseBox = ({ modalStatus, onClose, text }) => {
  return (
    <div>
      <Modal open={modalStatus} onClose={onClose}>
        <Box sx={style}>
          <Stack
            spacing={1}
            direction='row'
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '50px',
            }}
          >
            <Typography sx={typographyStyle}>{text}</Typography>
          </Stack>

          <Stack
            spacing={1}
            direction='row'
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <PrimaryButton
              styles={{
                width: '200px',
              }}
              text='OK'
              onClick={onClose}
            >
              OK
            </PrimaryButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default ResponseBox;
