import OfferLetterService from '../../services/OfferLetterServices';
import OfferLetterTemplateService from '../../services/OfferLetterTemplateService';

export const offerLetterLoader = async ({ params }) => {
  const offerLetter = await OfferLetterService.getOfferLetter(params.id);
  return {
    offerLetter: offerLetter?.letterContent,
    letterType: offerLetter?.letterType,
    candidateName: offerLetter?.candidateName,
    letterStatus: offerLetter?.letterStatus,
    comments: offerLetter?.comments,
  };
};

export const offerLetterTemplateLoader = async ({ params }) => {
  const offerLetter = await OfferLetterTemplateService.getOfferLetterTemplate(
    params.id,
  );
  return { offerLetter };
};

export const listOfferLetersLoader = async () => {
  const offerLetters = await OfferLetterService.listOfferLetters();
  return { offerLetters };
};

export const listOfferLetterTemplatesLoader = async () => {
  const offerLetterTemplates =
    await OfferLetterTemplateService.listOfferLetterTemplates();
  return { offerLetterTemplates };
};
