import React from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import ProtectedRoutes from './protectedRoutes';

// pages
import { DEFAULT_PAGE, PAGES } from '../data/routeDefinition';

// contexts
import { ProjectReportsProvider } from '../contexts/project-reports-context/ProjectReportsContext';

// loaders
import {
  offerLetterLoader,
  listOfferLetersLoader,
  offerLetterTemplateLoader,
  listOfferLetterTemplatesLoader,
} from './loaders/offerLetterLoader';
import { getReportsOfTheMonth } from './loaders/projectReportsLoader';

const router = createBrowserRouter([
  { path: PAGES.login.to, element: PAGES.login.component },
  {
    path: PAGES.initLogin.to,
    element: PAGES.initLogin.component,
  },
  {
    path: PAGES.errorResponsePage.to,
    element: PAGES.errorResponsePage.component,
  },
  {
    path: PAGES.constructionPage.to,
    element: PAGES.constructionPage.component,
  },
  {
    path: PAGES.companyRegistration.to,
    element: PAGES.companyRegistration.component,
  },
  {
    path: PAGES.employeeRegistration.to,
    element: PAGES.employeeRegistration.component,
  },
  {
    path: PAGES.passwordReset.to,
    element: PAGES.passwordReset.component,
  },
  {
    path: '/*',
    element: <ProtectedRoutes />,
    children: [
      {
        path: PAGES.dashboard.to,
        element: PAGES.dashboard.component,
      },
      {
        path: PAGES.payroll.to,
        element: PAGES.payroll.component,
      },
      {
        path: PAGES.payrollScheduleId.to,
        element: PAGES.payrollScheduleId.component,
      },
      {
        path: PAGES.payrollSchedule.to,
        element: PAGES.payrollSchedule.component,
      },
      {
        path: PAGES.employees.to,
        element: PAGES.employees.component,
      },
      { path: PAGES.admins.to, element: PAGES.admins.component },
      {
        path: PAGES.profile.to,
        element: PAGES.profile.component,
      },
      {
        path: PAGES.employeeDetails.to,
        element: PAGES.employeeDetails.component,
      },
      {
        path: PAGES.employeePaystub.to,
        element: PAGES.employeePaystub.component,
      },
      {
        path: PAGES.payrollSetting.to,
        element: PAGES.payrollSetting.component,
      },
      {
        path: PAGES.projects.to,
        element: PAGES.projects.component,
      },
      {
        path: PAGES.attendanceSettings.to,
        element: PAGES.attendanceSettings.component,
      },
      {
        path: PAGES.projectReports.to,
        element: (
          <ProjectReportsProvider>
            {PAGES.projectReports.component}
          </ProjectReportsProvider>
        ),
      },
      {
        path: PAGES.monthlyProjectReport.to,
        element: PAGES.monthlyProjectReport.component,
        loader: getReportsOfTheMonth,
      },
      {
        path: PAGES.offerLetterTemplates.to,
        element: PAGES.offerLetterTemplates.component,
        loader: listOfferLetterTemplatesLoader,
      },
      {
        path: PAGES.offerLetters.to,
        element: PAGES.offerLetters.component,
        loader: listOfferLetersLoader,
      },
      {
        path: PAGES.offerLettersReviewId.to,
        element: PAGES.offerLettersReviewId.component,
        loader: offerLetterLoader,
      },
      {
        path: PAGES.offerLetterId.to,
        element: PAGES.offerLetterId.component,
        loader: offerLetterLoader,
      },
      {
        path: PAGES.offerLetterTemplateId.to,
        element: PAGES.offerLetterTemplateId.component,
        loader: offerLetterTemplateLoader,
      },
      {
        path: PAGES.hybridSchedule.to,
        element: PAGES.hybridSchedule.component,
      },
      {
        path: PAGES.attendance.to,
        element: PAGES.attendance.component,
      },
      {
        path: '*',
        element: <Navigate to={DEFAULT_PAGE.to} replace={true} />,
      },
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
