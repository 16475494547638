import {
  Box,
  Divider,
  Drawer,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PrimaryButton from '../buttons/PrimaryButton';
import { useFormik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { OFFER_LETTER_TYPE_INTERN } from '../../constants/OfferLetter';
import OfferLetterService from '../../services/OfferLetterServices';
import PropTypes from 'prop-types';
import { TextFieldWithProps, defaultextFieldProps } from './InputField';
import CreateYupSchema from './OfferLetterSchema';
import { isValid } from 'date-fns';

const LetterSendDrawer = ({ openDrawer, toggleDrawer, tableRow }) => {
  const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
  // get input fields from table row or set empty array
  const inputFields = Array.isArray(tableRow?.inputFields)
    ? tableRow?.inputFields
    : [];
  const letterType = tableRow?.letterType;
  const anchor = 'right';

  // generate initial values for formik using inputFields
  const initValues = useMemo(() => {
    const values = {};
    inputFields.forEach((inputField) => {
      values[inputField.placeholder] = '';
    });
    return values;
  }, [inputFields]);

  // handle form submit and call api
  const handleSubmit = async (values) => {
    // format input values to send to api
    const formattedValues = {};
    // identify date fields and format them to dd/MM/yyyy
    // we need to do this in frontend because backend can be in different timezone
    Object.keys(values).forEach((key) => {
      if (isValid(new Date(values[key]))) {
        formattedValues[key] = new Date(values[key]).toLocaleDateString();
      } else {
        formattedValues[key] = values[key];
      }
    });

    // convert salary format to LKR 1,000.00
    formattedValues.salary = nf.format(values.salary);
    const res = await OfferLetterService.createOfferLetter({
      offerLetterTemplateId: tableRow?.id,
      formData: formattedValues,
    });
    // close drawer if success
    res?.success ? handleCloseDrawer() : null;
  };

  const handleCloseDrawer = () => {
    toggleDrawer();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: CreateYupSchema(inputFields),
    onSubmit: handleSubmit,
  });

  // reset formik values when drawer is closed
  useEffect(() => {
    if (!openDrawer) {
      formik.resetForm();
    }
  }, [openDrawer]);

  // listen to start date change and set end date to 6 months after
  useEffect(() => {
    if (letterType === OFFER_LETTER_TYPE_INTERN) {
      const start = new Date(formik.values?.startDate);
      formik.setFieldValue(
        'endDate',
        new Date(start.setMonth(start.getMonth() + 6)),
      );
    }
  }, [formik.values?.startDate]);

  // listen to letter date change and set expire date to 14 days after
  useEffect(() => {
    const letterDate = new Date(formik.values?.letterDate);
    formik.setFieldValue(
      'expiryDate',
      new Date(letterDate.setDate(letterDate.getDate() + 14)),
    );
  }, [formik.values?.letterDate]);

  return (
    <Drawer anchor={anchor} open={openDrawer} sx={{ width: '400px' }}>
      <Box width={480} padding={2}>
        <Stack
          direction='row'
          alignItems='center'
          alignContent={'center'}
          marginBottom={2}
        >
          <Typography variant='h6'>Employee Details</Typography>
          <CloseIcon
            onClick={handleCloseDrawer}
            sx={{ marginLeft: 'auto', cursor: 'pointer' }}
          />
        </Stack>
        <Divider />
        <Box marginX={2}>
          <form onSubmit={formik.handleSubmit}>
            {inputFields.map((inputField) =>
              getInputFieldComponent(inputField, formik),
            )}

            <PrimaryButton
              fullWidth
              sx={{ marginTop: '40px', borderRadius: '100px' }}
              type='submit'
              disabled={!formik.isValid || !formik.dirty}
            >
              Send
            </PrimaryButton>
          </form>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LetterSendDrawer;

const getInputFieldComponent = (inputField, formik) => {
  const handle = inputField?.placeholder;

  switch (inputField?.type) {
    case 'number':
    case 'phone':
    case 'text':
    case 'email':
      return <TextFieldWithProps inputField={inputField} formik={formik} />;

    case 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disablePast
            format='dd/MM/yyyy'
            label={inputField?.label}
            name={handle}
            value={formik.values[handle]}
            onChange={(newValue) => formik.setFieldValue(handle, newValue)}
            onBlur={formik.handleBlur}
            slotProps={{ textField: { ...defaultextFieldProps } }}
            error={formik.touched[handle] && Boolean(formik.errors[handle])}
            helperText={formik.touched[handle] && formik.errors[handle]}
          />
        </LocalizationProvider>
      );

    case 'currency':
      return (
        <TextFieldWithProps
          inputField={inputField}
          formik={formik}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>LKR</InputAdornment>
            ),
          }}
        />
      );

    default:
      break;
  }
};

LetterSendDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  tableRow: PropTypes.object.isRequired,
};
