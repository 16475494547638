/**
 * Function to display an alert
 *
 * @param { string } message - message to be displayed
 * @param { VariantType } variant - variant of the alert (success, error, warning, info)
 * @param { enqueueSnackbar } enqueueSnackbar - function to display the alert
 */
const alertHandler = (message, variant, enqueueSnackbar) => {
  enqueueSnackbar(message, { variant });
};

export { alertHandler };
