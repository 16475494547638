import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  MenuItem,
  Menu,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ExpandMore';

const ChevronDropdown = ({
  abbreviation,
  options,
  onUpdateAbbreviation,
  rectangleColor,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    onUpdateAbbreviation(option);
    handleCloseMenu();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '&:hover .chevron-icon': {
          opacity: 1,
        },
      }}
    >
      <Box sx={{ textAlign: 'center', marginRight: 'auto' }}>
        <Typography sx={{ fontSize: '16px', color: '#71717A' }}>
          {abbreviation}
        </Typography>
        <Box
          sx={{
            width: '40px',
            height: '4px',
            backgroundColor: rectangleColor,
            margin: '4px auto 0',
          }}
        />
      </Box>
      <IconButton
        onClick={handleOpenMenu}
        className='chevron-icon'
        sx={{
          color: '#0666EB',
          padding: '4px',
          marginLeft: 'auto',
          opacity: 0,
          transition: 'opacity 0.3s',
        }}
      >
        <ChevronRight />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{ padding: '10px' }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleSelectOption(option)}>
            {option}
            {index < options.length - 1 && <Divider />}{' '}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

ChevronDropdown.propTypes = {
  abbreviation: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdateAbbreviation: PropTypes.func.isRequired,
  rectangleColor: PropTypes.string.isRequired,
};

export default ChevronDropdown;
