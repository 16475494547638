import { styleProps } from '../styles/styleProps';
const { colors } = styleProps;

export const statusTextColor = (status) => {
  switch (status) {
    case 'Enabled':
      return {
        color: colors.textGreen,
        btnText: 'Disable',
      };
    case 'Disabled':
      return {
        color: colors.textRed,
        btnText: 'Enable',
      };
    case 'Invited':
      return {
        color: colors.primaryBlue,
        btnText: 'Re invite',
      };
    default:
      return {
        color: colors.primaryBlue,
        btnText: 'Enable',
      };
  }
};
