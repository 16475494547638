import { hexToRgb } from '@mui/material';

/**
 * Converts a color to RGB format.
 *
 * @param {string} color - color in hex, rgb, or rgba format
 * @returns {string} - rgb color value without the 'rgb' part
 */
export const convertToRgb = (color, theme) => {
  try {
    // If the color is a color from the palette, get the color from the palette
    if (theme.palette[color]) {
      color = theme.palette[color];
    }

    // If the color in hex format, convert to RGB
    if (color.startsWith('#')) {
      return hexToRgb(color).replace('rgb(', '').replace(')', '');
    }

    // If the color is already in RGB format, return it without the 'rgb' part
    if (color.startsWith('rgb')) {
      return color.replace('rgb(', '').replace(')', '');
    }

    // If the color is not in hex or RGB format, attempt to convert it
    const tempElem = document.createElement('div');
    tempElem.style.color = color;
    document.body.appendChild(tempElem);
    const computedColor = window.getComputedStyle(tempElem).color;
    document.body.removeChild(tempElem);

    // Extract the RGB values from the computed color
    const rgbValues = computedColor.match(/\d+/g).map(Number);

    // Return the RGB value without the 'rgb' part
    return rgbValues.join(', ');
  } catch (error) {
    console.error(`Error converting color to RGB: ${error.message}`);
  }

  // If the conversion fails or an error occurs, return the original color
  return color;
};
