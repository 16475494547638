/* eslint-disable react/prop-types */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { styleProps } from '../../../styles/styleProps';
import EditModal from '../modals/EditModal';
import { useState, useEffect } from 'react';
import { getEmployeeByDisplayId } from '../../../services/EmployeeService';
import { CurrencyCode } from '../../../enums/Currency';
import { EmployeeType } from '../../../enums/Employee';
import { calculateAdvancedPayeeTax } from '../../payroll-setting/utils/TotalPayrollCalculation';
import TooltipComponent from '../../tooltip/Tooltip';
const { fonts, colors } = styleProps;

const TableComponent = ({ columns, data, bulkData, setBulkData }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalTitle, setEditModalTitle] = useState('');
  const [editModalData, setEditModalData] = useState('');
  const [editModalFieldId, setEditModalFieldId] = useState('');
  useEffect(() => {
    const employees = Object.values(bulkData.employees ?? {});

    Object.values(employees).forEach((employee) => {
      employee.calculateEarningsAndDeductions();
    });
  }, []);

  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  const handleOpenEditModal = (title, data, fieldId) => {
    setEditModalTitle(title);
    setEditModalData(data);
    setEditModalFieldId(fieldId);
    setShowEditModal(true);
  };

  const hanelModalClose = () => {
    setEditModalTitle('');
    setEditModalData('');
    setEditModalFieldId('');
    setShowEditModal(false);
  };
  const calcDeductions = (
    employeeDeductionAdvance,
    employeeDeductionEpf,
    employeeDeductionLoan,
    employeePayeeTax,
    employeeNoPay,
  ) => {
    const deductions =
      employeeDeductionAdvance +
      employeeDeductionEpf +
      employeeDeductionLoan +
      employeePayeeTax +
      employeeNoPay;
    return deductions;
  };

  const handleChangeValue = async ({ fieldId, value, id }) => {
    const employeeData = await getEmployeeByDisplayId(
      bulkData.employees[id].employeeDisplayId,
    );
    const employeeCurrencyCode = employeeData.Currency.currencyCode;
    setBulkData((prev) => {
      const employees = prev.employees;
      const employee = employees[id];

      employee[fieldId] = value;
      if (
        fieldId === 'employeeNoPay' &&
        employeeCurrencyCode === CurrencyCode.LKR &&
        employee.employeeType === EmployeeType.Permanent
      ) {
        employee.employeeDeductionEpf =
          (employee.employeeSalary - employee.employeeNoPay) * 0.08;
        employee.employeePayeeTax = calculateAdvancedPayeeTax(
          employee.employeeTotalEarnings - employee.employeeNoPay,
        );
      }
      employees[id] = employee;
      return { ...prev, employees };
    });
    bulkData.employees[id].calculateEarningsAndDeductions();
  };

  return (
    // add margin to show hidden content by fixed navbar
    <>
      <Table stickyHeader sx={{ marginBottom: '80px' }}>
        <TableHead>
          <TableRow sx={{ border: 'none' }}>
            {columns.map((headerName, index) => {
              return (
                <TableCell key={index} align='left' sx={tableHeaderStyles}>
                  {headerName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: 'none', backgroundColor: 'white' }}>
          {data.map((data, index) => {
            return (
              <>
                <TableRow key={index} sx={{ backgroundColor: '#F5F7F8' }}>
                  <TableCell align='left' sx={tableCellStyles}>
                    <TooltipComponent
                      tooltipText={
                        <>
                          <TooltipText>{`Type: ${data.employeeType}`}</TooltipText>
                          <TooltipText>{`Currency: ${data.employeeCurrencyCode}`}</TooltipText>
                        </>
                      }
                      tooltipData={`${data.employeeFirstName ?? ''} ${
                        data.employeeLastName ?? ''
                      }`}
                    />
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeType === EmployeeType.Permanent &&
                    data.employeeCurrencyCode === CurrencyCode.LKR ? (
                      <>
                        {nf.format(data.employeeDeductionEpf)}
                        <Typography
                          component='p'
                          sx={penIconStyles}
                          onClick={() =>
                            handleOpenEditModal(
                              columns[1],
                              data,
                              'employeeDeductionEpf',
                            )
                          }
                        >
                          <CreateIcon />
                        </Typography>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeType === EmployeeType.Permanent ||
                    data.employeeType === EmployeeType.Intern ? (
                      <>
                        {nf.format(data.employeeDeductionAdvance)}
                        <Typography
                          component='p'
                          sx={penIconStyles}
                          onClick={() =>
                            handleOpenEditModal(
                              columns[2],
                              data,
                              'employeeDeductionAdvance',
                            )
                          }
                        >
                          <CreateIcon />
                        </Typography>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeType === EmployeeType.Permanent &&
                    data.employeeCurrencyCode === CurrencyCode.LKR ? (
                      <>
                        {nf.format(
                          (data.employeePayeeTax = calculateAdvancedPayeeTax(
                            data.employeeTotalEarnings - data.employeeNoPay,
                          )),
                        )}
                        <Typography
                          component='p'
                          sx={penIconStyles}
                          onClick={() =>
                            handleOpenEditModal(
                              columns[3],
                              data,
                              'employeePayeeTax',
                            )
                          }
                        >
                          <CreateIcon />
                        </Typography>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeType === EmployeeType.Permanent ||
                    data.employeeType === EmployeeType.Intern ? (
                      <>
                        {nf.format(data.employeeDeductionLoan)}
                        <Typography
                          component='p'
                          sx={penIconStyles}
                          onClick={() =>
                            handleOpenEditModal(
                              columns[4],
                              data,
                              'employeeDeductionLoan',
                            )
                          }
                        >
                          <CreateIcon />
                        </Typography>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeType !== EmployeeType.Consultant ? (
                      <>
                        {nf.format(data.employeeNoPay)}
                        <Typography
                          component='p'
                          sx={penIconStyles}
                          onClick={() =>
                            handleOpenEditModal(
                              columns[5],
                              data,
                              'employeeNoPay',
                            )
                          }
                        >
                          <CreateIcon />
                        </Typography>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {nf.format(
                      (data.totolPay =
                        data.employeeTotalEarnings -
                        calcDeductions(
                          data.employeeDeductionAdvance,
                          data.employeeDeductionEpf,
                          data.employeeDeductionLoan,
                          data.employeePayeeTax,
                          data.employeeNoPay,
                        )),
                    )}
                  </TableCell>
                </TableRow>

                {/* This is the divider */}
                <TableRow
                  sx={{
                    backgroundColor: 'white',
                    height: '5px',
                  }}
                ></TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>

      {/* Edit Modal for editing table data */}
      <EditModal
        visible={showEditModal}
        handleClose={hanelModalClose}
        data={editModalData}
        fieldId={editModalFieldId}
        title={editModalTitle}
        onChange={async (value) => {
          await handleChangeValue({
            fieldId: editModalFieldId,
            id: editModalData.employeeDisplayId,
            value,
          });
        }}
      />
    </>
  );
};

export default TableComponent;

const tableCellStyles = {
  position: 'relative',
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: colors.textDarkGrey,
  '&: hover': {
    '& p': {
      opacity: '1',
    },
  },
};

const tableHeaderStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  color: colors.textLightGrey,
};

const penIconStyles = {
  position: 'absolute',
  top: '50%',
  right: 10,
  transform: 'translate(-50%, -50%)',
  opacity: '0',
  cursor: 'pointer',
};

const TooltipText = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: 13,
});
