import PlatformIntegrationService from './AbstractPlatformIntegrationService';
import UrlRoute from '../configs/UrlRoute';

export const getByEmployeeDisplayId = async (employeeId) => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.GET_PAYSTUB}/${employeeId}`,
  );

  return data.message;
};

export const getAllPayStubsOfCompanyByMonthYear = async (
  displayId,
  year,
  month,
  name,
) => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.GET_PAYSTUB}/${displayId}?year=${year}&month=${month}&name=${name}`,
  );

  return data;
};

export const getPaystubByEmployeeIdYearMonth = async (
  displayId,
  year,
  month,
) => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.GET_PAYSTUB}/${displayId}?year=${year}&month=${month}`,
  );
  return data;
};
export const updateAllEmployeePaystubs = async (data) => {
  const updatedData = await PlatformIntegrationService.executeHttpPutRequest(
    UrlRoute.UPDATE_ALL_EMPLOYEE_PAYSTUB,
    data,
  );
  return updatedData;
};
