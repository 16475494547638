export const CountryCodes = {
  countries: [
    {
      code: '+91',
      name: 'IND',
      numberLength: 11,
    },

    {
      code: '+94',
      name: 'LK',
      numberLength: 9,
    },
  ],
};
