import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import TopBar from '../components/top-bar/TopBar';
import { getCurrentAdmin } from '../services/AdminService';
import ProfileDetails from '../components/profile/ProfileDetails';
import PasswordReset from '../components/profile/ResetPassword';
import PasswordResetView from '../components/profile/PasswordResetView';
import OtpModal from '../components/profile/Otp_Process/OtpModal';
import { CountryCodes } from '../../src/data/countryCodes';
import {
  fetchCompanyDetails,
  getCompanyCategories,
} from '../services/CompanyService';
import { getAllCurrencies } from '../services/CurrencyService';

const Profile = () => {
  const [user, setUser] = useState('');
  const [companyData, setCompanyData] = useState('');
  const [companyFields, setCompanyFields] = useState([]);
  const [companyCurrency, setCompanyCurrency] = useState([]);
  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const [isOtpAuthenticated, setIsOtpAuthenticated] = useState(false);
  const countryCodes = CountryCodes.countries;
  const [countryCodeValue, setCountryCodeValue] = useState('');
  const [number, setNumber] = useState('');
  const [companyCountryCodeValue, setCompanyCountryCodeValue] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const authToken = localStorage.getItem('loggedInAuth');

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      getCurrentAdmin().then((user) => {
        const userObj = user;
        setUser(userObj);

        if (userObj.adminMobile) {
          const countryCode = countryCodeExtractor(userObj.adminMobile);
          setNumber(
            userObj.adminMobile.substr(
              countryCode.length,
              userObj.adminMobile.length,
            ),
          );
          setCountryCodeValue(countryCode);
        }
      });

      fetchCompanyDetails(user.companyId).then((company) => {
        setCompanyData(company);

        if (company.companyMobile) {
          const countryCode = countryCodeExtractor(company.companyMobile);
          setCompanyNumber(
            company.companyMobile.substr(
              countryCode.length,
              company.companyMobile.length,
            ),
          );
          setCompanyCountryCodeValue(countryCode);
        }
      });

      getAllCurrencies().then((res) => {
        setCompanyCurrency(res);
      });

      getCompanyCategories().then((res) => {
        setCompanyFields(res);
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const countryCodeExtractor = (mobileNumber) => {
    const countryCode = countryCodes
      .map((item) => (mobileNumber.includes(item.code) ? item.code : ''))
      .filter((item) => item !== '')[0];
    return countryCode;
  };

  const openOtp = () => {
    try {
      const mode = 0;
      const email = user.adminEmail;

      const body = {
        email,
        mode,
      };

      axios
        .post('/api/otp/send', body, {
          headers: {
            Authorization: `"${authToken}"`,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            setIsOTPRequested(true);
          } else {
            console.log(response.data.error);
          }
        });
    } catch (err) {
      console.error(err.message);
    }
  };

  const closeOtp = () => {
    setIsOTPRequested(false);
  };

  const openPasswordResetModal = () => {
    setIsOtpAuthenticated(true);
    setIsOTPRequested(false);
  };

  const closePasswordChangeProcess = () => {
    setIsOtpAuthenticated(false);
    setIsOTPRequested(false);
  };

  return (
    <>
      {isOTPRequested === false ? (
        <>
          <TopBar title='Profile' user={user} />

          <Grid
            container
            sx={{ height: 'calc(100vh - 60px)', overflowY: 'scroll' }}
          >
            <Grid item xs={12} md={6}>
              <ProfileDetails
                user={user}
                refreshData={getData}
                companyData={companyData}
                currencyList={companyCurrency}
                fieldList={companyFields}
                countryCode={countryCodeValue}
                number={number}
                companyNumber={companyNumber}
                companyCountryCodeValue={companyCountryCodeValue}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                backgroundColor: '#F5F7F8',
                height: 'calc(100vh - 60px)',
              }}
            >
              {isOtpAuthenticated === false ? (
                <PasswordReset enableOtpView={openOtp} />
              ) : (
                <PasswordResetView
                  user={user}
                  closeResetPassword={closePasswordChangeProcess}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {user ? (
            <OtpModal
              email={user.adminEmail}
              disableOtpView={closeOtp}
              verifyEmailOtp={openPasswordResetModal}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default Profile;
