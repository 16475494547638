import React from 'react';
import PropTypes from 'prop-types';
import BaseButton from './BaseButton';

/**
 * JSX component that returns a SecondaryButton component
 *
 * @param {object} props
 * @returns  {JSX.Element} - SecondaryButton component
 */
const SecondaryButton = ({ children, ...props }) => {
  return (
    <BaseButton variant='outlined' {...props}>
      {children}
    </BaseButton>
  );
};

export default SecondaryButton;

SecondaryButton.propTypes = {
  icon: PropTypes.node,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
};
