import React from 'react';
import { getCurrentAdmin } from '../services/AdminService';
import TopBar from '../components/top-bar/TopBar';
import PayRollTable from '../components/payroll/tables/PayRollTable';
import PayRollOverview from '../components/payroll/PayRollOverview';
import '../styles/Payroll.css';
import { Alert, Box, Snackbar, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from 'react';
import ShortEmployeeSecondaryButton from '../components/buttons/ShortEmployeeSecondaryButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AttendanceUploadModal from '../components/payroll/modals/attendanceUpload';

function calculateMaxDate(currentDate) {
  return new Date(currentDate.getFullYear(), currentDate.getMonth() + 4, 1);
}

function calculateMinDate() {
  return new Date(2000, 0, 1);
}
const Payroll = () => {
  const [user, setUser] = useState('');
  const [attendanceUploadModal, setAttendanceUploadModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [snackbarVisibility, setSnackbarVisibility] = useState(false);
  const currentDate = new Date();
  const maxDate = calculateMaxDate(currentDate);
  const minDate = calculateMinDate(currentDate);

  const handleClose = () => {
    setSnackbarVisibility(false);
  };

  const completeMessage = () => {
    setAttendanceUploadModal(false);
    setSnackbarVisibility(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setUser(await getCurrentAdmin());
  };

  return (
    <>
      <>
        <TopBar title='Payroll' user={user} />
        <Box
          sx={{
            height: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            paddingX: 3,
          }}
        >
          <Box sx={styles.filterStyles}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container alignItems='center'>
                <Grid item>
                  <DatePicker
                    views={['year', 'month']}
                    label='Search payroll by date'
                    minDate={minDate}
                    maxDate={maxDate}
                    slotProps={{ textField: { size: 'small' } }}
                    className='custom-date-picker'
                    value={
                      selectedYear !== null && selectedMonth !== null
                        ? new Date(selectedYear, selectedMonth - 1, 1)
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        const year = date.getFullYear();
                        const month = date.getMonth() + 1;
                        setSelectedYear(year);
                        setSelectedMonth(month);
                      } else {
                        setSelectedYear(null);
                        setSelectedMonth(null);
                      }
                    }}
                  />
                </Grid>
                {selectedYear !== null && selectedMonth !== null && (
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        setSelectedYear(null) && setSelectedMonth(null)
                      }
                      size='small'
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </LocalizationProvider>
            <Box sx={{ display: 'flex' }}>
              <ShortEmployeeSecondaryButton
                text='Attendance Upload'
                onClick={() => setAttendanceUploadModal(true)}
              />
              <ShortEmployeeSecondaryButton
                text='Payroll Setting'
                onClick={() => (window.location = '/payrollSetting')}
              />
            </Box>
          </Box>

          <Box>
            <PayRollOverview />
          </Box>
          <Box>
            <PayRollTable
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
            />
          </Box>
        </Box>
      </>

      <AttendanceUploadModal
        visible={attendanceUploadModal}
        setVisible={setAttendanceUploadModal}
        onFinish={completeMessage}
      />

      <Snackbar
        open={snackbarVisibility}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity='success'>
          Attendance details bulk uploaded successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default Payroll;

const styles = {
  filterStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '3vh',
  },
};
