/* eslint-disable react/prop-types */
import React from 'react';
import { Box, styled, Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { styleProps } from '../../styles/styleProps';

const { fonts, fontSizes, colors } = styleProps;

//Styled components
const TopbarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '60px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
});

const TitleText = styled(Typography)({
  fontSize: fontSizes.header,
  fontWeight: 500,
  color: colors.textDarkGrey,
  marginLeft: '20px',
  fontFamily: fonts.primary,
});

const UserNameText = styled(Typography)({
  fontSize: fontSizes.normal,
  fontWeight: 500,
  color: colors.textDarkGrey,
  marginRight: '20px',
  fontFamily: fonts.primary,
});

const navigateToEmployee = () => {
  window.location = '/employees';
};

const TopBar = ({ title, user, backEnabled }) => {
  return (
    <TopbarContainer>
      {backEnabled && backEnabled === true ? (
        <TitleText>
          {' '}
          <IconButton onClick={navigateToEmployee}>
            <ArrowBackIosIcon />
          </IconButton>
          {title}
        </TitleText>
      ) : (
        <TitleText>{title}</TitleText>
      )}
      {user ? <UserNameText>{user.adminName}</UserNameText> : null}
    </TopbarContainer>
  );
};

export default TopBar;
