import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Skeleton, Typography } from '@mui/material';

const CardSkeletonContainer = ({ outerBoxSx, innerBoxSx, noOfItems = 4 }) => {
  const renderSkeleton = () => {
    const components = [];
    for (let i = 0; i < noOfItems; i++) {
      components.push(
        <Grid item lg={6}>
          <Box sx={outerBoxSx}>
            <Box sx={innerBoxSx}>
              <Typography variant={'h1'}>
                <Skeleton />
              </Typography>
              <Typography variant={'h3'}>
                <Skeleton />
              </Typography>
              <Typography variant={'body1'}>
                <Skeleton />
              </Typography>
              <Typography variant={'h3'}>
                <Skeleton />
              </Typography>
            </Box>
          </Box>
          ,
        </Grid>,
      );
    }
    return components;
  };

  return (
    <Grid container spacing={5}>
      {renderSkeleton()}
    </Grid>
  );
};

CardSkeletonContainer.propTypes = {
  outerBoxSx: PropTypes.object.isRequired,
  innerBoxSx: PropTypes.object.isRequired,
  noOfItems: PropTypes.number,
};

export default CardSkeletonContainer;
