import React from 'react';
import { Button, styled } from '@mui/material';

import { styleProps } from '../../styles/styleProps';

const { fonts, colors } = styleProps;

const ProceedButton = styled(Button)({
  fontFamily: fonts.primary,
  fontSize: 16,
  fontWeight: 500,
  minWidth: 200,
  height: 50,
  padding: 5,
  backgroundColor: colors.btnPrimary,
  borderRadius: 30,
  color: '#fff',
  // marginTop: 30,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: colors.btnPrimary,
  },
  ':disabled': {
    opacity: 0.3,
    color: '#fff',
  },
});

// eslint-disable-next-line react/prop-types
const ShortEmployeePrimaryBtn = ({ text, onClick, disabled }) => {
  return (
    <ProceedButton disabled={disabled} onClick={onClick}>
      {text}
    </ProceedButton>
  );
};

export default ShortEmployeePrimaryBtn;
