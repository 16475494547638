export const UrlRoute = {
  // company endpoints
  GET_COMPANY: '/api/company',
  GET_COMPANY_CATEGORIES: '/api/company-categories',

  // admin endpoints
  GET_ADMIN: '/api/admin',
  ADMIN_INVITE: '/api/admin/invite',
  ADMIN_UPDATE: '/api/admin/update',
  ADMIN_REGISTER: '/api/admin/register',
  ADMIN_PASSWORD_RESET: '/api/admin/reset/password',
  ADMIN_PASSWORD_UPDATE: '/api/admin/update/password',
  ADMIN_PASSWORD_CHECK: '/api/admin/password/check',

  // project endpoints
  PROJECTS: '/api/projects',
  UPDATE_PROJECT: '/api/projects/update',

  // Time record endpoints
  TIME_RECORDS: '/api/time-records',

  //signature endpoints
  SAVE_SIGNATURE: '/api/signature',
  GET_SIGNATURE_DETAILS: '/api/signature',

  // Project reports endpoints
  PROJECT_REPORTS: '/api/project-reports',
  PROJECT_REPORT_UPDATE_TIME: '/api/project-reports',
  PROJECT_REPORT_ADD_SUMMARY: '/api/project-reports',
  PROJECT_REPORT_APPROVE: '/api/project-reports',
  PROJECT_REPORT_EDIT_MONTHLY_SUMMARY: '/api/project-reports/monthly-report',
  PROJECT_REPORT_GENERATE_INVOICE: '/api/project-reports',

  // Employee endpoints
  EMPLOYEES: '/api/employees',
  EMPLOYEES_UPDATE: '/api/employees/update',
  EMPLOYEE_ACCOUNT: '/api/account/get',

  // Paystub endpoints
  GET_PAYSTUB: '/api/paystubs',
  UPDATE_ALL_EMPLOYEE_PAYSTUB: '/api/paystubs/update',
  PAYSTUB_RULE_ADD: '/api/paystub/add',

  // offer-letter-template endpoints
  OFFER_LETTER_TEMPLATES: '/api/offer-letter-templates',
  OFFER_LETTER: '/api/offer-letters',

  // Common
  CURRENCIES: '/api/currencies',
  PAYMENT: '/api/payment',
  PAYROLLS: '/api/payrolls',
  GET_FBO_BALANCE: '/api/account/fbo/balance',
  GET_NOTES: '/api/notes',
};

export default UrlRoute;
