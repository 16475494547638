import React, { useState, useEffect } from 'react';
import { Grid, Box, Tab, Tabs, Snackbar, Alert } from '@mui/material';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import TopBar from '../components/top-bar/TopBar';
import EmployeeSearch from '../components/employee/EmployeeSearch';
import ShortPrimaryBtn from '../components/buttons/ShortEmployeePrimaryButton';
import EmployeeTable from '../components/employee/EmployeeTable';
import { getCurrentAdmin } from '../services/AdminService';
import InviteEmployeeModal from '../components/employee/InviteEmployeeModal';
import ShortEmployeeSecondaryButton from '../components/buttons/ShortEmployeeSecondaryButton';
import EmployeeCsvUploardModal from '../components/employee/csv/EmployeeCsvUploardModal';
import { getEmployeeByStatus } from '../services/EmployeeService';
import { EmployeeStatus } from '../enums/Employee';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabStyles = {
  fontSize: '16px',
  fontWeight: 'bold',
};

const Employees = () => {
  const [user, setUser] = useState('');
  const [activeEmployeeData, setActiveEmployeeData] = useState([]);
  const [inactiveEmployeeData, setInactiveEmployeeData] = useState([]);
  const [invitedEmployeeData, setInvitedEmployeeData] = useState([]);
  const [value, setValue] = useState(0);
  const [inviteModalStatus, setInviteModalStatus] = useState(false);
  const [isInviteSuccessful, setInviteSuccessful] = useState(false);

  //csv uploading states
  const [csvUploardModalStatus, setCsvUploardModalStatus] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploadMessageSeverity, setUploadMessageSeverity] = useState('');
  const [showUploadMessage, setShowUploadMessage] = useState(false);

  const [filterText, setFilterText] = useState('');
  let [filteredActiveEmployeeData, setFilteredActiveEmployeeData] = useState(
    [],
  );
  let [filteredInactiveEmployeeData, setFilteredInactiveEmployeeData] =
    useState([]);
  let [filteredInvitedEmployeeData, setFilteredInvitedEmployeeData] = useState(
    [],
  );

  useEffect(() => {
    getData();
    checkMessageOnLoginEmployeeUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterText === '') {
      setFilteredActiveEmployeeData(activeEmployeeData);
      setFilteredInactiveEmployeeData(inactiveEmployeeData);
      setFilteredInvitedEmployeeData(invitedEmployeeData);
    } else {
      setFilteredActiveEmployeeData(filterEmployeeData(activeEmployeeData));
      setFilteredInactiveEmployeeData(filterEmployeeData(inactiveEmployeeData));
      setFilteredInvitedEmployeeData(filterEmployeeData(invitedEmployeeData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterText,
    activeEmployeeData,
    inactiveEmployeeData,
    invitedEmployeeData,
  ]);

  const filterEmployeeData = (employees) => {
    let filteredEmployees = employees.filter((employee) => {
      const lowerCaseFilterText = filterText.toLowerCase();
      const employeeIdLowerCase = employee.employeeEmployeeId?.toLowerCase();
      const firstNameLowerCase = employee.employeeFirstName?.toLowerCase();
      const lastNameLowerCase = employee.employeeLastName?.toLowerCase();

      return (
        (employeeIdLowerCase &&
          employeeIdLowerCase.startsWith(lowerCaseFilterText)) ||
        (firstNameLowerCase &&
          firstNameLowerCase.startsWith(lowerCaseFilterText)) ||
        (lastNameLowerCase && lastNameLowerCase.startsWith(lowerCaseFilterText))
      );
    });

    return filteredEmployees;
  };

  const getActiveEmployeeData = async () => {
    return await getEmployeeByStatus(EmployeeStatus.ENABLED);
  };

  const getInactiveEmployeeData = async () => {
    return await getEmployeeByStatus(EmployeeStatus.DISABLED);
  };

  const getInvitedEmployeeData = async () => {
    return await getEmployeeByStatus(EmployeeStatus.INVITED);
  };

  async function getData() {
    setUser(await getCurrentAdmin());
    let activeEmployeeList = await getActiveEmployeeData();
    let inactiveEmployeeList = await getInactiveEmployeeData();
    let invitedEmployeeList = await getInvitedEmployeeData();
    setActiveEmployeeData(activeEmployeeList.employees);
    setInactiveEmployeeData(inactiveEmployeeList.employees);
    setInvitedEmployeeData(invitedEmployeeList.employees);
  }

  const openInviteModal = () => {
    setInviteModalStatus(true);
  };

  const closeModal = () => {
    setInviteModalStatus(false);
  };

  const closeSuccessModal = () => {
    setInviteSuccessful(false);
  };

  const openInviteSuccessModal = () => {
    setInviteSuccessful(true);
  };

  //to open csv upload modal
  const openCsvBulkUploadModal = () => {
    setCsvUploardModalStatus(true);
  };

  const closeCsvBulkUploadModal = () => {
    setCsvUploardModalStatus(false);
  };

  const afterBulkEmployeeUpload = (message, severity) => {
    setUploadMessage(message);
    setUploadMessageSeverity(severity);
    setShowUploadMessage(true);
    getData();
  };

  const checkMessageOnLoginEmployeeUpload = () => {
    if (localStorage.getItem('message') && localStorage.getItem('severity')) {
      setUploadMessage(localStorage.getItem('message'));
      setUploadMessageSeverity(localStorage.getItem('severity'));
      //setShowUploadMessage(true);
      localStorage.removeItem('message');
      localStorage.removeItem('severity');
    }
  };

  return (
    <>
      <TopBar title='Employees' user={user} />
      <Box sx={{ height: 'calc(100vh - 60px)', overflowY: 'scroll' }}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <EmployeeSearch
              filterText={filterText}
              setFilterText={setFilterText}
            />
          </Grid>
          <Grid
            xs={12}
            md={5}
            sx={{
              marginTop: '3vh',
              paddingRight: '2%',
              textAlign: 'end',
            }}
          >
            <ShortEmployeeSecondaryButton
              text={'Bulk Employee Upload'}
              onClick={() => {
                openCsvBulkUploadModal();
              }}
            />

            <ShortPrimaryBtn
              text='Invite an Employee'
              sx={{ marginTop: 30 }}
              onClick={openInviteModal}
            />
          </Grid>
        </Grid>
        <Box sx={{ width: '45%', marginLeft: '2%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              sx={{
                '& button': {
                  borderRadius: 2,
                  paddingRight: '65px',
                  paddingLeft: '65px',
                  textTransform: 'none',
                },

                '& button:focus': {
                  // backgroundColor: '#0666EB',
                  // opacity: '0.1',
                  fontWeight: 'bold',
                },
              }}
            >
              <Tab sx={tabStyles} label='Active' {...a11yProps(0)} />
              <Tab sx={tabStyles} label='Inactive' {...a11yProps(1)} />
              <Tab sx={tabStyles} label='Invited' {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Box>
        {activeEmployeeData &&
        invitedEmployeeData &&
        inactiveEmployeeData &&
        user ? (
          <Grid container>
            <EmployeeTable
              value={value}
              activatedData={filteredActiveEmployeeData}
              invitedData={filteredInvitedEmployeeData}
              inactiveData={filteredInactiveEmployeeData}
              refreshData={getData}
              user={user}
            />
          </Grid>
        ) : (
          <div style={{ margin: '25px' }}>
            <SkeletonTheme baseColor='#F5F7F8' highlightColor='#ffff'>
              <p>
                <Skeleton count={1} height={40} />
                <br />
                <Skeleton count={5} height={65} />
              </p>
            </SkeletonTheme>
          </div>
        )}
      </Box>
      {user && (
        <>
          <InviteEmployeeModal
            modalStatus={inviteModalStatus}
            onClose={closeModal}
            user={user}
            refreshData={getData}
            openInviteSuccessModal={openInviteSuccessModal}
          />
          <EmployeeCsvUploardModal
            modalStatus={csvUploardModalStatus}
            onClose={closeCsvBulkUploadModal}
            whileAdminRegister={false}
            nextFunctoin={afterBulkEmployeeUpload}
          />
        </>
      )}
      <Snackbar
        open={isInviteSuccessful}
        autoHideDuration={6000}
        onClose={closeSuccessModal}
      >
        <Alert severity='success'>
          Invitations have been sent successfully
        </Alert>
      </Snackbar>

      <Snackbar
        open={showUploadMessage}
        autoHideDuration={6000}
        onClose={() => {
          setShowUploadMessage(false);
        }}
      >
        <Alert severity={uploadMessageSeverity}>{uploadMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default Employees;
