import React, { useState, useEffect, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import { Grid, Typography, styled, Stack, Radio } from '@mui/material';
import TopBar from '../components/top-bar/TopBar.js';
import { getCurrentAdmin } from '../services/AdminService.js';
import { savePayroll } from '../services/PayRollService.js';
import {
  processPayrollData,
  handleSelectedTypeChange,
  handleInputValueChange,
} from '../components/payroll-setting/utils/PayrollDataUtils.js';
import ShortEmployeeSecondaryButton from '../components/buttons/ShortEmployeeSecondaryButton.js';
import ShortPrimaryBtn from '../components/buttons/ShortPrimaryBtn.js';
import SalaryInputField from '../components/input-field/SalaryInputField.js';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { styleProps } from '../styles/styleProps.js';
import {
  PAYLOAD_TYPE_ALL,
  PAYLOAD_TYPE_CUSTOM,
} from '../enums/PayRollSetting.js';
import PayrollEmployeeTable from '../components/employee/PayrollEmployeeTable.js';

const { fonts } = styleProps;
const initialValues = {
  employeeAllowanceFuel: '',
  employeeAllowanceTravel: '',
  employeeAllowanceDailyPay: '',
  employeeAllowanceOt: '',
  employeeReimbursements: '',
  employeeDeductionAdvance: '',
  employeeDeductionLoan: '',
  employeeNoPay: '',
  startDate: null,
  endDate: null,
  employeeAllowanceBonus: '',
};

const Title = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '20px',
  fontWeight: 500,
  padding: '20px 0px 10px 40px',
});

const SubTitle = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '18px',
  fontWeight: 400,
  padding: '5px 40px ',
  color: '#71717A',
});

const Label = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '16px',
  fontWeight: 400,
  color: '#979797',
  paddingTop: 10,
  paddingLeft: 40,
});

const RadioLabel = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '16px',
  fontWeight: 400,
  color: '#979797',
  paddingTop: 10,
});

const PayrollSetting = () => {
  const [user, setUser] = useState('');
  const [selectedType, setSelectedType] = useState(PAYLOAD_TYPE_ALL);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [allEmployeesPayload, setAllEmployeesPayload] = useState(initialValues);
  const [customEmployeesPayload, setCustomEmployeesPayload] =
    useState(initialValues);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {}, [selectedEmployees]);

  async function getData() {
    const admin = await getCurrentAdmin();
    setUser(admin);
  }

  const onSubmit = async () => {
    const data = await processPayrollData(
      selectedType,
      allEmployeesPayload,
      customEmployeesPayload,
      selectedEmployees,
    );
    const response = await savePayroll(data);
    if (selectedType === PAYLOAD_TYPE_ALL) {
      setAllEmployeesPayload(initialValues);
    } else {
      setCustomEmployeesPayload(initialValues);
    }
    if (response) {
      window.location = '/payroll';
    }
  };
  const canSave = useMemo(() => {
    if (selectedType === PAYLOAD_TYPE_ALL) {
      return allEmployeesPayload.startDate && allEmployeesPayload.endDate;
    } else if (selectedType === PAYLOAD_TYPE_CUSTOM) {
      return customEmployeesPayload.startDate && customEmployeesPayload.endDate;
    }
    return false;
  }, [selectedType, allEmployeesPayload, customEmployeesPayload]);

  const handleSelectedType = handleSelectedTypeChange(setSelectedType);

  const handleInputChange = (field) =>
    handleInputValueChange(
      selectedType,
      setAllEmployeesPayload,
      setCustomEmployeesPayload,
      field,
    );

  return (
    <>
      <TopBar title='Payroll > Payroll Settings' user={user} />
      <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 64px)' }}>
        <Title>Applied to :</Title>
        <div style={{ paddingBottom: '25px' }}>
          <Grid
            container
            spacing={2}
            paddingX={5}
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
          >
            <Grid direction='row' container item xs={4}>
              <Stack spacing={1} direction='row'>
                <Radio
                  checked={selectedType === PAYLOAD_TYPE_ALL}
                  value={PAYLOAD_TYPE_ALL}
                  onChange={handleSelectedType}
                />
                <RadioLabel> All Employees </RadioLabel>
              </Stack>
              <Stack spacing={1} direction='row'>
                <Radio
                  checked={selectedType === PAYLOAD_TYPE_CUSTOM}
                  value={PAYLOAD_TYPE_CUSTOM}
                  onChange={handleSelectedType}
                />
                <RadioLabel> Custom Employees </RadioLabel>
              </Stack>
            </Grid>
          </Grid>

          {selectedType === PAYLOAD_TYPE_CUSTOM && (
            <Grid container paddingX={5}>
              <PayrollEmployeeTable
                setSelectedEmployees={setSelectedEmployees}
                selectedEmployees={selectedEmployees}
              />
            </Grid>
          )}
        </div>

        <Divider variant='middle' light />
        <Title>Applied Date Range :</Title>
        <Grid container spacing={2} paddingLeft={5} paddingBottom={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={3}>
              <Stack spacing={1} direction='row'>
                <DatePicker
                  value={
                    selectedType === PAYLOAD_TYPE_ALL
                      ? allEmployeesPayload.startDate
                      : customEmployeesPayload.startDate
                  }
                  label='Select start Date'
                  onChange={(value) => handleInputChange('startDate')(value)}
                  format='MMM dd, yyyy'
                />
                <Label> To </Label>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                label='Select end Date'
                minDate={
                  allEmployeesPayload.startDate ||
                  customEmployeesPayload.startDate
                }
                value={
                  selectedType === PAYLOAD_TYPE_ALL
                    ? allEmployeesPayload.endDate
                    : customEmployeesPayload.endDate
                }
                onChange={(value) => handleInputChange('endDate')(value)}
                format='MMM dd, yyyy'
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Divider variant='middle' light />
        <Title>Salary Customization :</Title>
        <SubTitle>Allowances :</SubTitle>
        <Grid container spacing={2} paddingLeft={5} paddingBottom={2}>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeAllowanceFuel
                  : customEmployeesPayload.employeeAllowanceFuel
              }
              setValue={(value) =>
                handleInputChange('employeeAllowanceFuel')(value)
              }
              name='employeeAllowanceFuel'
              label='Fuel & Maintenance'
            />
          </Grid>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeAllowanceTravel
                  : customEmployeesPayload.employeeAllowanceTravel
              }
              setValue={(value) =>
                handleInputChange('employeeAllowanceTravel')(value)
              }
              name='employeeAllowanceTravel'
              label='Travelling'
            />
          </Grid>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeAllowanceDailyPay
                  : customEmployeesPayload.employeeAllowanceDailyPay
              }
              setValue={(value) =>
                handleInputChange('employeeAllowanceDailyPay')(value)
              }
              name='employeeAllowanceDailyPay'
              label='Daily Paid'
            />
          </Grid>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeAllowanceOt
                  : customEmployeesPayload.employeeAllowanceOt
              }
              setValue={(value) =>
                handleInputChange('employeeAllowanceOt')(value)
              }
              name='employeeAllowanceOt'
              label='OT'
            />
          </Grid>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeAllowanceBonus
                  : customEmployeesPayload.employeeAllowanceBonus
              }
              setValue={(value) =>
                handleInputChange('employeeAllowanceBonus')(value)
              }
              name='employeeAllowanceBonus'
              label='Bonus'
            />
          </Grid>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeReimbursements
                  : customEmployeesPayload.employeeReimbursements
              }
              setValue={(value) =>
                handleInputChange('employeeReimbursements')(value)
              }
              name='employeeReimbursements'
              label='Reimbursement Expenses'
            />
          </Grid>
        </Grid>
        <SubTitle>Deductions :</SubTitle>
        <Grid container spacing={2} paddingLeft={5} paddingBottom={5}>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeDeductionAdvance
                  : customEmployeesPayload.employeeDeductionAdvance
              }
              setValue={(value) =>
                handleInputChange('employeeDeductionAdvance')(value)
              }
              name='employeeDeductionAdvance'
              label='Advance'
            />
          </Grid>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeDeductionLoan
                  : customEmployeesPayload.employeeDeductionLoan
              }
              setValue={(value) =>
                handleInputChange('employeeDeductionLoan')(value)
              }
              name='employeeDeductionLoan'
              label='Loan'
            />
          </Grid>
          <Grid item xs={3}>
            <SalaryInputField
              value={
                selectedType === PAYLOAD_TYPE_ALL
                  ? allEmployeesPayload.employeeNoPay
                  : customEmployeesPayload.employeeNoPay
              }
              setValue={(value) => handleInputChange('employeeNoPay')(value)}
              name='employeeNoPay'
              label='No Pay - D'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} marginBottom={3}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <ShortEmployeeSecondaryButton
              text='Cancel'
              onClick={() => (window.location = '/payrollSetting')}
            ></ShortEmployeeSecondaryButton>
            <ShortPrimaryBtn
              text='Save Settings'
              onClick={() => onSubmit()}
              disabled={!canSave}
            ></ShortPrimaryBtn>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PayrollSetting;
