/* eslint-disable react/prop-types */
import React from 'react';
import { TextField } from '@mui/material';
import { Box, Stack } from '@mui/system';
import Button from '../buttons/MediumPrimaryBtn';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import CardSkeletonContainer from '../skeletons/CardSkeletonContainer';

const ProjectsPage = ({ projects, setVisible, isLoading }) => {
  const [filteredProjects, setFilteredProjects] = useState(projects);

  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  const handleFilter = (e) => {
    const searchValue = e.target.value;
    if (searchValue === '') {
      setFilteredProjects(projects);
    } else {
      const filtered = projects.filter((project) => {
        return (
          project.projectName
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          project.displayId.toString().includes(searchValue)
        );
      });
      setFilteredProjects(filtered);
    }
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <TextField
          label='Search by Id or Name'
          variant='outlined'
          size='small'
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          onChange={handleFilter}
        />
        <Button text={'Create a New Project'} onClick={handleOpenModal} />
      </Stack>
      <Stack flexDirection={'row'} flexWrap={'wrap'} sx={{ marginTop: 4 }}>
        {isLoading ? (
          <CardSkeletonContainer
            outerBoxSx={{
              width: '100%',
              border: '2px solid #DEEBFC',
              borderRadius: 4,
              height: '300px',
            }}
            innerBoxSx={{
              flexDirection: 'column',
              display: 'flex',
              padding: '2%',
              justifyContent: 'space-around',
            }}
          />
        ) : (
          filteredProjects.map((project) => {
            return <ProjectCard key={project.displayId} {...project} />;
          })
        )}
      </Stack>
    </Box>
  );
};

export default ProjectsPage;
