import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';

const MonthYearSelector = ({
  currentDate,
  handleMonthChange,
  handleYearChange,
}) => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Typography variant='h6' component='span' sx={{ margin: '0 10px' }}>
          {currentDate.toLocaleString('default', { month: 'short' })}
        </Typography>
        <Box display='flex' flexDirection='column' sx={{ gap: 0 }}>
          <IconButton
            onClick={() => handleMonthChange(1)}
            size='small'
            sx={{ padding: '1px' }}
          >
            <ArrowDropUp />
          </IconButton>
          <IconButton
            onClick={() => handleMonthChange(-1)}
            size='small'
            sx={{ padding: '1px' }}
          >
            <ArrowDropDown />
          </IconButton>
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography variant='h6' component='span' sx={{ marginLeft: '20px' }}>
            {currentDate.getFullYear()}
          </Typography>
          <Box display='flex' flexDirection='column' sx={{ gap: 0 }}>
            <IconButton
              onClick={() => handleYearChange(1)}
              size='small'
              sx={{ padding: '1px' }}
            >
              <ArrowDropUp />
            </IconButton>
            <IconButton
              onClick={() => handleYearChange(-1)}
              size='small'
              sx={{ padding: '1px' }}
            >
              <ArrowDropDown />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

MonthYearSelector.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  handleMonthChange: PropTypes.func.isRequired,
  handleYearChange: PropTypes.func.isRequired,
};

export default MonthYearSelector;
