import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  Menu,
  MenuItem,
  Snackbar,
  SnackbarContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'; // Changed to outlined icon
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; // Changed to outlined icon
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styleProps } from '../../styles/styleProps';
import format from 'date-fns/format';

const { fonts } = styleProps;

const HolidaySettingsPanel = ({ isOpen, onClose }) => {
  const [selectedTab, setSelectedTab] = useState('add');
  const [holidayNameInputs, setHolidayNameInputs] = useState(['']);
  const [holidayDateInputs, setHolidayDateInputs] = useState([null]);
  const [holidays, setHolidays] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setHolidayNameInputs(['']);
      setHolidayDateInputs([null]);
      setEditIndex(-1);
    }
  }, [isOpen]);

  const handleDateChange = (index) => (newValue) => {
    const updatedDates = [...holidayDateInputs];
    updatedDates[index] = newValue;
    setHolidayDateInputs(updatedDates);
  };

  const handleNameChange = (index) => (e) => {
    const updatedNames = [...holidayNameInputs];
    updatedNames[index] = e.target.value;
    setHolidayNameInputs(updatedNames);
  };

  const handleAddNew = () => {
    setHolidayNameInputs([...holidayNameInputs, '']);
    setHolidayDateInputs([...holidayDateInputs, null]);
  };

  const handleClose = () => {
    onClose();
  };

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setEditIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    if (editIndex >= 0) {
      setHolidayNameInputs([holidays[editIndex].name]); // Set input field to the current holiday name
      setHolidayDateInputs([holidays[editIndex].date]); // Set input field to the current holiday date
      setSelectedTab('add');
      handleCloseMenu();
    }
  };

  const handleSaveEdit = () => {
    if (editIndex >= 0) {
      const updatedHolidays = [...holidays];
      updatedHolidays[editIndex] = {
        name: holidayNameInputs[0],
        date: holidayDateInputs[0],
      };
      setHolidays(updatedHolidays);
      handleCloseMenu();
      setEditIndex(-1); // Reset edit index
      setHolidayNameInputs(['']);
      setHolidayDateInputs([null]);
      setSelectedTab('view');
      showSnackbar('Holiday updated successfully!');
    }
  };

  const handleDelete = () => {
    if (editIndex >= 0) {
      const updatedHolidays = holidays.filter(
        (_, index) => index !== editIndex,
      );
      setHolidays(updatedHolidays);
      handleCloseMenu();
      setEditIndex(-1);
      showSnackbar('Holiday deleted successfully!');
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isAddEnabled = () => {
    return holidayNameInputs[0] && holidayDateInputs[0] !== null;
  };

  const isPartiallyFilled = () => {
    return holidayNameInputs.some(
      (name, index) =>
        (name && !holidayDateInputs[index]) ||
        (!name && holidayDateInputs[index]),
    );
  };

  const handleSubmit = () => {
    const newHolidays = holidayNameInputs
      .map((name, index) => ({
        name,
        date: holidayDateInputs[index],
      }))
      .filter((holiday) => holiday.name && holiday.date);

    setHolidays([...holidays, ...newHolidays]);
    setHolidayNameInputs(['']);
    setHolidayDateInputs([null]);
    showSnackbar('Holidays added successfully!');
  };

  return (
    <>
      {isOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={handleClose}
        />
      )}

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: isOpen ? 0 : '-100%',
          width: '620px',
          height: '100%',
          backgroundColor: '#FFFFFF',
          zIndex: 1000,
          boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.5)',
          transition: 'right 0.3s ease',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            borderBottom: '1px solid #979797',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              fontFamily: fonts.primary,
              color: '#000',
              marginBottom: '16px',
            }}
          >
            Holiday Settings
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            marginBottom: '30px',
            marginTop: '10px',
          }}
        >
          <Button
            onClick={() => setSelectedTab('add')}
            sx={{
              width: '310px',
              height: '44px',
              backgroundColor: selectedTab === 'add' ? '#E6F0FD' : 'inherit',
              borderRadius: '8px 0px 0px 8px',
              border: '1px solid var(--Secondary-Grey, #979797)',
              fontFamily: fonts.primary,
              fontWeight: 500,
              color: '#000',
              textTransform: 'none',
              opacity: selectedTab === 'add' ? 1 : 0.7,
              boxShadow: 'none',
            }}
          >
            Add Holidays
          </Button>
          <Button
            onClick={() => setSelectedTab('view')}
            sx={{
              width: '310px',
              height: '44px',
              backgroundColor: selectedTab === 'view' ? '#E6F0FD' : 'inherit',
              borderRadius: '0px 8px 8px 0px',
              border: '1px solid var(--Secondary-Grey, #979797)',
              fontFamily: fonts.primary,
              fontWeight: 500,
              color: '#000',
              textTransform: 'none',
              opacity: selectedTab === 'view' ? 1 : 0.7,
              boxShadow: 'none',
            }}
          >
            View Holidays
            {holidays.length > 0 && (
              <Typography
                sx={{
                  color: '#71717A',
                  fontSize: '14px',
                  marginLeft: '10px',
                }}
              >
                ({holidays.length})
              </Typography>
            )}
          </Button>
        </Box>

        {selectedTab === 'add' && (
          <Box>
            {holidayNameInputs.map((name, index) => (
              <Box
                display='flex'
                alignItems='center'
                gap={2}
                marginBottom={2}
                key={index}
              >
                <FormControl variant='outlined' sx={{ width: '300px' }}>
                  <InputLabel htmlFor={`holiday-name-${index}`}>
                    Holiday Name
                  </InputLabel>
                  <OutlinedInput
                    id={`holiday-name-${index}`}
                    value={name}
                    onChange={handleNameChange(index)}
                    label='Holiday Name'
                    sx={{
                      background: '#FFFFFF',
                      border: '1px #7F8489',
                      borderRadius: '5px',
                    }}
                  />
                </FormControl>
                <FormControl variant='outlined' sx={{ width: '300px' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Select Date'
                      value={holidayDateInputs[index]}
                      onChange={handleDateChange(index)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          sx={{
                            background: '#FFFFFF',
                            border: '1px solid #7F8489',
                            borderRadius: '8px',
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
            ))}

            <Box display='flex' alignItems='center' marginBottom={2}>
              <IconButton
                sx={{
                  background: '#0666EB',
                  width: '19.5px',
                  height: '19.5px',
                  borderRadius: '50%',
                  opacity: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleAddNew}
              >
                <AddIcon sx={{ color: 'white', fontSize: '14px' }} />
              </IconButton>
              <Typography
                sx={{
                  color: '#0666EB',
                  fontFamily: 'Readex Pro',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.17px',
                  marginLeft: '8px',
                }}
              >
                Add New
              </Typography>
            </Box>
          </Box>
        )}

        {selectedTab === 'view' && (
          <Box>
            {holidays.length === 0 ? (
              <Typography>No holidays added yet.</Typography>
            ) : (
              holidays.map((holiday, index) => (
                <Box
                  key={index}
                  display='flex'
                  justifyContent='space-between'
                  sx={{ borderBottom: '1px solid #ddd', padding: '10px 0' }}
                >
                  <Typography>{holiday.name}</Typography>
                  <Box display='flex' alignItems='center'>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Typography sx={{ marginBottom: 0 }}>
                        {format(new Date(holiday.date), 'dd MMM')}
                      </Typography>
                      <Typography
                        sx={{
                          color: '#71717A',
                          marginBottom: 0,
                        }}
                      >
                        {format(new Date(holiday.date), 'EEE').slice(0, 3)}{' '}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={(e) => handleMenuClick(e, index)}
                      sx={{ color: '#30A3F7', marginLeft: '10px' }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))
            )}
          </Box>
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleEdit}>
            <EditOutlinedIcon sx={{ marginRight: 1 }} /> Edit
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <DeleteOutlineIcon sx={{ marginRight: 1 }} /> Delete
          </MenuItem>
        </Menu>

        {selectedTab === 'add' && (
          <Box
            display='flex'
            justifyContent='space-between'
            marginTop='auto'
            paddingBottom={5}
          >
            <Button
              variant='outlined'
              sx={{
                background: '#FFFFFF',
                border: '1px solid #8D8D95',
                width: '300px',
                height: '50px',
                borderRadius: '30px',
                opacity: 1,
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              disabled={!isAddEnabled() || isPartiallyFilled()}
              sx={{
                background: isAddEnabled() ? '#0666EB' : '#A0A0A0',
                color: '#FFFFFF',
                width: '300px',
                height: '50px',
                borderRadius: '30px',
              }}
              onClick={editIndex >= 0 ? handleSaveEdit : handleSubmit}
            >
              {editIndex >= 0 ? 'Save Changes' : 'Add'}
            </Button>
          </Box>
        )}

        {/* Snackbar for toast messages */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <SnackbarContent
            sx={{
              background: '#FEEDED',
              boxShadow: '0px 1px 1.41px 0px #00000033',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            message={
              <Typography variant='body2' color='black'>
                {snackbarMessage}
              </Typography>
            }
            action={
              <IconButton size='small' onClick={handleSnackbarClose}>
                <CloseIcon style={{ color: 'black' }} />
              </IconButton>
            }
          />
        </Snackbar>
      </Box>
    </>
  );
};

HolidaySettingsPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HolidaySettingsPanel;
