export const ADJUSTMENTS = Object.freeze({
  FUEL: 'FUEL',
  OVER_TIME: 'OVER_TIME',
  DAILY_PAID: 'DAILY_PAID',
  TRAVELING: 'TRAVELING',
  BONUS: 'BONUS',
  REIMBURSEMENT: 'REIMBURSEMENT',
  EPF: 'EPF',
  PAYEE_TAX: 'PAYEE_TAX',
  LOAN: 'LOAN',
  NO_PAY: 'NO_PAY',
  ADVANCE: 'ADVANCE',
});
