import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './week-styles.css';
import { v4 } from 'uuid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  addMonths,
  endOfMonth,
  endOfWeek,
  isSameWeek,
  isWeekend,
  isWithinInterval,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { getDaysInMonth } from 'date-fns/esm';
import { Box } from '@mui/material';
import { DAYS, MONTHS } from '../../../data/genericConstants';

export const WeekPicker = ({ onChange, date }) => {
  const [selectedDate, setSelectedDate] = useState(date);
  const [week, setWeek] = useState({
    firstDay: startOfWeek(date, { weekStartsOn: 1 }),
    lastDay: endOfWeek(date, { weekStartsOn: 6 }),
  });

  // TODO: will need to display current date
  // eslint-disable-next-line no-unused-vars
  const convertDate = (date) => {
    let dt = new Date(date);

    return `${dt.getDate()}.${dt.getMonth() + 1}.${dt.getFullYear()}.`;
  };

  const handleClick = (e) => {
    let localDate;
    if (e.target.id.includes('prev')) {
      localDate = new Date(selectedDate.setDate(1));
      setSelectedDate(new Date(selectedDate.setDate(1)));
    } else if (e.target.id.includes('next')) {
      localDate = new Date(selectedDate.setDate(getDaysInMonth(selectedDate)));
      setSelectedDate(
        new Date(selectedDate.setDate(getDaysInMonth(selectedDate))),
      );
    } else {
      localDate = new Date(selectedDate.setDate(e.target.id));
      setSelectedDate(new Date(selectedDate.setDate(e.target.id)));
    }
    const firstDay = startOfWeek(localDate, { weekStartsOn: 1 });
    const lastDay = endOfWeek(localDate, { weekStartsOn: 6 });
    setWeek(() => {
      const updatedValue = { firstDay, lastDay };
      onChange(updatedValue);
      return updatedValue;
    });
  };

  function isInLastWeekOfMonth(date) {
    const endOfMonthDate = endOfMonth(date);
    return (
      isWithinInterval(date, { start: endOfMonthDate, end: endOfMonthDate }) ||
      isSameWeek(date, endOfMonthDate)
    );
  }

  const letDateClickable = (currentDate) => {
    if (isWeekend(new Date(currentDate))) {
      return false;
    } else if (
      isInLastWeekOfMonth(currentDate) &&
      new Date(currentDate).getDay() !== 5
    ) {
      return false;
    } else {
      return true;
    }
  };

  const renderDays = () => {
    let month = selectedDate.getMonth() + 1;
    let ar = [];
    for (let i = 1; i <= DAYS[month]; i++) {
      let currentDate = new Date(selectedDate).setDate(i);

      let cName = 'single-number ';
      if (
        new Date(week.firstDay).getTime() <= new Date(currentDate).getTime() &&
        new Date(currentDate).getTime() <= new Date(week.lastDay).getTime()
      ) {
        cName = cName + 'selected-week';
      }

      ar.push(
        <Box
          key={v4()}
          id={i}
          className={cName}
          onClick={letDateClickable(currentDate) && handleClick}
        >
          {i}
        </Box>,
      );
    }

    const displayDate = new Date(selectedDate).setDate(1);
    let dayInTheWeek = new Date(displayDate).getDay();
    if (dayInTheWeek < 1) {
      dayInTheWeek = 7;
    }
    let prevMonth = [];
    let prevMonthDays = new Date(selectedDate).getMonth();
    if (prevMonthDays === 0) {
      prevMonthDays = 12;
    }
    for (let i = dayInTheWeek; i > 1; i--) {
      let previousMonth = new Date(selectedDate).setMonth(
        new Date(selectedDate).getMonth() - 1,
      );
      let currentDate = new Date(previousMonth).setDate(
        DAYS[prevMonthDays] - i + 2,
      );
      let cName = 'single-number other-month';
      let currentTime = new Date(currentDate).getTime();
      let firstTime = new Date(week.firstDay).getTime();
      let endTime = new Date(week.lastDay).getTime();
      if (currentTime >= firstTime && currentTime <= endTime) {
        cName = 'single-number selected-week';
      }

      prevMonth.push(
        <Box
          onClick={handleClick}
          key={v4()}
          id={'prev-' + i}
          className={cName}
        >
          {DAYS[prevMonthDays] - i + 2}
        </Box>,
      );
    }

    return [...prevMonth, ...ar];
  };

  const changeMonthByArrowClick = (next) => {
    let localDate = new Date(selectedDate);
    if (next) {
      localDate = addMonths(localDate, 1);
    } else {
      localDate = subMonths(localDate, 1);
    }
    setSelectedDate(new Date(localDate));
  };

  return (
    <Box className='week-picker-display'>
      <div className='week-picker-options'>
        <div className='title-week'>
          <Box
            onClick={() => changeMonthByArrowClick()}
            className='arrow-container'
          >
            <ArrowBackIosIcon sx={{ marginRight: '-10px' }} />
          </Box>
          {`${MONTHS[selectedDate.getMonth()]} ${selectedDate.getFullYear()}.`}
          <Box
            onClick={() => changeMonthByArrowClick(true)}
            className='arrow-container'
          >
            <ArrowForwardIosIcon />
          </Box>
        </div>
        <div className='numbers-container'>
          <div className='single-number day'>Mon</div>
          <div className='single-number day'>Tue</div>
          <div className='single-number day'>Wed</div>
          <div className='single-number day'>Thu</div>
          <div className='single-number day'>Fri</div>
          <div className='single-number day'>Sat</div>
          <div className='single-number day'>Sun</div>
        </div>
        <div className='numbers-container'>{renderDays()}</div>
      </div>
    </Box>
  );
};

WeekPicker.propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.DateCalendar,
};
