import React from 'react';
import Alert from '@mui/material/Alert';

// eslint-disable-next-line react/prop-types
const ErrorAlert = ({ text }) => {
  return (
    <div>
      <Alert severity='error' sx={{ mt: 2 }}>
        {text}
      </Alert>
    </div>
  );
};

export default ErrorAlert;
