import { Grid, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import PrimaryButton from '../../components/buttons/PrimaryButton';

import { styleProps } from '../../styles/styleProps';

const { colors } = styleProps;

const TitleText = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  paddingTop: 10,
  color: colors.textLightGrey,
});

const DetailText = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  paddingTop: 10,
  color: colors.textDarkGrey,
});

const navigateToReset = () => {
  window.location = '/passwordReset/loggedIn';
};

// eslint-disable-next-line react/prop-types
const PasswordReset = ({ enableOtpView }) => {
  return (
    <Grid sx={{ padding: '7%' }}>
      <TitleText>Reset Password</TitleText>
      <DetailText>Verify your account to reset password</DetailText>
      <PrimaryButton
        fullWidth
        styles={{ marginTop: '5%', fontWeight: 400 }}
        onClick={enableOtpView}
      >
        Verify Account
      </PrimaryButton>
      <Stack
        justifyContent={'center'}
        gap={'5px'}
        flexDirection={'row'}
        sx={{ paddingTop: '2%' }}
      >
        <Typography sx={style.resetText}>Forget password ?</Typography>
        <Typography
          sx={[style.resetText, { color: 'text.accent', cursor: 'pointer' }]}
          onClick={navigateToReset}
        >
          Reset
        </Typography>
      </Stack>
    </Grid>
  );
};

export default PasswordReset;

const style = {
  resetText: { fontWeight: 500, fontSize: '14px', color: colors.textLightGrey },
};
