/* eslint-disable react/prop-types */
import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Grid, TextField } from '@mui/material';
import { ADJUSTMENTS } from '../../../enums/PaystubRule';
import { createPaystubRule } from '../../../services/PaystubRuleService';
import { useParams } from 'react-router-dom';

const AdjustmentModal = ({ open, setOpen, type }) => {
  const [selectedCateogry, setSelectedCategory] = useState(null);
  const [amount, setAmount] = useState();
  const [isLoading, setIsloading] = useState(false);
  const params = useParams();
  const employeeId = params.id;

  const handleClose = () => {
    setOpen('');
    setSelectedCategory('');
    setAmount(0);
  };

  const handleSetAdjustment = async () => {
    if (amount <= 0) return;
    setIsloading(true);
    await createPaystubRule(employeeId, selectedCateogry, amount);
    setIsloading(false);

    handleClose();
  };

  const allowanceTypes = [
    {
      type: ADJUSTMENTS.FUEL,
      label: 'Fuel & Maintenance',
    },
    {
      type: ADJUSTMENTS.OVER_TIME,
      label: 'Over time',
    },
    {
      type: ADJUSTMENTS.DAILY_PAID,
      label: 'Daily Paid',
    },
    {
      type: ADJUSTMENTS.TRAVELING,
      label: 'Traveling',
    },
    {
      type: ADJUSTMENTS.BONUS,
      label: 'Bonus',
    },
    {
      type: ADJUSTMENTS.REIMBURSEMENT,
      label: 'Reimbursement',
    },
  ];

  const deducationTypes = [
    {
      type: ADJUSTMENTS.EPF,
      label: 'EPF',
    },
    {
      type: ADJUSTMENTS.PAYEE_TAX,
      label: 'Payee Tax',
    },
    {
      type: ADJUSTMENTS.LOAN,
      label: 'Loan',
    },
    {
      type: ADJUSTMENTS.NO_PAY,
      label: 'No Pay ',
    },
    {
      type: ADJUSTMENTS.ADVANCE,
      label: 'Advance ',
    },
  ];

  function categoryRenderer(type) {
    if (type === 'A') {
      return allowanceTypes.map((el) => (
        <Grid item xs={4} key={el.type}>
          <Box
            onClick={() => setSelectedCategory(el.type)}
            sx={itemStyle(el.type, selectedCateogry)}
          >
            {el.label}
          </Box>
        </Grid>
      ));
    } else {
      return deducationTypes.map((el) => (
        <Grid item xs={6} key={el.type}>
          <Box
            onClick={() => setSelectedCategory(el.type)}
            sx={itemStyle(el.type, selectedCateogry)}
          >
            {el.label}
          </Box>
        </Grid>
      ));
    }
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography mb={2} variant='h6' component='h2'>
            Add {type === 'A' ? 'Allowance' : 'Deductions'}
          </Typography>

          <Box sx={modalBody}>
            <Typography variant='h7' component='h7' gutterBottom>
              Payment Type
            </Typography>

            <Grid container spacing={2}>
              {categoryRenderer(type)}
            </Grid>

            <TextField
              label='Amount'
              value={amount}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setAmount(e.target.value);
                }
              }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button sx={[buttonStyles.main]} onClick={handleClose}>
                Cancel
              </Button>

              <Button
                sx={[buttonStyles.main, buttonStyles.inverted]}
                disabled={!selectedCateogry || isLoading}
                onClick={() => handleSetAdjustment()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AdjustmentModal;

const modalBody = {
  flexGrow: 1,
  gap: 2,
  display: 'flex',
  flexDirection: 'column',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '.5rem',
};

const itemStyle = (el, selectedCateogry) => ({
  height: '5rem',
  padding: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #71717A',
  borderRadius: '.4rem',
  textAlign: 'center',
  color: '#71717A',
  transition: '0.1s',
  '&:hover': {
    backgroundColor: '#96c2ff',
    border: '1px solid #0666EB',
    cursor: 'pointer',
  },
  ...(el === selectedCateogry && {
    backgroundColor: '#96c2ff',
    border: '1px solid #0666EB',
    color: 'black',
  }),
});

const buttonStyles = {
  main: {
    width: '8rem',
    margin: '0 .4rem',
    color: 'black',
    borderRadius: '30px',
    fontWeight: '600',
    paddingX: 3,
    paddingY: 1,
  },
  inverted: {
    backgroundColor: '#0666EB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0666EB',
      color: 'white',
    },
    ':disabled': {
      opacity: 0.3,
      color: '#fff',
    },
  },
};
