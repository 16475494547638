/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import { updateEmployeeDetails } from '../../../services/EmployeeService';
import { EmployeeType } from '../../../enums/Employee';
import { styleProps } from '../../../styles/styleProps';
import { hourlyRate } from '../../../data/genericConstants';
import { getCountryCodeFromMobileNumber } from '../../../utils/commonUtils';
import { PAY_STUB_STATUS } from '../../../enums/Paystub';

const { fonts, colors } = styleProps;

const TableComponnet = ({
  columns,
  data,
  selectedEmployees,
  setSelectedEmployees,
  setUpdateTableData,
}) => {
  // State to handle popup and hours for each consultant
  const [hoursWorked, setHoursWorked] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState(null);

  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.employeeDisplayId);
      setSelectedEmployees(new Set(newSelecteds));
      return;
    }
    setSelectedEmployees(new Set());
  };

  const handleCheckboxClick = (employeeDisplayId) => {
    const isChecked = selectedEmployees.has(employeeDisplayId);
    let newSelected = new Set(selectedEmployees);

    if (isChecked) {
      newSelected.delete(employeeDisplayId);
    } else {
      newSelected.add(employeeDisplayId);
    }
    setSelectedEmployees(newSelected);
  };

  const handleOpenDialog = (consultant) => {
    setSelectedConsultant(consultant);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleHoursChange = (event) => {
    const value = event.target.value;
    setHoursWorked({
      ...hoursWorked,
      [selectedConsultant.employeeDisplayId]: value,
    });
  };

  const handleSave = async () => {
    await updateConsultantSalary();
    setUpdateTableData(Date.now());
    setOpenDialog(false);
  };
  const convertPaystubEnums = (status) => {
    if (status === PAY_STUB_STATUS.PAYMENT_FAILED) {
      return 'Payment Failed';
    } else {
      return 'Un Paid';
    }
  };

  const updateConsultantSalary = async () => {
    const updatedSalary =
      hoursWorked[selectedConsultant.employeeDisplayId] * hourlyRate;

    const countryCode = getCountryCodeFromMobileNumber(
      selectedConsultant.employeeMobile,
    );

    const body = {
      addressOne: selectedConsultant.employeeAddressLineOne,
      addressTwo: selectedConsultant.employeeAddressLineTwo,
      city: selectedConsultant.employeeCity,
      country: countryCode.name || 'LK',
      currency: selectedConsultant.Currency.currencyDisplayId,
      designation: selectedConsultant.employeeDesignation,
      email: selectedConsultant.employeeWorkEmail,
      empCountry: selectedConsultant.employeeCountry,
      employeeEmployeeId: selectedConsultant.employeeEmployeeId,
      firstName: selectedConsultant.employeeFirstName,
      lastName: selectedConsultant.employeeLastName,
      mobile: selectedConsultant.employeeMobile,
      nic: selectedConsultant.employeeNic,
      postal: selectedConsultant.employeePostalCode,
      salary: updatedSalary,
      state: selectedConsultant.employeeState,
      type: selectedConsultant.employeeType,
    };
    await updateEmployeeDetails(body);
  };

  return (
    <>
      <Table stickyHeader sx={{ marginBottom: '80px' }}>
        <TableHead>
          <TableRow sx={{ border: 'none' }}>
            <TableCell align='left' sx={[tableHeaderStyles, { width: 10 }]}>
              <Checkbox
                onChange={handleSelectAllClick}
                checked={
                  data.length > 0 && selectedEmployees.size === data.length
                }
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                  '&.Mui-checked': { color: '#059669' },
                }}
              />
            </TableCell>
            {columns.map((headerName, index) => {
              return (
                <TableCell key={index} align='left' sx={tableHeaderStyles}>
                  {headerName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: 'none', backgroundColor: 'white' }}>
          {data.map((data, index) => {
            return (
              <>
                <TableRow
                  key={index}
                  ableRow
                  sx={{
                    backgroundColor:
                      data.employeeType === EmployeeType.Consultant
                        ? '#EBF5FF'
                        : '#F5F7F8',
                    border:
                      data.employeeType === EmployeeType.Consultant
                        ? '2px solid #007BFF'
                        : 'none',
                    '&:hover': {
                      backgroundColor:
                        data.employeeType === EmployeeType.Consultant
                          ? '#D6E6FF'
                          : '#E0E0E0',
                    },
                  }}
                >
                  <TableCell align='left' sx={[tableCellStyles, { width: 10 }]}>
                    <Checkbox
                      checked={selectedEmployees.has(data.employeeDisplayId)}
                      onChange={() =>
                        handleCheckboxClick(data.employeeDisplayId)
                      }
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                        '&.Mui-checked': { color: '#059669' },
                      }}
                    />
                  </TableCell>

                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeEmployeeId}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {(data.employeeFirstName ?? '') +
                      ' ' +
                      (data.employeeLastName ?? '')}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeWorkEmail}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeDesignation}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeType === EmployeeType.Consultant ? (
                      <div>
                        {/* Conditionally show 'Add Hours' or amount */}
                        {data.employeeSalary > 0 ? (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <span
                            role='button'
                            tabIndex={0}
                            style={{ cursor: 'pointer', color: '#007BFF' }}
                            onClick={() => handleOpenDialog(data)}
                          >
                            {data.Currency.currencyCode}{' '}
                            {nf.format(data.employeeSalary)}
                          </span>
                        ) : (
                          <Button
                            variant='text'
                            onClick={() => handleOpenDialog(data)}
                          >
                            Add Hours
                          </Button>
                        )}
                      </div>
                    ) : (
                      `${data.Currency.currencyCode} ${nf.format(
                        data.employeeSalary,
                      )}`
                    )}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {data.employeeMobile}
                  </TableCell>
                  <TableCell align='left' sx={tableCellStyles}>
                    {convertPaystubEnums(data.PayStubs[0].status)}
                  </TableCell>
                </TableRow>

                {/* This is the divider */}
                <TableRow
                  sx={{
                    backgroundColor: 'white',
                    height: '5px',
                  }}
                ></TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
      {/* Popup Dialog for Consultants */}
      {selectedConsultant && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            sx: { borderRadius: '10px' },
          }}
        >
          <DialogTitle sx={{ padding: '20px' }}>
            {hoursWorked[selectedConsultant.employeeDisplayId]
              ? 'Edit Hours'
              : 'Enter Hours'}
          </DialogTitle>
          <DialogContent sx={{ padding: '20px' }}>
            <TextField
              type='number'
              placeholder='Worked hours'
              value={hoursWorked[selectedConsultant.employeeDisplayId] || ''}
              onChange={handleHoursChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions sx={{ padding: '20px' }}>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSave} variant='contained'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default TableComponnet;

const tableCellStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: colors.textDarkGrey,
};

const tableHeaderStyles = {
  borderBottom: 'none',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  color: colors.textLightGrey,
};
