export const OFFER_LETTER_TYPE_INTERN = 'Intern';
export const OFFER_LETTER_TYPE_CONTRACT = 'Contract';
export const OFFER_LETTER_TYPE_FULL_TIME = 'Full-time';

export const OFFER_LETTER_STATUS_SENT_FOR_APPROVAL = 'sent_for_approval';
export const OFFER_LETTER_STATUS_APPROVED = 'approved';
export const OFFER_LETTER_STATUS_REJECTED = 'rejected';
export const OFFER_LETTER_STATUS_EXPIRED = 'expired';
export const OFFER_LETTER_STATUS_SENT_FOR_SIGNATURE = 'sent_for_signature';
export const OFFER_LETTER_STATUS_FULLY_EXECUTED = 'fully_executed';
