import PlatformIntegrationService from './AbstractPlatformIntegrationService';
import UrlRoute from '../configs/UrlRoute';

export const getCompanyCategories = async () => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    UrlRoute.GET_COMPANY_CATEGORIES,
  );
  return data.data;
};

export const fetchCompanyDetails = async (companyId) => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.GET_COMPANY}/${companyId}`,
  );
  return data.message;
};
