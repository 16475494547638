import PlatformIntegrationService from './AbstractPlatformIntegrationService';
import UrlRoute from '../configs/UrlRoute';

/**
 * Fetches time records for employees within a date range.
 * @param {String} fromDate - Start date in YYYY-MM-DD format.
 * @param {String} toDate - End date in YYYY-MM-DD format.
 * @returns {Array} - Array of employee time records.
 */
export const getTimeRecordsByDateRange = async (fromDate, toDate) => {
  try {
    const response = await PlatformIntegrationService.executeHttpGetRequest(
      `${UrlRoute.TIME_RECORDS}?from=${fromDate}&to=${toDate}`,
    );
    return response.message; // Should contain the time records for employees
  } catch (error) {
    console.error('Error fetching time records by date range:', error);
    return [];
  }
};
