import React, { createContext, useReducer, useContext } from 'react';
import { DM_STATUS, SELECTIVE_TABS } from '../../data/projectReportConstants';
import { getISOWeek } from 'date-fns';

// TODO: CBC_STATUS will be used later

// Action types
const SET_REPORTS = 'SET_REPORTS';
const SET_TAB = 'SET_TAB';
const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT';
const SET_DM_STATUS = 'SET_DM_STATUS';
const SET_WEEK_YEAR = 'SET_WEEK_YEAR';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_MODIFIED_REPORT = 'SET_MODIFIED_REPORT';

// Reducer function
const projectReportsReducer = (state, action) => {
  switch (action.type) {
    case SET_REPORTS:
      return { ...state, reports: action.payload };
    case SET_TAB:
      return { ...state, selectedTab: action.payload };
    case SET_SELECTED_REPORT:
      return { ...state, selectedReport: action.payload };
    case SET_DM_STATUS:
      return { ...state, dmStatus: action.payload };
    case SET_WEEK_YEAR:
      return { ...state, weekYear: action.payload };
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_MODIFIED_REPORT:
      return { ...state, modifiedReport: action.payload };
    default:
      return state;
  }
};

// Initial state
const date = new Date();

const initProjectReports = {
  reports: [],
  selectedTab: SELECTIVE_TABS.WEEKLY,
  selectedReport: null,
  dmStatus: DM_STATUS.ALL,
  weekYear: {
    week: getISOWeek(date),
    year: date.getFullYear(),
  },
  currentPage: 1,
  modifiedReport: null,
};

const ProjectReportsContext = createContext();

// Provider component
// eslint-disable-next-line react/prop-types
export const ProjectReportsProvider = ({ children }) => {
  const [projectReports, dispatch] = useReducer(
    projectReportsReducer,
    initProjectReports,
  );

  const setReports = (reports) => {
    dispatch({ type: SET_REPORTS, payload: reports });
  };

  const setTab = (selectedTab) => {
    dispatch({ type: SET_TAB, payload: selectedTab });
  };

  const setSelectedReport = (selectedReport) => {
    dispatch({ type: SET_SELECTED_REPORT, payload: selectedReport });
  };

  const setDmStatus = (dmStatus) => {
    dispatch({ type: SET_DM_STATUS, payload: dmStatus });
  };

  const setWeekYear = (weekYear) => {
    dispatch({ type: SET_WEEK_YEAR, payload: weekYear });
  };

  const setCurrentPage = (currentPage) => {
    dispatch({ type: SET_CURRENT_PAGE, payload: currentPage });
  };

  const setModifiedReport = (modifiedReport) => {
    dispatch({ type: SET_MODIFIED_REPORT, payload: modifiedReport });
  };

  const actions = {
    setReports,
    setTab,
    setSelectedReport,
    setDmStatus,
    setWeekYear,
    setCurrentPage,
    setModifiedReport,
  };

  return (
    <ProjectReportsContext.Provider value={{ projectReports, actions }}>
      {children}
    </ProjectReportsContext.Provider>
  );
};

// Custom hook for the context
export const useProjectReport = () => {
  const context = useContext(ProjectReportsContext);
  if (!context) {
    throw new Error(
      'useProjectReport must be used within the ProjectReportsContext',
    );
  }
  return context;
};
