import React, { useRef } from 'react';
import { Box, Button } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
const DrawSignatureTab = ({ onCapture }) => {
  const sigCanvas = useRef();

  const captureSignature = () => {
    const signatureImage = sigCanvas.current.toDataURL();
    onCapture(signatureImage);
  };

  return (
    <Box sx={{ backgroundColor: 'white', margin: 2, borderRadius: 2 }}>
      <SignatureCanvas
        penColor='black'
        canvasProps={signatureCanvaStyles}
        ref={sigCanvas}
        onEnd={captureSignature}
      />
      <Button sx={clearBtnStyles} onClick={() => sigCanvas.current.clear()}>
        Clear
      </Button>
    </Box>
  );
};

DrawSignatureTab.propTypes = {
  onCapture: PropTypes.func.isRequired,
};

export default DrawSignatureTab;
const clearBtnStyles = {
  marginLeft: 50,
  textTransform: 'none',
  fontSize: 15,
};

const signatureCanvaStyles = {
  width: 480,
  height: 100,
};
