import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

// eslint-disable-next-line react/prop-types
const Selector = ({ name, label, options, value, onChange }) => (
  <FormControl fullWidth sx={{ width: 200 }}>
    <InputLabel>{label}</InputLabel>
    <Select
      label={label}
      sx={{ borderRadius: '8px' }}
      value={value}
      onChange={onChange}
      name={name}
    >
      {options?.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

Selector.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Selector;
