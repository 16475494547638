import React, { useState } from 'react';
import { TextField, styled, Typography, Stack } from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import ErrorAlert from '../alerts/ErrorAlert';

const { fonts } = styleProps;

const TextInput = styled(TextField)({
  width: '65%',
  '& .MuiInputBase-root': {
    height: 50,
  },
  paddingTop: '5px',
  border: '1px',
});

const Label = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '16px',
  fontWeight: 400,
  paddingTop: 10,
});

const Currency = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '16px',
  fontWeight: 400,
  color: '#979797',
  paddingTop: 20,
});

// eslint-disable-next-line react/prop-types
const SalaryInputField = ({ value, setValue, label }) => {
  const [isError, setIsError] = useState(false);

  const handleSalary = () => {
    const regex = /^\d+(\.\d{1,10})?$/g;
    if (regex.test(value)) {
      setValue(parseFloat(value).toFixed(2));
      setIsError(false);
    } else {
      setValue('');
      setIsError(true);
    }
  };

  return (
    <>
      <Label>{label}</Label>
      <Stack spacing={1} direction='row'>
        <TextInput
          value={value}
          placeholder='0.00'
          type='number'
          onChange={(e) => {
            setValue(e.target.value);
            setIsError(false);
          }}
          onBlur={() => {
            if (!value) {
              setValue('');
              setIsError(false);
            } else {
              handleSalary();
            }
          }}
        />
        <Currency>LKR</Currency>
      </Stack>
      {isError && <ErrorAlert text={'Invalid Input'} />}
    </>
  );
};
export default SalaryInputField;
