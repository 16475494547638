import PlatformIntegrationService from './AbstractPlatformIntegrationService';
import UrlRoute from '../configs/UrlRoute';

export const createProject = async (body) => {
  const data = await PlatformIntegrationService.executeHttpPostRequest(
    UrlRoute.PROJECTS,
    body,
  );
  return data.message;
};

export const getProjects = async () => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    UrlRoute.PROJECTS,
  );
  return data.message;
};

export const updateProject = async (body) => {
  const updatedData = await PlatformIntegrationService.executeHttpPutRequest(
    UrlRoute.UPDATE_PROJECT,
    body,
  );
  return updatedData;
};
