import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ItemText } from './MenuItem';

const AccordionItem = styled(Accordion)({
  boxShadow: 'unset',
  backgroundColor: 'unset',
  width: '65%',
  height: 'auto',
  cursor: 'pointer',
  padding: '7.5px',
  bottom: '-6px',
  '&:before': {
    display: 'none',
  },
});

const AccordionSummaryItem = styled(AccordionSummary)({
  padding: 'unset',
});

const AccordionSummaryDetailsItem = styled(AccordionDetails)({
  padding: 'unset',
});

const NestedMenu = ({ groupMenu, children }) => {
  return (
    <AccordionItem>
      <AccordionSummaryItem expandIcon={<ExpandMoreIcon />} disableGutters>
        <groupMenu.icon sx={{ fontSize: '20px' }} />
        <ItemText>{groupMenu.group}</ItemText>
      </AccordionSummaryItem>
      <AccordionSummaryDetailsItem>{children}</AccordionSummaryDetailsItem>
    </AccordionItem>
  );
};

NestedMenu.propTypes = {
  groupMenu: PropTypes.shape({
    icon: PropTypes.elementType.isRequired,
    group: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default NestedMenu;
