import React from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { Box } from '@mui/material';

const MonthPicker = ({ onChange, date }) => {
  const dateHandler = (value) => {
    onChange(value);
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateCalendar
          sx={{
            background: '#ffffff',
            borderRadius: '5px',
            border: '1px solid #c3c0cc',
            height: 'fit-content',
            width: '338px',
            '& .MuiMonthCalendar-root': {
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          }}
          views={['year', 'month']}
          openTo='month'
          onChange={dateHandler}
          value={date}
        />
      </LocalizationProvider>
    </Box>
  );
};

MonthPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
};

export default MonthPicker;
