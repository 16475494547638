import { useState, useEffect } from 'react';
import { getEmployeeByStatus } from '../services/EmployeeService';
import { EmployeeStatus } from '../enums/Employee';

const useFetchEmployeeByStatus = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEmployeeByStatus(EmployeeStatus.ENABLED);
        if (!data?.employees) return;

        // eslint-disable-next-line no-unused-vars
        setEmployees((prev) =>
          data.employees.map((employee) => ({
            label: employee.employeeFirstName + ' ' + employee.employeeLastName,
            id: employee.employeeDisplayId,
          })),
        );
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchData();
  }, []);

  return employees;
};

export default useFetchEmployeeByStatus;
