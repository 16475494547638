import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const ErrorResponsePage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Grid
        container
        sx={{
          height: 'calc(100vh - 60px)',
        }}
      >
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '28vh',
            }}
          >
            <SentimentVeryDissatisfiedIcon
              sx={{
                fontSize: 200,
                color: '#0666EB',
              }}
            />
            <Typography
              align='center'
              sx={{ fontSize: '28px', color: '#0666EB', paddingTop: 3 }}
            >
              Sorry we are facing an unexpected error, We will be back soon !
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorResponsePage;
