// eslint-disable-next-line no-undef
module.exports = {
  PayrollStatus: Object.freeze({
    DRFT: 'D',
    PAID: 'P',
    PARTIALLY_PAID: 'PP',
    SCHEDULED: 'S',
    CANCELLED: 'C',
  }),
};
