/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Typography,
  Box,
} from '@mui/material';
import EmployeePaystubSettingsSearch from './EmployeePaystubSettingsSearch';
import { styleProps } from '../../styles/styleProps';
import closeCircle from '../../assets/images/closeCircle.png';

const { fonts } = styleProps;

const Label = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '16px',
  fontWeight: 400,
});

const HeaderLabel = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '16px',
  fontWeight: 500,
  color: '#71717A',
});

const Email = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '12px',
  fontWeight: 400,
  color: '#71717A',
});

// eslint-disable-next-line react/prop-types
const PayrollEmployeeTable = ({ setSelectedEmployees, selectedEmployees }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <EmployeePaystubSettingsSearch
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
        />
        <Paper sx={{ maxHeightheight: 250, overflow: 'show', marginRight: 30 }}>
          {selectedEmployees && selectedEmployees.length !== 0 && (
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderBottom: 'none' }}>
                      <HeaderLabel>Employee ID</HeaderLabel>
                    </TableCell>
                    <TableCell align='left' style={{ borderBottom: 'none' }}>
                      <HeaderLabel>Name</HeaderLabel>
                    </TableCell>
                    <TableCell align='left' style={{ borderBottom: 'none' }}>
                      <HeaderLabel>Salary</HeaderLabel>
                    </TableCell>
                    <TableCell align='left' style={{ borderBottom: 'none' }}>
                      <HeaderLabel>Action</HeaderLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedEmployees &&
                    selectedEmployees.map((data) => (
                      <TableRow
                        key={data.employeeEmployeeId}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          align='left'
                          style={{ borderBottom: 'none' }}
                        >
                          <Label>{data.employeeEmployeeId}</Label>
                        </TableCell>
                        <TableCell
                          align='left'
                          style={{ borderBottom: 'none' }}
                        >
                          <Label>
                            {(data.employeeFirstName ?? '') +
                              ' ' +
                              (data.employeeLastName ?? '')}
                          </Label>
                          <Email> {data.employeeWorkEmail}</Email>
                        </TableCell>
                        <TableCell
                          align='left'
                          style={{ borderBottom: 'none' }}
                        >
                          <Label>{data.employeeSalary}</Label>
                        </TableCell>
                        <TableCell
                          align='left'
                          style={{ borderBottom: 'none' }}
                        >
                          <Box>
                            <img
                              src={closeCircle}
                              alt='remove'
                              width='25px'
                              onClick={() => {
                                setSelectedEmployees(
                                  selectedEmployees.filter(
                                    (item) => item.id !== data.id,
                                  ),
                                );
                              }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PayrollEmployeeTable;
