import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { updateProject } from '../../../services/ProjectServices';

export default function UpdateProject(projectData) {
  const {
    visible,
    setVisible,
    project,
    updateHandler,
    defaultEmployees,
    availableEmployees,
  } = projectData;
  const initMembers = project.projectMembers.map(
    (employee) => employee.employeeId,
  );

  const [formData, setFormData] = useState({
    projectName: project.projectName,
    projectDescription: project.description,
    projectMembers: initMembers,
  });

  const [isEmployeesUpdated, setIsEmployeesUpdated] = useState(false);

  const handleClose = () => {
    setFormData({
      projectName: '',
      projectDescription: '',
      projectMembers: [],
    });
    setVisible(false);
  };

  const fieldHandler = (e, val) => {
    const { name, value } = e.target;
    val
      ? setFormData((prev) => ({
          ...prev,
          projectMembers: val.map((member) => member.id),
        }))
      : setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
  };

  useEffect(() => {
    checkForUpdatedMembers(initMembers, formData.projectMembers);
  }, [formData.projectMembers]);

  const checkForUpdatedMembers = (prevMembers, newMembers) => {
    const _newMembers = newMembers.filter(
      (item) => !prevMembers.includes(item),
    );
    const _removedMembers = prevMembers.filter(
      (item) => !newMembers.includes(item),
    );

    if (_newMembers.length || _removedMembers.length) {
      setIsEmployeesUpdated(true);
    }
  };

  const handleSave = async () => {
    const data = await updateProject({
      displayId: project.displayId,
      projectId: project.id,
      projectName: formData.projectName,
      description: formData.projectDescription,
      newProjectEmployeeDisplayIds: formData.projectMembers,
      isEmployeesUpdated: isEmployeesUpdated,
    });
    if (data) {
      updateHandler(data.message);
      handleClose();
    }
  };

  return (
    <div>
      <Modal open={visible} onClose={handleClose}>
        <Box sx={ModalStyle}>
          <Typography variant='h7' fontSize={'17px'} marginRight='auto'>
            Update project
          </Typography>

          <FormControl sx={{ gap: 2 }} fullWidth>
            <TextFieldStyle
              label='Project Name'
              size='small'
              name='projectName'
              value={formData.projectName}
              onChange={fieldHandler}
            />
            <TextFieldStyle
              multiline
              rows={4}
              name='projectDescription'
              label='Project Description'
              size='small'
              value={formData.projectDescription}
              onChange={fieldHandler}
            />
            <Autocomplete
              name='projectMembers'
              size='small'
              multiple
              options={availableEmployees}
              getOptionLabel={(option) => option.label}
              key={(option) => option.id}
              defaultValue={defaultEmployees.length ? defaultEmployees : []}
              filterSelectedOptions
              onChange={fieldHandler}
              renderInput={(params) => (
                <TextFieldStyle {...params} label='Projects Members' />
              )}
            />
          </FormControl>

          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              variant='outlined'
              sx={[styles.buttonStyle, styles.InvertedButtonStyle]}
              color='primary'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={styles.buttonStyle}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

const ModalStyle = {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '10px',
  gap: '1.5rem',
};

const styles = {
  buttonStyle: {
    margin: '0 .4rem',
    borderRadius: '30px',
    fontWeight: '600',
    paddingX: 3,
    paddingY: 1,
    width: '40%',
  },
  InvertedButtonStyle: {
    backgroundColor: '#fff',
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
};

const TextFieldStyle = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 7px;
  }
`;
