import React from 'react';
import { Box, styled, Typography, Stack, Button } from '@mui/material';
import { styleProps } from '../../../styles/styleProps';
import appleIcon from '../../../assets/images/logo-apple.svg';
import googleIcon from '../../../assets/images/logo-google-playstore.svg';

const { fonts, colors } = styleProps;

const EmployeeNavigationModal = () => {
  const TitleContainer = styled(Box)({
    width: '100%',
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const Title = styled(Typography)({
    fontFamily: fonts.primary,
    fontSize: '28px',
    fontWeight: 500,
    color: colors.textDarkGrey,
  });

  const SubTitle = styled(Typography)({
    fontFamily: fonts.primary,
    fontSize: 20,
    fontWeight: 400,
    color: colors.textLightGrey,
    textAlign: 'center',
  });

  const SubTitle2 = styled(Typography)({
    fontFamily: fonts.primary,
    fontSize: 18,
    fontWeight: 400,
    color: colors.textLightGrey,
    textAlign: 'center',
    marginTop: 60,
    marginLeft: 30,
    marginRight: 30,
  });
  const ButtonLargeText = styled(Typography)({
    fontFamily: fonts.primary,
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 400,
  });

  const ButtonSmallText = styled(Typography)({
    fontFamily: fonts.primary,
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 400,
  });

  const containerStyles = {
    width: '100%',
    minHeight: '500px',
    borderRadius: '10px',
    background: 'white',
    padding: '20px 0px',
  };

  const stackStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 7,
  };

  const primaryButtonStyle = {
    color: 'white',
    textTransform: 'none',
    borderRadius: '25px',
    width: '250px',
    padding: '7px 16px',
  };

  const grayButtonStyle = {
    color: 'white',
    textTransform: 'none',
    borderRadius: '25px',
    width: '250px',
    padding: '7px 16px',
    backgroundColor: '#C5C5C5',
  };

  const redirectToPlayStore = () => {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.nimi.nimikash';
  };

  return (
    <div style={containerStyles}>
      <TitleContainer>
        <Title>Congratulations !</Title>
        <SubTitle>Account created successfully </SubTitle>
      </TitleContainer>
      <SubTitle2>
        To unlock the full experience, download and set up NimiKASH mobile app
        on your phone.
      </SubTitle2>
      <Stack spacing={3} direction='column' sx={stackStyle}>
        <Button
          variant='contained'
          style={primaryButtonStyle}
          startIcon={<img src={googleIcon} alt='Play Store' />}
          onClick={redirectToPlayStore}
        >
          <Stack spacing={0} direction='column'>
            <ButtonSmallText>Available on the</ButtonSmallText>
            <ButtonLargeText>Google play </ButtonLargeText>
          </Stack>
        </Button>
        <Button
          variant='contained'
          style={grayButtonStyle}
          startIcon={<img src={appleIcon} alt='googleIcon' />}
        >
          <Stack spacing={0} direction='column'>
            <ButtonSmallText>Coming soon to the</ButtonSmallText>
            <ButtonLargeText>App Store </ButtonLargeText>
          </Stack>
        </Button>
      </Stack>
    </div>
  );
};

export default EmployeeNavigationModal;
