import UrlRoute from '../configs/UrlRoute';
import PlatformIntegrationService from './AbstractPlatformIntegrationService';

const OfferLetterTemplateService = {
  getOfferLetterTemplate: async (id) => {
    const response = await PlatformIntegrationService.executeHttpGetRequest(
      `${UrlRoute.OFFER_LETTER_TEMPLATES}/${id}`,
    );
    return response.data;
  },

  listOfferLetterTemplates: async () => {
    const response = await PlatformIntegrationService.executeHttpGetRequest(
      UrlRoute.OFFER_LETTER_TEMPLATES,
    );
    return response.data;
  },
};

export default OfferLetterTemplateService;
