import React from 'react';
import { Button, styled } from '@mui/material';

import { styleProps } from '../../styles/styleProps';

const { fonts, colors } = styleProps;

const ProceedButton = styled(Button)({
  fontFamily: fonts.primary,
  fontSize: 16,
  fontWeight: 500,
  minWidth: 180,
  height: 50,
  padding: 5,
  borderRadius: 30,
  borderColor: colors.btnPrimary,
  color: colors.textPrimary,
  textTransform: 'none',
  ':disabled': {
    opacity: 0.3,
    color: '#fff',
  },
  marginRight: 20,
});

// eslint-disable-next-line react/prop-types
const ShortEmployeeSecondaryButton = ({ text, onClick, disabled }) => {
  return (
    <ProceedButton disabled={disabled} onClick={onClick} variant='outlined'>
      {text}
    </ProceedButton>
  );
};

export default ShortEmployeeSecondaryButton;
