import React, { useState, useEffect } from 'react';
import TopBar from '../components/top-bar/TopBar';
import { getAllPayrolls } from '../services/PayRollService';
import { getCurrentAdmin } from '../services/AdminService';

import DashboardSummary from '../components/dashboard/DashboardSummary/DashboardSummary';
import PaymentOverviewChart from '../components/dashboard/PaymentOverviewChart/PaymentOverviewChart';
import PayrollRunHistory from '../components/dashboard/PayrollRunHistory/PayrollRunHistory';
import CriticalPayrollAction from '../components/dashboard/CriticalPayrollAction/CriticalPayrollAction';
import PayrollUpdatesLog from '../components/dashboard/PayrollUpdatesLog/PayrollUpdatesLog';

import { Box } from '@mui/material';

function generateLast6MonthsLabels() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const last6MonthsLabels = [];
  for (let i = 6; i >= 1; i--) {
    const monthIndex = (currentMonth - i + 12) % 12;
    const year =
      currentMonth - i < 0
        ? currentDate.getFullYear() - 1
        : currentDate.getFullYear();
    const label = `${months[monthIndex]} ${year}`;
    last6MonthsLabels.push(label);
  }

  return last6MonthsLabels;
}
const DashBoard = () => {
  const [user, setUser] = useState('');
  const [paymentChartData, setPaymentChartData] = useState({
    labels: generateLast6MonthsLabels(),
    values: [10, 20, 30, 40, 50, 60],
  });

  useEffect(() => {
    getData();
    getPayrollsData();
  }, []);

  async function getPayrollsData() {
    const allPayrolls = await getAllPayrolls();

    // Filter payrolls based on the status attribute
    const filteredPayrolls = allPayrolls.message.filter(
      (payroll) =>
        payroll.status.includes('P') || payroll.status.includes('PP'),
    );
    if (filteredPayrolls) {
      // Extract month and year from lastPaymentTs and update paymentChartData
      const updatedValues = [];
      const last6MonthsLabels = generateLast6MonthsLabels();

      last6MonthsLabels.forEach((label) => {
        const [month, year] = label.split(' ');

        // Filter payrolls for the specific month and year
        const payrollsForMonth = filteredPayrolls.filter((payroll) => {
          const payrollMonth = new Date(
            payroll.payDate || payroll.lastPaymentTs,
          ).toLocaleString('en-US', { month: 'short' });
          const payrollYear = new Date(payroll.payDate || payroll.lastPaymentTs)
            .getFullYear()
            .toString();
          return payrollMonth === month && payrollYear === year;
        });

        // Calculate the sum of amounts for the month
        const totalAmount = payrollsForMonth.reduce(
          (sum, payroll) => sum + parseInt(payroll.amount),
          0,
        );
        updatedValues.push(totalAmount);
      });

      setPaymentChartData({
        labels: last6MonthsLabels,
        values: updatedValues,
      });
    }
  }

  async function getData() {
    setUser(await getCurrentAdmin());
  }

  return (
    <>
      <TopBar title='Dashboard' user={user} />
      <Box flex={1}>
        <Box
          style={{
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
          }}
        >
          <Box
            display='flex'
            flexDirection='row'
            margin='40px'
            justifyContent='space-between'
          >
            <DashboardSummary />
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            margin='40px'
            justifyContent='space-between'
            gap={'32px'}
          >
            <Box flex={1}>
              <PaymentOverviewChart data={paymentChartData} />
            </Box>
            <Box flex={1}>
              <PayrollRunHistory />
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            margin='40px'
            justifyContent='space-between'
            gap={'32px'}
          >
            <Box flex={1}>
              <CriticalPayrollAction />
            </Box>
            <Box flex={1}>
              <PayrollUpdatesLog />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashBoard;
