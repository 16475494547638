import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from '@mui/material';

const WeekNavigationButtons = ({
  handlePreviousWeek,
  handleNextWeek,
  isFirstWeek,
  isLastWeek,
}) => {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      sx={{ marginBottom: '5px' }}
    >
      <Button onClick={handlePreviousWeek} disabled={isFirstWeek}>
        Previous Week
      </Button>
      <Button onClick={handleNextWeek} disabled={isLastWeek}>
        Next Week
      </Button>
    </Box>
  );
};

WeekNavigationButtons.propTypes = {
  handlePreviousWeek: PropTypes.func.isRequired,
  handleNextWeek: PropTypes.func.isRequired,
  isFirstWeek: PropTypes.bool.isRequired,
  isLastWeek: PropTypes.bool.isRequired,
};

export default WeekNavigationButtons;
