import React, { useEffect, useState } from 'react';
import TopBar from '../components/top-bar/TopBar';
import { Box } from '@mui/material';
import { getCurrentAdmin } from '../services/AdminService';
const HybridSchedulePage = () => {
  const [user, setUser] = useState('');
  useEffect(() => {
    getCurrentAdmin().then((data) => {
      setUser(data);
    });
  }, []);

  return (
    <>
      <TopBar title={'Hybrid'} user={user} />
      <Box flex={1}></Box>
    </>
  );
};

export default HybridSchedulePage;
