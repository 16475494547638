import UrlRoute from '../configs/UrlRoute';
import PlatformIntegrationService from './AbstractPlatformIntegrationService';

export const getAllPayrolls = async () => {
  const res = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.PAYROLLS}`,
  );
  return res;
};

export const getPayRollById = async (displayId) => {
  const res = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.PAYROLLS}/${displayId}`,
  );
  return res;
};
export const getPayRollByDate = async (startDate, endDate) => {
  const res = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.PAYROLLS}?startDate=${startDate}&endDate=${endDate}`,
  );
  return res;
};

export const getAllPayRollsByYearAndMonth = async (year, month) => {
  const res = await PlatformIntegrationService.executeHttpGetRequest(
    `${UrlRoute.PAYROLLS}?year=${year}&month=${month}`,
  );
  return res;
};

export const savePayroll = async (data) => {
  const res = await PlatformIntegrationService.executeHttpPostRequest(
    `${UrlRoute.PAYROLLS}`,
    data,
  );
  return res;
};

export const deletePayroll = async (displayId) => {
  return await PlatformIntegrationService.executeHttpDeleteRequest(
    `${UrlRoute.PAYROLLS}/${displayId}`,
  );
};
