/* eslint-disable react/prop-types */
import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { styleProps } from '../../styles/styleProps';

const { colors, fonts } = styleProps;

function AccountDetails({ accounts }) {
  function truncateText(text) {
    return text.length <= 17 ? text : text.slice(0, 17) + '...';
  }

  function convertCamelCaseToSentence(camelCaseWord) {
    // Split the word by capital letters
    const words = camelCaseWord.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join with a space
    const sentence = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return sentence;
  }

  return (
    <>
      {accounts && accounts.localBankAccount != null ? (
        <>
          <Grid sx={{ marginTop: '30px', marginBottom: '20px' }}>
            <TypographyStyle2
              sx={{
                marginTop: '2%',
                fontWeight: 400,
                fontSize: '14px',
              }}
            >
              Net salary propositions
            </TypographyStyle2>
          </Grid>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={10}>
              {Object.keys(accounts).map((key) => (
                <Grid item key={key} xs={5}>
                  <Grid sx={accountGridStyle}>
                    <PrimaryAccount>
                      {convertCamelCaseToSentence(key)}
                    </PrimaryAccount>

                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <BankAccountDetail>Bank Name</BankAccountDetail>

                        <BankAccountDetail>Name</BankAccountDetail>
                        <BankAccountDetail>Account No</BankAccountDetail>
                        <BankAccountDetail>Send Salary</BankAccountDetail>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <BankAccountTitle sx={{ color: colors.textDarkGrey }}>
                          {truncateText(accounts[key].accountBankName)}
                        </BankAccountTitle>

                        <BankAccountTitle sx={{ color: colors.textDarkGrey }}>
                          {truncateText(accounts[key].accountOwnerName)}
                        </BankAccountTitle>
                        <BankAccountTitle sx={{ color: colors.textDarkGrey }}>
                          {' '}
                          {accounts[key].accountNumber}
                        </BankAccountTitle>
                        <BankAccountTitle sx={{ color: colors.btnPrimary }}>
                          {accounts[key].electedFundTransferPercentage}%
                        </BankAccountTitle>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      ) : null}
    </>
  );
}

export default AccountDetails;

const BankAccountDetail = styled(Typography)({
  color: colors.textLightGrey,
  fontWeight: 400,
  fontSize: '16px',
  paddingTop: 8,
  fontFamily: fonts.primary,
});

const BankAccountTitle = styled(Typography)({
  fontFamily: fonts.primary,
  fontWeight: 'bold',
  fontSize: '16px',
  textAlign: 'right',
  paddingTop: 8,
});

const TypographyStyle2 = styled(Typography)({
  color: colors.textLightGrey,
  fontFamily: fonts.primary,
});

const accountGridStyle = {
  backgroundColor: '#F5F7F8',
  p: 3,
  height: '250px',
};

const PrimaryAccount = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '16px',
});
