/* eslint-disable react/prop-types */
import React from 'react';
import { Page, Text, Document, StyleSheet } from '@react-pdf/renderer';
const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  mainText: {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 900,
  },
  subtitle: {
    fontSize: '12px',
    color: '#71717A',
  },
  topTitle: {
    fontSize: '22px',
    textAlign: 'center',
    font: '#2A2A2A',
  },
  dateStyle: {
    fontSize: '14px',
    textAlign: 'center',
  },
  subDateStyle: {
    fontSize: '12px',
    textAlign: 'center',
  },

  subtext: {
    fontSize: '10px',
    textAlign: 'center',
  },

  text: {
    margin: 10,
    fontSize: 12,
    textAlign: 'justify',
  },

  infoText: {
    fontSize: '12px',
    textAlign: 'left',
  },
});
//hi
// Create Document Component
const PdfFormat = ({ data, previousMonthPayDate, PayDate }) => (
  <>
    <Document>
      <Page style={styles.body}>
        <div>
          <Text style={styles.topTitle}>Nimi Tech Limited</Text>
        </div>

        <Text style={{ ...styles.subtext, color: '#71717A' }}>
          {' '}
          37th Floor , World Trade Centre, Bank of Ceylon Mawatha, Colombo, Sri
          Lanka
        </Text>

        <Text
          style={{
            ...styles.subtext,
            marginBottom: 18,
            color: '#71717A',
            textAlign: 'center',
          }}
        >
          {'core@nimidev.com        +94113456783'}
        </Text>

        <div
          style={{
            backgroundColor: '#F5F7F8',
            marginTop: 8,
            marginBottom: 8,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Text style={{ ...styles.mainText, fontWeight: 900 }}>
            Pay Advice
          </Text>
        </div>

        <div>
          <Text style={styles.dateStyle}>
            Employee Name :{' '}
            {data.employeeFirstName || data.employeeLastName
              ? (data.employeeFirstName ?? '') +
                ' ' +
                (data.employeeLastName ?? '')
              : data.employeePrimaryEmail}
          </Text>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.subDateStyle}>
              <Text style={{ color: '#71717A' }}>Designation : </Text>
              {data.employeeDesignation}
            </Text>
          </div>
          <div style={{}}>
            <Text style={styles.subDateStyle}>
              <Text style={{ color: '#71717A' }}>Employee ID : </Text>
              {data.employeeEmployeeId}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.subDateStyle}>
              <Text style={{ color: '#71717A' }}>Date of issue : </Text>
              {data.updatedDate.substring(0, 10)}
            </Text>
          </div>
          <div style={{}}>
            <Text style={styles.subDateStyle}>
              <Text style={{ color: '#71717A' }}>Pay Period : </Text>
              {previousMonthPayDate}-{PayDate}
            </Text>
          </div>
        </div>

        <div
          style={{
            flexDirection: 'row',
            backgroundColor: '#F5F7F8',
            marginTop: 18,
            padding: 10,
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <Text style={styles.infoText}>Earnings</Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.infoText}>Basic Salary - A</Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeeBasicSalary === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeBasicSalary)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeBasicSalary?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.infoText}>Allowances - B</Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1, paddingLeft: 35 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              Fuel & Maintenance
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeeAllowanceFuel === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeAllowanceFuel)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeAllowanceFuel?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1, paddingLeft: 35 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              Travelling
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeeAllowanceTravel === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeAllowanceTravel)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeAllowanceTravel?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1, paddingLeft: 35 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              Daily Paid
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeeAllowanceDailyPay === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeAllowanceDailyPay)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeAllowanceDailyPay?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1, paddingLeft: 35 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>OT</Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeeAllowanceOt === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeAllowanceOt)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeAllowanceOt?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.infoText}>Reimbursement Expenses - C</Text>
          </div>
          <div style={{}}>
            <Text style={styles.infoText}>
              {data.employeeReimbursements === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeReimbursements)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeReimbursements?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div
          style={{
            flexDirection: 'row',
            backgroundColor: '#97979729',
            padding: 5.6,
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.infoText}>Total Earnings</Text>
          </div>
          <div style={{}}>
            <Text style={styles.infoText}>
              {data.employeeTotalEarnings === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeTotalEarnings)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeTotalEarnings?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.infoText}>No Pay - D</Text>
          </div>
          <div style={{}}>
            <Text style={styles.infoText}>
              {data.employeeNoPay === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeNoPay)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeNoPay?.toLocaleString()}
            </Text>
          </div>
        </div>
        <div
          style={{
            flexDirection: 'row',
            backgroundColor: '#97979729',
            padding: 5.6,
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.infoText}>Gross Salary (A-D)</Text>
          </div>
          <div style={{}}>
            <Text style={styles.infoText}>
              {data.employeeGrossSalary === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeGrossSalary)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeGrossSalary?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div
          style={{
            flexDirection: 'row',
            backgroundColor: '#F5F7F8',
            marginTop: 18,
            padding: 5.6,
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <Text style={styles.infoText}>Deductions</Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1, paddingLeft: 35 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              EPF 8%
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeeDeductionEpf === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeDeductionEpf)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeDeductionEpf?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1, paddingLeft: 35 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              Advance
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeeDeductionAdvance === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeDeductionAdvance)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeDeductionAdvance?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1, paddingLeft: 35 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              Payee Tax
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeePayeeTax === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeePayeeTax)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeePayeeTax?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1, paddingLeft: 35 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              Loan
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employeeDeductionLoan === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeDeductionLoan)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeDeductionLoan?.toLocaleString()}
            </Text>
          </div>
        </div>
        <div
          style={{
            flexDirection: 'row',
            backgroundColor: '#97979729',
            padding: 5.6,
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <Text style={styles.infoText}>Net Salary</Text>
          </div>
          <div style={{}}>
            <Text style={styles.infoText}>
              {data.employeeNetSalary === 0
                ? data.employeeCurrencyCode +
                  ' ' +
                  nf.format(data.employeeNetSalary)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employeeNetSalary?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              EPF 12%
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employerEpf === 0
                ? data.employeeCurrencyCode + ' ' + nf.format(data.employerEpf)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employerEpf?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              ETF 3%
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.employerEtf === 0
                ? data.employeeCurrencyCode + ' ' + nf.format(data.employerEtf)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.employerEtf?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              Total for EPF contribution
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.totalEpf === 0
                ? data.employeeCurrencyCode + ' ' + nf.format(data.totalEpf)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.totalEpf?.toLocaleString()}
            </Text>
          </div>
        </div>

        <div style={{ flexDirection: 'row', padding: 5.6 }}>
          <div style={{ flexGrow: 1 }}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              Total for ETF contribution
            </Text>
          </div>
          <div style={{}}>
            <Text style={{ ...styles.subDateStyle, color: '#71717A' }}>
              {data.totalEtf === 0
                ? data.employeeCurrencyCode + ' ' + nf.format(data.totalEtf)
                : data.employeeCurrencyCode +
                  ' ' +
                  data.totalEtf?.toLocaleString()}
            </Text>
          </div>
        </div>
      </Page>
    </Document>
  </>
);

export default PdfFormat;
