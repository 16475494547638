import React, { useState, useEffect } from 'react';
import { Box, Grid, Alert } from '@mui/material';

import BackgroundImage from '../assets/images/img_nimikash_background.svg';
import LogoWhite from '../assets/images/img_nimikash_logo_white.png';
import LoginForm from '../components/login-form/LoginForm';

//Styles and styled components
const backgroundStyles = {
  minHeight: '100vh',
  background: `linear-gradient(0deg, rgba(6, 102, 235, 0.3), rgba(6, 102, 235, 0.3)), url(${BackgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};

const flexStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const LoginPage = () => {
  const [isSessionExpired, setIsSessionExpired] = useState('');

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (localStorage.getItem('sessionValid') != null) {
      setIsSessionExpired(localStorage.getItem('sessionValid'));
    }
  }, []);

  const removeSessionWaring = () => {
    setIsSessionExpired('true');
    localStorage.removeItem('sessionValid');
  };

  return (
    <div style={backgroundStyles}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            height: '15vh',
            ...flexStyle,
            marginBottom: `${isSessionExpired === 'false' ? 0 : 5}`,
            marginTop: 5,
          }}
        >
          <Box sx={flexStyle}>
            <img src={LogoWhite} alt='logo-white' width='250px' />
          </Box>
        </Grid>
        {isSessionExpired === 'false' ? (
          <Grid
            xs={12}
            sx={{
              height: '10vh',
              ...flexStyle,
              mb: 8,
              marginBottom: 0,
            }}
          >
            <Alert
              sx={{ width: '32vw', height: '38px' }}
              onClose={removeSessionWaring}
              severity='error'
            >
              Session has timed out. Please log in again.
            </Alert>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sx={{
            ...flexStyle,
            alignItems: 'flex-start',
          }}
        >
          <Grid
            item
            xs={9}
            md={5}
            lg={4}
            sx={{
              height: 'auto',
            }}
          >
            <LoginForm />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
