import * as yup from 'yup';
import PropTypes from 'prop-types';

const CreateYupSchema = (inputFields) => {
  if (!inputFields || !inputFields.length) {
    return yup.object().shape({});
  }

  const schemaObject = inputFields.reduce((schema, inputField) => {
    const { placeholder, type, required, validations = [] } = inputField;
    let validator;

    switch (type) {
      case 'email':
        validator = yup.string().email('Invalid email address');
        break;
      case 'date':
        validator = yup.date('Invalid date');
        break;
      case 'currency':
      case 'phone':
        validator = yup.number().typeError('Invalid format');
        break;
      default:
        validator = yup.string();
        break;
    }

    if (required) {
      validator = validator.required('Required');
    } else {
      validator = validator.optional();
    }

    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });

    schema[placeholder] = validator;

    return schema;
  }, {});

  return yup.object().shape(schemaObject);
};

export default CreateYupSchema;

CreateYupSchema.propTypes = {
  inputFields: PropTypes.array.isRequired,
};
