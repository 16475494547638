import PlatformIntegrationService from './AbstractPlatformIntegrationService';
import UrlRoute from '../configs/UrlRoute';

export const addSignature = async (body) => {
  const data = await PlatformIntegrationService.executeHttpPostRequest(
    UrlRoute.SAVE_SIGNATURE,
    body,
  );
  return data;
};
export const getSignatureDetails = async () => {
  const data = await PlatformIntegrationService.executeHttpGetRequest(
    UrlRoute.GET_SIGNATURE_DETAILS,
  );
  return data.message;
};
