import React from 'react';
import {
  Drawer,
  IconButton,
  Typography,
  Divider,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { styleProps } from '../../../styles/styleProps';

import PropTypes from 'prop-types';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

const OfferLetterReviewRejectDrawer = ({
  open,
  onClose,
  onRejectSubmit,
  rejectionReason,
  setRejectionReason,
}) => {
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      sx={{ width: '480px' }}
    >
      <Box
        sx={{
          width: '480px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Typography sx={headerTextStyle}>Rejection reason</Typography>
        <Divider sx={{ marginY: 3 }} />

        <TextField
          fullWidth
          multiline
          rows={10}
          sx={{
            border: '1px solid #979797',
            borderRadius: '8px',
          }}
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />

        <Box sx={{ flex: 1 }} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 2,
          }}
        >
          <SecondaryButton onClick={onClose} styles={submitCancelBtnStyle}>
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={onRejectSubmit} styles={submitCancelBtnStyle}>
            Submit
          </PrimaryButton>
        </Box>

        <IconButton
          edge='end'
          color='inherit'
          onClick={onClose}
          sx={{ position: 'absolute', top: '16px', right: '24px' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};
OfferLetterReviewRejectDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRejectSubmit: PropTypes.func.isRequired,
  rejectionReason: PropTypes.string.isRequired,
  setRejectionReason: PropTypes.func.isRequired,
};
export default OfferLetterReviewRejectDrawer;

const headerTextStyle = {
  fontFamily: styleProps.fonts.primary,
  fontWeight: 500,
  fontSize: '20px',
  color: styleProps.colors.textDarkGrey,
};
const submitCancelBtnStyle = {
  width: '218px',
  height: '44px',
  borderRadius: '100px',
  fontWeight: 600,
  fontSize: '16px',
  fontFamily: styleProps.fonts.primary,
  textTransform: 'none',
  padding: '10px 18px 10px 18px',
  border: '1px solid #979797',
};
