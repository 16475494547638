import React from 'react';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import LogoWhite from '../assets/images/img_nimikash_logo.png';
import OtpMobile from '../components/reset-password-flow/OtpMobile';

//Styles and styled components
const backgroundStyles = {
  minHeight: '100vh',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};

const flexStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const PasswordReset = () => {
  //get type from params
  const params = useParams();
  const userStatus = params.type;

  return (
    <div style={backgroundStyles}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            height: '20vh',
            ...flexStyle,
            mb: 8,
          }}
        >
          <Box sx={flexStyle}>
            <img src={LogoWhite} alt='logo-white' width='250px' />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            ...flexStyle,
            alignItems: 'flex-start',
            marginTop: -10,
          }}
        >
          <Grid
            item
            xs={9}
            md={5}
            lg={4}
            sx={{
              height: 'auto',
            }}
          >
            <OtpMobile type={userStatus} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PasswordReset;
