/* eslint-disable react/prop-types */
import {
  Alert,
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import { styleProps } from '../../styles/styleProps';
import { EmployeeType } from '../../enums/Employee';
import phone from 'phone';
import { CountryCodes } from '../../data/countryCodes';

function BasicData({
  isEditable,
  formik,
  isNICInvalid,
  setIsNICInvalid,
  setIsEmailInvalid,
  setIsMobileNumberInvalid,
  allCurrencies,
  isEmailInvalid,
  isMobileNumberInvalid,
  setSelectedCurrency,
}) {
  const countryCodes = CountryCodes.countries;
  const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  //Only number input function
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  // Mobile number validation
  const verifyTheNumber = (code, number) => {
    if (number.substring(0, 1) === '0') {
      setIsMobileNumberInvalid(true);
    } else {
      const mobile = code + number;
      if (number && code) {
        phone(mobile).isValid
          ? setIsMobileNumberInvalid(false)
          : setIsMobileNumberInvalid(true);
      } else {
        setIsMobileNumberInvalid(true);
      }
    }
  };

  // display error message
  const displayAlert = (isError, errorMessage) => {
    if (isError) {
      return (
        <Box sx={{ my: 1 }}>
          <Alert severity='error'>{errorMessage}</Alert>
        </Box>
      );
    }
  };

  //NIC Number validation
  const validateNIC = (nicNumber) => {
    const nicFormat = /^\d{9}[vVxX]$|^\d{12}$/;
    if (nicNumber.match(nicFormat)) {
      setIsNICInvalid(false);
    } else {
      setIsNICInvalid(true);
    }
  };

  //email Vlaidation
  const validateEmail = (email) => {
    var mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailFormat)) {
      setIsEmailInvalid(false);
    } else {
      setIsEmailInvalid(true);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          marginTop: '23px',
          padding: '2%',
        }}
      >
        <Grid item xs={12} md={12}>
          <Grid>
            <TypographyStyle variant='h6' sx={{ fontSize: '20px' }}>
              Basic Details
            </TypographyStyle>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Employee ID
              </TypographyStyle2>

              {isEditable ? (
                <TextInput
                  name='basicDetails.employeeEmployeeId'
                  value={formik.values.basicDetails.employeeEmployeeId}
                  InputLabelProps={{ style: { height: '10px' } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.basicDetails.employeeEmployeeId}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Legal First Name
              </TypographyStyle2>

              {isEditable ? (
                <TextInput
                  sx={{
                    color: 'white',
                  }}
                  label='Legal First Name *'
                  type='text'
                  name='basicDetails.firstName'
                  value={formik.values.basicDetails.firstName}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.basicDetails.firstName}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Legal Last Name
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='Legal Last Name *'
                  type='text'
                  name='basicDetails.lastName'
                  value={formik.values.basicDetails.lastName}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.basicDetails.lastName}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                NIC Number
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='NIC Number *'
                  name='basicDetails.employeeNic'
                  onBlur={() =>
                    validateNIC(formik.values.basicDetails.employeeNic)
                  }
                  value={formik.values.basicDetails.employeeNic}
                  onFocus={() => setIsNICInvalid(false)}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    validateNIC(e.target.value);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.basicDetails.employeeNic}
                </TypographyStyle3>
              )}

              {displayAlert(isNICInvalid, 'Incorrect NIC number')}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} sx={{ marginTop: '21px' }}>
          <Grid>
            <TypographyStyle
              sx={{ fontSize: '20px', paddingTop: 2, paddingBottom: 2 }}
            >
              Contact Deatils
            </TypographyStyle>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Mobile Number
              </TypographyStyle2>
              {isEditable ? (
                <Box sx={{ width: '100%' }}>
                  <CountryCodeSelect
                    name='contactDetails.countryCode'
                    value={formik.values.contactDetails.countryCode}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: 250,
                          height: 250,
                        },
                      },
                    }}
                    renderValue={(value) => value}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsMobileNumberInvalid(false);
                    }}
                    onBlur={() =>
                      verifyTheNumber(
                        formik.values.contactDetails.countryCode,
                        formik.values.contactDetails.employeeMobile,
                      )
                    }
                  >
                    {countryCodes.map((item, index) => (
                      <MenuItem key={index} value={item.code}>
                        {item.name.length > 15
                          ? item.code + `...(${item.name.slice(0, 15)})`
                          : item.code + ` (${item.name})`}
                      </MenuItem>
                    ))}
                  </CountryCodeSelect>

                  <MobileNumberInput
                    placeholder='Mobile number'
                    type='tel'
                    value={formik.values.contactDetails.employeeMobile}
                    name='contactDetails.employeeMobile'
                    onInput={(e) => onlyNumbers(e)}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsMobileNumberInvalid(false);
                    }}
                    onBlur={() =>
                      verifyTheNumber(
                        formik.values.contactDetails.countryCode,
                        formik.values.contactDetails.employeeMobile,
                      )
                    }
                  />
                  {displayAlert(
                    isMobileNumberInvalid,
                    'Incorrect mobile number',
                  )}
                </Box>
              ) : (
                <TypographyStyle3>
                  {formik.values.contactDetails.employeeFullMobileNumber}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Email
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='Email *'
                  type='email'
                  name='contactDetails.employeeWorkEmail'
                  onBlur={() =>
                    validateEmail(
                      formik.values.contactDetails.employeeWorkEmail,
                    )
                  }
                  value={formik.values.contactDetails.employeeWorkEmail}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.contactDetails.employeeWorkEmail}
                </TypographyStyle3>
              )}

              {displayAlert(isEmailInvalid, 'Invalid Email')}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} sx={{ marginTop: '21px' }}>
          <Grid>
            <TypographyStyle
              sx={{ fontSize: '20px', paddingTop: 2, paddingBottom: 2 }}
            >
              Address
            </TypographyStyle>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Address Line 01
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='Address Line 01 *'
                  type='text'
                  name='addressDetails.employeeAddressLineOne'
                  value={formik.values.addressDetails.employeeAddressLineOne}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.addressDetails.employeeAddressLineOne}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Address Line 02
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='Address Line 02 *'
                  type='text'
                  name='addressDetails.employeeAddressLineTwo'
                  value={formik.values.addressDetails.employeeAddressLineTwo}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.addressDetails.employeeAddressLineTwo}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                City
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='City *'
                  type='text'
                  name='addressDetails.employeeCity'
                  value={formik.values.addressDetails.employeeCity}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.addressDetails.employeeCity}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                State/Province/Region
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='State/Province/Region *'
                  type='text'
                  name='addressDetails.employeeState'
                  value={formik.values.addressDetails.employeeState}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.addressDetails.employeeState}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Zip/Postal Code
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='Zip/Postal Code *'
                  type='text'
                  name='addressDetails.employeePostalCode'
                  value={formik.values.addressDetails.employeePostalCode}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.addressDetails.employeePostalCode}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Country
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='Country *'
                  type='text'
                  name='addressDetails.employeeCountry'
                  value={formik.values.addressDetails.employeeCountry}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.addressDetails.employeeCountry}
                </TypographyStyle3>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} sx={{ marginTop: '21px' }}>
          <Grid>
            <TypographyStyle
              sx={{ fontSize: '20px', paddingTop: 2, paddingBottom: 2 }}
            >
              Employment Details
            </TypographyStyle>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Currency
              </TypographyStyle2>
              {isEditable ? (
                <CustomSelect
                  label='Currency *'
                  name='employementDetails.employeeCurrencyDisplayId'
                  value={
                    formik.values.employementDetails.employeeCurrencyDisplayId
                  }
                  onChange={formik.handleChange}
                  select
                >
                  {allCurrencies.map((item, index) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={index}
                      value={item.currencyDisplayId}
                      onClick={() => {
                        setSelectedCurrency(item.currency);
                      }}
                    >
                      {item.currency}
                    </MenuItem>
                  ))}
                </CustomSelect>
              ) : (
                <TypographyStyle3>
                  {formik.values.employementDetails.employeeCurrency}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Salary
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='Salary *'
                  type='email'
                  name='employementDetails.employeeSalary'
                  value={formik.values.employementDetails.employeeSalary}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    const regex = /^\d+(\.\d{0,10})?$/g;
                    if (e.target.value === '' || regex.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {nf.format(formik.values.employementDetails.employeeSalary)}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Designation
              </TypographyStyle2>
              {isEditable ? (
                <TextInput
                  label='Designation *'
                  type='text'
                  name='employementDetails.employeeDesignation'
                  value={formik.values.employementDetails.employeeDesignation}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              ) : (
                <TypographyStyle3>
                  {formik.values.employementDetails.employeeDesignation}
                </TypographyStyle3>
              )}
            </Grid>
            <Grid item xs={4}>
              <TypographyStyle2
                sx={{
                  marginY: '5%',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Employee Type
              </TypographyStyle2>
              {isEditable ? (
                <CustomSelect
                  label='Type *'
                  name='employementDetails.employeeType'
                  value={formik.values.employementDetails.employeeType}
                  onChange={formik.handleChange}
                  select
                >
                  {Object.keys(EmployeeType).map((key) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={key}
                      value={EmployeeType[key]}
                    >
                      {key}
                    </MenuItem>
                  ))}
                </CustomSelect>
              ) : (
                <TypographyStyle3>
                  {formik.values.employementDetails.employeeType}
                </TypographyStyle3>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default BasicData;

const { fonts, fontSizes, colors } = styleProps;

const TypographyStyle = styled(Typography)({
  fontFamily: fonts.primary,
  color: colors.textDarkGrey,
});

const TypographyStyle2 = styled(Typography)({
  color: colors.textLightGrey,
  fontFamily: fonts.primary,
});

const TypographyStyle3 = styled(Typography)({
  color: colors.black,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '20px',
  fontFamily: fonts.primary,
});

const CustomSelect = styled(TextField)({
  width: '100%',
  height: 50,
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const TextInput = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const menuItemStyles = {
  m: 0.5,
  '&:focus': {
    background: colors.btnPrimary,
    color: 'white',
    m: 0.5,
    borderRadius: 1,
    '&:hover': {
      background: colors.btnPrimary,
      color: 'white',
      m: 0.5,
      borderRadius: 1,
    },
  },
};
const CountryCodeSelect = styled(Select)({
  width: '25%',
  height: 50,
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '4px 0 0 4px',
    },
  },
  '& fieldset': {
    borderRadius: '4px 0 0 4px',
  },
});

const MobileNumberInput = styled(TextField)({
  width: '75%',
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '0 4px 4px 0',
    },
    '& input::placeholder': {
      fontSize: fontSizes.normal,
    },
  },
});
