/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  styled,
  TextField,
  Typography,
  MenuItem,
  Select,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { phone } from 'phone';

import { styleProps } from '../../styles/styleProps';
import OtpMobileVerification from './OtpMobileVerfication';
import OtpEmailVerification from './OtpEmailVerification';
import { CountryCodes } from '../../data/countryCodes';
import ResetPassword from './ResetPassword';
import { invalidStyles } from '../../../src/styles/textFieldErrorStyles';
import PrimaryButton from '../buttons/PrimaryButton';

const { colors, fonts, fontSizes } = styleProps;

const containerStyles = {
  minHeight: '500px',
  borderRadius: '10px',
  background: 'white',
  padding: '20px 0px',
  boxShadow: '0px 0px 50px rgba(6, 102, 235, 0.15)',
};

const flexStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const TextInput = styled(TextField)({
  marginTop: '80px',
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const SubTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  color: colors.textLightGrey,
  marginTop: '40px',
});

const InputContainerBox = styled(Box)({
  boxSizing: 'border-box',
  padding: '8% 12% 2% 12%',
  width: '100%',
  height: '80%',
});

const TitleContainer = styled(Box)({
  width: '100%',
  height: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: '28px',
  fontWeight: 500,
  marginTop: '100px',
  color: colors.textDarkGrey,
});

const DetailsContainer = styled(Box)({
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const CountryCodeSelect = styled(Select)({
  width: '25%',
  height: 50,
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '4px 0 0 4px',
    },
  },
  '& fieldset': {
    borderRadius: '4px 0 0 4px',
  },
});

const MobileNumberInput = styled(TextField)({
  width: '75%',
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '0 4px 4px 0',
    },
    '& input::placeholder': {
      fontSize: fontSizes.normal,
    },
  },
});

const TextButton = styled(Button)({
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: 500,
  padding: '10px 0px',
  color: colors.btnPrimary,
});

const OtpEmail = ({ type }) => {
  const [isMobileOTPRequested, setIsMobileOTPRequested] = useState(false);
  const [isEmailOTPRequested, setIsEmailOTPRequested] = useState(false);
  const [emailOTPModal, setEmailOTPModal] = useState(true);
  const [mobileOTPModal, setMobileOTPModal] = useState(false);
  const countryCodes = CountryCodes.countries;
  const [resetPasswordView, setResetPasswordView] = useState(false);
  const [isEmptyBasicFields, setIsEmptyBasicFields] = useState(true);
  const [isMobileFieldEmpty, setIsMobileFieldEmpty] = useState(true);
  const [mobileCode, setMobileCode] = useState('+94');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mail, setMail] = useState('');
  const [isMobileNumberInvalid, setIsMobileNumberInvalid] = useState(false);

  //validations
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  const initialValues = {
    userData: {
      email: '',
    },
  };

  const validationSchema = Yup.object({
    userData: Yup.object().shape({
      email: Yup.string()
        .email('Invalid Email')
        .matches(/.*\..*/, 'Email must contain a dot (.)'),
    }),
  });

  const onSubmit = () => {
    if (type === 'loggedOut') {
      //fetch temporary auth code
      try {
        const email = formik.values.userData.email;

        setMail(email);

        const body = {
          email,
        };

        axios.post('/api/user/password/jwt', body).then((response) => {
          if (response.data.success === true) {
            //set the temporary auth in the localStorage
            localStorage.setItem(
              'tempAuth',
              response.headers.get('access-token'),
            );
            requestEmailOtp();
          } else {
            console.log(response.data.message);
          }
        });
      } catch (err) {
        console.error(err.message);
      }
    } else {
      //send the email OTP
      try {
        const mode = 0;
        const email = formik.values.userData.email;

        const body = {
          email,
          mode,
        };

        axios
          .post('/api/otp/send', body, {
            headers: {
              Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
            },
          })
          .then((response) => {
            if (response.data.success === true) {
              setIsEmailOTPRequested(true);
              setEmailOTPModal(false);
            } else {
              console.log(response.data.error);
              setIsEmailInvalid(true);
            }
          });
      } catch (err) {
        console.error(err.message);
        window.location = '/error';
      }
    }
  };

  const requestEmailOtp = () => {
    //send the email OTP
    try {
      const mode = 0;
      const email = formik.values.userData.email;

      const body = {
        email,
        mode,
      };

      axios
        .post('/api/otp/send', body, {
          headers: {
            Authorization: `"${localStorage.getItem('tempAuth')}"`,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            setIsEmailOTPRequested(true);
            setEmailOTPModal(false);
          } else {
            console.log(response.data.error);
            setIsEmailInvalid(true);
          }
        });
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  //Only number input function
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    let res = checkInputFields(formik.values.userData);
    setIsEmptyBasicFields(res);
  }, [formik.values.userData]);

  useEffect(() => {
    if (
      mobileCode === null ||
      mobileCode === '' ||
      mobileNumber === null ||
      mobileNumber === '' ||
      isMobileNumberInvalid === true
    ) {
      setIsMobileFieldEmpty(true);
    } else {
      setIsMobileFieldEmpty(false);
    }
  }, [mobileCode, mobileNumber]);

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  const navigateToLogin = () => {
    if (type === 'loggedIn') {
      window.location = '/profile';
    } else {
      window.location = '/login';
    }
  };

  const openOtpMobileVerification = () => {
    setMobileOTPModal(false);
    setIsMobileOTPRequested(true);

    // send otp to mobile
    sendOtpToMobile();
  };

  const openOtpEmailModal = () => {
    setIsEmailOTPRequested(false);
    // setMobileOTPModal(true);
    setResetPasswordView(true);
  };

  const sendOtpToMobile = () => {
    try {
      const mode = 1;

      const body = {
        email: mail,
        mobile: `${mobileCode}${mobileNumber}`,
        country: countryCodes[1].name,
        mode,
      };

      axios
        .post('/api/otp/send', body, {
          headers: {
            Authorization: `"${localStorage.getItem('tempAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              setIsMobileOTPRequested(true);
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            if (error.response.status === 401) {
              localStorage.setItem('sessionValid', 'false');
              window.location = '/login';
            } else {
              window.location = '/error';
            }
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  const openResetPasswordModal = () => {
    setIsMobileOTPRequested(false);
    setResetPasswordView(true);
  };

  //verify mobile number format
  const verifyTheNumber = (type) => {
    const mobile = mobileCode + mobileNumber;
    if (type === 'number' && mobileCode != '') {
      if (phone(mobile).isValid === false) {
        setIsMobileNumberInvalid(true);
      } else {
        setIsMobileNumberInvalid(false);
      }
    } else if (type === 'code' && mobileNumber != '') {
      if (phone(mobile).isValid === false) {
        setIsMobileNumberInvalid(true);
      } else {
        setIsMobileNumberInvalid(false);
      }
    }
  };

  const changeMobileCode = (e) => {
    setMobileCode(e.target.value);
    setIsMobileNumberInvalid(false);
  };

  const changeMobileNumber = (e) => {
    setMobileNumber(e.target.value);

    setIsMobileNumberInvalid(false);
  };

  return (
    <>
      {!resetPasswordView ? (
        <div style={{ ...containerStyles, width: '600px' }}>
          <TitleContainer>
            <Title>Verify Account</Title>
            <SubTitle>
              {emailOTPModal ? (
                'Please enter the registered email to send you the verification code.'
              ) : (
                <br />
              )}
            </SubTitle>
          </TitleContainer>
          <InputContainerBox>
            {isMobileOTPRequested ? (
              <OtpMobileVerification
                email={mail}
                mobile={mobileCode + mobileNumber}
                country={countryCodes[1].name}
                closeOtpModal={openResetPasswordModal}
              />
            ) : isEmailOTPRequested ? (
              <OtpEmailVerification
                email={formik.values.userData.email}
                emailVerified={openOtpEmailModal}
                type={type}
              />
            ) : mobileOTPModal ? (
              <DetailsContainer
                sx={{
                  display: 'flex',
                }}
              >
                <SubTitle>
                  We have to find your account to verify. Please enter the
                  registered mobile number to send you the verification code.
                </SubTitle>
                <Box sx={{ width: '100%', marginTop: '10%' }}>
                  <CountryCodeSelect
                    name='userData.code'
                    value={mobileCode}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: 250,
                          height: 250,
                        },
                      },
                    }}
                    onChange={(e) => changeMobileCode(e)}
                    onBlur={() => verifyTheNumber('code')}
                    renderValue={(value) => value}
                  >
                    {countryCodes.map((item, index) => (
                      <MenuItem key={index} value={item.code}>
                        {item.name.length > 15
                          ? item.name.slice(0, 15) + `... (${item.code})`
                          : item.name + ` (${item.code})`}
                      </MenuItem>
                    ))}
                  </CountryCodeSelect>
                  <MobileNumberInput
                    placeholder='Mobile number'
                    type='tel'
                    onInput={onlyNumbers}
                    name='userData.number'
                    value={mobileNumber}
                    onChange={(e) => changeMobileNumber(e)}
                    onBlur={() => verifyTheNumber('code')}
                  />
                </Box>
                {isMobileNumberInvalid === true ? (
                  <>
                    <br /> <br />
                    <Alert severity='error'>Incorrect mobile number</Alert>
                  </>
                ) : null}
                <Box
                  sx={{
                    ...flexStyles,
                    flexDirection: 'column',
                    mb: -3,
                    marginTop: '150px',
                  }}
                >
                  <PrimaryButton
                    disabled={isMobileFieldEmpty}
                    onClick={openOtpMobileVerification}
                    fullWidth
                  >
                    Verify account
                  </PrimaryButton>
                  <TextButton onClick={navigateToLogin}>Go Back</TextButton>
                </Box>
              </DetailsContainer>
            ) : (
              <DetailsContainer
                sx={{
                  display: 'flex',
                }}
              >
                <Box sx={{ display: 'block', marginTop: '10%' }}>
                  <TextInput
                    label='Email address'
                    type='email'
                    name='userData.email'
                    value={formik.values.userData.email}
                    sx={invalidStyles(
                      formik.errors?.userData?.email ? false : true,
                    )}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsEmailInvalid(false);
                      formik.errors?.userData?.email &&
                        delete formik.errors.userData.email;
                    }}
                  />
                  {formik.errors?.userData?.email && (
                    <>
                      <br /> <br />
                      <Alert severity='error'>
                        {formik.errors.userData.email}
                      </Alert>
                    </>
                  )}
                  {isEmailInvalid && (
                    <>
                      <br /> <br />
                      <Alert severity='error'>
                        Email address is not registered
                      </Alert>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    ...flexStyles,
                    flexDirection: 'column',
                    mb: -3,
                    marginTop: '150px',
                  }}
                >
                  <PrimaryButton
                    disabled={isEmptyBasicFields}
                    onClick={formik.handleSubmit}
                    fullWidth
                  >
                    Verify account
                  </PrimaryButton>
                  <TextButton onClick={navigateToLogin}>Go Back</TextButton>
                </Box>
              </DetailsContainer>
            )}
          </InputContainerBox>
        </div>
      ) : (
        <div style={{ ...containerStyles, width: '710px' }}>
          <ResetPassword type={type} email={formik.values.userData.email} />
        </div>
      )}
    </>
  );
};

export default OtpEmail;
