import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ReportCard, { CustomDivider } from '.';
import { styleProps } from '../../../styles/styleProps';

const ReportCardLayout = ({ report, showEditTime, children }) => {
  return (
    <Box sx={styleProps.projectReportContainer}>
      <ReportCard report={report} showEditTime={showEditTime} />
      <CustomDivider />
      {children}
    </Box>
  );
};

ReportCardLayout.propTypes = {
  report: PropTypes.object.isRequired,
  showEditTime: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default ReportCardLayout;
