import { format } from 'date-fns';
const payrollCalculations = (bulkData) => {
  const {
    payrollEndDate = new Date(),
    payrollStartDate = new Date(),
    payDate = new Date(),
  } = bulkData;

  const startPayrollDate =
    format(payrollStartDate, 'MMM') + ' ' + format(payrollStartDate, 'dd');
  const endPayrollDate =
    format(payrollEndDate, 'MMM') + ' ' + format(payrollEndDate, 'dd');
  const payDay = format(payDate, 'MMM') + ' ' + format(payDate, 'dd');

  const employees = Object.values(bulkData.employees);

  const localCurrencyEmployees = employees.filter(
    (employee) => employee.employeeCurrencyCode === 'LKR',
  );

  const employeeCount = employees.length;
  const localCurrencyEmployeeCount = localCurrencyEmployees.length;

  if (employees.length === 0) {
    return {
      salary: 0,
      fuelAndMaintenance: 0,
      travel: 0,
      dailyPay: 0,
      overtime: 0,
      bonus: 0,
      reimbursements: 0,
      totalPay: 0,
      startPayrollDate,
      endPayrollDate,
      payDay,
      employeeCount,
    };
  }

  const totalPayLKR = calculateTotalPay(localCurrencyEmployees);
  const totalPayUSD = calculateTotalPay(
    employees.filter((employee) => !localCurrencyEmployees.includes(employee)),
  );
  const totalPay = calculateTotalPay(employees);

  return {
    salary: employees.reduce(
      (total, employee) =>
        total +
        (employee.employeeSalary - employee.employeeTotalDeductions || 0),
      0,
    ),
    fuelAndMaintenance: employees.reduce(
      (total, employee) => total + (employee.employeeAllowanceFuel || 0),
      0,
    ),
    travel: employees.reduce(
      (total, employee) => total + (employee.employeeAllowanceTravel || 0),
      0,
    ),
    dailyPay: employees.reduce(
      (total, employee) => total + (employee.employeeAllowanceDailyPay || 0),
      0,
    ),
    overtime: employees.reduce(
      (total, employee) => total + (employee.employeeAllowanceOt || 0),
      0,
    ),
    bonus: employees.reduce(
      (total, employee) => total + (employee.employeeAllowanceBonus || 0),
      0,
    ),
    reimbursements: employees.reduce(
      (total, employee) => total + (employee.employeeReimbursements || 0),
      0,
    ),
    totalPay,
    startPayrollDate,
    endPayrollDate,
    payDay,
    employeeCount,
    localCurrencyEmployeeCount,
    totalPayUSD,
    totalPayLKR,
  };
};

export default payrollCalculations;

const calculateTotalPay = (employees) => {
  return employees.reduce((total, employee) => {
    return total + (employee.totolPay || 0);
  }, 0);
};
