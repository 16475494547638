/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Modal,
  Button,
  Stack,
  Typography,
  TextField,
  Select,
  styled,
  MenuItem,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

import { CountryCodes } from '../../data/countryCodes';
import { styleProps } from '../../styles/styleProps';
import ConfirmationModal from '../alerts/ConfirmationModal';

import { adminUpdate } from '../../services/AdminService';

const { fontSizes, colors } = styleProps;

const EditUserDetailsModal = ({
  modalStatus,
  onClose,
  userDetails,
  refreshData,
  countryCodeValue,
  number,
}) => {
  const countryCodes = CountryCodes.countries;
  const [isEmptyFields, setIsEmptyFields] = useState(false);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState(false);
  const [isMobileNumberInvalid, setIsMobileNumberInvalid] = useState(false);
  const [isUpdateUnsuccessful, setIsUpdateUnsuccessful] = useState(false);

  //Validation
  const [invalidCompanyName, setInvalidCompanyName] = useState(false);
  const errorMessage = '';

  const initialValues = {
    name: userDetails.adminName,
    code: countryCodeValue,
    number: number,
  };

  const validationSchema = Yup.object({
    name: Yup.string(),
    code: Yup.string(),
    number: Yup.string().test('len', 'Invalid mobile number', function (value) {
      const selectedCountry = countryCodes.find(
        (country) => country.code === this.parent.code,
      );
      if (
        value &&
        selectedCountry &&
        value.length !== selectedCountry.numberLength
      ) {
        return false;
      }
      return true;
    }),
  });

  const editUserDetails = () => {
    const name = formik.values.name;
    const email = userDetails.adminEmail;
    const mobile = formik.values.code + formik.values.number;

    let countryCodeValue = countryCodes.filter(
      (field) => field.code === formik.values.code,
    );

    let country = countryCodeValue[0].name;

    if (formik.values.name.trim().length === 0) {
      setInvalidCompanyName(true);
      return;
    }
    const body = {
      name,
      email,
      mobile,
      country,
    };

    adminUpdate(body).then(() => {
      onClose();
      refreshData();
    });
  };

  const onSubmit = () => {
    editUserDetails();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    let res = checkInputFields(formik.values);
    if (res === false && isMobileNumberInvalid === false) {
      setIsEmptyFields(false);
    } else {
      setIsEmptyFields(true);
    }
  }, [formik.values]);

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  //Only number input function
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  const closeModal = () => {
    if (isEmptyFields === true) {
      onClose();
    } else {
      setConfirmationModalStatus(true);
    }
  };

  const confirmationModalApproval = () => {
    setConfirmationModalStatus(false);
    onClose();
  };

  const confirmationModalDecline = () => {
    setConfirmationModalStatus(false);
  };

  return (
    <div>
      <ConfirmationModal
        modalStatus={confirmationModalStatus}
        confirm={confirmationModalApproval}
        reject={confirmationModalDecline}
      />
      <Modal open={modalStatus} onClose={closeModal}>
        <Box sx={{ ...styles.container, height: 'auto', width: '460px' }}>
          <Stack justifyContent='space-between' direction='row'>
            <Typography sx={styles.HeadingStyle}>Edit User Profile</Typography>
            <Button onClick={onClose} style={styles.BtnStyle}>
              <CloseIcon />
            </Button>
          </Stack>
          <hr style={{ border: '1px solid #E5E5E5' }}></hr>

          <Grid sx={{ boxShadow: 0, margin: '5%' }}>
            <TextInput
              placeholder='user name'
              value={formik.values.name}
              onChange={(e) => {
                formik.handleChange(e);
                setIsUpdateUnsuccessful(false);
                setInvalidCompanyName(false);
              }}
              name='name'
            />
            {invalidCompanyName ? (
              <>
                <br />
                <Alert severity='error'>Invalid Company Name!</Alert>
              </>
            ) : null}
            <Box sx={{ width: '100%' }}>
              <CountryCodeSelect
                name='code'
                defaultValue={formik.values.code}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: 250,
                      height: 250,
                    },
                  },
                }}
                renderValue={(value) => value}
                onChange={(e) => {
                  formik.handleChange(e);
                  setIsMobileNumberInvalid(false);
                  setIsUpdateUnsuccessful(false);
                }}
              >
                {countryCodes.map((item, index) => (
                  <MenuItem key={index} value={item.code}>
                    {item.name.length > 15
                      ? item.name.slice(0, 15) + `... (${item.code})`
                      : item.name + ` (${item.code})`}
                  </MenuItem>
                ))}
              </CountryCodeSelect>

              <MobileNumberInput
                placeholder='Mobile number'
                type='tel'
                name='number'
                value={formik.values.number}
                onInput={(e) => onlyNumbers(e)}
                onChange={(e) => {
                  formik.handleChange(e);
                  setIsMobileNumberInvalid(false);
                  setIsUpdateUnsuccessful(false);
                }}
              />
              {formik.touched.number &&
              formik.errors.number &&
              formik.errors.number.includes('Invalid mobile number') ? (
                <>
                  <br />
                  <br />
                  <Alert severity='error'>{formik.errors.number}</Alert>
                </>
              ) : null}
            </Box>
            {isUpdateUnsuccessful === true ? (
              <>
                <br />
                <Alert severity='error'>{errorMessage}</Alert>
              </>
            ) : null}
            <Stack spacing={2} direction='row' sx={styles.StackStyle}>
              <SecondaryButton styles={styles.actionBtn} onClick={closeModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                styles={styles.actionBtn}
                disabled={isEmptyFields}
                onClick={formik.handleSubmit}
              >
                Update
              </PrimaryButton>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    borderRadius: '8px',
    background: '#FFFFFF',
  },
  StackStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5%',
  },
  BtnStyle: {
    backgroundColor: 'none',
    '&:hover': {
      backgroundColor: '#FFDFAC',
    },
    color: colors.textDarkGrey,
  },
  HeadingStyle: {
    margin: '5%',
    color: colors.textDarkGrey,
    fontWeight: 500,
    fontSize: '20px',
  },
  actionBtn: {
    marginLeft: '5px',
    marginRight: '10%',
    width: '195px',
    fontWeight: 500,
  },
};

const CountryCodeSelect = styled(Select)({
  width: '25%',
  height: 50,
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,

    '& fieldset': {
      borderRadius: '4px 0 0 4px',
    },
  },
  '& fieldset': {
    borderRadius: '4px 0 0 4px',
  },
});

const MobileNumberInput = styled(TextField)({
  width: '75%',
  marginTop: '15px',
  '& .MuiInputBase-root': {
    height: 50,
    '& fieldset': {
      borderRadius: '0 4px 4px 0',
    },
    '& input::placeholder': {
      fontSize: fontSizes.normal,
    },
  },
});

const TextInput = styled(TextField)({
  width: '100%',
  borderRadius: '8px',
});

export default EditUserDetailsModal;
