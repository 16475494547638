import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

// Reusable tooltip component
const TooltipComponent = ({ tooltipText, tooltipData }) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <span>{tooltipData}</span>
    </Tooltip>
  );
};

TooltipComponent.propTypes = {
  tooltipText: PropTypes.node.isRequired,
  tooltipData: PropTypes.node.isRequired,
};

export default TooltipComponent;
