/* eslint-disable react/prop-types */
import {
  Grid,
  Typography,
  styled,
  IconButton,
  TextField,
  InputAdornment,
  Stack,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { styleProps } from '../../styles/styleProps';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { invalidStyles } from '../../styles/textFieldErrorStyles';
import ResponseBox from '../alerts/ResponseBox';
import { adminPasswordUpdate } from '../../services/AdminService';

const { fonts, colors } = styleProps;

const PasswordResetView = ({ user, closeResetPassword }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVerified, setIsPasswordVerified] = useState(true);
  const [invalidPasswordError, setInvalidPasswordError] = useState('');
  const [isEmptyBasicFields, setIsEmptyBasicFields] = useState(true);
  const [isPasswordChangeSuccessful, setIsPasswordChangeSuccessful] =
    useState(false);
  const navigate = useNavigate();

  const initialValues = {
    userData: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  };

  //Handle password visibility
  const handlePasswordVisibility = () => {
    setIsPasswordVisible((prevVal) => !prevVal);
  };

  const validationSchema = Yup.object({
    userData: Yup.object().shape({
      oldPassword: Yup.string(),
      password: Yup.string(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords do not match',
      ),
    }),
  });

  const onSubmit = () => {
    const password = formik.values.userData.password;
    const email = user.adminEmail;
    const body = {
      password,
      email,
    };

    adminPasswordUpdate(body).then(() => {
      setIsPasswordChangeSuccessful(true);
      navigate('/login');
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  //check old password
  const passwordCheck = () => {
    try {
      const email = user.adminEmail;
      const password = formik.values.userData.oldPassword;
      const body = {
        email,
        password,
      };
      axios
        .post('/api/admin/password/check', body, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === false) {
              setIsPasswordVerified(false);
              setInvalidPasswordError(response.data.error);
            }
          },
          (error) => {
            if (error.response.status === 401) {
              localStorage.setItem('sessionValid', 'false');
              window.location = '/login';
            } else {
              window.location = '/error';
            }
          },
        );
    } catch (err) {
      window.location = '/error';
    }
  };

  useEffect(() => {
    let res = checkInputFields(formik.values.userData);
    if (res === false) {
      if (isPasswordVerified === true) {
        setIsEmptyBasicFields(false);
      } else {
        setIsEmptyBasicFields(true);
      }
    }
  }, [formik.values.userData]);

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  const closeSuccessModal = () => {
    setIsPasswordVisible(false);
    setIsPasswordVerified(true);
    setInvalidPasswordError('');
    setIsEmptyBasicFields(true);
    setIsPasswordChangeSuccessful(false);
    closeResetPassword();
  };

  return (
    <>
      <ResponseBox
        text={'Password Reset Successfully'}
        modalStatus={isPasswordChangeSuccessful}
        onClose={closeSuccessModal}
      />
      <Grid sx={{ padding: '7%' }}>
        <TitleText>Reset Password</TitleText>
        <TextInput
          placeholder='Old Password'
          name='userData.oldPassword'
          type={isPasswordVisible ? 'text' : 'password'}
          onBlur={passwordCheck}
          value={formik.values.userData.oldPassword}
          onChange={(e) => {
            setIsPasswordVerified(true);
            formik.handleChange(e);
          }}
          sx={{ marginTop: '15px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton edge='end' onClick={handlePasswordVisibility}>
                  {isPasswordVisible ? (
                    <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                  ) : (
                    <VisibilityOutlined sx={{ fontSize: 18 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ style: { fontSize: 14 } }}
        />
        {isPasswordVerified === false ? (
          <ErrorText>{invalidPasswordError}</ErrorText>
        ) : null}

        <TextInput
          placeholder='Password'
          name='userData.password'
          type={isPasswordVisible ? 'text' : 'password'}
          value={formik.values.userData.password}
          onChange={formik.handleChange}
          sx={{ marginTop: '15px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton edge='end' onClick={handlePasswordVisibility}>
                  {isPasswordVisible ? (
                    <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                  ) : (
                    <VisibilityOutlined sx={{ fontSize: 18 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ style: { fontSize: 14 } }}
        />

        <TextInput
          placeholder='Confirm password'
          name='userData.confirmPassword'
          type={isPasswordVisible ? 'text' : 'password'}
          value={formik.values.userData.confirmPassword}
          onChange={(e) => {
            formik.handleChange(e);
            formik.errors?.userData?.confirmPassword &&
              delete formik.errors.userData.confirmPassword;
          }}
          sx={invalidStyles(
            formik.errors?.userData?.confirmPassword ? false : true,
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton edge='end' onClick={handlePasswordVisibility}>
                  {isPasswordVisible ? (
                    <VisibilityOffOutlined sx={{ fontSize: 18 }} />
                  ) : (
                    <VisibilityOutlined sx={{ fontSize: 18 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ style: { fontSize: 14 } }}
        />
        {formik.errors?.userData?.confirmPassword && (
          <ErrorText>{formik.errors.userData.confirmPassword}</ErrorText>
        )}

        <PrimaryButton
          styles={{ marginTop: '30px', fontWeight: 400 }}
          disabled={isEmptyBasicFields}
          onClick={formik.handleSubmit}
          fullWidth
        >
          Reset Password
        </PrimaryButton>
        <SecondaryButton
          fullWidth
          onClick={closeSuccessModal}
          styles={{ marginTop: '10px', fontWeight: 400 }}
        >
          Cancel
        </SecondaryButton>
        <Stack
          justifyContent={'center'}
          gap={'5px'}
          flexDirection={'row'}
          sx={{ paddingTop: '2%' }}
        >
          <Typography sx={resetText}>Forget password ?</Typography>
          <Typography
            sx={[resetText, { color: 'text.accent', cursor: 'pointer' }]}
          >
            Reset
          </Typography>
        </Stack>
      </Grid>
    </>
  );
};

const TitleText = styled(Typography)({
  fontFamily: fonts.primary,
  fontWeight: 400,
  fontSize: '14px',
  paddingTop: 10,
  color: colors.textLightGrey,
});

const resetText = {
  fontWeight: 500,
  fontSize: '14px',
  color: colors.textLightGrey,
};

const TextInput = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const ErrorText = styled(Typography)({
  fontSize: '12px',
  textTransform: 'none',
  padding: '0px 0px',
  color: 'red',
});

export default PasswordResetView;
