import React from 'react';
import { Box, Typography } from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import NotificationSchedule from './NotificationSchedule';

import {
  getSchedules,
  updateSchedule,
} from '../../services/NotificationScheduleService';

const AttendanceSetting = () => {
  const [morning, lunch, evening] = getSchedules();
  const onUpdate = (schedule) => (value) => {
    updateSchedule(schedule.id, value);
  };
  return (
    <Box
      sx={{
        margin: 10,
        border: '2px solid #DEEBFC',
        borderRadius: '8px',
        padding: '20px',
      }}
    >
      <Typography
        sx={{
          margin: 2,
          fontSize: 20,
          fontWeight: 500,
          fontFamily: styleProps.fonts.primary,
          color: styleProps.colors.textDarkGrey,
        }}
      >
        Configure Clocking Push notifications
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
        <NotificationSchedule
          title={'Morning notification'}
          description={'Set the time to send the morning notification'}
          label={'Set morning time'}
          value={morning}
          onUpdate={onUpdate(morning)}
        />
        <NotificationSchedule
          title={'Lunch notification'}
          description={'Set the time to send the lunch notification'}
          label={'Set lunch time'}
          value={lunch}
          onUpdate={onUpdate(lunch)}
        />
        <NotificationSchedule
          title={'End of the work day notification'}
          description={
            'Set the time to send the End of the work day notification'
          }
          label={'Set work end time'}
          value={evening}
          onUpdate={onUpdate(evening)}
        />
      </Box>
    </Box>
  );
};

export default AttendanceSetting;
