import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CardSkeletonContainer from '../skeletons/CardSkeletonContainer';
import NoReportsFound from './NoReportsFound';
import ReportCardLayout from './report-card/ReportCardLayout';
//import ReportSummary from './report-card/ReportSummary';
import MonthlyReport from './MonthlyReport';
import GenerateProjectReport from './GenerateProjectReport';
import { Alert, Snackbar } from '@mui/material';

// Note : -----------------------
// Some code sections are currently commented out as they are not in use at the moment.
// They are retained for potential future functionality implementation.
// Please refer to the documentation or discuss with the team before making changes or removing this code.

const ReportsContainer = ({ isLoading, reports, isMonthlyReport = false }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleErrors = () => {
    setErrorMessage('Error Downloading Report');
  };

  if (isLoading) {
    return (
      <CardSkeletonContainer
        outerBoxSx={{
          width: '100%',
          border: '2px solid #DEEBFC',
          borderRadius: 4,
          height: '300px',
        }}
        innerBoxSx={{
          flexDirection: 'column',
          display: 'flex',
          padding: '2%',
          justifyContent: 'space-around',
        }}
      />
    );
  }
  if (!reports.length) {
    return <NoReportsFound />;
  } else {
    return (
      <>
        <>
          {isMonthlyReport ? (
            <MonthlyReport reports={reports} />
          ) : (
            <Grid
              gap={4}
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
            >
              {reports.map((report) => {
                return (
                  <ReportCardLayout
                    key={report.id}
                    report={report}
                    showEditTime={true}
                  >
                    <GenerateProjectReport
                      report={report}
                      handleErrors={handleErrors}
                    />
                    {/* <ReportSummary report={report} showEditSummary={true} /> */}
                  </ReportCardLayout>
                );
              })}
            </Grid>
          )}
        </>
        <Snackbar
          open={errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert
            severity='error'
            sx={{ width: '100%' }}
            onClose={() => setErrorMessage('')}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
};

ReportsContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMonthlyReport: PropTypes.bool.isRequired,
};

export default ReportsContainer;
