/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { styleProps } from '../../../styles/styleProps';
import { resolvePath } from '../../../data/routeDefinition';

const { fonts, fontSizes, colors } = styleProps;

const ItemContainer = styled(Link)({
  width: '65%',
  height: 'auto',
  cursor: 'pointer',
  marginTop: '15px',
  padding: '16px',
  borderRadius: '10px',
  color: 'black',
  textDecoration: 'none',
  display: 'block',
  '&:hover': {
    background: '#0666EB80',
  },
});

const ItemWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  fontSize: fontSizes.normal,
});

const ItemText = styled(Typography)({
  marginLeft: 10,
  fontWeight: 500,
  fontSize: fontSizes.normal,
  fontFamily: fonts.primary,
});

const Menu = ({ data }) => {
  const [isSelected, setIsSelected] = useState(false);
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    if (resolvePath(data.to) === pathName) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  return (
    <ItemContainer
      to={data.to}
      sx={{ background: isSelected ? '#0666EB33' : 'none' }}
    >
      <ItemWrapper sx={{ color: isSelected && colors.textPrimary }}>
        {data.icon && <data.icon sx={{ fontSize: '20px' }} />}
        <ItemText>{data.title}</ItemText>
      </ItemWrapper>
    </ItemContainer>
  );
};

export default Menu;
