import React, { useState } from 'react';
import TopBar from '../components/top-bar/TopBar';
import { Box, Menu, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';
import OfferLetterUploadModal from '../components/offer-letter-templates/modals/OfferLetterUploadModal';
import OfferLetterTemplateTable from '../components/offer-letter-templates/tables/OfferLetterTemplateTable';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import PrimaryButton from '../components/buttons/PrimaryButton';
import LetterSendDrawer from '../components/offer-letter-templates/LetterSendDrawer';

const OfferLettersPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { offerLetterTemplates } = useLoaderData();
  // state for menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleCloseMenu = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <>
        <TopBar title='Offer Letter Templates' user={user} />
        <Box flex={1}>
          <Box
            style={{
              maxHeight: 'calc(100vh - 64px)',
              overflowY: 'auto',
            }}
          >
            <Stack alignItems={'flex-end'} sx={{ margin: '32px 27px 0 0' }}>
              <PrimaryButton onClick={() => setOpen(true)}>
                Add offer letter template
              </PrimaryButton>
            </Stack>

            <Box marginX={'28px'} marginTop={'28px'}>
              <OfferLetterTemplateTable
                offerLetters={offerLetterTemplates}
                setAnchorEl={setAnchorEl}
                setSelectedRow={setSelectedRow}
              />
            </Box>

            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleCloseMenu}
              position='left'
            >
              <MenuItem
                sx={{ width: '180px', gap: '10px' }}
                onClick={() => {
                  handleCloseMenu();
                  navigate(`/offerLetter/template/${selectedRow.displayId}`, {
                    state: { letterType: selectedRow.letterType },
                  });
                }}
              >
                <LaunchIcon style={{ marginRight: '7px' }} />
                View
              </MenuItem>
              <MenuItem
                sx={{ gap: '10px' }}
                onClick={() => {
                  setOpenDrawer(true);
                  handleCloseMenu();
                }}
              >
                <SendOutlinedIcon style={{ marginRight: '7px' }} />
                Send
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </>

      <OfferLetterUploadModal open={open} setOpen={setOpen} />

      <LetterSendDrawer
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        tableRow={selectedRow}
      />
    </>
  );
};

export default OfferLettersPage;
