import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getCurrentAdmin } from '../services/AdminService';
import PropTypes from 'prop-types';

const AuthContext = createContext();

const JWT_KEY = 'loggedInAuth';
const USER_DATA_KEY = 'userData';

function parseData(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error('Error in parsing data', error);
    return null;
  }
}

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem(JWT_KEY) || null);
  const [user, setUser] = useState(
    token ? parseData(localStorage.getItem(USER_DATA_KEY)) : null,
  );
  const [loading, setLoading] = useState(true);
  const authenticated = useMemo(() => !!user, [user]);

  useEffect(() => {
    if (!token) return; // If no JWT, no need to fetch user details
    if (authenticated) return setLoading(false); // If user details already fetched, no need to fetch again

    getCurrentAdmin()
      .then((admin) => {
        setUser(admin);
        localStorage.setItem('userData', JSON.stringify(admin));
      })
      .catch((error) => {
        setUser(null);
        console.error('Error in fetching user details', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, authenticated]);

  const values = {
    user,
    authenticated,
    loading,
    setToken,
  };
  return (
    <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
