/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import TopBar from '../components/top-bar/TopBar';
import { getCurrentAdmin } from '../services/AdminService';
import { useParams } from 'react-router-dom';
import {
  getEmployeeAccounts,
  getEmployeeByDisplayId,
} from '../services/EmployeeService';
import { getByEmployeeDisplayId } from '../services/PaysubServices';
import PaystubData from '../components/employee-paystubs/paystubData';
import AccountDetails from '../components/employee-paystubs/AccountDetails';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { styleProps } from '../styles/styleProps';

const { colors, fonts } = styleProps;

function EmployeePaystub() {
  const params = useParams();
  const employeeId = params.id;
  const urlParams = new URLSearchParams(window.location.search);
  const month = urlParams.get('month');
  const year = urlParams.get('year');
  const [user, setUser] = useState('');
  const [employee, setEmployee] = useState({});
  const [paystubs, setPaystubs] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedPaystub, setSelectedPaystub] = useState('');
  const employeeName = `${employee?.employeeFirstName || ''} ${
    employee?.employeeLastName || ''
  }`;

  const yearOptions = () => {
    const years = [];
    for (let paystub of paystubs) {
      if (!years.includes(paystub.year)) {
        years.push(paystub.year);
      }
    }

    return years.map((year) => {
      // eslint-disable-next-line react/jsx-key
      return <MenuItem value={year}>{year}</MenuItem>;
    });
  };

  const monthOptions = () => {
    let months = [];
    for (let paystub of paystubs) {
      if (!months.includes(paystub.month) && paystub.year === selectedYear) {
        months.push(paystub.month);
      }
    }

    return months.map((month) => {
      return (
        <MenuItem value={month}>
          {format(new Date(2021, month, 1), 'MMMM')}
        </MenuItem>
      );
    });
  };

  const onChangeMonth = (month) => {
    setSelectedMonth(month);

    for (let paystub of paystubs) {
      if (paystub.month === month && paystub.year === selectedYear) {
        setSelectedPaystub(paystub);
      }
    }
  };

  // set selected month and year on page load
  useEffect(() => {
    if (month && year) {
      setSelectedMonth(Number(month));
      setSelectedYear(Number(year));

      for (let paystub of paystubs) {
        if (paystub.month === Number(month) && paystub.year === Number(year)) {
          setSelectedPaystub(paystub);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, paystubs]);

  // selected month removed when year is changed
  useEffect(() => {
    setSelectedMonth('');
    setSelectedPaystub('');
  }, [selectedYear]);

  // fetch employee , paystubs and user data on page load
  useEffect(() => {
    getCurrentAdmin().then((response) => {
      setUser(response);
    });

    getEmployeeByDisplayId(employeeId).then((res) => {
      setEmployee(res);
    });

    getByEmployeeDisplayId(employeeId).then((data) => {
      setPaystubs(data);
    });

    getEmployeeAccounts(employeeId).then((res) => {
      setAccounts(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paystubStatusRenderer = (status) => {
    switch (status) {
      case 'N':
        return <span style={{ color: 'blue' }}>UN-PAID</span>;
      case 'PS':
        return <span style={{ color: 'yellow' }}>PAYMENT PROCESSING</span>;
      case 'PF':
        return <span style={{ color: 'red' }}>PAYMENT FALIED</span>;
      case 'PCP':
        return (
          <span style={{ color: 'orange' }}>
            PAYMENT COMPLETED CONFIRMATION PENDING
          </span>
        );
      case 'PC':
        return (
          <span style={{ color: 'green' }}>
            PAYMENT COMPLETED WITH CONFIRMATION
          </span>
        );
      default:
        return <span style={{ color: 'red' }}>CANCELLED</span>;
    }
  };

  return (
    <>
      <TopBar
        title={`Employee Profile > ${employeeName} > Paystub`}
        user={user}
      />
      <Box flex={1}>
        <Box
          style={{
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
        >
          <Stack
            marginTop={5}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Stack>
              <Typography
                gutterBottom
                color={colors.textLightGrey}
                variant='h7'
              >
                Employee ID : {employee?.employeeEmployeeId || ''}
              </Typography>
              <Typography variant='h7' fontSize={22}>
                {employeeName}
              </Typography>
            </Stack>

            {/* Select options  */}
            <Stack gap={2} direction={'row'}>
              <FormControl sx={{ width: 150 }}>
                <InputLabel sx={labelStyles}> Year </InputLabel>
                <Select
                  displayEmpty
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  label='Year'
                  sx={roundedTextField}
                >
                  {yearOptions()}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 150 }}>
                <InputLabel sx={labelStyles}> Month </InputLabel>
                <Select
                  displayEmpty
                  value={selectedMonth}
                  onChange={(e) => onChangeMonth(e.target.value)}
                  label='Month'
                  sx={roundedTextField}
                >
                  {monthOptions()}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 150 }}>
                <InputLabel sx={labelStyles}> Pay Slip Type </InputLabel>
                <Select
                  defaultValue={1}
                  label='Pay Slip Type'
                  sx={roundedTextField}
                >
                  <MenuItem value={1}>Local Bank</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          {selectedPaystub && (
            <Stack>
              <PaystubData paystub={selectedPaystub} />
            </Stack>
          )}

          <AccountDetails accounts={accounts} />

          <Stack marginTop={5} gap={2} marginBottom={5}>
            <Box>
              Paystub Status : {paystubStatusRenderer(selectedPaystub?.status)}
            </Box>
            Updated at : {new Date(selectedPaystub?.updatedAt).toLocaleString()}
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default EmployeePaystub;

const roundedTextField = {
  borderRadius: '0.7em',
  border: '1px solid #E5E5E5',
};

const labelStyles = {
  color: '#71717A',
  fontWeight: 500,
  fontFamily: fonts.primary,
};
