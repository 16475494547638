import FBO from '../images/wallet.png';
import Emp from '../images/businessman.png';
import LASTPAY from '../images/calendar.png';
import LATPAYROLL from '../images/payroll.png';

const images = {
  FBO,
  Emp,
  LASTPAY,
  LATPAYROLL,
};

export default images;
