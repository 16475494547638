export const invalidStyles = (state) => {
  let styles = {
    marginTop: '15px',
    '& label.Mui-focused': {
      color: state ? '' : 'red',
    },
    '& label': {
      color: state ? '' : 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: state ? '' : 'red',
      },
      '&:hover fieldset': {
        borderColor: state ? '' : 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: state ? '' : 'red',
      },
    },
  };
  return styles;
};
