import React from 'react';
import PropTypes from 'prop-types';
import { Button, styled } from '@mui/material';

import { styleProps } from '../../styles/styleProps';

const { fonts, colors } = styleProps;

const ProceedButton = styled(Button)`
  font-family: ${fonts.primary};
  font-size: 16px;
  font-weight: 500;
  min-width: 150px;
  width: ${(props) =>
    props.fullWidth ? '100%' : props.width ? props.width : 'unset'};
  height: 50px;
  background-color: ${colors.btnPrimary};
  border-radius: 30px;
  color: #fff;
  text-transform: none;
  &:hover {
    background-color: ${colors.btnPrimary};
  }

  &:disabled {
    opacity: 0.3;
    color: #fff;
  }
`;

const ShortPrimaryBtn = ({
  text,
  onClick,
  disabled,
  width,
  fullWidth = false,
}) => {
  return (
    <ProceedButton
      disabled={disabled}
      onClick={onClick}
      width={width}
      fullWidth={fullWidth}
    >
      {text}
    </ProceedButton>
  );
};

ShortPrimaryBtn.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default ShortPrimaryBtn;
