import React from 'react';
import { Box } from '@mui/material';
import TopBar from '../components/top-bar/TopBar';
import { useAuth } from '../contexts/authContext';
import { useLoaderData, useLocation } from 'react-router-dom';
import OfferLetterHeader from '../components/offerLetterView/OfferLetterHeader';

const OfferLetterView = () => {
  const { user } = useAuth();
  const location = useLocation();
  const loaderData = useLoaderData();
  const { offerLetter, candidateName, letterStatus, comments } = loaderData;
  const letterType = location.state?.letterType || loaderData?.letterType; //
  const isTemplatePath = location.pathname.includes('template');

  const title = isTemplatePath
    ? `Offer Letter Templates > ${letterType}`
    : `Offer Letters > Offer Letter ${letterType} ${candidateName}`;

  return (
    <>
      <TopBar title={title} user={user} />
      <Box flex={1}>
        <Box
          style={{
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
          }}
        >
          <OfferLetterHeader comments={comments} letterStatus={letterStatus} />
          <div
            dangerouslySetInnerHTML={{ __html: offerLetter }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '80px 111px 0 111px',
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default OfferLetterView;
