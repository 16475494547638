/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Stack,
  Typography,
  Select,
  MenuItem,
  styled,
  TextField,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import ShortPrimaryBtn from '../buttons/ShortPrimaryBtn';
import ShortSecondaryBtn from '../buttons/ShortSecondaryBtn';
import { styleProps } from '../../styles/styleProps';

import { EmployeeType } from '../../enums/Employee';
import { getAllCurrencies } from '../../services/CurrencyService';

const { fonts, fontSizes, colors } = styleProps;

const style = {
  fontFamily: fonts.primary,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  background: '#FFFFFF',
  borderRadius: '8px',
  width: 623,
};

const EmployeeName = styled(Typography)({
  color: '#000000',
  marginLeft: '3%',
  fontWeight: 'bold',
  fontSize: '20px',
});

const EmployeeId = styled(Typography)({
  marginTop: '2%',
  color: colors.textLightGrey,
  marginLeft: '3%',
  fontWeight: 400,
  fontSize: fontSizes.normal,
});

const stackStyle = {
  marginY: 5,
  alignItems: 'right',
  justifyContent: 'right',
  marginRight: '4%',
};

const TextInput = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const CustomSelect = styled(Select)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 50,
  },
});

const menuItemStyles = {
  m: 0.5,
  '&:focus': {
    background: colors.btnPrimary,
    color: 'white',
    m: 0.5,
    borderRadius: 1,
    '&:hover': {
      background: colors.btnPrimary,
      color: 'white',
      m: 0.5,
      borderRadius: 1,
    },
  },
};
const Title = styled(Typography)({
  color: '#2A2A2A',
  fontWeight: 500,
  fontSize: '20px',
  padding: '3%',
});

const CloseButton = styled(Button)({
  backgroundColor: 'none',
  '&:hover': {
    backgroundColor: '#FFDFAC',
  },
  color: '#2A2A2A',
  marginTop: 0,
});

const EmployeeCurrencyEdit = ({ modalStatus, onClose, data }) => {
  const [companyCurrency, setCompanyCurrency] = useState([]);
  const [isEmptyBasicFields, setIsEmptyBasicFields] = useState(true);

  const [invalidSalary, setInvalidSalary] = useState(false);
  const [invalidDesignation, setInvalidDesignation] = useState(false);

  const initialValues = {
    employee: {
      salary: data.employeeSalary,
      designation: data.employeeDesignation,
      currency: data.Currency.currencyDisplayId,
      type: data.employeeType,
    },
  };

  const validationSchema = Yup.object({
    companyData: Yup.object().shape({
      salary: Yup.string(),
      designation: Yup.string(),
      currency: Yup.string(),
      type: Yup.string(),
    }),
  });

  const onSubmit = () => {
    try {
      const email = data.employeeWorkEmail;
      const employeeId = data.employeeDisplayId;
      const salary = formik.values.employee.salary;
      const designation = formik.values.employee.designation;
      const currency = formik.values.employee.currency;
      const type = formik.values.employee.type;
      const body = {
        email,
        employeeId,
        salary: salary.toString(), // backend expects string as salary
        designation,
        currency,
        type,
        status: 'INVITED',
      };
      axios
        .put('/api/employees/update', body, {
          headers: {
            Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
          },
        })
        .then(
          (response) => {
            if (response.data.success === true) {
              onClose();
            }
          },
          (error) => {
            if (error.response.status === 401) {
              localStorage.setItem('sessionValid', 'false');
              window.location = '/login';
            } else {
              window.location = '/error';
            }
          },
        );
    } catch (err) {
      console.error(err.message);
      window.location = '/error';
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    let res = checkInputFields(formik.values.employee);
    setIsEmptyBasicFields(res);
  }, [formik.values]);

  //Check for empty fields
  const checkInputFields = (obj) => {
    for (var key in obj) {
      if (!obj[key] || obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  // display error message
  const displayAlert = (isError, errorMessage) => {
    if (isError) {
      return (
        <Box sx={{ my: 1 }}>
          <Alert severity='error'>{errorMessage}</Alert>
        </Box>
      );
    }
  };

  useEffect(() => {
    getAllCurrencies().then((data) => {
      setCompanyCurrency(data);
    });
  }, []);

  return (
    <>
      {data ? (
        <div>
          <Modal open={modalStatus} onClose={onClose}>
            <Box sx={style}>
              <Stack justifyContent='space-between' direction='row'>
                <Title>Edit Employee</Title>
                <CloseButton onClick={onClose}>
                  <CloseIcon />
                </CloseButton>
              </Stack>
              <hr style={{ border: '1px solid #E5E5E5' }}></hr>

              <Stack justifyContent='space-between' direction='row'>
                <EmployeeId>Employee ID : {data.employeeEmployeeId}</EmployeeId>
              </Stack>

              <Stack justifyContent='space-between' direction='row'>
                <EmployeeName>
                  {(data.employeeFirstName ?? '') +
                    ' ' +
                    (data.employeeLastName ?? '')}
                </EmployeeName>
              </Stack>

              <br />
              <Box sx={{ width: 'auto', marginX: '3%' }}>
                <TextInput
                  name='employee.salary'
                  type='number'
                  placeholder='Salary*'
                  value={formik.values.employee.salary}
                  sx={{ marginTop: 2 }}
                  InputLabelProps={{
                    style: { fontSize: 14 },
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setInvalidSalary(false);
                  }}
                  onBlur={() =>
                    !formik.values.employee.salary && setInvalidSalary(true)
                  }
                />
                {displayAlert(invalidSalary, 'Invalid Salary')}

                <TextInput
                  name='employee.designation'
                  placeholder='Designation*'
                  value={formik.values.employee.designation}
                  sx={{ marginTop: 2 }}
                  InputLabelProps={{
                    style: { fontSize: 14 },
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setInvalidDesignation(false);
                  }}
                  onBlur={() =>
                    !formik.values.employee.designation &&
                    setInvalidDesignation(true)
                  }
                />
                {displayAlert(invalidDesignation, 'Invalid Designation')}

                <CustomSelect
                  placeholder='Currency'
                  name='employee.currency'
                  sx={{ marginTop: 2 }}
                  value={formik.values.employee.currency}
                  onChange={formik.handleChange}
                  displayEmpty
                >
                  {companyCurrency.map((item, index) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={index}
                      value={item.currencyDisplayId}
                    >
                      {item.currency}
                    </MenuItem>
                  ))}
                </CustomSelect>

                <CustomSelect
                  placeholder='Type'
                  name='employee.type'
                  sx={{ marginTop: 2 }}
                  value={formik.values.employee.type}
                  onChange={formik.handleChange}
                >
                  {Object.keys(EmployeeType).map((key) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={key}
                      value={EmployeeType[key]}
                    >
                      {key}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Box>

              <Stack spacing={1} direction='row' sx={stackStyle}>
                <ShortSecondaryBtn
                  text='Cancel'
                  onClick={onClose}
                  variant='contained'
                ></ShortSecondaryBtn>
                <ShortPrimaryBtn
                  onClick={formik.handleSubmit}
                  disabled={isEmptyBasicFields}
                  sx={{
                    marginLeft: '5px',
                    marginRight: '10%',
                  }}
                  variant='contained'
                  text='Update'
                ></ShortPrimaryBtn>
              </Stack>
            </Box>
          </Modal>
        </div>
      ) : null}
    </>
  );
};

export default EmployeeCurrencyEdit;
