import React from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import PropTypes from 'prop-types';

import { Stack } from '@mui/system';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const GenerateProjectReport = ({ report, handleErrors }) => {
  const fetchData = async () => {
    try {
      const fetchedData = await Promise.all(
        report.employees.map(async (employee) => {
          const { employeeId, time_record_ids } = employee;
          const timeRecordIds = time_record_ids.join(',');

          const response = await axios.get('/api/time-record-summary', {
            params: { id: employeeId, timeRecordIds },
            headers: {
              Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
            },
          });

          return {
            ...employee,
            responseData: response.data,
          };
        }),
      );
      const excelData = formatDataForExport(fetchedData);
      generateReport(excelData);
    } catch (error) {
      handleErrors();
      console.error('Error fetching data:', error);
    }
  };

  const formatDataForExport = (fetchedData) => {
    return fetchedData
      .flatMap((employee) =>
        employee.responseData.message.data.map((entry) => ({
          Date: entry.date || 'N/A',
          Ticket: entry.tickets || 'N/A',
          Type: entry.taskType || 'N/A',
          'Assign to': employee.name,
          'Hours Spent': entry.hours,
        })),
      )
      .sort((a, b) => new Date(a.Date) - new Date(b.Date));
  };

  const generateReport = (data) => {
    const headers = [
      { label: 'Date', key: 'Date' },
      { label: 'Ticket', key: 'Ticket' },
      { label: 'Type', key: 'Type' },
      { label: 'Assign to', key: 'Assign to' },
      { label: 'Hours Spent', key: 'Hours Spent' },
    ];

    const ws = XLSX.utils.json_to_sheet(data, {
      header: headers.map((header) => header.key),
    });

    // Add header labels to the first row
    const headerRow = headers.map((header) => header.label);
    XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

    // Apply styles to the header
    for (let i = 0; i < headerRow.length; i++) {
      const cellRef = XLSX.utils.encode_cell({ c: i, r: 0 });
      if (!ws[cellRef]) ws[cellRef] = {};
      ws[cellRef].s = {
        fill: {
          fgColor: { rgb: 'D3D3D3' },
        },
        font: {
          bold: true,
        },
      };
    }

    // Set column widths
    const columnWidths = [
      { wpx: 80 }, // Date
      { wpx: 80 }, // Ticket
      { wpx: 150 }, // Type
      { wpx: 100 }, // Assign to
      { wpx: 80 }, // Hours Spent
    ];

    ws['!cols'] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `Report_${report.projectName}_${report.reportType}`,
    );

    XLSX.writeFile(
      wb,
      `Report_${report.projectName}_${report.reportType}_W${
        report.period.week
      }_Y${report.period.year}_${new Date().toISOString().slice(0, 10)}.xlsx`,
    );
  };

  return (
    <Stack
      sx={{ padding: '2%' }}
      flexDirection={'row'}
      justifyContent={'right'}
    >
      <Button variant='text' onClick={fetchData}>
        <DownloadIcon sx={{ paddingRight: '4px' }} /> Download Report
      </Button>
    </Stack>
  );
};

export default GenerateProjectReport;

GenerateProjectReport.propTypes = {
  report: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleErrors: PropTypes.func.isRequired,
};
