import React from 'react';
import { LoadingButton as Button } from '@mui/lab';
import PropTypes from 'prop-types';
import { convertToRgb } from '../../utils/formatColor';
import { useTheme } from '@mui/system';

const BaseButton = (props) => {
  const {
    color = 'primary', // default color if not provided
    onClick = () => {},
    icon = null,
    fullWidth = false,
    disabled,
    variant = 'contained', // default variant if not provided
    loading,
    styles = {},
    children,
  } = props;
  const theme = useTheme();
  const isOutlined = variant === 'outlined';
  const isText = variant === 'text';
  const buttonProps = {
    loading: loading,
    variant: variant, // 'outlined' : 'contained'
    sx: {
      height: '50px',
      padding: '0 20px',
      margin: '0px',
      fontSize: '16px',
      fontWeight: '500',
      borderRadius: '100px',
      ...styles,
      width: fullWidth ? '100%' : styles.width || 'auto',
      textTransform: 'none', // disable auto uppercase
      color: isOutlined || isText ? color : 'white',
      borderColor: isOutlined ? color : 'transparent',
      backgroundColor: isOutlined ? 'transparent' : color,
      '&:hover': {
        backgroundColor: isOutlined
          ? `rgba(${convertToRgb(color, theme)}, 0.1)`
          : `rgba(${convertToRgb(color, theme)}, 0.8)`,
        borderColor: isOutlined ? color : 'transparent',
      },
    },
    onClick,
    disabled,
    ...props, // rest of the props passed to the component (e.g. type='submit')
  };

  return (
    <Button {...buttonProps} startIcon={icon || null}>
      {children}
    </Button>
  );
};

BaseButton.propTypes = {
  icon: PropTypes.node,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.arrayOf(['outlined', 'contained']),
  loading: PropTypes.bool,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default BaseButton;
