import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack, TextField } from '@mui/material';
import ApprovedCard from './report-card/ApprovedCard';
import ShortSecondaryBtn from '../buttons/ShortSecondaryBtn';
import ShortPrimaryBtn from '../buttons/ShortPrimaryBtn';

const MonthlyReport = ({ reports }) => {
  const [isLoading, setIsLoading] = useState(false);
  const saveSummary = () => {
    setIsLoading(true);
  };

  return (
    <>
      <Grid gap={4} display={'grid'} gridTemplateColumns={'repeat(2, 1fr)'}>
        <>
          {reports.map((report) => {
            return <ApprovedCard key={report.id} report={report} />;
          })}
        </>
      </Grid>

      {/* //TODO: Add Rich-text editor here */}
      <TextField
        label='Add project summary'
        multiline
        rows={6}
        variant='outlined'
        fullWidth
        onChange={(e) => {
          console.log(e.target.value);
        }}
        margin='normal'
      />
      <Stack
        sx={{ marginTop: '20px' }}
        flexDirection={'row'}
        justifyContent={'right'}
      >
        <ShortSecondaryBtn text={'Cancel'} onClick={() => {}} />
        <Box sx={{ width: '20px' }} />
        <ShortPrimaryBtn
          disabled={isLoading}
          text={isLoading ? 'Saving' : 'Save'}
          onClick={saveSummary}
        />
      </Stack>
    </>
  );
};

MonthlyReport.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MonthlyReport;
